import { Icon, Layout, Text } from '@ui-kitten/components';
import { View, StyleSheet, Platform } from 'react-native';
import React from 'react';
import moment from 'moment';

export default function StatusCard({ activity, level='1' }: any) {
  return (
    <Layout
      level={level}
      style={[
        styles.shadow,
        {
          padding: 16,
          borderWidth: 1,
          borderColor: Platform.OS === 'web' ? '#eee' : 'transparent',
          borderRadius: 4,
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={styles.headerWrap}>
          <Icon style={styles.icon} fill={'green'} name="bulb-outline" />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={[styles.headerDate]}>
              Status Change
            </Text>
          </View>
        </View>
      </View>
      {/* body */}
      <View style={{ paddingLeft: 8, paddingVertical: 8 }}>
        {Boolean(activity.status) && (
          <Text category="p1" style={{ paddingVertical: 8 }}>
            {activity.status}
          </Text>
        )}
        <Text category="label">
          Updated: {moment(activity.createdAt).format('lll')}
        </Text>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  headerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerDate: {
    marginLeft: 6,
    fontSize: 14,
  },
  icon: {
    width: 22,
    height: 22,
    marginRight: 4,
  },
  label: {
    marginTop: 4,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 3,
    maxWidth: 84,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labeltext: {},
  shadow: {
    shadowColor: '#c3bcae',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3,
  },
});
