import * as AuthSession from 'expo-auth-session';
import { isAfter } from 'date-fns';
import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';
// @ts-ignore
import { EXPO_PUBLIC_TENANT_ID, EXPO_PUBLIC_CLIENT_ID } from '@env';
import { secure } from '../../services/app/StorageService';

const config = {
  issuer: `https://login.microsoftonline.com/${EXPO_PUBLIC_TENANT_ID}/v2.0`,
  clientId: EXPO_PUBLIC_CLIENT_ID,
  redirectUrl: AuthSession.makeRedirectUri(),
  scopes: ['openid', 'offline_access', 'profile', 'mail.send'],
};

// eslint-disable-next-line import/prefer-default-export
export class AuthManager implements AuthenticationProvider {
  discovery: AuthSession.DiscoveryDocument = null;

  constructor() {
    this.discover();
  }

  async discover() {
    this.discovery = await AuthSession.fetchDiscoveryAsync(config.issuer);
  }

  async getAccessToken(): Promise<string> {
    const { accessToken } = await this.getAccessTokenAsync();
    return accessToken;
  }

  async signOutAsync() {
    // Clear storage
    await secure.remove('idToken');
    await secure.remove('refreshToken');
    await secure.remove('expireTime');
    const { idToken } = await this.getAccessTokenAsync();
    await AuthSession.revokeAsync({ token: idToken }, this.discovery);
  }

  async getAccessTokenAsync(): Promise<{ idToken: string, accessToken: string, error?: string }> {
    const expireTime = await secure.get('expireTime');
    const refreshToken = await secure.get('refreshToken');
    if (expireTime !== null && refreshToken !== null) {
      // Get expiration time - 5 minutes
      // If it's <= 5 minutes before expiration, then refresh
      const now = new Date();
      const expire = new Date(expireTime);
      expire.setMinutes(expire.getMinutes() - 5);
      if (isAfter(now, expire)) {
        return this.discover().then(() => AuthSession.refreshAsync({ ...config, refreshToken }, this.discovery).then(
          async (response) => {
            const {
              idToken, refreshToken: newRefreshToken, accessToken,
            } = response;
            const expireTimeDate = new Date();
            expireTimeDate.setHours(expireTimeDate.getHours() + 1);
            await secure.set('idToken', idToken);
            await secure.set('refreshToken', newRefreshToken);
            await secure.set('accessToken', accessToken);
            await secure.set('expireTime', expireTimeDate.toISOString());
            return { idToken, accessToken };
          },

        ).catch((error) => {
          console.log('error -----', error);
          return { error, idToken: null, accessToken: null };
        }).catch((error) => {
          console.log('error -----', error);
          return { error, idToken: null, accessToken: null };
        }));
      }
      // Not expired, just return saved access token
      const idToken = await secure.get('idToken');
      const accessToken = await secure.get('accessToken');
      if (idToken !== null && idToken !== undefined) {
        return { idToken, accessToken };
      }
    }
    return { idToken: null, accessToken: null };
  }
}
