import I18n from 'i18n-js';
import en from './locale/en.json';

I18n.locale = 'en';

I18n.fallbacks = true;
I18n.translations = {
  en,
};

export default function $t(key, params = {}) {
  return I18n.t(key, params);
}
