const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  successBackground: 'green',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  custGray: '#DCDCDC',
  // Ark Colors accordding to style guide
  appBackground: '#f4f7fa',
  // appBackground: 'rgba(249, 249, 249, 1)',
  background: 'white',
  primaryRed: '#E4312E',
  secondaryRed: '#9E2322',
  fontBlue: 'rgba(34, 73, 115, 1)',
  accentBlue: '#2F80ED',
  Refinance: 'lawngreen',
  Purchase: 'goldenrod',
  secondaryBlue: 'rgba(52, 107, 179, 1)',
  darkBlue: '#224973',
  blueGray: '#9BABB7',
  lightBlue: '#65a3d8',
  lightRed: '#e4312e',
  darkRed: '#9e2322',
  white: 'white',
  backgroundGray: '#E5E5E5',
  // WHITE AND BLACKS
  darkGrey: 'rgb(50, 50, 50)',
  lightDropShadow: 'rgb(150, 150, 150)',
  darkDropShadow: 'rgb(75, 75, 75)',
  altText: '#757B84',
  borderGrey: '#C4C4C4',
};
