import React, { useEffect } from 'react';
import { Icon, Layout, Text } from '@ui-kitten/components';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  eachDayOfInterval,
  endOfMonth,
  lastDayOfMonth,
  startOfMonth,
  sub,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import PointsBreakdown from '../../components-global/Home/PointsBreakdown';
import { navigation } from '../../../services/app/NavigationService';
import PointsByTeam from '../../components-global/PointsByTeam';
import { useGetStatsNoSecurityQuery } from '../../services/StatsSlice';
import PointsByLoTop5 from '../../components-global/PointsByLoTop5';
import { MobileNavigation } from '../../components-global/MobileNavigation';


type UserStat = {
  total: number;
  calls: number;
  advanced: number;
  apps: number;
  locks: number;
  user: string;
  team: string;
};

const teams = ['Platinum', 'Zelensky', 'Diamond', 'Tenacity'];
const userByTeam = [
  {
    name: 'Abraham Gluck',
    team: 'Diamond',
  },
  {
    name: 'David Friedman',
    team: 'Diamond',
  },
  {
    name: 'Mayer Weiss',
    team: 'Diamond',
  },
  {
    name: 'Boruch Spitzer',
    team: 'Diamond',
  },
  {
    name: 'Joe Nakach',
    team: 'Diamond',
  },
  {
    name: 'Lipa Kish',
    team: 'Platinum',
  },
  {
    name: 'Jack Mann',
    team: 'Platinum',
  },
  {
    name: 'Aaron Krishevsky',
    team: 'Platinum',
  },
  {
    name: 'Shloimy Tauber',
    team: 'Platinum',
  },
  {
    name: 'Chaim Guttman',
    team: 'Platinum',
  },
  {
    name: 'Nachum Kahan',
    team: 'Tenacity',
  },
  {
    name: 'Shlomo Ordentlich',
    team: 'Tenacity',
  },
  {
    name: 'Issac Weisz',
    team: 'Tenacity',
  },
  {
    name: 'Yonah Sabel',
    team: 'Tenacity',
  },
  {
    name: 'David Davidovitz',
    team: 'Tenacity',
  },
  {
    name: 'Shulem Ekstein',
    team: 'Zelensky',
  },
  {
    name: 'Yoel Ephraim Weiss',
    team: 'Zelensky',
  },
  {
    name: 'Yoel Lax',
    team: 'Zelensky',
  },
  {
    name: 'Gedalya Kish',
    team: 'Zelensky',
  },
  {
    name: 'Benyamin Sandel',
    team: 'Zelensky',
  },
];
const userGoals = {
  'David Friedman': 3,
  'Mayer Weiss': 3,
  'Abraham Gluck': 1,
  'Joe Nakach': 2,
  'Aaron Krishevsky': 2,
  'Shloimy Tauber': 2,
  'Jack Mann': 3,
  'Chaim Guttman': 1,
  'Lipa Kish': 3,
  'Issac Weisz': 2,
  'Nachum Kahan': 3,
  'Shlomo Ordentlich': 2,
  'Yonah Sabel': 1,
  'David Davidovitz': 1,
  'Gedalya Kish': 1,
  'Yoel Lax': 3,
  'Benyamin Sandel': 1,
  'Shulem Ekstein': 1,
  'Yoel Ephraim Weiss': 3,
};

function getWorkingDaysInMonth() {
  const days = eachDayOfInterval({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  }).filter((day) => [1, 2, 3, 4].includes(day.getDay()));
  return days.length;
}

export default function PointsScreen() {
  const [teamData, setTeamData] = React.useState(
    teams.map((team) => ({
      team,
      total: 0,
    }))
  );
  const [userData, setUserData] = React.useState(
    userByTeam.map((user) => ({
      user: user.name,
      total: 0,
      calls: 0,
      advances: 0,
      apps: 0,
      locks: 0,
      team: user.team,
    }))
  );

  const { data, isSuccess } = useGetStatsNoSecurityQuery({});

  const daysInMonth = getWorkingDaysInMonth();
  const goalsByTeam = {};
  userByTeam.forEach((user) => {
    if (!goalsByTeam[user.team]) {
      goalsByTeam[user.team] = 0;
    }
    goalsByTeam[user.team] += userGoals[user.name] * daysInMonth;
  });

  useEffect(() => {
    if (isSuccess) {
      const filterDate = startOfMonth(new Date());
      const localData = {};
      teams.forEach((team) => {
        localData[team] = {
          total: 0,
          team,
        };
      });

      const byUser = {};
      Object.keys(userGoals).forEach((user) => {
        byUser[user] = { ...userData.find((u) => u.user === user) };
      });

      data.loans.forEach((loan) => {
        if (!byUser[loan.loan_officer]) {
          return;
        }
        if (
          loan.application_date &&
          utcToZonedTime(loan.application_date, '') > filterDate
        ) {
          byUser[loan.loan_officer].apps += 1;
          byUser[loan.loan_officer].total += 1;
        }
        if (loan.lock_date && utcToZonedTime(loan.lock_date, '') > filterDate) {
          byUser[loan.loan_officer].locks += 1;
          byUser[loan.loan_officer].total += 1;
        }
      });

      data.tasks.forEach((task) => {
        if (!byUser[task.loan_officer]) {
          return;
        }
        if (
          task.completed_at &&
          utcToZonedTime(task.completed_at, '') > filterDate &&
          task.subject === 'Sales Call'
        ) {
          byUser[task.loan_officer].calls += 1;
        }
        if (
          task.completed_at &&
          utcToZonedTime(task.completed_at, '') > filterDate &&
          task.advanced
        ) {
          byUser[task.loan_officer].advances += 1;
          byUser[task.loan_officer].total += 1;
        }
      });

      Object.values(byUser).forEach((user: UserStat) => {
        if (!user.team) {
          return;
        }
        const totalCalls = Math.floor(user.calls / 10);
        user.calls = totalCalls;
        user.total += totalCalls;
        if (Object.keys(user).length === 0) {
          return;
        }
        localData[user.team].total += user.total;
      });

      const byTeam: { team: string; total: number; percent: number }[] =
        Object.values(localData);
      byTeam.forEach((team) => {
        team.percent = (team.total / goalsByTeam[team.team]) * 100;
      });
      byTeam.sort((a, b) => a.total - b.total);
      byTeam.reverse();
      setTeamData(byTeam);
      setUserData(Object.values(byUser));
      console.log('byUser', byUser);
      console.log('byTeam', byTeam);
    }
  }, [data, isSuccess]);

  const header = () =>
    Platform.OS === 'web' ? (
      <Layout level="1" style={[styles.shadow, styles.layoutContainer]}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Pressable
            style={{ marginRight: 32 }}
            onPress={() => navigation.goBack()}
          >
            <Icon
              style={[{ width: 32, height: 32 }]}
              name="arrow-ios-back-outline"
              fill="red"
            />
          </Pressable>
          <View>
            <Text category="h3">Points</Text>
            <Text appearance={'hint'} category="c1">
              Some of the numbers get updated every 15 minutes, so if you just
              did something, it might take a few minutes to show up here.
            </Text>
          </View>
        </View>
      </Layout>
    ) : (
      <MobileNavigation
        showBack
        disableSearch
        title="Points"
        totalItems={null}
      />
    );

  return (
    <Layout style={{ flex: 1 }} level="2">
      {header()}
      <ScrollView style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: Platform.OS === 'web' ? 'row' : 'column',
            flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap',
            padding: 16,
            justifyContent: 'space-around',
            marginVertical: 32,
          }}
        >
          <PointsBreakdown />
          <PointsByTeam teamData={teamData} />
          <PointsByLoTop5 userData={userData} />
        </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  layoutContainer: {
    width: '100%',
    height: 79,
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  headerText: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});
