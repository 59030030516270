import { Icon } from '@ui-kitten/components';
import React from 'react';

export const FirstPage = (props) => (
  <Icon {...props} name="arrowhead-left-outline" />
);
export const LastPage = (props) => (
  <Icon {...props} name="arrowhead-right-outline" />
);
export const NextPage = (props) => (
  <Icon {...props} name="arrow-ios-forward-outline" />
);
export const PrevPage = (props) => (
  <Icon {...props} name="arrow-ios-back-outline" />
);
export const CheckmarkIcon = (props) => <Icon {...props} size='large' name="checkmark-outline" />;
export const DownloadIcon = (props) => <Icon {...props} name="cloud-download-outline" />;
export const ShareIcon = (props) => <Icon {...props} name="share-outline" />;
export const StarIcon = (props) => <Icon {...props} name="star" />;
export const PhoneIcon = (props) => <Icon {...props} name="phone-outline" />;
export const NoteIcon = (props) => <Icon {...props} name="book-open-outline" />;
export const EmailIcon = (props) => <Icon {...props} name="email-outline" />;
export const CloseIcon = (props) => <Icon {...props} name="close-outline" />;
export const TrashIcon = (props) => <Icon {...props} name="trash-2-outline" />;
export const EventIcon = (props) => <Icon {...props} name="calendar" />;
export const TaskIcon = (props) => <Icon {...props} name="clipboard-outline" />;
export const SearchIcon = (props) => <Icon {...props} name="search-outline" />;
export const FileIcon = (props) => <Icon {...props} name="file-add-outline" />;
export const UploadIcon = (props) => <Icon {...props} name="upload-outline" />;
export const PeopleIcon = (props) => <Icon {...props} name="people" />;
export const BackIcon = (props) => <Icon {...props} name="arrow-back" />;
export const SettingsIcon = (props) => (
  <Icon {...props} fill="#8F9BB3" name="settings-outline" />
);
export const EditIcon = (props) => <Icon {...props} name="edit" />;

export const MenuIcon = (props) => (
  <Icon {...props} fill="white" name="more-vertical" />
);

export const InfoIcon = (props) => <Icon {...props} name="info" />;

export const LogoutIcon = (props) => <Icon {...props} name="log-out" />;
