export const phoneFormat = (text) => {
  const cleaned = ('' + text).replace(/\D/g, '');
  const match1 = cleaned.match(/^(\d{3})$/);
  const match2 = cleaned.match(/^(\d{3})(\d{3})$/);
  const match3 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  let number = []
  if(match1){
    number.push(match1[1] + '-')
  }
  if(match2){
    number.push(match2[1] + '-' + match2[2] + '-')
  }
  if(match3){
    number.push(match3[1] + '-' + match3[2] + '-' + match3[3])
  }
  return number.length ? number.join('') : text
};

export const taxpayerIdFormat = (text) => {
  const cleaned = ('' + text).replace(/\D/g, '');
  const match1 = cleaned.match(/^(\d{3})$/);
  const match2 = cleaned.match(/^(\d{3})(\d{2})$/);
  const match3 = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  let number = []
  if(match1){
    number.push(match1[1] + '-')
  }
  if(match2){
    number.push(match2[1] + '-' + match2[2] + '-')
  }
  if(match3){
    number.push(match3[1] + '-' + match3[2] + '-' + match3[3])
  }
  return number.length ? number.join('') : text

};

export const dateOfBirthFormat = (text) => {
  const cleaned = ('' + text).replace(/\D/g, '');
  const match1 = cleaned.match(/^(\d{2})$/);
  const match2 = cleaned.match(/^(\d{2})(\d{2})$/);
  const match3 = cleaned.match(/^(\d{2})(\d{2})(\d{4})$/);
  let number = []
  if(match1){
    number.push(match1[1] + '/')
  }
  if(match2){
    number.push(match2[1] + '/' + match2[2] + '/')
  }
  if(match3){
    number.push(match3[1] + '/' + match3[2] + '/' + match3[3])
  }
  return number.length ? number.join('') : text

};