import React from 'react';
import { Input, InputProps } from '@ui-kitten/components';
import { currencyFormat } from '../helpers/dataTransformerHelper';

export interface MaskedInputProps extends InputProps {
  mask: 'currency' | 'percentage' | 'fullPercentage' | 'phone' | 'ssn' | 'zip' | 'date' | 'time' | 'datetime' ;
}
export default function MaskedInput(props: MaskedInputProps) {
  const { onChangeText, value, mask, ...rest } = props;

  const onChange = (nextValue) => {
    switch (mask) {
      case 'currency':
        console.log(nextValue, 'NEXT VALUE')
        const cleanedValue = nextValue.replace(/[$,]/g, '')
        console.log(cleanedValue, 'CLEANED')
        onChangeText(cleanedValue);
        return;
      default:
        onChangeText(nextValue);
    }
  };

  const displayFormat = (val) => {
    switch (mask) {
      case 'currency':
        return currencyFormat(val);
      case 'percentage':
        return `${(val * 100).toFixed(2)}%`;
        case 'fullPercentage':
          return `${(val).toFixed(2)}%`;
      default:
        return val;
    }
  };

  return (
    <Input {...rest} onChangeText={onChange} value={displayFormat(value)} />
  );
}
