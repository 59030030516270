import React from 'react';
import { Platform, View } from 'react-native';
import {
  Avatar,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
import Colors from '../../helpers/Colors';
import { TouchableWebElement } from '@ui-kitten/components/devsupport';
import { BackIcon } from '../../components-ui/Icons';

type Props = {
  heading: string;
  subHeading?: string;
  style?: any;
};

const Header = (props: Props) => {
  const { heading, subHeading } = props;
  if (Platform.OS === 'web') {
    return (
      <View
        style={{
          backgroundColor: Colors.darkBlue,
          paddingTop: 8,
          paddingBottom: 8,
          maxHeight:  96 ,
          marginTop: -32,
          marginHorizontal: -32,
          paddingHorizontal: 16,
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            style={{ marginRight: 16, position: 'absolute', left: 0 }}
            size={Platform.OS === 'web' ? 'giant' : 'small'}
            source={require('../../../assets/images/ark.png')}
          />
          <Text
            style={{ color: 'white', textAlign: 'center', paddingVertical: 8 }}
            category={Platform.OS === 'web' ? 'h4' : 'h6'}
          >
            {props.heading}
          </Text>
        </View>
        <Text style={{ color: 'white' }} category="s1">
          {props.subHeading || ''}
        </Text>
      </View>
    );
  }

  const renderTitle = (props): React.ReactElement => (
    <View
      style={{
        flexDirection: 'row',
        width: '120%',
        paddingVertical: 16,
        marginTop: -40,
        marginHorizontal: -32,
        backgroundColor: Colors.darkBlue,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
      }}
    >
      <Avatar
        style={{ marginLeft: 32, marginRight: 16, position: 'absolute', left: 0 }}
        size={'small'}
        source={require('../../../assets/images/ark.png')}
      />
      <Text
        style={{ fontFamily: 'VerbBold', color: 'white', textAlign: 'center' }}
      >
        {heading}
      </Text>
    </View>
  );

  return (
    <View>
      <TopNavigation
        appearance="control"
        alignment="center"
        title={renderTitle}
        subtitle={props.subHeading || ''}

      />
    </View>
  );
};

export default Header;
