import { endOfMonth, startOfMonth, sub } from 'date-fns';
import { Stat } from '../interfaces/StatsInterfaces';

export const currentAnnualIncome: Stat = {
  dataType: 'currentAnnualIncome',
  resource: 'loan',
  filters: [
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'purchasedDate',
      value: '',
      matchType: 'notEqual',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: sub(new Date(), { months: 12 }).toISOString(),
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: startOfMonth(new Date()).toISOString(),
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'SUM',
      column: 'loCommission',
      alias: 'currentAnnualIncome',
    },
  ],
};

export const estimatedAnnualIncome: Stat = {
  dataType: 'estimatedAnnualIncome',
  resource: 'loan',
  filters: [
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: sub(startOfMonth(new Date()), { months: 6 }).toISOString(),
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: endOfMonth(sub(new Date(), { months: 1 })).toISOString(),
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'SUM',
      column: 'loCommission',
      alias: 'estimatedAnnualIncome',
    },
  ],
};

export const lastYearsIncome: Stat = {
  dataType: 'lastYearsIncome',
  resource: 'loan',
  filters: [
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'purchasedDate',
      value: '',
      matchType: 'notEqual',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: sub(new Date(), { months: 12 }).toISOString(),
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: endOfMonth(sub(new Date(), { months: 1 })).toISOString(),
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'SUM',
      column: 'loCommission',
      alias: 'lastYearsIncome',
    },
  ],
};

export const countOfApplications = (
  type: string,
  fromDate: Date,
  toDate: Date
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'applicationDate',
      value: fromDate.toISOString(),
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: toDate.toISOString(),
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: type,
    },
  ],
});

export const closedLoans = (
  type: string,
  fromDate: Date,
  toDate: Date
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'phase',
      value: 'Closed Loan',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: fromDate.toISOString(),
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: toDate.toISOString(),
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'units',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'amount',
    },
  ],
});

export const applications = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'applicationDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: toDate,
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'units',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'amount',
    },
  ],
});

export const lockedLoansByStage = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'lockStatus',
      value: 'Locked',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: '',
      matchType: 'notEqual',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'applications',
    },
  ],
  groupBy: {
    column: 'stage',
    alias: 'stage',
  },
});

export const applicationsByStage = (type: string): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'lockStatus',
      value: 'Locked',
      matchType: 'notEqual',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: '',
      matchType: 'notEqual',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'applications',
    },
  ],
  groupBy: {
    column: 'stage',
    alias: 'stage',
  },
});

export const closedLoansTrend = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'phase',
      value: 'Closed Loan',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: toDate,
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'count',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'loanAmount',
    },
    {
      type: 'MONTH',
      column: 'closeDate',
      alias: 'monthIndex',
    },
    {
      type: 'YEAR',
      column: 'closeDate',
      alias: 'year',
    },
  ],
  groupBy: {
    column: 'closeDate',
    alias: 'closeDate',
  },
});

export const applicationsTrend = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'applicationDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: toDate,
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'count',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'loanAmount',
    },
    {
      type: 'MONTH',
      column: 'applicationDate',
      alias: 'monthIndex',
    },
    {
      type: 'YEAR',
      column: 'applicationDate',
      alias: 'year',
    },
  ],
  groupBy: {
    column: 'applicationDate',
    alias: 'applicationDate',
  },
});

export const closedLoanCommissionAmount = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'purchasedDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'purchasedDate',
      value: toDate,
      matchType: 'lessThan',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'count',
    },
    {
      type: 'SUM',
      column: 'loCommission',
      alias: 'loCommission',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'loanAmount',
    },
  ],
});

export const closedLoanAmount = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'phase',
      value: 'Closed Loan',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeStatus',
      value: 'Won',
      matchType: 'equals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'closeDate',
      value: toDate,
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'count',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'loanAmount',
    },
  ],
});

export const applicationLoanAmount = (
  type: string,
  fromDate: string,
  toDate: string
): Stat => ({
  dataType: type,
  resource: 'loan',
  filters: [
    {
      column: 'applicationDate',
      value: '',
      matchType: 'notEqual',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: fromDate,
      matchType: 'greaterThanOrEquals',
      precision: 'exact',
    },
    {
      column: 'applicationDate',
      value: toDate,
      matchType: 'lessThanOrEquals',
      precision: 'exact',
    },
  ],
  aggregates: [
    {
      type: 'COUNT',
      column: 'id',
      alias: 'count',
    },
    {
      type: 'SUM',
      column: 'loanAmount',
      alias: 'loanAmount',
    },
  ],
});
