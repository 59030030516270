import { Layout } from "@ui-kitten/components";
import { View, ScrollView, Dimensions } from "react-native";
import ActivityIndicatorComponent from "../../../components/Universal/ActivityIndicatorComponent";
import { StatusCard } from "../../components-ui";

const LeadStatusLogsColumn = ({ leadStatusLogs, isLoadingStatusLogs }) => {
  return (
    <Layout
      level="2"
      style={{
        flex: 2,
        borderLeftWidth: 1,
        width: '100%',
        borderLeftColor: '#aaa',
      }}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        {/* <Text category="h5" style={{marginBottom: 16}}>Status Logs</Text> */}
        <ScrollView style={{ width: '100%' }}>
          {leadStatusLogs
            ? leadStatusLogs.leadStatusLogs.map((log) => {
                return <StatusCard key={log.id} activity={log} level="1" />;
              })
            : null}
          {isLoadingStatusLogs ? (
            <View
              style={{
                flex: 1,
                minHeight: Dimensions.get('screen').height - 80,
              }}
            >
              <ActivityIndicatorComponent />
            </View>
          ) : null}
        </ScrollView>
      </View>
    </Layout>
  );
};

export default LeadStatusLogsColumn