import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory-native';
import { Platform, View } from 'react-native';
import { useTheme, Text } from '@ui-kitten/components'
import moment from 'moment';
import Colors from '../../../src/helpers/Colors';
import ActivityIndicatorComponent from '../../Universal/ActivityIndicatorComponent';

function ClosedLoansChart({ prevTrend, currTrend }) {
  let point;
  const theme = useTheme()
  const getSlope = (xValues, trend) => {
    const n = xValues.length;
    let sumOfXValues = 0;
    let sumOfXSquaredValues = 0;
    let sumOfYValues = 0;
    let sumOfXTimesYValues = 0;

    xValues.map((value) => {
      sumOfXValues += value;
      sumOfXSquaredValues += value * value;
    });

    Object.keys(trend).forEach((key, index) => {
      if (key === 'year') return; // skip when key is year
      if (index >= n) return; // only look at length of data points
      sumOfYValues += trend[key].loanAmount;
      sumOfXTimesYValues += xValues[index] * trend[key].loanAmount;
    });

    return (
      (n * sumOfXTimesYValues - sumOfXValues * sumOfYValues) /
      (n * sumOfXSquaredValues - sumOfXValues * sumOfXValues)
    );
  };

  const getYIntercept = (slope, xValues, trend) => {
    const n = xValues.length;
    let sumOfXValues = 0;
    let sumOfYValues = 0;

    Object.keys(trend).forEach((key, index) => {
      if (key === 'year') return; // Skip when key is year
      if (index >= n) return; // only look at length of data points
      sumOfXValues += xValues[index];
      sumOfYValues += trend[key].loanAmount;
    });

    return (sumOfYValues - slope * sumOfXValues) / n;
  };

  const getTrendLine = (trend, x) => {
    const months = new Date().getMonth(); // 0 index month
    const xValues = [];
    for (let i = 0; i <= months; i++) {
      xValues.push(i + 1);
    }
    const slope = getSlope(xValues, trend);
    const yIntercept = getYIntercept(slope, xValues, trend);

    if (isNaN(slope) || isNaN(yIntercept)) return 0;

    point = slope * x + yIntercept;
    if (point <= 0) {
      point = null;
    }
    return point;
    /* console.log('SLOPE: ',slope,'Y-INTERCEPT',yIntercept,'EQUATION: ',trendEquation); */
  };

  return (
    <>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            voronoiBlacklist={['line']}
            labels={({ datum }) =>
              `${datum.x}: ${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'symbol',
              }).format(datum.y)}`
            }
            labelComponent={<VictoryTooltip />}
          />
        }
        domainPadding={{ x: 50 }}
        horizontal={Platform.OS !== 'web'}
        width={Platform.OS === 'web' ? 1920 : 300}
        height={Platform.OS === 'web' ? 500 : 450}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={
            Platform.OS === 'web'
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
              : [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
          }
          tickFormat={
            Platform.OS === 'web'
              ? [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ]
              : [
                'Dec',
                'Nov',
                'Oct',
                'Sep',
                'Aug',
                'Jul',
                'Jun',
                'May',
                'Apr',
                'Mar',
                'Feb',
                'Jan',
              ]
          }
          style={{ tickLabels: { fontFamily: 'VerbBold' } }}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => {
            if (String(x).length >= 6) {
              return `$${x / 1000000}M`;
            }
            return `$${x / 1000}K`;
          }}
          style={{ tickLabels: { fontFamily: 'VerbBold' } }}
        />

        <VictoryGroup
          offset={Platform.OS === 'web' ? 48 : 10}
          style={{ data: { width: Platform.OS === 'web' ? 15 : 10 } }}
          colorScale={[theme['color-info-500'], theme['color-warning-500']]}
        >
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            barWidth={Platform.OS === 'web' ? 48 : 14}
            data={
              Platform.OS === 'web'
                ? [
                  { x: 'Jan', y: prevTrend.Jan.loanAmount },
                  { x: 'Feb', y: prevTrend.Feb.loanAmount },
                  { x: 'Mar', y: prevTrend.Mar.loanAmount },
                  { x: 'Apr', y: prevTrend.Apr.loanAmount },
                  { x: 'May', y: prevTrend.May.loanAmount },
                  { x: 'Jun', y: prevTrend.Jun.loanAmount },
                  { x: 'Jul', y: prevTrend.Jul.loanAmount },
                  { x: 'Aug', y: prevTrend.Aug.loanAmount },
                  { x: 'Sep', y: prevTrend.Sep.loanAmount },
                  { x: 'Oct', y: prevTrend.Oct.loanAmount },
                  { x: 'Nov', y: prevTrend.Nov.loanAmount },
                  { x: 'Dec', y: prevTrend.Dec.loanAmount },
                ]
                : [
                  { x: 'Dec', y: prevTrend.Dec.loanAmount },
                  { x: 'Nov', y: prevTrend.Nov.loanAmount },
                  { x: 'Oct', y: prevTrend.Oct.loanAmount },
                  { x: 'Sep', y: prevTrend.Sep.loanAmount },
                  { x: 'Aug', y: prevTrend.Aug.loanAmount },
                  { x: 'Jul', y: prevTrend.Jul.loanAmount },
                  { x: 'Jun', y: prevTrend.Jun.loanAmount },
                  { x: 'May', y: prevTrend.May.loanAmount },
                  { x: 'Apr', y: prevTrend.Apr.loanAmount },
                  { x: 'Mar', y: prevTrend.Mar.loanAmount },
                  { x: 'Feb', y: prevTrend.Feb.loanAmount },
                  { x: 'Jan', y: prevTrend.Jan.loanAmount },
                ]
            }
          />
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            barWidth={Platform.OS === 'web' ? 48 : 14}
            data={
              Platform.OS === 'web'
                ? [
                  { x: 'Jan', y: currTrend.Jan.loanAmount },
                  { x: 'Feb', y: currTrend.Feb.loanAmount },
                  { x: 'Mar', y: currTrend.Mar.loanAmount },
                  { x: 'Apr', y: currTrend.Apr.loanAmount },
                  { x: 'May', y: currTrend.May.loanAmount },
                  { x: 'Jun', y: currTrend.Jun.loanAmount },
                  { x: 'Jul', y: currTrend.Jul.loanAmount },
                  { x: 'Aug', y: currTrend.Aug.loanAmount },
                  { x: 'Sep', y: currTrend.Sep.loanAmount },
                  { x: 'Oct', y: currTrend.Oct.loanAmount },
                  { x: 'Nov', y: currTrend.Nov.loanAmount },
                  { x: 'Dec', y: currTrend.Dec.loanAmount },
                ]
                : [
                  { x: 'Dec', y: currTrend.Dec.loanAmount },
                  { x: 'Nov', y: currTrend.Nov.loanAmount },
                  { x: 'Oct', y: currTrend.Oct.loanAmount },
                  { x: 'Sep', y: currTrend.Sep.loanAmount },
                  { x: 'Aug', y: currTrend.Aug.loanAmount },
                  { x: 'Jul', y: currTrend.Jul.loanAmount },
                  { x: 'Jun', y: currTrend.Jun.loanAmount },
                  { x: 'May', y: currTrend.May.loanAmount },
                  { x: 'Apr', y: currTrend.Apr.loanAmount },
                  { x: 'Mar', y: currTrend.Mar.loanAmount },
                  { x: 'Feb', y: currTrend.Feb.loanAmount },
                  { x: 'Jan', y: currTrend.Jan.loanAmount },
                ]
            }
          />
        </VictoryGroup>
        {Platform.OS === 'web' ? (
          <VictoryLine
            name="line"
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{ data: { stroke: theme['color-danger-500'] } }}
            data={[
              { x: 'Jan', y: getTrendLine(currTrend, 1) },
              { x: 'Feb', y: getTrendLine(currTrend, 2) },
              { x: 'Mar', y: getTrendLine(currTrend, 3) },
              { x: 'Apr', y: getTrendLine(currTrend, 4) },
              { x: 'May', y: getTrendLine(currTrend, 5) },
              { x: 'Jun', y: getTrendLine(currTrend, 6) },
              { x: 'Jul', y: getTrendLine(currTrend, 7) },
              { x: 'Aug', y: getTrendLine(currTrend, 8) },
              { x: 'Sep', y: getTrendLine(currTrend, 9) },
              { x: 'Oct', y: getTrendLine(currTrend, 10) },
              { x: 'Nov', y: getTrendLine(currTrend, 11) },
              { x: 'Dec', y: getTrendLine(currTrend, 12) },
            ]}
          />
        ) : null}
      </VictoryChart>
      <View
        style={{
          flexDirection: 'column',
          alignSelf: 'center',
          borderWidth: 1,
          borderColor: Colors.custGray,
          borderRadius: 8,
          padding: 10,
          marginRight: 10,
          marginLeft: -25,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
          }}
        >
          <Text style={{ fontFamily: 'VerbBold', fontSize: 18 }} category='label' status='info'>{prevTrend.Jan.year}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
          }}
        >
          <Text style={{ fontFamily: 'VerbBold', fontSize: 18 }} category='label' status='warning'>{currTrend.Jan.year}</Text>
        </View>
      </View>
    </>
  );
}

export default ClosedLoansChart;
