import { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
  Platform,
  Keyboard,
  ScrollView,
} from 'react-native';
import { Card, Input, Layout, Text } from '@ui-kitten/components';
import { Client } from '@microsoft/microsoft-graph-client';
import Footer from '../Footer';
import Header from '../Header';
import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';
import Colors from '../../../helpers/Colors';
import ActivityIndicatorComponent from '../../../../components/Universal/ActivityIndicatorComponent';
import { AuthManager } from '../../../auth/AuthManager';

function BulkEmailForm({ cancelAction, defaultData }: any) {
  const [error, setError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [sentCount, setSentCount] = useState(0);

  const [state, setState] = useState<any>({
    subject: '',
    body: '',
  });

  let client;
  async function sendEmail({ email, subject, body }) {
    if (!client) {
      client = Client.initWithMiddleware({
        authProvider: new AuthManager(),
      });
    }

    const message = {
      toRecipients: [
        {
          emailAddress: {
            address: email,
          },
        },
      ],
      subject,
      body: {
        content: body,
        contentType: 'Text',
      },
    };
    const response = await client.api('/me/sendMail').post({ message });
    return response;
  }
  const windowWidth = Dimensions.get('window').width;

  async function handleSubmit() {
    try {
      setError('');
      setIsSending(true);
      console.log(defaultData);
      for (let i = 0; i < defaultData.contactList.length; i++) {
        const contact = defaultData.contactList[i];
        if (contact.email) {
          await sendEmail({
            email: contact.email,
            subject: state.subject,
            body: state.body,
          });
          setSentCount(i + 1);
        }
      }
      setTimeout(() => {
        setIsComplete(true);
      }, 1000);
    } catch (error) {
      setIsSending(false);
      setError(error.message);
    }
  }

  useEffect(() => {
    if (isComplete) {
      setTimeout(() => {
        cancelAction();
      }, 3000);
    }
  }, [isComplete]);

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading="Send Bulk Email" />
      {defaultData?.contactList ? (
        <View>
          <Text style={{ textAlign: 'center' }} category="label">
            Sending email to {defaultData.contactList.length}{' '}
            {defaultData.contactList[0]['@id'].split('/')[1].slice(0, -1)}
            (s)
          </Text>
        </View>
      ) : null}
      {/* description */}
      <FormGroup label="Subject">
        <Input
          onBlur={() => Keyboard.dismiss()}
          placeholder=""
          value={state.subject || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, subject: e }))
          }
        />
      </FormGroup>
      <FormGroup label="Body">
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline
          textStyle={{ minHeight: 184 }}
          placeholder=""
          value={state.body || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, body: e }))
          }
        />
      </FormGroup>
      {isSending ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View style={{ maxHeight: 40, marginBottom: 24 }}>
            <ActivityIndicatorComponent />
          </View>
          <Text category="label" style={{ fontSize: 18 }}>
            Emails sent: {sentCount}
          </Text>
        </View>
      ) : null}
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {isComplete ? <FormSuccess /> : null}

      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={isSending}
        submitText="SEND"
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default BulkEmailForm;
