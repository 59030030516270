import { Layout, Text } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

const FormGroup = ({ label, children, required = true }: any) => (
  <Layout style={styles.layoutContainer}>
    <Layout style={styles.elementWrap}>
      {Boolean(label) && (
        <View style={{ flexDirection: 'row', height: 18 }}>
          {
            <Text
              style={{
                fontSize: Platform.OS === 'android' ? 10 : 12,
                fontFamily: 'VerbBold',
              }}
            >
              {label}
            </Text>
          }
          {required ? <Text style={{ color: 'red' }}> *</Text> : null}
        </View>
      )}
      {children}
    </Layout>
  </Layout>
);

const styles = StyleSheet.create({
  layoutContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 4,
  },
  elementWrap: {
    margin: 8,
    flex: 1,
    width: '100%',

    justifyContent: 'center',
  },
});

export default FormGroup;
