import { apiSlice } from './ApiSlice';

export const LeadApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLeads: build.query({
      query: (query) => `leads?${query}`,
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
            // Provides a tag for each lead in the current page,
            // as well as the 'PARTIAL-LIST' tag.
            ...result['hydra:member'].map(({ id }) => ({
              type: 'Leads',
              id,
            })),
            { type: 'Leads', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Leads', id: 'PARTIAL-LIST' }],
    }),
    getLead: build.query({
      query: (id) => `/leads/${id}`,
      providesTags: (result, error, id) => [{ type: 'Leads', id }],
    }),
    addNewLead: build.mutation({
      query: (body) => ({
        url: 'leads',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Leads', id: 'PARTIAL-LIST' }],
    }),
    updateLead: build.mutation({
      query: ({ id, ...put }) => ({
        url: `leads/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Leads', id }],
    }),
    deleteLead: build.mutation({
      query: (id) => ({
        url: `leads/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this lead `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getLeads` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, id) => [
        { type: 'Leads', id },
        { type: 'Leads', id: 'PARTIAL-LIST' },
      ],
    }),
    patchLead: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `leads/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Leads', id }, { type: 'Leads', id: 'PARTIAL-LIST' },
      ],
    }),
    getLeadActivities: build.query({
      query: ({ id, dateRangeOption }) =>
        `/leads/data/${id}/${dateRangeOption}`,
      providesTags: () => [{ type: 'LeadActivities', id: 'PARTIAL-LIST' }],
    }),
    inviteLeadTLC: build.mutation({
      query: (id) => ({ url: `/leads/invite/${id}`, method: 'POST', id }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Leads', id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
  useGetLeadQuery,
  useAddNewLeadMutation,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
  usePatchLeadMutation,
  useGetLeadActivitiesQuery,
  useInviteLeadTLCMutation,
} = LeadApiSlice;
