import React, { useEffect } from 'react';
import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
} from 'victory-native';
import { Card, useTheme, Text, Icon } from '@ui-kitten/components';
import { Dimensions, Platform, View } from 'react-native';
import { roundToNearestMinutes, startOfMonth } from 'date-fns';
import Svg, { G } from 'react-native-svg';
import {
  useGetJoinedStatsQuery,
  useGetStatsQuery,
} from '../../services/StatsSlice';
import { MatchType } from '../../interfaces/StatsInterfaces';
import { Link } from '../../components/Navigation/Link';
//@ts-ignore
import { useNavigation } from '../../navigation/router';
import routes from '../../navigation/routes';

const firstDateOfMonth = startOfMonth(new Date());

const chartSize = 350;
const chartWidth = Dimensions.get('window').width > 400 ? 350 : 275;

function ExternalLink(props) {
  return (
    <Icon
      {...props}
      name="external-link-outline"
      fill={props.theme['text-hint-color']}
      style={[
        { width: 20, height: 20 },
        props.hovered && {
          transform: [{ scale: 1.1 }],
          borderColor: props.theme['text-hint-color'],
          borderWidth: 1,
        },
      ]}
    />
  );
}

export default function PointsBreakdown({ isHomePage = false }) {
  const [data, setData] = React.useState([
    {
      category: 'Calls',
      amount: 0,
    },
    {
      category: 'Advances',
      amount: 0,
    },
    {
      category: 'Apps',
      amount: 0,
    },
    {
      category: 'Locks',
      amount: 0,
    },
  ]);
  const [hovered, setHovered] = React.useState(false);
  const navigation = useNavigation();
  const theme = useTheme();

  const { data: applicationsData, isSuccess: applicationsSuccess } =
    useGetStatsQuery({
      filter: {
        dataType: 'applicationsThisMonth',
        resource: 'Loan',
        filters: [
          {
            column: 'applicationDate',
            matchType: MatchType.greaterThanOrEquals,
            value: firstDateOfMonth.toISOString(),
            precision: 'exact',
          },
        ],
        aggregates: [
          { column: 'id', type: 'COUNT', alias: 'applicationsThisMonth' },
        ],
      },
    });
  const { data: locksData, isSuccess: locksSuccess } = useGetStatsQuery({
    filter: {
      dataType: 'locksThisMonth',
      resource: 'Loan',
      filters: [
        {
          column: 'lockDate',
          matchType: MatchType.greaterThanOrEquals,
          value: firstDateOfMonth.toISOString(),
          precision: 'exact',
        },
      ],
      aggregates: [{ column: 'id', type: 'count', alias: 'locksThisMonth' }],
    },
  });

  const { data: completedCallsData, isSuccess: completedCallsSuccess } =
    useGetJoinedStatsQuery({
      filter: [
        {
          dataType: 'completedSalesCallsThisMonth',
          resource: 'Task',
          filters: [
            {
              column: 'completedAt',
              matchType: MatchType.greaterThanOrEquals,
              value: firstDateOfMonth.toISOString(),
              precision: 'exact',
            },
            {
              column: 'type',
              matchType: MatchType.equals,
              value: 'Sales Call',
              precision: 'exact',
            },
          ],
          aggregates: [
            {
              column: 'id',
              type: 'count',
              alias: 'completedSalesCallsThisMonth',
            },
          ],
        },
        {
          dataType: 'completedSalesCallsThisMonth',
          resource: 'Event',
          filters: [
            {
              column: 'completedAt',
              matchType: MatchType.greaterThanOrEquals,
              value: firstDateOfMonth.toISOString(),
              precision: 'exact',
            },
            {
              column: 'subject',
              matchType: MatchType.equals,
              value: 'Sales Call',
              precision: 'exact',
            },
          ],
          aggregates: [
            {
              column: 'id',
              type: 'count',
              alias: 'completedSalesCallsThisMonth',
            },
          ],
        },
      ],
    });

  const { data: advanceData, isSuccess: advanceDataSuccess } =
    useGetJoinedStatsQuery({
      filter: [
        {
          dataType: 'advancesThisMonth',
          resource: 'Task',
          filters: [
            {
              column: 'completedAt',
              matchType: MatchType.greaterThanOrEquals,
              value: firstDateOfMonth.toISOString(),
              precision: 'exact',
            },
            {
              column: 'advanced',
              matchType: MatchType.equals,
              value: 'true',
              precision: 'exact',
            },
          ],
          aggregates: [
            { column: 'id', type: 'count', alias: 'advancesThisMonth' },
          ],
        },
        {
          dataType: 'advancesThisMonth',
          resource: 'Event',
          filters: [
            {
              column: 'completedAt',
              matchType: MatchType.greaterThanOrEquals,
              value: firstDateOfMonth.toISOString(),
              precision: 'exact',
            },
            {
              column: 'advanced',
              matchType: MatchType.equals,
              value: 'true',
              precision: 'exact',
            },
          ],
          aggregates: [
            { column: 'id', type: 'count', alias: 'advancesThisMonth' },
          ],
        },
      ],
    });

  useEffect(() => {
    const localData = [...data];

    if (completedCallsSuccess) {
      localData[0].amount = Math.round(
        completedCallsData.completedSalesCallsThisMonth / 10
      );
    }

    if (advanceDataSuccess) {
      localData[1].amount = advanceData.advancesThisMonth;
    }

    if (applicationsSuccess) {
      localData[2].amount = applicationsData.applicationsThisMonth;
    }

    if (locksSuccess) {
      localData[3].amount = locksData.locksThisMonth;
    }
    setData(localData);
  }, [
    completedCallsSuccess,
    advanceDataSuccess,
    applicationsSuccess,
    locksSuccess,
    completedCallsData,
    advanceData,
    applicationsData,
    locksData,
  ]);

  const colorScheme = [
    '#6794dc',
    '#6771dc',
    '#a367dc',
    '#dc67d1',
    '#dc67a3',
    '#dc6771',
    '#dc9467',
    '#dcb767',
  ];

  return (
    <Card
      onPress={() =>
        isHomePage && navigation.navigate(routes.ExtraRoutes.Points)
      }
      style={{
        flex: 1,
        minWidth: Platform.OS === 'web' ? 375 : 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: isHomePage ? 650 : 450,
      }}
      header={(props) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <Text category="h6">My Points This Month</Text>
        </View>
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Svg
        viewBox={`0 0 ${chartSize} ${chartSize}`}
        height={chartSize}
        width={chartWidth}
      >
        <VictoryPie
          standalone={false}
          height={chartSize}
          width={chartSize}
          data={data}
          innerRadius={chartSize / 4}
          labelRadius={chartSize / 3.5}
          style={{ labels: { fill: 'white' } }}
          x="category"
          y="amount"
          colorScale={colorScheme}
          labels={({ datum }) =>
            datum.amount > 0
              ? Platform.OS === 'web'
                ? `${datum.category}: ${String(Math.round(datum.amount))}`
                : `${String(Math.round(datum.amount))}`
              : ''
          }
          labelComponent={
            Platform.OS === 'web' ? (
              <VictoryTooltip
                constrainToVisibleArea
                flyoutStyle={{ fill: '#000', zIndex: 9999 }}
                flyoutPadding={16}
              />
            ) : (
              <VictoryLabel />
            )
          }
          animate={{ duration: 500, easing: 'bounce' }}
          theme={VictoryTheme.material}
        />

        <VictoryLabel
          textAnchor="middle"
          style={{ fill: theme['text-basic-color'], fontFamily: 'Verdana' }}
          x={chartSize / 2}
          y={chartSize / 2}
          text={`Total: ${data.reduce((a, b) => a + (b.amount || 0), 0)}`}
        />
        <VictoryLegend
          x={0}
          y={chartSize - 30}
          gutter={16}
          standalone={false}
          orientation="horizontal"
          style={{
            labels: {
              color: theme['text-basic-color'],
            },
          }}
          data={data.map((item) => ({
            name: `${item.category}: ${item.amount}`,
          }))}
          colorScale={colorScheme}
          theme={VictoryTheme.material}
        />
      </Svg>
    </Card>
  );
}

const CustomLabel = (props) => {
  const defaultEvents = VictoryTooltip.defaultEvents;
  console.log(defaultEvents, 'DEFAULT EVENTS');
  return (
    <G>
      <VictoryTooltip
        {...props}
        events={defaultEvents}
        constrainToVisibleArea
        flyoutStyle={{ fill: '#000', zIndex: 9999 }}
        flyoutPadding={16}
      />
      <VictoryLabel {...props} />
    </G>
  );
};
