import React from 'react';
import { Image, ActivityIndicator } from 'react-native';

export default function EmojiIcon({ weeklyProgress }) {
  let progress = 0;
  if (!Number.isNaN(weeklyProgress)) {
    progress = parseFloat(weeklyProgress);
  }

  const number = parseFloat(parseFloat(progress).toFixed(2));

  const image = number < 0.01
    ? require('../../../assets/images/emoji-1.png')
    : number < 0.25
      ? require('../../../assets/images/emoji-2.png')
      : number < 0.5
        ? require('../../../assets/images/emoji-3.png')
        : number < 0.75
          ? require('../../../assets/images/emoji-4.png')
          : number <= 1
            ? require('../../../assets/images/emoji-5.png')
            : require('../../../assets/images/emoji-5.png');

  return (
    <>
      {!Number.isNaN(number) ? (
        <Image
          source={image}
          style={{ height: 35, width: 35, marginLeft: 10 }}
        />
      ) : (
        <ActivityIndicator />
      )}
    </>
  );
}
