import { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  View,
  Modal as NativeModal,
} from 'react-native';
import {
  Button,
  CheckBox,
  Layout,
  Popover,
  Text,
  Modal as KittenModal,
} from '@ui-kitten/components';
import { useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import Footer from '../Footer';
import Header from '../Header';
import Colors from '../../../helpers/Colors';
import { FormSuccess } from '../../../components-ui';
import InputField from '../react-hook-form/InputField';
import DropdownField from '../react-hook-form/DropdownField';
import {
  useGetAllBorrowersQuery,
} from '../../../services/BorrowerApiSlice';
import { useGetMeQuery } from '../../../services/UserSlice';
import { useCreateCreditRequestMutation } from '../../../services/CreditRequestApiSlice';
// @ts-ignore
import ModalWrapper from '../../../components-ui/Modals/ModalWrapper';
import CreateNewBorrower from './CreateNewBorrower';
import { useGetAllBorrowerPairsQuery } from '../../../services/BorrowerPairsApiSlice';
import { showModal as realShow } from '../../../services/FormSlice';
import { useDispatch } from 'react-redux';

function CreditRequest({ cancelAction, defaultData }: any) {
  const Modal = Platform.OS === 'web' ? KittenModal : NativeModal;
  const loanData = defaultData?.loanData;
  const [showEditBorrower, setShowEditBorrower] = useState<boolean>(false);
  const [borrowerEditData, setBorrowerEditData] = useState<any>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [showPairs, setShowPairs] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [creditDocumentId, setCreditDocumentId] = useState<number | null>(null);
  const isSuccess = false;
  const { data: user } = useGetMeQuery({});
  const canPullCredit = user?.xactusUsername;
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { data: loanBorrowerPairs, isFetching: fetchingBorrowerPairs } = useGetAllBorrowerPairsQuery({
    loanId: loanData.id,
  });

  const { data: borrowerData, isFetching: fetchingBorrowerData } = useGetAllBorrowersQuery(loanData ? { loanId: loanData.id } : skipToken);

  const [createCreditRequest, { isLoading: isSubmitting }] = useCreateCreditRequestMutation();

  async function onSubmit(values): Promise<void> {
    try {
      setError('');
      setFormSubmitted(true);
      // check consent for all pairs before submitting
      const borrowerPairKeys = Object.keys(values.borrowerPairs);

      borrowerPairKeys.forEach((key) => {
        const pair = values.borrowerPairs[`${key}`];
        const borrower = pair?.[0] || null;
        const coBorrower = pair?.[1] || null;
        if (borrower && borrower.pullCredit && !borrower.consent) {
          throw new Error(
            `${borrower.firstName} ${borrower.lastName} must give consent to pull credit`,
          );
        }
        if (coBorrower && coBorrower?.pullCredit && !coBorrower.consent) {
          throw new Error(
            `${coBorrower.firstName} ${coBorrower.lastName} must give consent to pull credit`,
          );
        }
      });

      for (let i = 0; i < borrowerPairKeys?.length; i++) {
        const pair = values.borrowerPairs[`${borrowerPairKeys[i]}`];
        const borrower = pair?.[0] || null;
        const coBorrower = pair?.[1] || null;

        const data: any = {
          requestType: values.requestType,
          loan: loanData['@id'],
          action: values.requestAgency === 'reissue' ? 'reissue' : 'submit',
        };
        if (borrower && borrower.pullCredit) {
          if (!borrower.consent) {
            throw new Error(
              `${borrower.firstName} ${borrower.lastName} must give consent to pull credit`,
            );
          }
          data.borrower1 = borrower['@id'];
        }
        if (coBorrower && coBorrower.pullCredit) {
          if (!coBorrower.consent) {
            throw new Error(
              `${borrower.firstName} ${borrower.lastName} must give consent to pull credit`,
            );
          }
          if (!data.borrower1) {
            data.borrower1 = coBorrower['@id'];
          } else {
            data.borrower2 = coBorrower['@id'];
          }
        }
        if (!data.borrower1 && !data.borrower2) continue;

        if (values.requestAgency === 'all') {
          data.includeEquifax = true;
          data.includeExperian = true;
          data.includeTransunion = true;
        }
        if (values.requestAgency === 'includeEquifax') {
          data.includeEquifax = true;
        }
        if (values.requestAgency === 'includeExperian') {
          data.includeExperian = true;
        }
        if (values.requestAgency === 'includeTransunion') {
          data.includeTransunion = true;
        }
        if (values.requestAgency === 'reissue') {
          data.creditReportIdentifier = values.creditReportIdentifier;
        }
        const creditRequestResponse = await createCreditRequest({
          loanId: loanData.id,
          data,
        }).unwrap();
        if (creditRequestResponse?.id) {
          setCreditDocumentId(creditRequestResponse.id);
        } else {
          throw new Error('Something went wrong');
        }
        // DATA FOR POST REQUEST TO CREDIT API data
      }

      setFormSubmitted(false);
    } catch (error) {
      if (error?.data?.detail) {
        setError(error.data.detail);
      } else {
        setError(error.message);
      }
      setFormSubmitted(false);
    }
  }

  useEffect(() => {
    if (user) {
      if (!user?.xactusUsername) {
        setError(
          'You must have a Xactus username and password to pull credit.  Please update your profile.',
        );
      }
    }
  }, [user]);

  useEffect(() => {
    if (creditDocumentId) {
      showModal({
        formType: 'PreviewPdf',
        data: { creditDocumentId, loanId: loanData.id },
      });
    }
  }, [creditDocumentId]);

  const {
    control,
    handleSubmit,
    setFocus,
    register,
    formState: { errors, isDirty },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      requestType: 'soft_pull',
      requestAgency: '',
      creditReportIdentifier: null,
      borrowerPairs: {},
    },
  });

  const realtimeBorrowerPairs = watch('borrowerPairs');

  useEffect(() => {
    if (borrowerData && Object.keys(realtimeBorrowerPairs)?.length > 0) {
      const borrowerPairKeys = Object.keys(realtimeBorrowerPairs); // [0,1]
      const pairCopy = JSON.parse(JSON.stringify(realtimeBorrowerPairs));

      for (let i = 0; i < borrowerPairKeys?.length; i++) {
        const pair = pairCopy[`${borrowerPairKeys[i]}`];

        const newPairData = pair.map((borrower) => {
          const newBorrowerData = borrowerData?.find(
            (data) => borrower?.['@id'] === data?.['@id'],
          );

          if (newBorrowerData?.id) {
            const address = newBorrowerData?.borrowerAddresses?.[0] || false;

            const pullBorrower = !!(newBorrowerData.taxpayerIdentifier
              && address.houseNumber
              && address.street
              && address.city
              && address.state
              && address.zipCode);

            borrower.firstName = newBorrowerData.firstName;
            borrower.lastName = newBorrowerData.lastName;
            borrower.consent = false;
            borrower.pullCredit = pullBorrower;
            borrower.ss = newBorrowerData.taxpayerIdentifier || null;
            borrower.address = newBorrowerData.borrowerAddresses?.[0] || false;
          }
          return borrower;
        });
        pairCopy[`${borrowerPairKeys[i]}`] = newPairData;
      }

      setValue('borrowerPairs', pairCopy);
    }
  }, [borrowerData]);

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading="Credit Request" />
      <View style={{ flexDirection: 'column', flex: 1, minHeight: 350 }}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <DropdownField
              control={control}
              name="requestAgency"
              label="Credit Request Type"
              required
              options={[
                { name: '3 in file merge', value: 'all' },
                { name: 'Experian', value: 'includeExperian' },
                { name: 'Equifax', value: 'includeEquifax' },
                { name: 'Transunion', value: 'includeTransunion' },
                { name: 'Reissue', value: 'reissue' },
              ]}
            />
          </View>
          <View style={{ flex: 1 }}>
            <DropdownField
              control={control}
              name="requestType"
              label="Credit Request Type"
              required
              options={[
                { name: 'Soft Pull', value: 'soft_pull' },
                { name: 'Hard Pull', value: 'hard_pull' },
              ]}
            />
          </View>
        </View>
        {watch('requestAgency') === 'reissue' ? (
          <InputField
            control={control}
            name="creditReportIdentifier"
            label="Credit Reference Number"
            required
            nextField={() => setFocus('requestAgency')}
            register={register}
          />
        ) : null}
        <View style={{ paddingHorizontal: 8 }}>
          <Popover
            visible={showPairs}
            fullWidth
            backdropStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            anchor={() => (
              <Button
                disabled={fetchingBorrowerData || !canPullCredit}
                onPress={() => setShowPairs(true)}
              >
                {fetchingBorrowerData || fetchingBorrowerPairs ? (
                  <ActivityIndicator />
                ) : (
                  'Add Borrower Pairs'
                )}
              </Button>
            )}
            onBackdropPress={() => setShowPairs(false)}
          >
            <Layout style={{ padding: 24 }}>
              {borrowerData?.length
                && loanBorrowerPairs?.map((pair, index) => {
                  const borrower = borrowerData?.find((b) => b['@id'] === pair.borrower)
                    ?? null;
                  const coBorrower = borrowerData?.find((b) => b['@id'] === pair.coBorrower)
                    ?? null;

                  return (
                    <View
                      key={`borrower-pair-${index}`}
                      style={{ marginBottom: 16 }}
                    >
                      <Text
                        category="label"
                        style={{
                          textAlign: 'center',
                          fontFamily: 'VerbBold',
                          paddingLeft: 6,
                        }}
                      >
                        Pair
                        {' '}
                        {pair.pairNumber}
                      </Text>
                      <Button
                        size="large"
                        status={
                          watch('borrowerPairs')?.[index] ? 'info' : 'basic'
                        }
                        appearance="outline"
                        key={index}
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 32,
                          alignItems: 'center',
                          marginBottom: 8,
                          flex: 1,
                        }}
                        onPress={() => {
                          if (realtimeBorrowerPairs?.[index]) {
                            const copy = realtimeBorrowerPairs;
                            delete copy[index];
                            setValue('borrowerPairs', copy);
                            return;
                          }
                          const borrowerAddress = borrower?.borrowerAddresses?.[0] ?? null;
                          const coBorrowerAddress = coBorrower?.borrowerAddresses?.[0] ?? null;
                          const pullBorrower = !!(borrowerAddress?.houseNumber
                            && borrowerAddress?.street
                            && borrowerAddress?.city
                            && borrowerAddress?.state
                            && borrowerAddress?.zipCode);
                          const pullCoBorrower = !!(coBorrowerAddress?.houseNumber
                            && coBorrowerAddress?.street
                            && coBorrowerAddress?.city
                            && coBorrowerAddress?.state
                            && coBorrowerAddress?.zipCode);

                          setValue('borrowerPairs', {
                            ...realtimeBorrowerPairs,
                            [index]: [
                              borrower && {
                                '@id': borrower['@id'],
                                firstName: borrower.firstName,
                                lastName: borrower.lastName,
                                consent: false,
                                pullCredit: pullBorrower,
                                ss: borrower.taxpayerIdentifier || null,
                                address:
                                  borrower.borrowerAddresses?.[0] || false,
                              },
                              coBorrower && {
                                '@id': coBorrower['@id'],
                                firstName: coBorrower.firstName,
                                lastName: coBorrower.lastName,
                                consent: false,
                                pullCredit: pullCoBorrower,
                                ss: coBorrower.taxpayerIdentifier || null,
                                address:
                                  coBorrower.borrowerAddresses?.[0] || false,
                              },
                            ],
                          });
                        }}
                      >
                        {(props: any) => (
                          <>
                            {borrower ? (
                              <Text {...props}>
                                {index === 0 ? 'Primary Borrower: ' : ''}
                                {' '}
                                {borrower.firstName}
                                {' '}
                                {borrower.lastName}
                              </Text>
                            ) : null}
                            {coBorrower ? (
                              <Text {...props}>
                                {index === 0 ? 'Primary CoBorrower: ' : ''}
                                {' '}
                                {coBorrower.firstName}
                                {' '}
                                {coBorrower.lastName}
                              </Text>
                            ) : null}
                          </>
                        )}
                      </Button>
                    </View>
                  );
                })}
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 32,
                }}
              >
                <Button onPress={() => setShowPairs(false)}>Close</Button>
              </View>
            </Layout>
          </Popover>
        </View>
        {Object.keys(realtimeBorrowerPairs)?.length ? (
          <View
            style={{
              paddingHorizontal: 8,
              marginTop: 8,
              borderTopColor: '#eee',
              borderTopWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'column' }}>
              {Object.keys(realtimeBorrowerPairs).map((pair, index) => {
                const pairData = realtimeBorrowerPairs[pair];
                return (
                  <View style={{ marginBottom: 16, marginTop: 8 }} key={pair}>
                    <Text>
                      Pair
                      {parseInt(pair) + 1}
                    </Text>
                    <Layout
                      level="2"
                      style={{
                        flexDirection: 'column',
                        padding: 16,
                      }}
                    >
                      {pairData
                        && pairData?.map((borrower, borrowerIndex) => {
                          if (!borrower) return;
                          const requestCredit = pairData[borrowerIndex].pullCredit;
                          const hasConsent = pairData[borrowerIndex].consent;
                          const hasAddress = !!(borrower?.address?.houseNumber
                            && borrower?.address?.street
                            && borrower?.address?.city
                            && borrower?.address?.state
                            && borrower?.address?.zipCode);
                          const creditElegible = !!(hasAddress && borrower?.ss);
                          if (!creditElegible) {
                            return (
                              <View
                                key={borrower.firstName}
                                style={{
                                  paddingHorizontal: 16,
                                  gap: 8,
                                  flexDirection: 'column',
                                }}
                              >
                                {fetchingBorrowerData ? (
                                  <ActivityIndicator />
                                ) : (
                                  <>
                                    <View
                                      style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 16,
                                      }}
                                    >
                                      <Text
                                        status="basic"
                                        style={{
                                          fontFamily: 'VerbBold',
                                          fontSize: 14,
                                        }}
                                      >
                                        {borrower.firstName}
                                        {' '}
                                        {borrower.lastName}
                                      </Text>
                                      <Button
                                        onPress={() => {
                                          const borrowerId = borrower['@id'];
                                          // Find this borrower data from borrowerData
                                          const dataToEdit = borrowerData?.find(
                                            (borrower) => borrower['@id'] === borrowerId,
                                          );
                                          if (dataToEdit?.id) {
                                            setBorrowerEditData(dataToEdit);
                                            setShowEditBorrower(true);
                                          }
                                        }}
                                        size="tiny"
                                        status="info"
                                      >
                                        Edit Borrower
                                      </Button>
                                    </View>
                                    <Text
                                      style={{ marginBottom: 16 }}
                                      category="label"
                                      status="danger"
                                    >
                                      Unable to pull credit report.
                                      {' '}
                                      {hasAddress
                                        ? 'Please update the borrower taxpayer identifier.'
                                        : 'Please update the borrower address.'}
                                    </Text>
                                  </>
                                )}
                              </View>
                            );
                          }
                          return (
                            <View
                              key={borrower.firstName}
                              style={{
                                flexDirection:
                                  Platform.OS === 'web' ? 'row' : 'column',
                                justifyContent: 'center',
                                padding: 16,
                                gap: 8,
                                borderBottomColor: '#eee',
                                borderBottomWidth:
                                  pairData.length <= borrowerIndex + 1 ? 0 : 1,
                              }}
                            >
                              {Platform.OS !== 'web' ? (
                                <Text
                                  style={{
                                    flex: 1,
                                    fontFamily: 'VerbBold',
                                  }}
                                >
                                  {borrower.firstName}
                                  {' '}
                                  {borrower.lastName}
                                </Text>
                              ) : null}

                              <CheckBox
                                checked={!!requestCredit}
                                onChange={(nextChecked) => {
                                  const pairsCopy = realtimeBorrowerPairs;
                                  pairsCopy[pair][borrowerIndex].pullCredit = nextChecked;
                                  setValue('borrowerPairs', pairsCopy);
                                }}
                              >
                                Request
                              </CheckBox>
                              {Platform.OS === 'web' ? (
                                <Text
                                  style={{
                                    flex: 1,
                                    fontFamily: 'VerbBold',
                                    paddingHorizontal: 16,
                                    marginHorizontal: 16,
                                    textAlign: 'center',
                                  }}
                                >
                                  {borrower.firstName}
                                  {' '}
                                  {borrower.lastName}
                                </Text>
                              ) : null}
                              <CheckBox
                                checked={!!hasConsent}
                                onChange={(nextChecked) => {
                                  const pairsCopy = realtimeBorrowerPairs;
                                  pairsCopy[pair][borrowerIndex].consent = nextChecked;
                                  setValue('borrowerPairs', pairsCopy);
                                }}
                              >
                                Consent to pull credit
                              </CheckBox>
                            </View>
                          );
                        })}
                    </Layout>
                  </View>
                );
              })}
            </View>
          </View>
        ) : null}
      </View>
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {!!isSuccess && <FormSuccess />}
      {/* @ts-ignore */}
      <Modal
        animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
        style={{ zIndex: 999 }}
        visible={showEditBorrower}
      >
        <ModalWrapper>
          <CreateNewBorrower
            defaultData={{
              loanIRI: loanData?.['@id'],
              loanId: loanData.id,
              borrowerData: borrowerEditData,
            }}
            cancelAction={() => {
              setShowEditBorrower(false);
            }}
          />
        </ModalWrapper>
      </Modal>
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit(onSubmit)}
        disabled={
          formSubmitted
          || !canPullCredit
          || fetchingBorrowerData
          || isSubmitting
        }
        submitText="SUBMIT"
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default CreditRequest;
