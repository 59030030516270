import { apiSlice } from './ApiSlice';

export const TlcInviteSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTlcInvites: build.query({
      query: (query) => ({
        url: '/tlc_invites?' + query || '' ,
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'TlcInvites', id: 'PARTIAL-LIST' }],
    }),
    getTlcInvite: build.query({
      query: ({id = null}) => ({
        url: `/tlc_invites/${id}`,
        method: 'GET',
      })
    }),
    addNewTlcInvite: build.mutation({
      query: (body) => ({
        url: '/tlc_invites',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'TlcInvites', id: 'PARTIAL-LIST' },
      ],
    }),
    updateTlcInvite: build.mutation({
      query: ({id, ...put}) => ({
        url: `/tlc_invites/${id}`,
        method: 'put',
        put,
      }),
      invalidatesTags: ({id}) => [
        { type: 'TlcInvites', id },
      ],
    }),
    deleteTlcInvite: build.mutation({
      query: (id) => ({
        url: `/tlc_invites/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTlcInvitesQuery,
  useGetTlcInviteQuery,
  useAddNewTlcInviteMutation,
  useUpdateTlcInviteMutation,
  useDeleteTlcInviteMutation,
} = TlcInviteSlice;