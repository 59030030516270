import {
  Button,
  Card,
  Icon,
  IconElement,
  IndexPath,
  Input,
  Layout,
  MenuItem,
  Modal,
  OverflowMenu,
  Popover,
  Text,
} from '@ui-kitten/components';
import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { KittenSelect } from '../../components-ui';
import {
  CloseIcon,
  EmailIcon,
  EventIcon,
  NoteIcon,
  SettingsIcon,
  TaskIcon,
} from '../../components-ui/Icons';
import { SavedView } from '../../interfaces/Table';
import {
  useGetMeQuery,
  useUpdateFrontendSettingsMutation,
} from '../../services/UserSlice';
import {
  clearFilters,
  selectLocalViewState,
  setActiveView as setActiveViewReducer,
  setGlobalSearch,
} from '../../services/TableSlice';
import metadata, { defaultViews } from '../../helpers/ObjectMetadata';
import TableSettings from './TableSettings';
import { showModal as realShow } from '../../services/FormSlice';

type Props = {
  selectedRows: any[];
  objectName: string;
  customActions?: Function;
  disableFilters?: string[] | null;
  defaultSavedView?: SavedView[];
};

function ViewNameInput({
  viewName,
  onPress,
  allViews,
}: {
  viewName: string;
  onPress: (viewName: string) => void;
  allViews: SavedView[];
}) {
  const [value, setValue] = useState(viewName);
  const error = allViews.find(
    (view) => view.name === value && view.name === 'Default'
  );

  const onSubmit = () => {
    onPress(value);
  };

  return (
    <Layout
      level="1"
      style={{
        padding: 24,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Input
        size="small"
        label="Profile Name"
        placeholder="Table Name"
        value={value}
        onChangeText={setValue}
        autoFocus
        status={error ? 'danger' : 'basic'}
        caption={error ? 'Please use a different name' : ''}
      />
      <Button
        onPress={onSubmit}
        disabled={!!error}
        status="info"
        size="small"
        style={{
          marginTop: 18,
          marginLeft: 8,
        }}
      >
        Save
      </Button>
    </Layout>
  );
}

function StarIcon(props): IconElement {
  return <Icon {...props} name="star" />;
}

function EmailIconBlack(props): IconElement {
  return <EmailIcon {...props} fill="black" />;
}

function OverflowMenuDropdown({ selectedRows, customActions }) {
  const [visible, setVisible] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const onSelect = (index): void => {
    setSelectedIndex(index);
    setVisible(false);
  };

  const renderToggleButton = (): React.ReactElement => (
    <Button
      accessoryLeft={StarIcon}
      status="danger"
      size="tiny"
      onPress={() => setVisible(true)}
    >
      Actions
    </Button>
  );
  return (
    <Layout level="1" style={{ marginHorizontal: 16 }}>
      <OverflowMenu
        anchor={renderToggleButton}
        visible={visible}
        selectedIndex={selectedIndex}
        onSelect={onSelect}
        onBackdropPress={() => setVisible(false)}
      >
        {customActions ? (
          <>{customActions(selectedRows)}</>
        ) : (
          <>
            <MenuItem
              title="Create Tasks"
              accessoryLeft={(props) => <TaskIcon {...props} fill="#ff715b" />}
              onPress={() =>
                showModal({
                  formType: 'task',
                  data: { contactList: selectedRows },
                })
              }
            />
            <MenuItem
              title=" Create Events"
              onPress={() =>
                showModal({
                  formType: 'event',
                  data: { contactList: selectedRows },
                })
              }
              accessoryLeft={(props) => <EventIcon {...props} fill="#ffb302" />}
            />
            <MenuItem
              title="Create Notes"
              onPress={() =>
                showModal({
                  formType: 'note',
                  data: { contactList: selectedRows },
                })
              }
              accessoryLeft={(props) => <NoteIcon {...props} fill="#65a3d8" />}
            />
            <MenuItem
              title="Bulk Email"
              onPress={() =>
                showModal({
                  formType: 'bulkEmail',
                  data: { contactList: selectedRows },
                })
              }
              accessoryLeft={EmailIconBlack}
            />
          </>
        )}
      </OverflowMenu>
    </Layout>
  );
}

let timeout: string | number | NodeJS.Timeout;

function TableActions({
  selectedRows,
  objectName,
  customActions,
  disableFilters,
  defaultSavedView,
}: Props) {
  const [visible, setVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [allViews, setAllViews] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(new IndexPath(0));
  const [search, setSearch] = useState('');
  const { data: user, isLoading } = useGetMeQuery({});
  const [updateFrontendSettings] = useUpdateFrontendSettingsMutation();

  const localViewState = useSelector((state) =>
    selectLocalViewState(state, objectName)
  );
  const { activeView: savedView } = localViewState;
  const columns = metadata[objectName];

  const dispatch = useDispatch();
  const viewModified = useMemo(() => {
    if (!savedView || !allViews?.length) return false;
    const snapshot = allViews.find((view) => view.name === savedView.name);
    // additionalFilter, columns, filters, order
    return (
      JSON.stringify({
        columns: savedView.columns,
        filters: savedView.filters,
        order: savedView.order,
        categoryFilter: savedView.categoryFilter,
      }) !==
      JSON.stringify({
        columns: snapshot.columns,
        filters: snapshot.filters,
        order: snapshot.order,
        categoryFilter: snapshot.categoryFilter,
      })
    );
  }, [savedView, allViews]);
  // combine all views into one array
  useEffect(() => {
    if (isLoading) return;
    const defaultView = defaultSavedView || defaultViews[objectName];
    const settings = user?.frontendSettings || {};
    const additional = settings[objectName]?.savedViews || [];
    const savedViewToLoad = defaultView.filter((view) => !view.isNative);
    setAllViews([...savedViewToLoad, ...additional]);
  }, [user?.frontendSettings, isLoading, objectName]);

  // set the initial view to the default view
  useEffect(() => {
    if (!savedView && allViews?.length) {
      let indexOfDefault = allViews.findIndex(
        (view) => view.isDefault && view.name !== 'Default'
      );

      if (indexOfDefault === -1) indexOfDefault = 0;

      setSelectedProfile(new IndexPath(indexOfDefault));
      dispatch(
        setActiveViewReducer({
          objectName,
          savedView: allViews[indexOfDefault],
        })
      );
    } else if (savedView && allViews?.length) {
      const index = allViews.findIndex((view) => view.name === savedView.name);
      setSelectedProfile(new IndexPath(index));
    }
  }, [dispatch, allViews, objectName, savedView]);

  useEffect(() => {
    setSearch(localViewState.globalSearch);
  }, [localViewState.globalSearch]);

  // handle saving views to the backend when allViews changes
  function saveViewsToDb(views: SavedView[]) {
    const filtered = views.filter((view) => view.name !== 'Default');
    const settings = user?.frontendSettings || {};

    const newSettings = {
      ...settings,
      [objectName]: {
        ...settings[objectName],
        savedViews: filtered,
      },
    };
    updateFrontendSettings({ id: user.id, frontendSettings: newSettings });
  }

  function saveProfile(viewName: string) {
    const newView = { ...savedView };
    newView.isDefault = true;
    newView.name = viewName;
    const newAllViews = allViews
      .filter((view) => view.name !== viewName)
      .map((view) => {
        const temp = JSON.parse(JSON.stringify(view));
        temp.isDefault = false;
        return temp;
      });

    const localAllViews = [...newAllViews, newView];
    saveViewsToDb(localAllViews);
    setAllViews(localAllViews);
    dispatch(setActiveViewReducer({ objectName, savedView: newView }));
    // todo check this out if it is necessary
    setSelectedProfile(new IndexPath(localAllViews.indexOf(newView)));
    setVisible(false);
  }

  function deleteProfile() {
    const localAllViews = [
      ...allViews.filter((view) => view.name !== savedView.name),
    ];
    setAllViews(localAllViews);
    dispatch(setActiveViewReducer({ objectName, savedView: localAllViews[0] }));
    setSelectedProfile(new IndexPath(0));
    saveViewsToDb(localAllViews);
  }

  function changeView(index: IndexPath) {
    setSelectedProfile(new IndexPath(index.row));
    dispatch(
      setActiveViewReducer({ objectName, savedView: allViews[index.row] })
    );
  }

  const renderIcon = (props) => (
    <TouchableWithoutFeedback
      onPress={() => setGlobalSearch({ objectName, globalSearch: '' })}
    >
      <Icon
        {...props}
        name={localViewState.globalSearch ? 'close-outline' : 'search-outline'}
      />
    </TouchableWithoutFeedback>
  );

  const handleSetDefault = (viewName) => {
    let newAllViews;
    if (viewName === 'Default') {
      // remove all other default views
      newAllViews = allViews.map((view) => {
        const temp = { ...view };
        temp.isDefault = false;
        return temp;
      });
    } else {
      // remove default from all and set isDefault to current view
      newAllViews = allViews.map((view) => {
        const temp = { ...view };
        if (view.name === viewName) {
          temp.isDefault = true;
        } else {
          temp.isDefault = false;
        }
        return temp;
      });
    }
    saveViewsToDb(newAllViews);
    setAllViews(newAllViews);
  };

  const renderSaveProfileButton = () => (
    <Button
      onPress={() => setVisible(true)}
      size="small"
      status="primary"
      appearance="ghost"
      disabled={!viewModified}
    >
      Save Profile
    </Button>
  );

  const handleSearch = (value: string) => {
    setSearch(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setGlobalSearch({ objectName, globalSearch: value }));
    }, 500);
  };

  return (
    <View style={styles.actionWrapper}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',

          paddingLeft: 8,
        }}
      >
        <Input
          placeholder="Search"
          value={search}
          accessoryRight={renderIcon}
          onChangeText={(nextValue) => handleSearch(nextValue)}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {selectedRows.length !== 0 && (
            <Text category="label" style={styles.selectedText}>
              {selectedRows?.length || 0} selected
            </Text>
          )}
          {selectedRows.length !== 0 && (
            <OverflowMenuDropdown
              customActions={customActions}
              selectedRows={selectedRows}
            />
          )}

          {disableFilters?.length ? null : (
            <Button
              accessoryLeft={CloseIcon}
              style={styles.icons}
              size="tiny"
              appearance="ghost"
              status="primary"
              disabled={
                savedView?.filters?.length === 0 &&
                !savedView?.order?.length &&
                localViewState.globalSearch === ''
              }
              onPress={() => dispatch(clearFilters({ objectName }))}
            >
              Clear Filters
            </Button>
          )}
        </View>
      </View>
      {savedView && allViews && (
        <View
          style={{
            paddingHorizontal: 8,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Popover
            visible={visible}
            anchor={renderSaveProfileButton}
            onBackdropPress={() => setVisible(false)}
          >
            <ViewNameInput
              viewName={savedView.isDefault ? 'New View' : savedView.name}
              onPress={(viewName) => saveProfile(viewName)}
              allViews={allViews}
            />
          </Popover>
          <Button
            disabled={savedView.name === 'Default'}
            onPress={() => deleteProfile()}
            size="small"
            status="danger"
            appearance="ghost"
            style={{ marginHorizontal: 8 }}
          >
            Remove
          </Button>
          {selectedProfile ? (
            <KittenSelect
              selectedIndex={selectedProfile}
              size="small"
              setSelectedIndex={(index: IndexPath) => changeView(index)}
              options={allViews.map((view) => view.name)}
            />
          ) : null}
          <Button
            onPress={() => setSettingsVisible(true)}
            style={styles.button}
            appearance="ghost"
            accessoryLeft={SettingsIcon}
          />
        </View>
      )}
      <Modal
        visible={settingsVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setSettingsVisible(false)}
      >
        <Card disabled={false}>
          {savedView && (
            <TableSettings
              close={() => setSettingsVisible(false)}
              savedView={savedView}
              setSavedView={(view) =>
                dispatch(setActiveViewReducer({ objectName, savedView: view }))
              }
              columns={columns}
              allViews={allViews}
              handleSetDefault={handleSetDefault}
            />
          )}
          {/* <Button onPress={() => setSettingsVisible(false)}>
            DISMISS
          </Button> */}
        </Card>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    justifyContent: 'flex-start',
    backgroundColor: '#eee',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  button: {
    margin: 2,
  },
  icons: {
    padding: 8,
    marginHorizontal: 8,
  },
  head: { height: 32, backgroundColor: 'rgb(0, 122, 255)' },
  headText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#fff',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  text: { margin: 6, fontSize: 12, textAlign: 'left' },
  row: { flexDirection: 'row', backgroundColor: '#fff' },
  selectAllCell: {
    width: 45,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderColor: '#ddd',
    backgroundColor: '#fff',
    borderBottomWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
  },
  actionWrapper: {
    borderWidth: 1,
    borderColor: '#eee',
    padding: 4,
    minHeight: 45,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 38,
    minWidth: 1036,
  },
  selectedText: {
    marginRight: 16,
    fontSize: 12,

    fontStyle: 'italic',
    paddingLeft: 12,
  },
  orderIcon: {
    width: 14,
    height: 14,
    marginLeft: 6,
    marginVertical: -4,
  },
});

export default TableActions;
