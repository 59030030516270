import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Spinner } from '@ui-kitten/components';
import Colors from '../../src/helpers/Colors';

export default function ActivityIndicatorComponent() {
  return (
    <View style={styles.container}>
      <Spinner size={'giant'} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.background,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    borderRadius: 8,
  },
});
