import { apiSlice } from './ApiSlice';

export const StatsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStats: build.query({
      query: ({ filter }) => ({
        url: '/report/stats',
        method: 'POST',
        body: filter,
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'Stats',
          id: arg.filter.dataType,
        },
      ],
    }),
    getStatsNoSecurity: build.query({
      query: () => '/report/screen-cloud',
    }),
    getJoinedStats: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const promiseArr = [];
        for (let i = 0; i < _arg.filter.length; i++) {
          promiseArr.push(
            fetchWithBQ({
              url: '/report/stats',
              method: 'POST',
              body: _arg.filter[i],
            }),
          );
        }
        const result = await Promise.all(promiseArr);
        const { dataType } = _arg.filter[0];
        const data = result.reduce(
          (acc, cur) => {
            if (cur.data) {
              acc[dataType] += cur.data[dataType];
            }
            return acc;
          },
          { [dataType]: 0 },
        );

        return { data };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetStatsQuery, useGetJoinedStatsQuery, useGetStatsNoSecurityQuery } = StatsSlice;
