import {
  Button, ButtonGroup, Text, useTheme,
} from '@ui-kitten/components';
import { Platform, View } from 'react-native';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckmarkIcon, CloseIcon } from '../../components-ui/Icons';
import { selectLocalViewState } from '../../services/TableSlice';
import { returnPayPeriod } from '../../helpers/returnPayPeriod';
import { useGetMeQuery } from '../../services/UserSlice';
import { useBulkLoanApprovalMutation } from '../../services/LoanApiSlice';
import { showModal as realShow } from '../../services/FormSlice';

type Props = {
  rowData: any;
};

function CommissionActionButtons({ rowData }: Props) {
  // grab table filters, disable if filter is not this pay period
  const localViewState = useSelector((state) => selectLocalViewState(state, 'Commission'));
  const theme = useTheme();
  const [bulkLoanApproval, { isSuccess: isSuccessBulkApproval }] = useBulkLoanApprovalMutation();

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { data: user } = useGetMeQuery('');

  const userRole = user?.roles?.includes('ROLE_MANAGER') ? 'Admin' : 'Officer';
  const queryStringMatch = `purchasedDate[after]=${
    returnPayPeriod('CURRENT').start
  }&purchasedDate[strictly_before]=${returnPayPeriod('CURRENT').end}`;

  const isApproved = userRole === 'Officer' && rowData?.commissionApproval?.loApproval
    ? true
    : !!(userRole === 'Admin' && rowData?.commissionApproval?.managerApproval);

  const isRejected = userRole === 'Officer' && rowData?.commissionApproval?.loApproval === false
    ? true
    : !!(userRole === 'Admin'
        && rowData?.commissionApproval?.managerApproval === false);

  const isCurrentPayPeriod = !!localViewState?.queryString?.includes(
    queryStringMatch,
  );

  const handleCommissionApproval = (rows) => {
    if (userRole === 'Officer') {
      const loanIDs = rows.reduce((acc, current) => {
        if (
          !current.commissionApproval
          || current?.commissionApproval?.loApproval !== true
        ) {
          acc.push(current.id);
        }

        return acc;
      }, []);
      if (loanIDs.length === 0) return;
      const loComment = 'Approved';
      bulkLoanApproval({
        loans: loanIDs,
        commissionApproval: { loApproval: true, loComment },
      });
    }
    if (userRole === 'Admin') {
      const loanIDs = rows.reduce((acc, current) => {
        if (
          !current.commissionApproval
          || current?.commissionApproval?.managerApproval !== true
        ) {
          acc.push(current.id);
        }

        return acc;
      }, []);
      if (loanIDs.length === 0) return;
      const managerComment = 'Approved';
      bulkLoanApproval({
        loans: loanIDs,
        commissionApproval: {
          managerApproval: true,
          managerComment,
        },
      });
    }
  };

  if (isApproved) {
    return (
      <Text
        style={{ fontFamily: 'VerbBold', textAlign: 'center' }}
        status="success"
      >
        Approved
      </Text>
    );
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start',
        alignItems: 'center',
        width: Platform.OS === 'web' ? '100%' : 'auto',
        backgroundColor: 'transparent',
      }}
    >
      <ButtonGroup size={Platform.OS === 'web' ? 'small' : 'tiny'}>
        <Button
          onPress={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleCommissionApproval([rowData]);
          }}
          disabled={isApproved || !isCurrentPayPeriod}
          style={{
            backgroundColor:
              isApproved || !isCurrentPayPeriod
                ? theme['color-basic-300']
                : theme['color-success-500'],
          }}
          appearance=""
          status="success"
          accessoryLeft={CheckmarkIcon}
        />
        <Button
          onPress={(e) => {
            e.stopPropagation();
            e.preventDefault();
            showModal({
              formType: 'commissionReject',
              data: { ...rowData, userRole },
            });
          }}
          disabled={isApproved || isRejected || !isCurrentPayPeriod}
          style={{
            backgroundColor:
              isApproved || !isCurrentPayPeriod || isRejected
                ? theme['color-basic-300']
                : theme['color-danger-500'],
          }}
          appearance=""
          status="danger"
          accessoryLeft={CloseIcon}
        />
      </ButtonGroup>
    </View>
  );
}

export default CommissionActionButtons;
