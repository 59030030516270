import { apiSlice } from './ApiSlice';

export const LeadStatusLogApiSlice = apiSlice.injectEndpoints({
  endpoints: ((build) => ({
    getLeadStatusLogs: build.query({
      query: (query) => `lead_status_logs?${query}`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each lead status log in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'LeadStatusLog',
            id,
          })),
          { type: 'LeadStatusLog', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'LeadStatusLog', id: 'PARTIAL-LIST' }]),
    }),
    getLeadStatusLog: build.query({
      query: (id) => `/lead_status_logs/${id}`,
      providesTags: (result, error, id) => [{ type: 'LeadStatusLog', id }],
    }),
    createLeadStatusLog: build.mutation({
      // eslint-disable-next-line no-shadow
      query: ({ leadId, ...body }) => ({
        url: 'lead_status_logs',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { leadId }) => [
        { type: 'LeadStatusLog', id: 'PARTIAL-LIST' },
        { type: 'LeadStatusLogsByLead', id: 'PARTIAL-LIST' },
      ],
    }),
    getLeadStatusLogsByLead: build.query({
      query: ({ id, dateRangeOption }) => `/lead_status_logs/data/${id}/${dateRangeOption}`,
      providesTags: () => [{ type: 'LeadStatusLogsByLead', id: 'PARTIAL-LIST' }],
    }),
  })),
  overrideExisting: false,
});

export const {
  useGetLeadStatusLogsQuery,
  useGetLeadStatusLogQuery,
  useCreateLeadStatusLogMutation,
  useGetLeadStatusLogsByLeadQuery,
} = LeadStatusLogApiSlice;
