import { apiSlice } from './ApiSlice';

export const taskApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query({
      query: (query) => `tasks?${query}`,
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
              // Provides a tag for each lead in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result['hydra:member'].map(({ id }) => ({
                type: 'Tasks',
                id,
              })),
              { type: 'Tasks', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Tasks', id: 'PARTIAL-LIST' }],
    }),
    createTask: build.mutation({
      // eslint-disable-next-line no-shadow
      query: ({ contactId, ...body }) => ({
        url: 'tasks',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: 'Tasks', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    editTask: build.mutation({
      query: ({ id, contactId, ...put }) => ({
        url: `tasks/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Tasks', id },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    deleteTask: build.mutation({
      query: ({ id }) => ({
        url: `tasks/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this tasks `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getTasks` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Tasks', id },
        { type: 'Tasks', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    patchTask: build.mutation({
      query: ({ id, contactId, ...patch }) => ({
        url: `tasks/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Tasks', id },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'AvailableLeads', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTasksQuery,
  useCreateTaskMutation,
  useEditTaskMutation,
  useDeleteTaskMutation,
  usePatchTaskMutation,
} = taskApiSlice;
