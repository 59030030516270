import {
  IndexPath, Input, Layout, MenuItem, OverflowMenu, Text,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { removeColumnFilter, setColumnFilter } from '../../services/TableSlice';
import { SavedView } from '../../interfaces/Table';

interface RangeFilterProps {
  objectName: string;
  columnName: string;
  savedView: SavedView;
}
const dropdownOptions = ['<', '<=', '>', '>='];
let timeout: string | number | NodeJS.Timeout;
function RangeFilter({ columnName, objectName, savedView }: RangeFilterProps) {
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
  const [visible, setVisible] = useState(false);
  const [rangeAmount, setRangeAmount] = useState('');
  const dispatch = useDispatch();
  const filter = savedView.filters.find((fil) => fil.column === columnName);

  useEffect(() => {
    if (filter) {
      setRangeAmount(filter.value);
      setSelectedIndex(new IndexPath(dropdownOptions.indexOf(filter.operator)));
    } else {
      setRangeAmount('');
      setSelectedIndex(new IndexPath(0));
    }
  }, [filter]);

  const updateSavedView = (amount : string, index : IndexPath) => {
    if (!amount) {
      dispatch(removeColumnFilter({ objectName, column: columnName }));
    } else {
      dispatch(setColumnFilter({
        objectName,
        filter: {
          column: columnName, operator: dropdownOptions[index.row], value: amount,
        },
      }));
    }
  };

  const onItemSelect = (index: IndexPath) => {
    setSelectedIndex(index);
    setVisible(false);
    updateSavedView(rangeAmount, index);
  };

  const onRangeAmountChange = (nextText: string) => {
    setRangeAmount(nextText);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateSavedView(nextText, selectedIndex);
    }, 200);
  };

  const renderToggleButton = () => (
    <Pressable>
      <Text onPress={() => setVisible(true)}>
        {dropdownOptions[selectedIndex?.row || 0]}
      </Text>
    </Pressable>
  );

  return (
    <View style={{ flexDirection: 'row' }}>
      {/* Modifier */}
      <Layout style={styles.dateMenuContainer} level="1">
        <OverflowMenu
          style={{ width: 95 }}
          anchor={renderToggleButton}
          visible={visible}
          selectedIndex={selectedIndex}
          onSelect={onItemSelect}
          onBackdropPress={() => setVisible(false)}
        >
          {dropdownOptions.map((item) => <MenuItem key={item} style={styles.menuItem} title={item} />)}
        </OverflowMenu>
      </Layout>
      <Input
        onChangeText={(nextText) => {
          onRangeAmountChange(nextText);
        }}
        value={rangeAmount}
        style={{ backgroundColor: '#fff', maxWidth: 50 }}
        status="basic"
        size="small"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  dateMenuContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 6,
    marginVertical: 1,
  },
  menuItem: {
    maxWidth: 95,
  },
});

export default RangeFilter;
