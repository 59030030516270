import React, { useEffect, useRef, useState } from 'react';
import type { PropsWithChildren } from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Icon, useTheme } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';

type AccordionItemPros = PropsWithChildren<{
  title: string;
}>;

function AccordionItem({ children, title }: AccordionItemPros): JSX.Element {
  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);
  const theme = useTheme();
  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  useEffect(() => {
    Animated.timing(animatedController, {
      duration: 0,
      toValue: 0,
      easing: null,
      useNativeDriver: false,
    }).start();
    setOpen(false);
  }, [title]);

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 600,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 600,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false,
      }).start();
    }
    setOpen(!open);
  };

  const body = <View style={styles.accordBody}>{children}</View>;

  return (
    <View style={styles.accordContainer}>
      <TouchableOpacity
        style={[
          styles.accordHeader,
          { backgroundColor: theme['color-basic-400'] },
        ]}
        onPress={toggleListItem}
      >
        <Text style={styles.accordTitle}>{title}</Text>
        <Icon
          name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
          style={{ width: 24, height: 24 }}
          fill="#000"
        />
      </TouchableOpacity>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <ScrollView>
          <View
            style={styles.bodyContainer}
            onLayout={(event) =>
              setBodySectionHeight(event.nativeEvent.layout.height)
            }
          >
            {body}
          </View>
        </ScrollView>
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bodyBackground: {
    maxHeight: Dimensions.get('window').height - 145,
    overflow: 'hidden',
  },
  bodyContainer: {},
  accordContainer: {},
  accordHeader: {
    padding: 12,
    backgroundColor: '#fff',
    color: '#eee',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordTitle: {
    fontSize: 14,
    fontFamily: 'VerbBold',
  },
  accordBody: {},
  textSmall: {
    fontSize: 12,
  },
  seperator: {
    height: 12,
  },
});

export default AccordionItem;
