export default {
  MenuItems: {
    Home: {
      name: 'Home',
      path: '/home',
    },
    Leads: {
      name: 'Leads',
      path: '/leads',
    },
    Pipeline: {
      name: 'Pipeline',
      path: '/pipeline',
    },
    Contacts: {
      name: 'Contacts',
      path: '/contacts',
    },
    Profile: {
      name: 'Profile',
      path: '/profile',
    },
  },
  Modals: {
    GoalEditor: {
      name: 'GoalEditor',
      path: '/goal-editor',
    },
  },
  ExtraRoutes: {
    Visualizer: {
      name: 'Visualizer',
      path: '/visualizer'
    },
    CommissionApprovals: {
      name: 'CommissionApprovals',
      path: '/commission',
    },
    ImportLeads: {
      name: 'ImportLeads',
      path: '/leads/import',
    },
    ImportContacts: {
      name: 'ImportContacts',
      path: '/contacts/import',
    },
    Contact: {
      name: 'Contact',
      path: '/contacts/:id',
    },
    Lead: {
      name: 'Lead',
      path: '/leads/:id',
    },
    Loan: {
      name: 'Loan',
      path: '/loans/:id',
    },
    Error: {
      name: 'Error',
      path: '/error',
    },
    Tasks: {
      name: 'Tasks',
      path: '/tasks',
    },
    Points: {
      name: 'Points',
      path: '/points',
    },
  },
};
