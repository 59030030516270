import React, { createRef, useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View, Text, Keyboard } from 'react-native';
import {
  Select,
  IndexPath,
  Input,
  CheckBox,
  Datepicker,
  NativeDateService,
  Layout,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import { renderOption } from '../renderMethods';
import FormGroup from '../FormGroup';
import Colors from '../../../helpers/Colors';
import { useEditEventMutation } from '../../../services/EventApiSlice';
import { OTHERTYPE, SALESTYPE } from '../../../helpers/constants';
import { FormSuccess } from '../../../components-ui';
import moment from 'moment';
import RNTimePicker from '../../../components-ui/TimePicker/RNTimePicker';
const TASKTYPE = ['Sales Call', 'Follow Up', 'Other'];

function CompleteEventForm({ cancelAction, defaultData, showModal }: any) {
  const types = defaultData.type === 'Sales Call' ? SALESTYPE : OTHERTYPE;
  const componentRef = createRef<Datepicker>();
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [followUp, setFollowUp] = useState<boolean>(false);
  const [state, setState] = useState<any>({
    type: defaultData.outcome
      ? new IndexPath(types.indexOf(defaultData.outcome))
      : new IndexPath(0),
    description: defaultData.resolution || '',
    numOfReferrals: defaultData.referralCount || 0,
    taskType: new IndexPath(TASKTYPE.indexOf(defaultData.subject)),
    completedDate: defaultData.completedAt
      ? new Date(defaultData.completedAt)
      : null,
    completedTime: defaultData.completedAt
      ? moment(defaultData.completedAt).format('lll')
      : null,
  });

  const [
    patchEvent,
    { isSuccess: patchSuccess, isError: patchHasError, error: patchError },
  ] = useEditEventMutation();

  const displayValueTask = types[state.type.row];

  useEffect(() => {
    if (patchError) {
      if (patchHasError && patchError) {
        //@ts-ignore
        if (patchError?.data && patchError.data['hydra:description']) {
          //@ts-ignore
          setError(patchError?.data['hydra:description']);
        } else {
          setError('Something went wrong, please try again.');
        }
        setFormSubmitted(false);
      }
    }
  }, [patchError]);

  useEffect(() => {
    if (patchSuccess) {
      setTimeout(() => {
        if (followUp) {
          const payload = {
            ...defaultData,
            id: null,
            type: 'Follow Up',
            dueDate: moment(defaultData.startDatetime).add(1, 'day').format(),
            dueDateTime: defaultData.startDatetime,
          };
          showModal({
            formType: 'task',
            data: payload,
          });
        } else {
          cancelAction();
        }
      }, 2000);
    }
  }, [patchSuccess]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      // Check for fields
      if (!state.type || state.type.row === 0)
        throw new Error(
          'Missing Outcome, please make sure all required fields are filled'
        );
      if (!state.description || state.description.length === 0)
        throw new Error(
          'Missing notes, please make sure all required fields are filled'
        );

      const patchData = {
        type: TASKTYPE[state.taskType.row],
        subject: TASKTYPE[state.taskType.row],
        id: defaultData.id,
        outcome: displayValueTask,
        resolution: state.description,
        referralCount: parseInt(state.numOfReferrals) || 0,
        isCompleted: true,
      };

      if (state.completedDate && state.completedTime) {
        const formattedCompletedDate = moment(state.completedDate).format(
          'YYYY-DD-MM'
        );
        const completedAt = moment(
          formattedCompletedDate +
            ' ' +
            moment(state.completedTime).format('h:mm A'),
          'YYYY-DD-MM h:mm A'
        ).toISOString();

        patchData['completedAt'] = completedAt;
      }

      await patchEvent(patchData);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={`Complete Event`} />
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Outcome">
            <Select
              placeholder="Outcome"
              selectedIndex={state.type}
              onSelect={(index: any) =>
                setState((prevState) => ({ ...prevState, type: index }))
              }
              value={displayValueTask}
            >
              {types.map(renderOption)}
            </Select>
          </FormGroup>
        </View>
        {displayValueTask.includes('Referrals') && (
          <View style={{ flex: 1 }}>
            <FormGroup label="Number of referrals">
              <Input
                onBlur={() => Keyboard.dismiss()}
                placeholder=""
                value={state.numOfReferrals || ''}
                onChangeText={(e) =>
                  setState((prevState) => ({ ...prevState, numOfReferrals: e }))
                }
              />
            </FormGroup>
          </View>
        )}
      </View>
      {/* description */}
      <FormGroup label="Notes">
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: 80 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      {defaultData?.completedAt ? (
        <View style={{ flexDirection: 'row' }}>
          <FormGroup label="Completed Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={new Date(new Date())}
              ref={componentRef}
              date={state.completedDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  completedDate: e,
                }))
              }
            />
          </FormGroup>
          <FormGroup label="Select Completed Date">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select end time'}
                value={state.completedTime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    completedTime: moment(dateTime).format('lll'),
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      ) : null}
      <FormGroup label="" required={false}>
        <CheckBox
          checked={followUp}
          onChange={(nextChecked) => setFollowUp(nextChecked)}
        >
          Create Follow Up Task
        </CheckBox>
      </FormGroup>
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
          {error &&
          error ===
            'subject: You cannot have 2 sales activities within 90 days of each other' ? (
            <FormGroup label="Update Task Type">
              <View>
                <Select
                  placeholder="TASK TYPE"
                  selectedIndex={state.taskType}
                  onSelect={(index: any) =>
                    setState((prevState) => ({
                      ...prevState,
                      taskType: index,
                    }))
                  }
                  value={TASKTYPE[state.taskType.row]}
                >
                  {TASKTYPE.map(renderOption)}
                </Select>
              </View>
            </FormGroup>
          ) : null}
        </View>
      )}
      {!!patchSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'SAVE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default CompleteEventForm;
