import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Platform,
  View,
  ActivityIndicator,
} from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import Footer from '../../Footer';
import Header from '../../Header';
import Colors from '../../../../helpers/Colors';
//@ts-ignore
import FinalStepLoan from './steps/FinalStepLoan';

import { useGetMeQuery } from '../../../../services/UserSlice';
import {
  useAddNewPreApprovalMutation,
  useEditPreapprovalMutation,
} from '../../../../services/PreApprovalLoanSlice';
//@ts-ignore
import PreviewActions from './steps/PreviewActions';

interface FormState {
  selectedAssociate: any;
  loan?: string;
  borrowerName: string;
  borrowerEmail: string;
  propertyAddress: string;
  propertyType: string;
  interestRate: string | null;
  documentationType: string;
  fico: string | null;
  ltv: number;
  loanType: string;
  occupancy: string;
  loanAmount: number;
  purchasePrice: number;
  loanTerm: number;
  owner: string;
}

function GeneratePreapprovalForm({ cancelAction, defaultData }: any) {
  const [error, setError] = useState<null | string>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState<FormState>({
    selectedAssociate: null,
    borrowerName: '',
    borrowerEmail: '',
    propertyType: '',
    interestRate: null,
    documentationType: '',
    propertyAddress: 'TBD',
    loanType: '',
    occupancy: '',
    fico: null,
    loanAmount: 0,
    ltv: 0,
    purchasePrice: 0,
    loanTerm: 360,
    owner: '',
  });

  useEffect(() => {
    if (defaultData && !defaultData.clientData) {
      setState((prevState) => ({
        ...prevState,
        ...defaultData,
      }));
      setFormSubmitted(true);
    }
    if (defaultData?.clientData && defaultData?.preapprovalData) {
      setState((prevState) => ({
        ...defaultData.preapprovalData,
        borrowerEmail:
          defaultData.clientData?.email ||
          defaultData.clientData?.loanContact?.email ||
          defaultData.clientData?.contact?.email,
      }));
      setIsEdit(true);
      return;
    }
    if (defaultData?.clientData) {
      setState((prevState) => ({
        ...prevState,
        borrowerName: defaultData.clientData.name,
        borrowerEmail: defaultData.clientData.email,
        selectedAssociate: defaultData.clientData,
      }));
    }
  }, [defaultData]);

  const [
    createPreapproval,
    {
      isLoading: isCreatingPreapproval,
      data: preApprovalData,
      isSuccess: successCreatingPreapproval,
      error: mutationError,
    },
  ] = useAddNewPreApprovalMutation();

  const [
    patchPreapproval,
    { isLoading: isPatchingPreapproval, isSuccess: patchSuccess },
  ] = useEditPreapprovalMutation();

  const { data: user } = useGetMeQuery('');

  async function handleSubmit(): Promise<void> {
    setError(null);
    try {
      if (successCreatingPreapproval) {
        cancelAction();
        return;
      }
      checkForRequiredFields();
      const cleanedFields = Object.keys(state).reduce((acc, key) => {
        if (state[key]) {
          acc[key] = state[key];
        }
        return acc;
      }, {});

      let ficoScore = state.fico ? parseInt(state.fico) : null;

      if (isEdit) {
        patchPreapproval({
          id: defaultData.preapprovalData.id,
          body: {
            ...cleanedFields,
            interestRate: state.interestRate
              ? parseFloat(state.interestRate)
              : null,
            fico: ficoScore,
          },
        });
      } else {
        createPreapproval({
          ...cleanedFields,
          interestRate: state.interestRate
            ? parseFloat(state.interestRate)
            : null,
          fico: ficoScore,
        });
      }
      //generate
    } catch (error) {
      if (error.message) {
        setError(error.message);
      }
    }
  }

  function checkForRequiredFields() {
    const requiredFields = {
      borrowerName: 'Borrower Name',
      borrowerEmail: 'Borrower Email',
      loanType: 'Loan Type',
      occupancy: 'Occupancy',
      loanAmount: 'Loan Amount',
      purchasePrice: 'Sales Price',
      loanTerm: 'Loan Term',
      owner: 'Owner',
      propertyAddress: 'Property Address',
      propertyType: 'Property Type',
      documentationType: 'Documentation Type',
    };
    Object.keys(requiredFields).forEach((field) => {
      if (!state[field] || state[field] === '') {
        throw new Error(`${requiredFields[field]} is required`);
      }
    });
  }

  useEffect(() => {
    if (defaultData.preapprovalData) return;
    if (state.selectedAssociate) {
      const isLoan = state.selectedAssociate['@type'] === 'Loan';
      if (isLoan) {
        const borrowerName = state?.selectedAssociate?.loanContact?.firstName
          ? `${state?.selectedAssociate?.loanContact?.firstName} ${state?.selectedAssociate?.loanContact?.lastName}`
          : state?.selectedAssociate?.contact?.name || '';
        const borrowerEmail =
          state?.selectedAssociate?.loanContact?.email ||
          state?.selectedAssociate?.contact?.email ||
          '';
        setState((prevState: any) => {
          const newState = {
            ...prevState,
            borrowerName: borrowerName,
            borrowerEmail: borrowerEmail,
            loan: state.selectedAssociate['@id'] || '',
            propertyAddress: state.selectedAssociate.propertyAddress || 'TBD',
            propertyType: state.selectedAssociate.propertyType || '',
            purchasePrice: state.selectedAssociate.purchasePrice || 0,
            loanTerm: state.selectedAssociate.term || 360,
            units: state.selectedAssociate.units || 0,
            loanAmount: state.selectedAssociate.loanAmount || 0,
            interestRate: state.selectedAssociate.interestRate || null,
            loanType: state.selectedAssociate.loanType || '',
            occupancy: state.selectedAssociate.occupancy || '',
            fico: state.selectedAssociate.creditScore || null,
          };
          if (state.selectedAssociate?.contact?.['@id']) {
            newState['contact'] = state.selectedAssociate?.contact?.['@id'];
          }
          return newState;
        });
      } else {
        setState((prevState: any) => ({
          ...prevState,
          borrowerName:
            state.selectedAssociate.title ||
            `${state.selectedAssociate.firstName} ${state.selectedAssociate.lastName}` ||
            '',
          borrowerEmail: state.selectedAssociate.email || '',
          contact: state.selectedAssociate['@id'] || '',
        }));
      }
    }
  }, [state.selectedAssociate]);

  useEffect(() => {
    if (state.loanAmount && state.purchasePrice) {
      setState((prevState: any) => ({
        ...prevState,
        ltv: Math.floor((state.loanAmount / state.purchasePrice) * 100),
      }));
    }
  }, [state.loanAmount, state.purchasePrice]);

  useEffect(() => {
    if (user?.['@id']) {
      setState((prevState: any) => ({ ...prevState, owner: user['@id'] }));
    }
  }, [user]);

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <>
        {successCreatingPreapproval || patchSuccess || defaultData?.id ? (
          <>
            <View>
              <PreviewActions
                isEdit={isEdit}
                preapprovalId={
                  preApprovalData?.id ||
                  defaultData?.preapprovalData?.id ||
                  defaultData?.id
                }
                borrowerName={state.borrowerName}
                isPreviewOnly={true}
                cancelAction={cancelAction}
                borrowerEmail={defaultData.email}
              />
            </View>
          </>
        ) : (
          <>
            <Header
              heading={
                defaultData?.id
                  ? 'Preview Pre-Approval'
                  : isEdit
                  ? 'Edit Pre-Approval'
                  : 'Generate Pre-Approval'
              }
            />
            <View style={{ flex: 1 }}>
              {(state?.selectedAssociate || defaultData?.preapprovalData) && state ? (
                <FinalStepLoan setState={setState} state={state} />
              ) : (
                <ActivityIndicator />
              )}
            </View>
            <Footer
              cancelAction={cancelAction}
              hideCancel={successCreatingPreapproval || patchSuccess}
              cancelText={'CLOSE'}
              submitAction={handleSubmit}
              disabled={
                isCreatingPreapproval || formSubmitted || isPatchingPreapproval
              }
              submitText={
                successCreatingPreapproval
                  ? 'CLOSE'
                  : isEdit
                  ? 'SUBMIT'
                  : 'GENERATE'
              }
            />
          </>
        )}
        {error ? (
          <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
            {error}
          </Text>
        ) : null}
        {/* {!!addSuccess && <FormSuccess />} */}
      </>
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    minWidth: Platform.OS === 'web' ? 568 : Dimensions.get('window').width - 8,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default GeneratePreapprovalForm;
