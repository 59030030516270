import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ActivityIndicator, Platform, View } from 'react-native';
import { Button, Text, useTheme, Modal } from '@ui-kitten/components';
import {
  CloseIcon,
  EditIcon,
  PeopleIcon,
  TrashIcon,
} from '../../../components-ui/Icons';
//@ts-ignore
import ModalWrapper from '../../../components-ui/Modals/ModalWrapper';
import CreateNewBorrower from './CreateNewBorrower';
import { useDeleteBorrowerMutation } from '../../../services/BorrowerApiSlice';
import {
  useDeleteBorrowerPairMutation,
  usePatchBorrowerPairMutation,
} from '../../../services/BorrowerPairsApiSlice';

type Props = {
  loanData: any;
  borrowerData: any;
  columns: any;
  setColumns: any;
  borrowerPairs: any;
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    if (destItems.length === 2 && destination.droppableId !== 'allBorrowers')
      return;
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function BorrowerDND({
  loanData,
  columns,
  setColumns,
  borrowerPairs,
  borrowerData,
}: Props) {
  const [editable, setEditable] = useState(false);
  const [showModifyBorrowerModal, setShowModifyBorrowerModal] = useState(false);
  const [borrowerEditData, setBorrowerEditData] = useState<any>();
  const [payload, setPayload] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const theme = useTheme();

  const [deleteBorrower, { isLoading: isDeleting }] =
    useDeleteBorrowerMutation();
  const [patchBorrowerPair, { isLoading: isPatching }] =
    usePatchBorrowerPairMutation();
  const [deleteBorrowerPair, { isLoading: isDeletingPair }] =
    useDeleteBorrowerPairMutation();

  useEffect(() => {
    if (!borrowerData) return;
    const borrowers = borrowerData.map((borrower) => {
      return {
        ...borrower,
        id: borrower.id.toString(),
      };
    });

    const borrowerStatus = {
      allBorrowers: {
        name: 'All Borrowers',
        items: borrowers,
      },
    };
    borrowerPairs?.forEach((pair) => {
      const items = [];
      const borrower = pair.borrower
        ? borrowerStatus.allBorrowers.items.find(
            (borrower) => borrower['@id'] === pair.borrower
          )
        : null;

      //if borrower, remove from borrowerStatus.allborrowers.items
      if (borrower) {
        borrowerStatus.allBorrowers.items =
          borrowerStatus.allBorrowers.items.filter(
            (item) => item['@id'] !== borrower['@id']
          );
        items.push(borrower);
      }

      const coBorrower = pair.coBorrower
        ? borrowerStatus.allBorrowers.items.find(
            (borrower) => borrower['@id'] === pair.coBorrower
          )
        : null;

      //if coBorrower, remove from borrowerStatus.allborrowers.items
      if (coBorrower) {
        borrowerStatus.allBorrowers.items =
          borrowerStatus.allBorrowers.items.filter(
            (item) => item['@id'] !== coBorrower['@id']
          );
        items.push(coBorrower);
      }

      borrowerStatus[pair.pairNumber] = {
        name: `Pair ${pair.pairNumber}`,
        items: items,
      };
    });

    setColumns(borrowerStatus);
  }, [loanData, borrowerData]);

  useEffect(() => {
    if (!columns?.allBorrowers) return;
    const keys = Object.keys(columns).splice(
      0,
      Object.keys(columns).length - 1
    );
    if (keys.length === 0) {
      setColumns({
        ...columns,
        1: {
          name: `Pair 1`,
          items: [],
        },
      });
      return;
    }
    //all keys should have items before adding new key
    if (
      keys.every((key) => columns[key].items.length > 0) &&
      columns[keys[keys.length - 1]].items.length > 0 &&
      borrowerData?.length > parseInt(keys[keys.length - 1])
    ) {
      const newKey = Object.keys(columns).length;
      setColumns({
        ...columns,
        [newKey]: {
          name: `Pair ${newKey}`,
          items: [],
        },
      });
      return;
    }
    //if last 2 columns are empty, delete the last one
    if (
      keys.length > 1 &&
      columns[keys[keys.length - 1]].items.length === 0 &&
      columns[keys[keys.length - 2]].items.length === 0
    ) {
      const newColumns = { ...columns };
      delete newColumns[keys[keys.length - 1]];
      setColumns(newColumns);
      return;
    }
    //if last 2 columns are empty, delete the last one

    if (
      keys.length > 1 &&
      keys.some(
        (key) =>
          columns[key].items.length === 0 && key !== keys[keys.length - 1]
      )
    ) {
      const newColumns = { ...columns };
      let emptyKeys = keys.filter(
        (key) =>
          columns[key].items.length === 0 && key !== keys[keys.length - 1]
      );

      newColumns[emptyKeys[0]].items =
        newColumns[(parseInt(emptyKeys[0]) + 1).toString()].items;
      newColumns[(parseInt(emptyKeys[0]) + 1).toString()].items = [];
      setColumns(newColumns);
      return;
    }
  }, [columns]);

  async function handleDeleteBorrower(borrowerId, borrowerIRI) {
    try {
      const isInAPair = borrowerPairs?.find(
        (pair) =>
          pair.borrower === borrowerIRI || pair.coBorrower === borrowerIRI
      );
      //if part of a pair, and single borrower, delete pair, delete borrower
      if (!isInAPair) {
        await deleteBorrower({ loanId: loanData.id, borrowerId }).unwrap();
        return;
      }
      if (isInAPair && isInAPair.borrower && isInAPair.coBorrower) {
        //if is primary, patch pair, delete borrower
        if (isInAPair.borrower === borrowerIRI) {
          await patchBorrowerPair({
            loanId: loanData.id,
            borrowerPairId: isInAPair.id,
            borrowerPair: { borrower: isInAPair.coBorrower, coBorrower: null },
          }).unwrap();
          await deleteBorrower({ loanId: loanData.id, borrowerId }).unwrap();
          return;
        }
        await patchBorrowerPair({
          loanId: loanData.id,
          borrowerPairId: isInAPair.id,
          borrowerPair: { coBorrower: null },
        }).unwrap();
        await deleteBorrower({ loanId: loanData.id, borrowerId }).unwrap();
      }
      if (isInAPair && isInAPair.borrower && !isInAPair.coBorrower) {
        await deleteBorrowerPair({
          loanId: loanData.id,
          borrowerPairId: isInAPair.id,
        }).unwrap();
        await deleteBorrower({ loanId: loanData.id, borrowerId }).unwrap();
        return;
      }
    } catch (err) {
      console.log(err);
      setBorrowerEditData(null);
      setShowModifyBorrowerModal(false);
    }
  }

  if (!columns?.allBorrowers)
    return (
      <View>
        <ActivityIndicator />
      </View>
    );
  return (
    <div style={{ maxHeight: 968, overflow: 'auto', position: 'relative' }}>
      <div>
        <DragDropContext
          onDragEnd={(result) => {
            const columnName = result.destination.droppableId;
            const isChangingPrimary =
              (result.destination.index === 0 &&
                result.destination.droppableId !== 'allBorrowers' &&
                columns[columnName].items.length > 0) ||
              (result.source.droppableId !== 'allBorrowers' &&
                result.source.index === 0 &&
                columns[result.source.droppableId].items.length > 1);
            if (isChangingPrimary) {
              setPayload(result);
            } else {
              onDragEnd(result, columns, setColumns);
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexBasis: '100%',
            }}
          >
            <View
              style={{
                position: 'relative',
                width: '100%',
                paddingVertical: 4,
                flexDirection: 'row',
              }}
            >
              <View style={{ flexDirection: 'row', gap: 8, flex: 1 }}>
                <Button
                  disabled={isDelete}
                  onPress={() => setEditable(!editable)}
                  size="small"
                  appearance="ghost"
                  status="info"
                  accessoryLeft={!editable ? EditIcon : CloseIcon}
                  style={{ marginRight: 4 }}
                >
                  {editable ? 'Done' : 'Assign'}
                </Button>
              </View>
              <Text category="h5" style={{ textAlign: 'center', flex: 1 }}>
                Unassigned
              </Text>
              <View style={{ flexDirection: 'row', gap: 8, flex: 1 }}>
                <Button
                  style={{ flex: 1 }}
                  disabled={editable || isDelete}
                  onPress={() => setShowModifyBorrowerModal(true)}
                  size="small"
                  appearance="ghost"
                  status="primary"
                  accessoryLeft={PeopleIcon}
                >
                  Add Borrower
                </Button>
                <Button
                  style={{ flex: 1 }}
                  disabled={editable}
                  onPress={() => setIsDelete(!isDelete)}
                  size="small"
                  appearance="ghost"
                  status="danger"
                  accessoryLeft={isDelete ? CloseIcon : TrashIcon}
                >
                  {isDelete ? 'Done' : 'Delete Borrower'}
                </Button>
              </View>
            </View>
            <div style={{ margin: 8, width: '100%' }}>
              <Droppable droppableId={'allBorrowers'} direction="horizontal">
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? theme['color-success-100']
                          : editable
                          ? 'white'
                          : '#eee',
                        border: editable
                          ? '3px dashed #eee'
                          : `3px solid ${theme['color-basic-200']}`,
                        borderRadius: '8px',
                        padding: 6,
                        flex: 1,
                        flexWrap: 'wrap',
                        minHeight: 50,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {columns['allBorrowers'].items.map((item, index) => {
                        return (
                          <Draggable
                            isDragDisabled={!editable}
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              if (!editable) {
                                return (
                                  <button
                                    ref={provided.innerRef}
                                    style={{
                                      cursor: 'pointer',
                                      userSelect: 'none',
                                      outline: 'revert',
                                      border: 'none',
                                      flex: 1,
                                      padding: 8,
                                      margin: '8px',
                                      minHeight: '25px',
                                      maxWidth: 'calc(33% - 32px)',
                                      minWidth: 'calc(33% - 32px)',
                                      textAlign: 'center',
                                      backgroundColor: isDelete
                                        ? theme['color-danger-600']
                                        : theme[`color-basic-600`],
                                      color: 'white',
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0px 0px 5px 0px rgba(0,0,0,0.5)',
                                    }}
                                    onClick={() => {
                                      setBorrowerEditData(item);
                                      setShowModifyBorrowerModal(true);
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: 'VerbBold',
                                        textAlign: 'center',
                                        fontSize: 16,
                                      }}
                                    >
                                      {item.firstName} {item.lastName}
                                    </h2>
                                  </button>
                                );
                              }
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 8,
                                    margin: '8px',
                                    minHeight: '25px',
                                    maxWidth: snapshot.isDragging
                                      ? '200px'
                                      : 'calc(33% - 32px)',
                                    minWidth: 'calc(33% - 32px)',
                                    textAlign: 'center',
                                    backgroundColor: snapshot.isDragging
                                      ? '#263B4A'
                                      : theme[`color-basic-600`],
                                    color: 'white',
                                    borderRadius: '8px',
                                    boxShadow:
                                      '0px 0px 5px 0px rgba(0,0,0,0.5)',
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: 'VerbBold',
                                      textAlign: 'center',
                                      fontSize: 16,
                                    }}
                                  >
                                    {item.firstName} {item.lastName}
                                  </h2>
                                  <p>{}</p>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            {Object.entries(columns).map(
              ([columnId, column]: [string, any], columnIndex) => {
                if (columnId === 'allBorrowers') return null;
                if (columnIndex >= 0 && !editable && column.items.length === 0)
                  return null;
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      flexBasis: '100%',
                    }}
                    key={columnId}
                  >
                    <Text category="h6">{column.name}</Text>
                    <div style={{ margin: 8, width: '100%' }}>
                      <Droppable
                        droppableId={columnId}
                        key={columnId}
                        direction="horizontal"
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background:
                                  snapshot.isDraggingOver &&
                                  column.items.length === 2
                                    ? theme['color-danger-500']
                                    : snapshot.isDraggingOver
                                    ? theme['color-success-100']
                                    : column.items.length === 2
                                    ? '#eee'
                                    : editable
                                    ? 'white'
                                    : '#eee',
                                border:
                                  column.items.length === 2
                                    ? `3px solid ${theme['color-basic-200']}`
                                    : editable
                                    ? '3px dashed #eee'
                                    : `3px solid ${theme['color-basic-200']}`,
                                borderRadius: '8px',
                                padding: 6,
                                flex: 1,
                                minHeight: 50,
                                display: 'flex',
                                flexDirection: 'row',
                                position: 'relative',
                              }}
                            >
                              {column.items.map((item, index) => {
                                const isPrimary =
                                  index === 0 && columnIndex === 0;
                                const isCoBorrower =
                                  index === 1 && columnIndex === 0;
                                return (
                                  <Draggable
                                    isDragDisabled={!editable}
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      if (!editable) {
                                        return (
                                          <button
                                            ref={provided.innerRef}
                                            style={{
                                              cursor: 'pointer',
                                              position: 'relative',
                                              userSelect: 'none',
                                              outline: 'revert',
                                              border: 'none',
                                              flex: 1,
                                              padding: 8,
                                              margin: '8px',
                                              minHeight: '25px',
                                              maxWidth: 'calc(50% - 32px)',
                                              minWidth: 'calc(50% - 32px)',
                                              textAlign: 'center',
                                              backgroundColor:
                                                isDelete && !isPrimary
                                                  ? theme['color-danger-600']
                                                  : isDelete && isPrimary
                                                  ? theme['color-basic-600']
                                                  : isPrimary || isCoBorrower
                                                  ? theme[
                                                      `color-info-${Math.min(
                                                        columnIndex + 6,
                                                        9
                                                      )}00`
                                                    ]
                                                  : theme[
                                                      `color-primary-${Math.min(
                                                        columnIndex + 6,
                                                        9
                                                      )}00`
                                                    ],
                                              color: 'white',
                                              borderRadius: '8px',
                                              boxShadow:
                                                '0px 0px 5px 0px rgba(0,0,0,0.5)',
                                            }}
                                            onClick={() => {
                                              if (isPrimary && isDelete) return;
                                              setBorrowerEditData(item);
                                              setShowModifyBorrowerModal(true);
                                            }}
                                          >
                                            <h2
                                              style={{
                                                fontFamily: 'VerbBold',
                                                textAlign: 'center',
                                                fontSize: 16,
                                              }}
                                            >
                                              {item.firstName} {item.lastName}
                                            </h2>
                                            <Text
                                              category="basic"
                                              appearance="hint"
                                              style={{
                                                fontSize: 10,
                                                color: 'white',
                                              }}
                                            >
                                              {isPrimary
                                                ? 'Primary'
                                                : isCoBorrower
                                                ? 'CoBorrower'
                                                : ''}
                                            </Text>
                                          </button>
                                        );
                                      }
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            position: snapshot.isDragging
                                              ? 'static'
                                              : 'relative',
                                            userSelect: 'none',
                                            padding: 8,
                                            margin: '8px',
                                            minHeight: '25px',
                                            flex: snapshot.isDragging ? 0 : 1,
                                            minWidth: 'calc(50% - 32px)',
                                            maxWidth: 'calc(50% - 32px)',
                                            textAlign: 'center',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            boxShadow:
                                              '0px 0px 5px 0px rgba(0,0,0,0.5)',
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : isPrimary || isCoBorrower
                                              ? theme[
                                                  `color-info-${Math.min(
                                                    columnIndex + 6,
                                                    9
                                                  )}00`
                                                ]
                                              : theme[
                                                  `color-primary-${Math.min(
                                                    columnIndex + 6,
                                                    9
                                                  )}00`
                                                ],
                                            color: 'white',
                                            zIndex: 2,
                                          }}
                                        >
                                          <h2
                                            style={{
                                              fontFamily: 'VerbBold',
                                              textAlign: 'center',
                                              fontSize: 16,
                                            }}
                                          >
                                            {item.firstName} {item.lastName}
                                          </h2>
                                          {snapshot.isDragging ? null : (
                                            <Text
                                              category="basic"
                                              appearance="hint"
                                              style={{
                                                fontSize: 10,
                                                color: 'white',
                                              }}
                                            >
                                              {isPrimary
                                                ? 'Primary'
                                                : isCoBorrower
                                                ? 'CoBorrower'
                                                : ''}
                                            </Text>
                                          )}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                              {column.items.length <= 1 && editable ? (
                                <Text
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#aeaeae',
                                    fontSize: 22,
                                    zIndex: 0,
                                  }}
                                >
                                  + Drag a Borrower
                                </Text>
                              ) : null}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </DragDropContext>
      </div>
      <Modal
        animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
        style={{ zIndex: 999 }}
        visible={payload}
        backdropStyle={{
          backgroundColor: Platform.OS === 'web' ? 'rgba(0,0,0,.5)' : '#fff',
        }}
      >
        <ModalWrapper>
          <Text style={{ textAlign: 'center' }} category="h6">
            You are changing the Primary Borrower. Would you like to proceed?
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: 16,
              justifyContent: 'flex-end',
              marginTop: 32,
            }}
          >
            <Button status="basic" onPress={() => setPayload(null)}>
              Cancel
            </Button>
            <Button
              status="info"
              onPress={() => {
                onDragEnd(payload, columns, setColumns);
                setPayload(null);
              }}
            >
              Accept
            </Button>
          </View>
        </ModalWrapper>
      </Modal>
      {/* @ts-ignore */}
      <Modal
        animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
        style={{ zIndex: 999 }}
        visible={showModifyBorrowerModal}
        backdropStyle={{
          backgroundColor: Platform.OS === 'web' ? 'rgba(0,0,0,.5)' : '#fff',
        }}
      >
        <ModalWrapper>
          {isDelete && borrowerEditData ? (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Text category="h6">
                Are you sure you want to delete this borrower?
              </Text>
              <Text
                style={{
                  marginTop: 8,
                  fontSize: 16,
                  fontFamily: 'VerbBold',
                }}
              >
                {borrowerEditData.firstName} {borrowerEditData.lastName}
              </Text>
              <Text style={{ color: 'red', fontSize: 12, marginTop: 16 }}>
                This action can not be undone.
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  gap: 16,
                  justifyContent: 'flex-end',
                  marginTop: 32,
                }}
              >
                <Button
                  disabled={isDeleting || isPatching || isDeletingPair}
                  status="basic"
                  onPress={() => {
                    setBorrowerEditData(null);
                    setShowModifyBorrowerModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isDeleting || isPatching || isDeletingPair}
                  status="danger"
                  onPress={async () => {
                    if (borrowerEditData) {
                      await handleDeleteBorrower(
                        borrowerEditData.id,
                        borrowerEditData['@id']
                      );
                      setBorrowerEditData(null);
                      setShowModifyBorrowerModal(false);
                    }
                  }}
                >
                  {isDeleting || isPatching || isDeletingPair
                    ? (props: any) => (
                        <>
                          <ActivityIndicator />
                          <Text style={props.style}>DELETE</Text>
                        </>
                      )
                    : 'DELETE'}
                </Button>
              </View>
            </View>
          ) : (
            <CreateNewBorrower
              defaultData={{
                loanIRI: loanData['@id'],
                loanId: loanData.id,
                borrowerData: borrowerEditData,
              }}
              cancelAction={() => {
                setBorrowerEditData(null);
                setShowModifyBorrowerModal(false);
              }}
            />
          )}
        </ModalWrapper>
      </Modal>
    </div>
  );
}

export default BorrowerDND;
