import React, { useLayoutEffect, useState } from 'react';
import {
  ScrollView, View, StyleSheet, Platform,
} from 'react-native';
import {
  Button, ButtonGroup, Layout, Text,
} from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../../components-global/ErrorBoundary';
import { LeadsMobilePills, QuickActionFAB } from '../../components-global';
import { useRoute, useNavigation } from '../../navigation/router';
import { FileIcon, UploadIcon } from '../../components-ui/Icons';
import { MobileNavigation } from '../../components-global/MobileNavigation';
import {
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
} from '../../services/LeadApiSlice';
import Table from '../../components-global/Tables/Table';
import {
  selectLocalViewState,
  selectQueryString,
  setGlobalSearch,
  setPage,
  toggleCategoryFilter,
} from '../../services/TableSlice';
import { showModal as realShow } from '../../services/FormSlice';
import { RootState } from '../../services/store';

type Props = {
  navigation: any;
};

const categoryFilterMap = [
  {
    objectName: 'Lead',
    filter: {
      column: 'isPartner',
      value: 'true',
      operator: 'eq',
    },
    selectedCategory: 0,
  },
  {
    objectName: 'Lead',
    filter: {
      column: 'status',
      value: 'New',
      operator: 'eq',
    },
    selectedCategory: 1,
  },
  {
    objectName: 'Lead',
    filter: [
      {
        column: 'status',
        value: 'New',
        operator: 'eq',
      },
      {
        column: 'status',
        value: 'In Progress',
        operator: 'eq',
      },
      {
        column: 'status',
        value: 'Contacted - No answer',
        operator: 'eq',
      },
      {
        column: 'status',
        value: 'On Hold',
        operator: 'eq',
      },
    ],
    selectedCategory: 2,
  },
  {
    objectName: 'Lead',
    filter: {
      column: 'status',
      value: 'Unqualified',
      operator: 'eq',
    },
    selectedCategory: 3,
  },
];

let timeout: any = null;
export function LeadsLandingPage({ navigation }: Props) {
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { defaultData } = useSelector((state:RootState) => state.form);
  const [searchTerm, setSearchTerm] = useState('');
  const route = useRoute();
  const { navigate } = useNavigation();
  const localViewState = useSelector((state) => selectLocalViewState(state, 'Lead'));
  const { activeView: savedView } = localViewState;
  const selectedCategory: number = savedView?.selectedCategory || 0;
  const queryString = useSelector((state) => selectQueryString(state, 'Lead'));
  const toggleCategory = (index: number) => {
    dispatch(toggleCategoryFilter(categoryFilterMap[index]));
  };

  const { data: leads } = useGetLeadsQuery(queryString);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setPage({ objectName: 'Lead', page: 1 }));
      dispatch(
        setGlobalSearch({ objectName: 'Lead', globalSearch: value || '' }),
      );
    }, 500);
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      route.setOptions({ headerShown: false });
    }
  }, [route]);

  return (
    <Layout style={{ flex: 1 }}>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1, padding: Platform.OS === 'web' ? 0 : 0 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 24,
              marginBottom: 24,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text category="h1">Leads</Text>
              <ButtonGroup style={{ marginLeft: 24 }} status="basic">
                <Button
                  style={selectedCategory === 0 && styles.selected}
                  onPress={() => {
                    toggleCategory(0);
                  }}
                >
                  Partners
                </Button>
                <Button
                  style={selectedCategory === 1 && styles.selected}
                  onPress={() => {
                    toggleCategory(1);
                  }}
                >
                  New Leads
                </Button>
                <Button
                  style={selectedCategory === 2 && styles.selected}
                  onPress={() => {
                    toggleCategory(2);
                  }}
                >
                  Active Leads
                </Button>
                <Button
                  style={selectedCategory === 3 && styles.selected}
                  onPress={() => {
                    toggleCategory(3);
                  }}
                >
                  Unqualified Leads
                </Button>
              </ButtonGroup>
            </View>
            <View style={styles.createLeadWrap}>
              <Button
                status="basic"
                style={{ marginRight: 15 }}
                accessoryLeft={UploadIcon}
                onPress={() => navigate(
                  { path: '/leads/import' },
                  { type: 'leads' },
                )}
              >
                Import Leads
              </Button>
              <Button
                status="danger"
                appearance="ghost"
                style={{ backgroundColor: '#ffefef' }}
                accessoryLeft={FileIcon}
                onPress={() => showModal({ formType: 'createLead' })}
              >
                Create Lead
              </Button>
            </View>
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{
                flex: 1,
              }}
            >
              <ErrorBoundary>
                <Table dataHandler={useLazyGetLeadsQuery} objectName="Lead" />
              </ErrorBoundary>
            </ScrollView>
            {/* <View style={{ flex: 1, marginTop: 164, marginLeft: 16 }}>
                <AvailableLeads />
              </View> */}
          </View>
        </View>
      ) : (
        <>
          <MobileNavigation
            searchTerm={searchTerm}
            setSearchTerm={handleSearch}
            title="Leads"
            totalItems={leads ? leads['hydra:totalItems'] : null}
          />
          <View style={{ flex: 1 }}>
            <LeadsMobilePills
              toggleFilter={selectedCategory}
              setToggleFilter={(nextValue: number) => toggleCategory(nextValue)}
            />
            <ErrorBoundary>
              <Table dataHandler={useLazyGetLeadsQuery} objectName="Lead" />
            </ErrorBoundary>
          </View>
        </>
      )}
      {Platform.OS !== 'web' ? (
        <>
          {defaultData?.contactList?.length ? null : (
            <QuickActionFAB
              onlyShow={['createLead', 'logCall', 'logMeeting']}
            />
          )}
        </>
      ) : null}
    </Layout>
  );
}

const styles = StyleSheet.create({
  selected: {
    backgroundColor: '#c8def5',
  },
  createLeadWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
    paddingRight: 16,
  },
});
