import React, { useEffect } from 'react';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import Svg from 'react-native-svg';
import {
  VictoryAnimation,
  VictoryLabel,
  VictoryPie,
  VictoryTheme,
} from 'victory-native';
import { Button, Card, Icon, IconElement, Text } from '@ui-kitten/components';

import { Tier, useGetTiersQuery } from '../../../services/TierSlice';
import {
  useGetMeQuery,
  usePatchUserMutation,
} from '../../../services/UserSlice';
import routes from '../../../navigation/routes';
import { Link } from '../../../components/Navigation/Link';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { isAfter, isBefore, startOfQuarter } from 'date-fns';

const colorScheme = ['#f4511e', '#eee', '#dc67d1', '#dc67a3', '#dc6771'];
const chartSize = 450;
const chartWidth = Dimensions.get('window').width > 500 ? chartSize + 100 : 325;

function LeftIcon(props): IconElement {
  return (
    <Icon
      {...props}
      style={[props.style, { width: 32, height: 32 }]}
      name="chevron-left"
    />
  );
}

function RightIcon(props): IconElement {
  return (
    <Icon
      {...props}
      style={[props.style, { width: 32, height: 32 }]}
      name="chevron-right"
    />
  );
}

export default function GoalTier(props) {
  const { data: tiers, isSuccess: tiersLoaded } = useGetTiersQuery({});
  const { data: user, isSuccess: userLoaded } = useGetMeQuery({});
  const [pieData, setPieData] = React.useState([]);
  const [patchUser] = usePatchUserMutation();
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  const closedLoanAmountQTD = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.phase &&
          loan.phase === 'Closed Loan' &&
          loan.closeDate &&
          loan.loanAmount &&
          isAfter(new Date(loan.closeDate), startOfQuarter(new Date())) &&
          isBefore(new Date(loan.closeDate), new Date())
        ) {
          return acc + loan.loanAmount;
        }
        return acc;
      }, 0);

  const tiersArray: Tier[] = tiersLoaded ? tiers['hydra:member'] : [];

  const getData = (percent) => [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];

  const baseTier = {
    percentage: 0,
    min: 0,
    tier: 0,
  };

  // LOCAL STATE
  const [selectedTierIndex, setSelectedTierIndex] = React.useState(1);
  const [goalText, setGoalText] = React.useState(false);
  const selectedTier = tiersLoaded
    ? tiersArray[selectedTierIndex - 1]
    : baseTier;

  useEffect(() => {
    if (userLoaded) {
      setSelectedTierIndex(user?.profileGoalTier || 1);
    }
  }, [user?.profileGoalTier, userLoaded]);

  const updateGoal = (index) => {
    setSelectedTierIndex(index);
    patchUser({
      id: user.id,
      profileGoalTier: index,
    });
  };

  const textForGoal = goalText
    ? `Tier ${selectedTier.tier}`
    : selectedTier.percentage;
  let divided = closedLoanAmountQTD / selectedTier.min;
  // Handle NaN and Infinity
  divided = Number.isNaN(divided) || !Number.isFinite(divided) ? 0 : divided;
  const percentComplete = Math.round(divided * 100);
  // const pieData = getData(percentComplete);
  useEffect(() => {
    setPieData(getData(percentComplete));
  }, [percentComplete]);
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Card
      style={{}}
      header={(props) => (
        <View {...props}>
          <Text style={{ textAlign: 'center' }} category="h5">
            Goal Tier
          </Text>
        </View>
      )}
      footer={(props) => (
        <View {...props}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              appearance="ghost"
              accessoryLeft={LeftIcon}
              onPress={() => updateGoal(selectedTierIndex - 1)}
              disabled={selectedTierIndex === 1}
            />
            <TouchableOpacity
              onPress={() => {
                setGoalText(!goalText);
              }}
            >
              <Text style={{ fontSize: 35, fontFamily: 'VerbBold' }}>
                {textForGoal}
              </Text>
            </TouchableOpacity>
            <Button
              appearance="ghost"
              accessoryLeft={RightIcon}
              onPress={() => updateGoal(selectedTierIndex + 1)}
              disabled={selectedTierIndex === 7}
            />
          </View>
        </View>
      )}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: Platform.OS === 'android' ? 0 : 16,
          flex: 1,
          flexWrap: 'wrap',
          minWidth: 200,
        }}
      >
        <View
          style={{
            margin: -24,
          }}
        >
          <Link
            route={routes.MenuItems.Pipeline}
            queryParams={{
              dataType: 'leads',
            }}
          >
            <Svg
              viewBox={`0 0 ${chartSize} ${chartSize}`}
              height={chartSize}
              width={chartWidth}
            >
              <VictoryPie
                key={percentComplete}
                standalone={false}
                height={chartSize}
                width={chartSize}
                animate={{ duration: 1000 }}
                data={pieData}
                innerRadius={chartSize / 3.5}
                labelRadius={chartSize / 3.5}
                colorScale={colorScheme}
                labels={() => null}
                theme={VictoryTheme.material}
              />
              <VictoryAnimation duration={1000} data={pieData}>
                {() => (
                  <VictoryLabel
                    textAnchor="middle"
                    verticalAnchor="middle"
                    x={chartSize / 2}
                    y={chartSize / 2}
                    text={
                      percentComplete >= 100
                        ? 'Achieved'
                        : `${Math.round(percentComplete)}%`
                    }
                    style={{
                      fontSize: percentComplete >= 100 ? 14 : 16,
                      fontFamily: 'VerbBold',
                    }}
                  />
                )}
              </VictoryAnimation>
            </Svg>
          </Link>
        </View>
      </View>
    </Card>
  );
}
