import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { View, Dimensions, Platform, Animated } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  Button, Divider, Icon, Layout as UILayout, useTheme, Avatar, Text
} from '@ui-kitten/components';
import { useDispatch } from 'react-redux';
import $t from '../../../i18n';
import routes from '../../navigation/routes';
import routeDetails from '../../navigation/routeDetails';
import { setImpersonation } from '../../services/ImpersonationSlice';
import AllModals from '../AllModals';
import { useRoute } from '../../navigation/router';

function Layout(props) {
  const [originalUser, setOriginalUser] = React.useState(true);
  const [impersonateName, setImpersonateName] = React.useState();
  const [pathHovered, setPathHovered] = React.useState();
  const { children } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRoute()
  useEffect(() => {
    AsyncStorage.getItem('@impersonating').then((res) => {
      if (res) {
        setOriginalUser(false);
        const option = JSON.parse(res);
        setImpersonateName(option.name);
        dispatch(setImpersonation(option));
      } else {
        setOriginalUser(true);
      }
    });
  }, []);


  const ImpersonationBar = () => (
    !originalUser && (
      <div
        style={{
          width: '100%',
          height: 40,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme['color-danger-600'],
        }}
      >
        <Button
          onPress={() => {
            AsyncStorage.removeItem('@impersonating').then(() => {
              window.location.reload();
            });
          }}
          color="white"
          appearance="ghost"
          accessoryLeft={(iconProps) => <Icon {...iconProps} name="log-out-outline" />}
        >
          Current User:
          {' '}
          {impersonateName}
        </Button>
      </div>
    )
  );
  const windowHeight = Dimensions.get('window').height;
  return (
    <UILayout style={{ flex: 1, height: windowHeight, position: 'relative' }}>
      <ImpersonationBar />
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={{ backgroundColor: '#224973' }}>
          {Platform.OS === 'web' ? (
            <Animated.View style={{ width: '100%', height: 80, justifyContent: 'center', alignItems: 'center', borderBottomWidth: 1, borderColor: '#eee', backgroundColor: '#c9e2ff' }}>
              <Avatar
                size={Platform.OS === 'web' ? "large" : 'small'}
                source={require('../../../assets/images/SAIL-1024.png')}
              />
            </Animated.View>
          ) : null}
          {Object.values(routes.MenuItems).map((mit) => (
            <Link
              key={mit.name}
              to={mit.path}
              style={{ textDecoration: 'none' }}
            >
              <View
                onMouseOver={() => setPathHovered(mit.path)}
                onMouseLeave={() => setPathHovered(null)}
                style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 20, fill: window.location.pathname.includes(mit.path) ? '#65a3d8' : pathHovered === mit.path ? '#8BC1E7' : '#ddd', color: window.location.pathname.includes(mit.path) ? '#65a3d8' : pathHovered === mit.path ? '#8BC1E7' : '#ddd' }
                }>
                <Icon
                  fill='inherit'
                  style={{ width: 24, height: 24, color: 'inherit' }}
                  name={routeDetails[mit.name].icon}
                  pack={routeDetails[mit.name].iconPack || 'eva'} />
                <Text
                  category='label'
                  style={{ color: 'inherit', marginTop: 8 }}>
                  {$t(routeDetails[mit.name].text)}</Text>
              </View>
              <Divider style={{ width: '100%', backgroundColor: '#aaa' }} />
            </Link>
          ))}
        </View>
        <Divider style={{ width: 1, height: '100&' }} />
        <View style={{ flex: 1 }}>
          {children}
          <AllModals />
        </View>
      </View>

    </UILayout>
  );
}

export default Layout;
