import React, { useContext } from 'react';

import { StyleSheet, View } from 'react-native';
import {
  Button,
  Text,
  MenuItem,
  OverflowMenu,
  Icon,
  Layout,
} from '@ui-kitten/components';
import {
  TaskIcon,
  NoteIcon,
  EventIcon,
  PeopleIcon,
  FileIcon,
  PhoneIcon,
} from '../components-ui/Icons';
import { useDispatch } from 'react-redux';
import { showModal as realShow } from '../services/FormSlice';

type Props = {
  title: string;
  status: string;
  icon: string;
  type: string;
  clientData?: any;
};

function QuickActionsDropDown({
  title,
  status,
  icon,
  type,
  clientData = null,
}: Props) {
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const ButtonIcon = (props) => (
    <Icon {...props} style={{ ...props.style, height: 16 }} name={icon} />
  );
  const renderToggleButton = () => (
    <Button
      appearance="ghost"
      accessoryRight={ButtonIcon}
      status={status}
      size={'medium'}
      onPress={() => setVisible(true)}
      style={{
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 7,

        elevation: 5,
      }}
    >
      <Text style={{ color: '#262626' }}>{title}</Text>
    </Button>
  );

  return (
    <Layout level="1" style={styles.buttonContainer}>
      <OverflowMenu
        anchor={renderToggleButton}
        visible={visible}
        placement="bottom end"
        onBackdropPress={() => setVisible(false)}
      >
        {type === 'contacts' ? (
          <>
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'createLead' });
              }}
              accessoryLeft={PeopleIcon}
              title="Create Lead"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'createContact' });
              }}
              style={{ width: 140 }}
              accessoryLeft={PeopleIcon}
              title="Create Contact"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({
                  formType: 'createScenario',
                  data: { type: 'Contact', create: true },
                });
              }}
              style={{ width: 140 }}
              accessoryLeft={FileIcon}
              title="Create Scenario"
            />
          </>
        ) : null}
        {type === 'createTask' && !clientData ? (
          <>
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'logCall' });
              }}
              accessoryLeft={PhoneIcon}
              title="Log Call"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'task' });
              }}
              accessoryLeft={TaskIcon}
              title="Create Task"
            />
          </>
        ) : null}
        {type === 'task' && !clientData ? (
          <>
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'logCall' });
              }}
              accessoryLeft={PhoneIcon}
              title="Log Call"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'logMeeting' });
              }}
              accessoryLeft={PeopleIcon}
              title="Log Meeting"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'task' });
              }}
              accessoryLeft={TaskIcon}
              title="Create Task"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'note' });
              }}
              accessoryLeft={NoteIcon}
              title="Create Note"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'event' });
              }}
              accessoryLeft={EventIcon}
              title="Create Event"
            />
          </>
        ) : null}
        {clientData ? (
          <>
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'logCall', data: clientData });
              }}
              accessoryLeft={PhoneIcon}
              title="Log Call"
            />
            <MenuItem
              onPress={() => {
                setVisible(false);
                showModal({ formType: 'logMeeting', data: clientData });
              }}
              accessoryLeft={PeopleIcon}
              title="Log Meeting"
            />
          </>
        ) : null}
      </OverflowMenu>
    </Layout>
  );
}

export default QuickActionsDropDown;

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 80,
    marginHorizontal: 16,
  },
});
