import { apiSlice } from './ApiSlice';

export const TierSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTiers: build.query({
      query: () => '/tiers',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTiersQuery,
} = TierSlice;

export type Tier = {
  id: number;
  selectedIndex: number;
  min: number;
  max: number;
  rate: number;
  percentage: string;
  tier: number;
  quarter: number;
  year: number;
};
