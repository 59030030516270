import React, { useEffect } from 'react';
import {
  Icon,
  IconElement,
  Layout,
  MenuItem,
  TopNavigation,
  TopNavigationAction,
  Text,
  Modal,
  Button,
} from '@ui-kitten/components';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { TouchableWebElement } from '@ui-kitten/components/devsupport';
import { DownloadIcon, EmailIcon } from '../../../../components-ui/Icons';
import Colors from '../../../../helpers/Colors';
import SendEmailModal from './SendEmailModal';

type PreviewProps = {
  handleSend: () => void;
  handleDownload: () => void;
  borrowerName: string;
  borrowerEmail: string;
  cancelAction: () => void;
  error: string | null;
  sendSuccess: boolean | null;
  isSending: boolean | null;
};

const BackIcon = (props): IconElement => (
  <Icon {...props} fill="white" name="close" />
);

export const PreviewHeader = (props: PreviewProps): React.ReactElement => {
  const {
    handleDownload,
    borrowerName,
    borrowerEmail,
    cancelAction,
    handleSend,
    sendSuccess,
    error,
    isSending,
  } = props;
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);
  const toggleMenu = (): void => {
    setMenuVisible(!menuVisible);
  };

  const renderRightActions = (): React.ReactElement => (
    <>
      <MenuItem
        style={{ backgroundColor: Colors.darkBlue }}
        onPress={() => {
          toggleMenu();
          setTimeout(() => {
            handleDownload();
          }, 50);
        }}
        accessoryLeft={DownloadIcon}
        title={() => (
          <Text
            style={{
              fontFamily: 'VerbBold',
              marginBottom: 4,
              color: 'white',
              marginTop: 6,
              paddingRight: 8,
            }}
          >
            Download
          </Text>
        )}
      />
      <MenuItem
        style={{ backgroundColor: Colors.darkBlue }}
        onPress={() => {
          toggleMenu();
          setTimeout(() => {
            setShowEmail(true);
          }, 50);
        }}
        accessoryLeft={EmailIcon}
        title={() => (
          <Text
            style={{
              fontFamily: 'VerbBold',
              marginBottom: 4,
              color: 'white',
              marginTop: 6,
              paddingRight: 8,
            }}
          >
            Send
          </Text>
        )}
      />
    </>
  );

  const renderBackAction = (): TouchableWebElement => (
    <TopNavigationAction onPress={() => cancelAction()} icon={BackIcon} />
  );

  useEffect(() => {
    if (Boolean(sendSuccess)) {
      setTimeout(() => {
        setShowEmail(false);
      }, 2500);
    }
  }, [sendSuccess]);

  return (
    <Layout style={styles.container} level="1">
      <TopNavigation
        style={{ backgroundColor: Colors.darkBlue }}
        alignment="center"
        title={() => (
          <Text
            style={{ fontFamily: 'VerbBold', marginBottom: 4, color: 'white' }}
          >
            {borrowerName}
          </Text>
        )}
        subtitle="Pre-Approval"
        accessoryLeft={renderBackAction}
        accessoryRight={renderRightActions}
      />
      <SendEmailModal
        showEmail={showEmail}
        isSending={isSending}
        sendSuccess={sendSuccess}
        error={error}
        borrowerEmail={borrowerEmail}
        borrowerName={borrowerName}
        handleSend={handleSend}
        setShowEmail={setShowEmail}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 32,
    marginHorizontal: -24,
    position: 'relative',
    borderBottomColor: '#eee',
    borderBottomWidth: 1,
    marginBottom: 8,
  },
});
