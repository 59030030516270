import React, { Component } from 'react';
import {
  Text, StyleSheet,
} from 'react-native';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#629D10' : 'rgb(237, 241, 247)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#E2F6FD' : 'none',
  padding: grid,
  overflow: 'auto',
  scroll: 'auto',
});

interface Props {
  visibleColumns: string[];
  setVisibleColumns: Function;
  allColumns: {};
}

// For some reason this needs to be a class component to use refs
class DraggableList extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.visibleColumns,
      result.source.index,
      result.destination.index,
    );

    this.props.setVisibleColumns(items);
  };

  render() {
    const { visibleColumns, allColumns } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {visibleColumns.map((item, index) => {
                const column = allColumns[item];
                return (
                  <Draggable key={column.name} draggableId={column.name} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        style={getItemStyle(
                          dragSnapshot.isDragging,
                          dragProvided.draggableProps.style,
                        )}
                      >
                        <Text>{column.label}</Text>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DraggableList;

const styles = StyleSheet.create({
  rowItem: {
    alignItems: 'center',
    backgroundColor: 'rgb(237, 241, 247)',
    marginBottom: 8,
    flexDirection: 'row',
    borderRadius: 4,
    padding: 8,
    marginRight: 8,
  },
  text: {
    color: '#346bb3',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'left',
  },
});
