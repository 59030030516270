import React, { useEffect, useRef, useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { View, StyleSheet, Platform } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import {
  Text,
  Input,
  Layout,
  Datepicker,
  Button,
  Icon,
} from '@ui-kitten/components';
import FormGroup from '../FormGroup';
import {
  useCreateBorrowerMutation,
  usePatchBorrowerMutation,
} from '../../../services/BorrowerApiSlice';
import FormSuccess from '../../../components-ui/FormSuccess';
import { add, endOfDay, format, formatISO, parse } from 'date-fns';
//@ts-ignore
import GlobalSearchAutoComplete from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
//@ts-ignore
import { dataTransformer } from '../../../components-ui/GlobalSearchAutoComplete';
import {
  dateOfBirthFormat,
  phoneFormat,
  taxpayerIdFormat,
} from '../../../helpers/inputFormatFns';
import moment from 'moment';

function CreateNewBorrower({ defaultData, cancelAction }: any) {
  const { borrowerData, loanId, loanIRI } = defaultData;
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const calendarRef = useRef<any>(null);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(borrowerData ? skipToken : query);
  const [createBorrower, { isSuccess: createSuccess, isLoading: isCreating }] =
    useCreateBorrowerMutation();

  const [patchBorrower, { isSuccess: patchSuccess, isLoading: isPatching }] =
    usePatchBorrowerMutation();

  const {
    control,
    handleSubmit,
    setFocus,
    setValue,
    register,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      loan: loanIRI,
      firstName: borrowerData?.firstName || null,
      lastName: borrowerData?.lastName || null,
      middleName: borrowerData?.middleName || null,
      dateOfBirth: borrowerData?.dateOfBirth
        ? moment(borrowerData?.dateOfBirth.split('T')[0]).format('MM/DD/YYYY')
        : null,
      email: borrowerData?.email || null,
      homePhone: borrowerData?.homePhone || null,
      taxpayerIdentifier: borrowerData?.taxpayerIdentifier || null,
      borrowerAddresses: {
        houseNumber: borrowerData?.borrowerAddresses?.[0]?.houseNumber || null,
        street: borrowerData?.borrowerAddresses?.[0]?.street || null,
        apartment: borrowerData?.borrowerAddresses?.[0]?.apartment || null,
        city: borrowerData?.borrowerAddresses?.[0]?.city || null,
        state: borrowerData?.borrowerAddresses?.[0]?.state || null,
        zipCode: borrowerData?.borrowerAddresses?.[0]?.zipCode || null,
        addressType:
          borrowerData?.borrowerAddresses?.[0]?.addressType || 'current',
      },
    },
  });

  const onSubmit = async (data) => {
    //loop throught and remove any null values
    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
      if (typeof data[key] === 'object' && data[key] !== null) {
        for (const key2 in data[key]) {
          if (data[key][key2] === null) {
            delete data[key][key2];
          }
        }
      }
    }
    data.borrowerAddresses = [data.borrowerAddresses];

    if (data?.dateOfBirth) {
      data.dateOfBirth = moment(data.dateOfBirth, 'MM/DD/YYYY').toISOString();
    }
    if (borrowerData?.['@id']) {
      patchBorrower({
        loanId: loanId,
        borrowerId: borrowerData.id,
        borrower: data,
      });
    } else {
      createBorrower({
        loanId: loanId,
        borrower: data,
      });
    }
  };

  useEffect(() => {
    if (!isFetchingContacts) {
      if (page === 1) {
        setAutoCompleteData([...dataTransformer(contactsData)]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
        ]);
      }
    }
  }, [isFetchingContacts]);

  useEffect(() => {
    if (selectedAssociate?.['@id']) {
      setValue('firstName', selectedAssociate.firstName || null);
      setValue('lastName', selectedAssociate.lastName || null);
      setValue('middleName', selectedAssociate.middleName || null);
      setValue('email', selectedAssociate.email || null);
      setValue('homePhone', selectedAssociate.phone || null);
      if (selectedAssociate?.address) {
        let houseNumber = selectedAssociate?.address?.houseNumber;
        let street = selectedAssociate?.address?.streetAddress;
        if (street && !houseNumber) {
          const houseNumberRegex = /\d+/g;
          const houseNumberMatch =
            selectedAssociate?.address?.streetAddress.match(houseNumberRegex);
          if (houseNumberMatch) {
            houseNumber = houseNumberMatch[0];
            street = street.replace(`${houseNumber} `, '');
          }
        }
        setValue('borrowerAddresses.houseNumber', houseNumber || null);
        setValue('borrowerAddresses.street', street || null);
        setValue(
          'borrowerAddresses.apartment',
          selectedAssociate.address?.apartment || null
        );
        setValue(
          'borrowerAddresses.city',
          selectedAssociate.address?.city || null
        );
        setValue(
          'borrowerAddresses.state',
          selectedAssociate.address?.state || null
        );
        setValue(
          'borrowerAddresses.zipCode',
          selectedAssociate.address?.zipCode || null
        );
      }
    }
  }, [selectedAssociate]);

  useEffect(() => {
    if (patchSuccess || createSuccess) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [patchSuccess, createSuccess]);

  const DOB = watch('dateOfBirth')?.replace(/\D/g, '') ?? '';
  const formattedDOB =
    DOB && DOB.match(/^(\d{2})(\d{2})(\d{4})$/)
      ? new Date(moment(DOB, 'MMDDYYYY').toISOString())
      : new Date();

  return (
    <Layout
      level="1"
      style={{
        flex: 1,
        position: 'relative',
        minWidth: Platform.OS === 'web' ? 620 : 'auto',
        minHeight: Platform.OS === 'web' ? 620 : 'auto',
      }}
    >
      <Header
        heading={borrowerData?.['@id'] ? 'Edit Borrower' : 'Create A Borrower'}
      />
      <View>
        {!borrowerData ? (
          <View style={{ marginBottom: 20, position: 'relative', zIndex: 999 }}>
            <FormGroup label="Create Borrower from Contact" required={false}>
              {autoCompleteData && (
                <GlobalSearchAutoComplete
                  clearAutoCompleteData={() => setAutoCompleteData([])}
                  autoCompleteData={autoCompleteData}
                  isFetching={isFetchingContacts}
                  setQuery={setQuery}
                  handleOnChange={(e) => setSelectedAssociate(e)}
                  defaultValue={undefined}
                  listDirection="down"
                  page={page}
                  setPage={setPage}
                  selectedAssociate={selectedAssociate}
                />
              )}
            </FormGroup>
          </View>
        ) : null}

        <View
          style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}
        >
          <View style={{ flex: 1 }}>
            <FormGroup label="First Name" required>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('firstName')}
                    returnKeyType="next"
                    onSubmitEditing={(props) => setFocus('middleName')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="firstName"
              />
            </FormGroup>
            {errors.firstName && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Middle Name" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('middleName')}
                    returnKeyType="next"
                    onSubmitEditing={(props) => setFocus('lastName')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="middleName"
              />
            </FormGroup>
            {errors.middleName && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Last Name" required>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('lastName')}
                    returnKeyType="next"
                    onSubmitEditing={(props) => setFocus('dateOfBirth')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="lastName"
              />
            </FormGroup>
            {errors.lastName && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
        </View>
        <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column',  }}>
          <View style={{ flex: 1 }}>
            <FormGroup label="Date Of Birth" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={{ position: 'relative' }}>
                    <Input
                      {...register('dateOfBirth')}
                      returnKeyType="next"
                      onSubmitEditing={(props) => setFocus('email')}
                      onBlur={onBlur}
                      inputMode="numeric"
                      placeholder="MM/DD/YYYY"
                      onKeyPress={(e) => {
                        if (
                          e.nativeEvent.key === 'Backspace' &&
                          value[value.length - 1] === '/'
                        ) {
                          onChange(value.slice(0, -1));
                        }
                      }}
                      onChangeText={(value) =>
                        onChange(dateOfBirthFormat(value))
                      }
                      value={value}
                      maxLength={10}
                    />
                    <Button
                      style={{ position: 'absolute', top: 0, right: 2 }}
                      onPress={() => calendarRef?.current?.focus()}
                      appearance="ghost"
                      status="info"
                      size="tiny"
                      accessoryLeft={() => (
                        <Icon
                          style={{
                            width: 24,
                            height: 24,
                          }}
                          fill="#8F9BB3"
                          name="calendar-outline"
                        />
                      )}
                    />
                    <Datepicker
                      min={new Date('1900-01-01')}
                      date={formattedDOB}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        left: 0,
                        opacity: 0,
                        width: 0,
                      }}
                      ref={calendarRef}
                      onSelect={(date) => {
                        onChange(dateOfBirthFormat(format(date, 'MM-dd-yyyy')));
                      }}
                    />
                  </View>
                )}
                name="dateOfBirth"
              />
            </FormGroup>
            {errors.dateOfBirth && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Email Address" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('email')}
                    returnKeyType="next"
                    onSubmitEditing={(props) => setFocus('homePhone')}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="email"
              />
            </FormGroup>
            {errors.email && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Phone Number" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('homePhone')}
                    returnKeyType="next"
                    onSubmitEditing={(props) => setFocus('taxpayerIdentifier')}
                    inputMode="tel"
                    onBlur={onBlur}
                    maxLength={12}
                    placeholder="XXX-XXX-XXXX"
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.key === 'Backspace' &&
                        value[value.length - 1] === '-'
                      ) {
                        onChange(value.slice(0, -1));
                      }
                    }}
                    onChangeText={(val) => {
                      const cleanedValue = phoneFormat(val);
                      onChange(cleanedValue);
                    }}
                    value={value?.length === 10 ? phoneFormat(value) : value}
                  />
                )}
                name="homePhone"
              />
            </FormGroup>
            {errors.homePhone && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <FormGroup
              label="Social Security/Taxpayer Identifier"
              required={false}
            >
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('taxpayerIdentifier')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.houseNumber')
                    }
                    inputMode="numeric"
                    onBlur={onBlur}
                    placeholder="XXX-XX-XXXX"
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.key === 'Backspace' &&
                        value[value.length - 1] === '-'
                      ) {
                        onChange(value.slice(0, -1));
                      }
                    }}
                    onChangeText={(val) => {
                      const cleanedValue = taxpayerIdFormat(val);
                      onChange(cleanedValue);
                    }}
                    maxLength={11}
                    value={value}
                  />
                )}
                name="taxpayerIdentifier"
              />
            </FormGroup>
            {errors.taxpayerIdentifier && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
        </View>
        <Text style={{ marginTop: 8, textAlign: 'center' }} category="label">
          Borrower Address
        </Text>
        <View
          style={{
            borderBottomColor: '#eee',
            borderBottomWidth: 1,
            paddingTop: 8,
            marginBottom: 16,
          }}
        />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="House #" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.houseNumber')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.street')
                    }
                    inputMode="numeric"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.houseNumber"
              />
            </FormGroup>
            {errors.borrowerAddresses?.houseNumber && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 5 }}>
            <FormGroup label="Street" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.street')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.apartment')
                    }
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.street"
              />
            </FormGroup>
            {errors.borrowerAddresses?.street && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 2 }}>
            <FormGroup label="Apartment #" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.apartment')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.city')
                    }
                    inputMode="numeric"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.apartment"
              />
            </FormGroup>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 4 }}>
            <FormGroup label="City" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.city')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.state')
                    }
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.city"
              />
            </FormGroup>
            {errors.borrowerAddresses?.city && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 2 }}>
            <FormGroup label="State" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.state')}
                    returnKeyType="next"
                    onSubmitEditing={(props) =>
                      setFocus('borrowerAddresses.zipCode')
                    }
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.state"
              />
            </FormGroup>
            {errors.borrowerAddresses?.state && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
          <View style={{ flex: 3 }}>
            <FormGroup label="Zipcode" required={false}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    {...register('borrowerAddresses.zipCode')}
                    maxLength={5}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="borrowerAddresses.zipCode"
              />
            </FormGroup>
            {errors.borrowerAddresses?.zipCode && (
              <Text style={styles.errorText}>This is required</Text>
            )}
          </View>
        </View>
      </View>
      {(!!patchSuccess || !!createSuccess) && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit(onSubmit)}
        disabled={
          (!isDirty && !selectedAssociate?.['@id']) ||
          isPatching ||
          isCreating ||
          patchSuccess ||
          createSuccess
        }
        submitText={borrowerData?.['@id'] ? 'EDIT' : 'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  errorText: {
    fontSize: 10,
    color: 'red',
    marginLeft: 12,
    marginTop: -8,
  },
});

export default CreateNewBorrower;
