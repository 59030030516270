import React from 'react';
import { Platform, View } from 'react-native';
import {
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import {
  Button, ButtonGroup, Card, Divider,
} from '@ui-kitten/components';
import { MatchType, Stat } from '../../interfaces/StatsInterfaces';
import ThisWeekLine from './ThisWeekLine';
import { useNavigation } from '../../navigation/router';
import routes from '../../navigation/routes';

const newLeadsFilter = (date: string): Stat => ({
  dataType: 'newLeadsThisWeek',
  resource: 'Lead',
  filters: [
    {
      column: 'createdAt',
      matchType: MatchType.greaterThanOrEquals,
      value: date,
      precision: 'exact',
    },
    {
      column: 'status',
      matchType: MatchType.equals,
      value: 'New',
      precision: 'exact',
    },
  ],
  aggregates: [{ column: 'id', type: 'count', alias: 'newLeadsThisWeek' }],
});

const completedSalesCallsFilter = (date: string): Stat[] => [
  {
    dataType: 'completedSalesCallsThisWeek',
    resource: 'Task',
    filters: [
      {
        column: 'completedAt',
        matchType: MatchType.greaterThanOrEquals,
        value: date,
        precision: 'exact',
      },
      {
        column: 'type',
        matchType: MatchType.equals,
        value: 'Sales Call',
        precision: 'exact',
      },
    ],
    aggregates: [
      { column: 'id', type: 'count', alias: 'completedSalesCallsThisWeek' },
    ],
  },
  {
    dataType: 'completedSalesCallsThisWeek',
    resource: 'Event',
    filters: [
      {
        column: 'completedAt',
        matchType: MatchType.greaterThanOrEquals,
        value: date,
        precision: 'exact',
      },
      {
        column: 'subject',
        matchType: MatchType.equals,
        value: 'Sales Call',
        precision: 'exact',
      },
    ],
    aggregates: [
      { column: 'id', type: 'count', alias: 'completedSalesCallsThisWeek' },
    ],
  },
];

const newProspectsFilter = (date: string): Stat => ({
  dataType: 'newProspectsThisWeek',
  resource: 'Loan',
  filters: [
    {
      column: 'createdAt',
      matchType: MatchType.greaterThanOrEquals,
      value: date,
      precision: 'exact',
    },
  ],
  aggregates: [{ column: 'id', type: 'count', alias: 'newProspectsThisWeek' }],
});
const newAppsFilter = (date: string): Stat => ({
  dataType: 'newAppsThisWeek',
  resource: 'Loan',
  filters: [
    {
      column: 'applicationDate',
      matchType: MatchType.greaterThanOrEquals,
      value: date,
      precision: 'exact',
    },
    {
      column: 'phase',
      matchType: MatchType.equals,
      value: 'Application',
      precision: 'exact',
    },
  ],
  aggregates: [{ column: 'id', type: 'count', alias: 'newAppsThisWeek' }],
});

export default function ThisWeekCard() {
  const { navigate } = useNavigation();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const modifiers = [startOfWeek, startOfMonth, startOfQuarter, startOfYear];
  const options = ['Week', 'Month', 'Quarter', 'Year'];

  return (
    <Card
      style={{
        minWidth: 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        // flexGrow: 1,
        // flexBasis: 0,
        // flexShrink: 0,
        flex: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: 6,
        }}
      >
        <ButtonGroup status="basic" size="small" appearance="outline">
          {options.map((option, index) => (
            <Button
              key={option}
              style={index === selectedIndex && { backgroundColor: '#c8def5' }}
              onPress={() => setSelectedIndex(index)}
            >
              {option}
            </Button>
          ))}
        </ButtonGroup>
      </View>

      <Divider />
      <ThisWeekLine
        filter={newLeadsFilter(
          modifiers[selectedIndex](new Date()).toISOString(),
        )}
        alias="newLeadsThisWeek"
        title="New Leads"
        onPress={() => navigate(routes.MenuItems.Leads)}
      />
      <Divider />
      <ThisWeekLine
        filter={completedSalesCallsFilter(
          modifiers[selectedIndex](new Date()).toISOString(),
        )}
        alias="completedSalesCallsThisWeek"
        title="Completed Sales Calls"
        onPress={() => navigate(routes.ExtraRoutes.Tasks)}
      />
      <Divider />
      <ThisWeekLine
        filter={newProspectsFilter(
          modifiers[selectedIndex](new Date()).toISOString(),
        )}
        alias="newProspectsThisWeek"
        title="New Prospects"
        onPress={() => navigate(routes.MenuItems.Pipeline)}
      />
      <Divider />
      <ThisWeekLine
        filter={newAppsFilter(
          modifiers[selectedIndex](new Date()).toISOString(),
        )}
        alias="newAppsThisWeek"
        title="New Apps"
        onPress={() => navigate(routes.MenuItems.Pipeline)}
      />
    </Card>
  );
}
