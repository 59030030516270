import React from 'react';
import {
  ImageBackground, Platform, View,
} from 'react-native';
import { Button, Text } from '@ui-kitten/components';
import * as WebBrowser from 'expo-web-browser';
import {
  exchangeCodeAsync,
  makeRedirectUri,
  useAuthRequest,
  useAutoDiscovery,
} from 'expo-auth-session';
import Colors from '../helpers/Colors';
import { secure } from '../../services/app/StorageService';
import { EXPO_PUBLIC_TENANT_ID, EXPO_PUBLIC_CLIENT_ID } from '@env';

WebBrowser.maybeCompleteAuthSession();

export default function SignInPage({ setIdToken, error }) {
  const backgroundImage = Platform.OS === 'web' ? require('../../assets/images/Sail-away-web.png') : require('../../assets/images/Sail-Away-MOBILE.png');
  const discovery = useAutoDiscovery(
    `https://login.microsoftonline.com/${EXPO_PUBLIC_TENANT_ID}/v2.0`,
  );
  const redirectUri = makeRedirectUri({
    scheme: 'sail',
    path: 'auth/done',
  });

  // We store the JWT in here
  const setToken = async (token) => {
    const {
      idToken, expiresIn, refreshToken, accessToken,
    } = token;
    const expireTime = new Date();
    expireTime.setSeconds(expireTime.getSeconds() + expiresIn);
    await secure.set('idToken', idToken);
    await secure.set('refreshToken', refreshToken);
    await secure.set('expireTime', expireTime.toISOString());
    await secure.set('accessToken', accessToken);
    setIdToken(idToken);
  };

  // Request
  const [request, , promptAsync] = useAuthRequest(
    {
      clientId: EXPO_PUBLIC_CLIENT_ID,
      scopes: ['openid', 'profile', 'email', 'offline_access', 'mail.send'],
      redirectUri,
    },
    discovery,
  );

  return (
    <ImageBackground
      source={backgroundImage}
      // resizeMode='contain'
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        flex: 1,
        paddingHorizontal: 16,
        paddingVertical: 24,
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
      }}
    >
      <View style={{
        maxWidth: 400,
        maxHeight: 800,
        flex: 1,
        justifyContent: 'space-between',
        marginTop: 24,
      }}
      >
        <View>
          <Text
            style={{
              zIndex: 1,
              alignSelf: 'center',
              marginTop: 24,
            }}
            category="h2"
            status="control"
          >
            Hello
          </Text>
          <Text
            style={{
              zIndex: 1,
              alignSelf: 'center',
              marginTop: 64,
            }}
            status="control"
          >
            Please sign in
          </Text>
        </View>
        {error && (
          <Text style={{ color: Colors.darkRed, marginBottom: 10 }}>
            An error occurred signing in.
          </Text>
        )}
        <Button
          size="giant"
          onPress={() => {
            promptAsync().then((codeResponse) => {
              if (request && codeResponse?.type === 'success' && discovery) {
                exchangeCodeAsync(
                  {
                    clientId: EXPO_PUBLIC_CLIENT_ID,
                    code: codeResponse.params.code,
                    extraParams: request.codeVerifier
                      ? { code_verifier: request.codeVerifier }
                      : undefined,
                    redirectUri,
                  },
                  discovery,
                ).then((res) => {
                  setToken(res);
                });
              }
            });
          }}
        >
          Sign in
        </Button>
      </View>
    </ImageBackground>
  );
}
