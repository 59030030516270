import { StyleSheet, View } from 'react-native';
import { Layout, Select, SelectItem } from '@ui-kitten/components';

export default function KittenSelect({
  selectedIndex,
  setSelectedIndex,
  options,
  size='medium',
  label='',
  level='1'
}) {
  // const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const displayValue = options[selectedIndex.row];

  const renderOption = (title) => <SelectItem key={title} title={title} />;

  return (
    <View style={styles.container} >
      <Select
        size={size}
        label={label}
        style={styles.select}
        placeholder="Default"
        value={displayValue}
        selectedIndex={selectedIndex}
        onSelect={(index: any) => setSelectedIndex(index)}
      >
        {options.map(renderOption)}
      </Select>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    flex: 1,
    margin: 2,
   
  },
});
