import { Layout, Text } from '@ui-kitten/components';
import moment from 'moment';
import React from 'react';
import { Pressable } from 'react-native';
import TimePickerModal from './Time/TimePickerModal';

type Props = {
  placeholder: string;
  value: string;
  onSelect: (value: string) => void;
};

function RNTimePicker({ placeholder = 'Select Time', value, onSelect }: Props) {

  const [visible, setVisible] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      setVisible(false);
      onSelect(moment().hours(hours).minutes(minutes).format('lll'));
      console.log({ hours, minutes });
    },
    [setVisible]
  );
  
  const hour = parseInt(moment(value, 'lll').format('H'));
  const minute = parseInt(moment(value, 'lll').format('mm'));
 

  return (
    <Layout
      level="2"
      style={{
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#ededed',
        height: 40,
      }}
    >
      <Pressable
        style={{ flex: 1, paddingLeft: 16 }}
        onPress={() => setVisible(true)}
      >
        <Text style={{ textAlign: 'left' }}>
          {value ? `${moment(value, 'lll').format('h:mm a')}` : placeholder}
        </Text>
      </Pressable>
      <TimePickerModal
        visible={visible}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        hours={hour}
        minutes={minute}
      />
    </Layout>
  );
}

export default RNTimePicker;
