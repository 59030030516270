import { apiSlice } from './ApiSlice';

export const PreApprovalLoanSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContactsPreApprovals: build.query({
      query: (id) => ({
        url: `/contacts/${id}/pre_approvals`,
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
            ...result['hydra:member'].map(({ id }) => ({
              type: 'PreApproval',
              id,
            })),
            { type: 'PreApproval', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'PreApproval', id: 'PARTIAL-LIST' }],
    }),
    getLoansPreApproval: build.query({
      query: (id) => ({
        url: `/loans/${id}/pre_approvals`,
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
            ...result['hydra:member'].map(({ id }) => ({
              type: 'PreApproval',
              id,
            })),
            { type: 'PreApproval', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'PreApproval', id: 'PARTIAL-LIST' }],
    }),
    getAllPreAprovals: build.query({
      query: (query) => ({
        url: `/pre_approvals?${query}`,
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
            ...result['hydra:member'].map(({ id }) => ({
              type: 'PreApproval',
              id,
            })),
            { type: 'PreApproval', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'PreApproval', id: 'PARTIAL-LIST' }],
    }),
    editPreapproval: build.mutation({
      query: ({ id, body }) => ({
        url: `/pre_approvals/${id}`,
        method: 'PATCH',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'PreApproval', id: arg.id }, { type: 'PreApproval', id: 'PARTIAL-LIST' }, { type: 'PreApproval', id: 'LIST' }]
    }),
    addNewPreApproval: build.mutation({
      query: (body) => ({
        url: '/pre_approvals',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'PreApproval', id: 'PARTIAL-LIST' },
      ],
    }),
    getPreApprovalPreview: build.query({
      query: (id) => ({
        url: `/pre_approvals/${id}/preview`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseHandler: (response) => response.blob(),
        providesTags: (result, error, arg) => [{ type: 'PreApproval', id: arg.id }],
      }),

    }),
    sendPreApproval: build.mutation({
      query: (id) => ({
        url: `/pre_approvals/${id}/send`,
        method: 'POST',
        body: { id: id },
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'PreApproval', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContactsPreApprovalsQuery,
  useGetLoansPreApprovalQuery,
  useGetAllPreAprovalsQuery,
  useAddNewPreApprovalMutation,
  useGetPreApprovalPreviewQuery,
  useSendPreApprovalMutation,
  useEditPreapprovalMutation,

} = PreApprovalLoanSlice;
