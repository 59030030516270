import { Divider, Layout, Text } from '@ui-kitten/components';
import moment from 'moment';
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  View,
  StyleSheet,
} from 'react-native';
// @ts-ignore
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import GoogleMap from '../../components/GoogleMap';
import PreapprovalList from './PreapprovalList';

function LoanDetailColumn({ loanData }) {
  return (
    <Layout
      level="2"
      style={{
        flex: 3,
        minWidth: Platform.OS === 'web' ? 485 : Dimensions.get('screen').width,
        borderRightWidth: 1,
        borderRightColor: '#aaa',
        paddingBottom: Platform.OS === 'web' ? 0 : 64,
      }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 24, paddingTop: 24 }}
      >
        <View style={{ flex: 1 }}>
          <View style={{ marginBottom: 24 }}>
            <PreapprovalList clientData={loanData} />
          </View>
          {/* Loan Details */}
          <Layout style={styles.cardWrapper}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
                position: 'relative',
              }}
            >
              <Text style={styles.cardHeader} category="h6">
                {loanData['@type']} Details
              </Text>
            </View>
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Loan Amount:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.loanAmount
                      ? currencyFormat(loanData.loanAmount)
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Loan Type:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.loanType || ''}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Sales Price:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.purchasePrice
                      ? currencyFormat(loanData.purchasePrice)
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Amortization Type:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.amortizationType || ''}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Assessed Value Price:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.estimatedHomeValue
                      ? currencyFormat(loanData.estimatedHomeValue)
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    LTV:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.loanToValue || ''}%
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Interest Rate:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.interestRate || ''}
                  </Text>
                </View>
              </View>

              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Term:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData?.term || ''}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Lock Date:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData?.lockDate
                      ? moment(loanData.lockDate).format('L')
                      : ''}
                  </Text>
                </View>
              </View>

              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Lock Expire:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData?.lockExpireDate
                      ? moment(loanData.lockExpireDate).format('L')
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </Layout>
          {/* Qualifications*/}
          <Layout style={styles.cardWrapper}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
                position: 'relative',
              }}
            >
              <Text style={styles.cardHeader} category="h6">
                Qualifications
              </Text>
            </View>
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Credit Score:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.creditScore || ''}
                  </Text>
                </View>
              </View>

              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Total DTI:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.dti || ' '}%
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Cash To Close:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.cashToClose
                      ? currencyFormat(loanData.cashToClose)
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
          </Layout>
          <Layout style={styles.cardWrapper}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
                position: 'relative',
              }}
            >
              <Text style={styles.cardHeader} category="h6">
                Property Details
              </Text>
              {loanData.propertyAddress &&
              loanData.propertyCity &&
              loanData.propertyState ? (
                <View style={{ position: 'absolute', right: 16 }}>
                  <Pressable
                    onPress={() => {
                      window.open(
                        `https://www.zillow.com/homes/${loanData.propertyAddress
                          .split(' ')
                          .join('-')},${loanData.propertyCity
                          .split(' ')
                          .join('-')},${loanData.propertyState}_rb/`,
                        'NewWindow'
                      );
                    }}
                  >
                    <Text style={{ color: '#fff' }}>Open in Zillow</Text>
                  </Pressable>
                </View>
              ) : null}
            </View>
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Address:
                  </Text>
                </View>

                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData.propertyAddress}
                  </Text>
                </View>
              </View>
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    City:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.propertyCity}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    State:
                  </Text>
                </View>

                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData.propertyState}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Zip:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.propertyZip}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Property Type:
                  </Text>
                </View>

                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData.propertyType}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Appraised Value:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.estimatedHomeValue
                      ? currencyFormat(loanData.estimatedHomeValue)
                      : ''}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Occupancy:
                  </Text>
                </View>

                <View>
                  <Text category="label" style={[styles.value]}>
                    {loanData.occupancy || ''}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Unit(s):
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {loanData.units || ''}
                  </Text>
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider
                style={{
                  marginBottom: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: '#aaa',
                }}
              />
            ) : null}
            <View>
              <View style={{ height: 275 }}>
                <GoogleMap loanDetails={loanData} />
              </View>
            </View>
          </Layout>
        </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  icon: {
    height: 18,
    width: 18,
    marginHorizontal: 4,
    backgroundColor: '#ff2503',
    borderRadius: 32,
    padding: 8,
  },
  divider: {
    height: 2,
    backgroundColor: 'white',
    width: '100%',
    marginVertical: 32,
  },
  label: {
    fontSize: 15,
    marginRight: 8,
  },
  value: {
    fontSize: 15,
    flexShrink: 1,
    fontFamily: 'VerbBold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 16,
    paddingHorizontal: 16,
    flex: 1,
  },
  cardWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginBottom: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    flex: 1,
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
});

export default LoanDetailColumn;
