import React from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import { Card, Divider, ListItem, Text } from '@ui-kitten/components';
import { useGetHomeDataQuery } from '../../services/HomeSlice';

const styles = StyleSheet.create({
  titleText: {
    fontWeight: 'bold',
    fontSize: Platform.OS === 'web' ? 17 : 15,
    marginRight: 12,

  },
  tableText: {
    fontSize: Platform.OS === 'web' ? 16 : 11,
    flex: 1,
  },
});

export default function RankingsTable() {
  const { data: homeData } = useGetHomeDataQuery({});

  const tableDataRows = [
    {
      title: 'Your Pull-Thru',
      YTD: homeData?.pullThru || '',
    },
    {
      title: 'Your Closings',
      YTD: homeData?.user?.closingsYtd || '',
      QTD: homeData?.user?.closingsQtd || '',
      MTD: homeData?.user?.closingsMtd || '',
    },
    {
      title: 'Your Applications',
      YTD: homeData?.user?.appsYtd || '',
      QTD: homeData?.user?.appsQtd || '',
      MTD: homeData?.user?.appsMtd || '',
    },
    {
      title: 'Branch Closings',
      YTD: homeData?.branch?.branchClosingsYtd || '',
      QTD: homeData?.branch?.branchClosingsQtd || '',
      MTD: homeData?.branch?.branchClosingsMtd || '',
    },
    {
      title: 'Branch Applications',
      YTD: homeData?.branch?.branchAppsYtd || '',
      QTD: homeData?.branch?.branchAppsQtd || '',
      MTD: homeData?.branch?.branchAppsMtd || '',
    },
  ];

  const company = homeData?.company[0] || {};
  const COMPANY_PULLTHRU = company.won / (company.won + company.lost);

  return (
    <Card
      style={{
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        // padding: Platform.OS === 'web' ? 16 : 0,
        minWidth: 300,
        flexGrow: 1,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 20,
          marginBottom: 16,
        }}
      >
        Ranks
      </Text>
      <ListItem style={{ backgroundColor: '#c9e2ff',}}>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}> </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>YTD</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>QTD</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>MTD</Text>
        </View>
      </ListItem>
      {tableDataRows.map((row, index) => {
        if (row.title === 'Your Pull-Thru') {
          return (
            <ListItem
              key={row.title}
              style={{
                backgroundColor: 'white',
                justifyContent: 'space-between',
              }}
            >
              <View style={styles.tableText}>
                <Text
                  category={Platform.OS === 'web' ? 'p1' : 'label'}
                  style={{ fontFamily: 'VerbBold' }}
                >
                  {row.title}
                </Text>
              </View>
              <View style={styles.tableText}>
                <Text
                  style={[
                    styles.tableText,
                    {
                      color:
                        parseFloat(homeData?.user?.pullTru) < COMPANY_PULLTHRU
                          ? 'red'
                          : 'green',
                    },
                  ]}
                  category="p1"
                >
                  <Text category="p1">{(row.YTD * 100).toFixed(2)}%</Text>
                </Text>
              </View>
              <View style={styles.tableText}>
                <Text>{row.QTD}</Text>
              </View>
              <View style={styles.tableText}>
                <Text>{row.MTD}</Text>
              </View>
            </ListItem>
          );
        }
        return (
          <ListItem
            key={row.title}
            style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f7f7f7' }}
          >
            <View style={styles.tableText}>
              <Text
                category={Platform.OS === 'web' ? 'p1' : 'label'}
                style={{ fontFamily: 'VerbBold' }}
              >
                {row.title}
              </Text>
            </View>
            <View style={styles.tableText}>
              <Text>{row.YTD}</Text>
            </View>
            <View style={styles.tableText}>
              <Text>{row.QTD}</Text>
            </View>
            <View style={styles.tableText}>
              <Text>{row.MTD}</Text>
            </View>
            <Divider />
          </ListItem>
        );
      })}
    </Card>
  );
}
