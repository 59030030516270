import { Button, Layout, Modal, Popover, Text } from '@ui-kitten/components';
import React, { useRef, useState } from 'react';
import {
  View,
  Platform,
  StyleSheet,
} from 'react-native';
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import ActivityIndicatorComponent from '../../components/Universal/ActivityIndicatorComponent';
import Colors from '../helpers/Colors';
import { CreateContactFrom, CreateLeadForm } from '../components-global';
import { PeopleIcon } from './Icons';
//@ts-ignore
import ModalWrapper from './Modals/ModalWrapper';

export default function GlobalSearchAutoComplete({
  autoCompleteData,
  handleOnChange = () => null,
  defaultValue,
  placeholder = 'Search',
  setQuery,
  isFetching,
  clearAutoCompleteData,
  listDirection = 'up',
  disableAddNew = false,
  hideLead = false,
}: any) {
  /*
       * @description: auto complete searchable input field

           <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              defaultValue={undefined}
              handleOnChange={(e) => setSelectedAssociate(e)}
            />

      * place this in your parent component, modify as needed

        const [selectedAssociate, setSelectedAssociate] = useState<any>({});
        const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
        const [query, setQuery] = useState('&search=a');
        const [isFetching, setIsFetching] = useState(false);
        const { data: contactsData, isFetching: isFetchingContacts } =
          useGetContactsQuery(query);
        const { data: loansData, isFetching: isFetchingLoans } =
          useGetLoansQuery(query);
        const { data: leadsData, isFetching: isFetchingLeads } =
          useGetLeadsQuery(query);

        useEffect(() => {
          if (!isFetching) {
            console.log('IS FETCHING DONE');
            setAutoCompleteData([
              ...dataTransformer(contactsData),
              ...dataTransformer(loansData),
              ...dataTransformer(leadsData),
            ]);
          }
        }, [isFetching]);

        useEffect(() => {
          if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
            setIsFetching(false);
          } else {
            setIsFetching(true);
          }
        }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  */

  const dropdownController = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [visible, setVisible] = useState(false);

  const renderToggleButton = () => (
    <Button
      style={{
        marginLeft: 8,
        height: 32,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
      }}
      size="tiny"
      appearance="outline"
      status="danger"
      onPress={() => setVisible(true)}
    >
      +
    </Button>
  );

  return (
    <>
      {autoCompleteData ? (
        <View
          style={{
            flexDirection: 'row',

            alignItems: 'center',
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
              flex: 1,
            }}
          >
            <AutocompleteDropdown
              containerStyle={{
                flexGrow: 1,
                flexShrink: 1,
                backgroundColor: 'white',
              }}
              controller={(controller) => {
                dropdownController.current = controller;
              }}
              direction={listDirection}
              clearOnFocus={false}
              closeOnBlur={false}
              closeOnSubmit={true}
              showChevron={true}
              debounce={800}
              useFilter={false}
              loading={isFetching}
              inputHeight={36}
              emptyResultText={'Nothing Found'}
              suggestionsListMaxHeight={325}
              suggestionsListContainerStyle={{
                backgroundColor: '#eee',
                zIndex: 99999,
              }}
              onChangeText={(value) => {
                clearAutoCompleteData();
                setQuery(`&search=${value}`);
              }}
              renderItem={(item: any) => {
                if (!item) return null;
                return (
                  <View key={item.title}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        height: 40,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: 'VerbBold',
                          fontSize: 10,
                          marginHorizontal: 8,
                          color:
                            item.type === 'Loan'
                              ? Colors.primaryRed
                              : item.type === 'Lead'
                              ? Colors.accentBlue
                              : Colors.darkBlue,
                          width: 50,
                        }}
                      >
                        {item.type ? `${item.type}` : ''}
                      </Text>
                      <Text style={{ color: 'black', padding: 15 }}>
                        {item?.title}
                      </Text>
                      {item.loanNumber ? (
                        <Text category="label">
                          Loan Number: {item.loanNumber}
                        </Text>
                      ) : null}
                    </View>
                  </View>
                );
              }}
              initialValue={defaultValue ? defaultValue : '1'} // or just '2'
              onSelectItem={(e) => {
                handleOnChange(e);
              }}
              dataSet={[
                isFetching
                  ? defaultValue || { id: 1, title: 'Searching...' }
                  : defaultValue
                  ? defaultValue
                  : { id: 0, title: '' },
                ...autoCompleteData,
              ]}
              rightButtonsContainerStyle={{
                right: Platform.OS === 'web' ? 72 : 48,
                height: 30,
                alignSelf: 'center',
              }}
              textInputProps={{
                placeholder: placeholder,
                autoCorrect: false,
                autoCapitalize: 'none',
                placeholderTextColor: '#646464',
                style: {
                  paddingLeft: 18,
                  paddingRight: 18,
                },
              }}
            />
            <Button
              style={{
                flexGrow: 0,
                marginLeft: 4,
                height: 33,
                marginTop: 3,
                position: 'absolute',
                right: 0,
                zIndex: 1,
              }}
              size={'tiny'}
              status={'info'}
              appearance="ghost"
              onPress={() => dropdownController.current.close()}
            >
              Close
            </Button>
          </View>
          {disableAddNew ? null : (
            <Popover
              anchor={renderToggleButton}
              visible={visible}
              placement="top"
              onBackdropPress={() => setVisible(false)}
            >
              <Layout style={{ paddingHorizontal: 16 }}>
                <View>
                  {hideLead ? null : <Button
                    appearance="ghost"
                    status="warning"
                    accessoryLeft={PeopleIcon}
                    style={styles.createButton}
                    onPress={() => {
                      setModalType('lead');
                      setVisible(false);
                      setModalVisible(true);
                    }}
                  >
                    Create Lead
                  </Button>}
                  <Button
                    appearance="ghost"
                    status="primary"
                    accessoryLeft={PeopleIcon}
                    style={styles.createButton}
                    onPress={() => {
                      setModalType('contact');
                      setVisible(false);
                      setModalVisible(true);
                    }}
                  >
                    Create Contact
                  </Button>
                </View>
              </Layout>
            </Popover>
          )}
        </View>
      ) : (
        <View style={{ maxHeight: 32 }}>
          <ActivityIndicatorComponent />
        </View>
      )}
      <Modal
        style={{ zIndex: 999 }}
        animationType="fade"
        backdropStyle={{ backgroundColor: 'black', opacity: 0.25 }}
        visible={modalVisible}
      >
        <ModalWrapper style={{ marginTop: 16, marginLeft: 16 }}>
          {modalType === 'contact' ? (
            <CreateContactFrom
              cancelAction={() => setModalVisible(false)}
              defaultData={{
                callback: function (data) {
                  dropdownController.current.setItem({
                    ['@id']: data['@id'],
                    id: data['@id'],
                    title: data.name,
                    contactId: data.id,
                    type: data['@type'] || 'Contact',
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                  });
                },
              }}
            />
          ) : null}
          {modalType === 'lead' ? (
            <CreateLeadForm
              cancelAction={() => setModalVisible(false)}
              defaultData={{
                callback: function (data) {
                  dropdownController.current.setItem({
                    ['@id']: data['@id'],
                    id: data['@id'],
                    title: data.name,
                    contactId: data.id,
                    type: data['@type'] || 'Lead',
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                  });
                },
              }}
            />
          ) : null}
        </ModalWrapper>
      </Modal>
    </>
  );
}

export function dataTransformer(hydraData: any) {
  // This transforms hydra data into usable data for the global search component
  let dataArr = [];
  if (!hydraData?.['hydra:member']?.length) return dataArr;

  hydraData['hydra:member'].forEach((member) => {
    dataArr.push({
      ['@id']: member['@id'],
      contactId: `${member.id}`,
      id: member['@id'],
      title: `${
        member['@type'] === 'Loan'
          ? member?.loanContact?.firstName || member.encompassGuid
          : member.firstName
      } ${
        member['@type'] === 'Loan'
          ? member?.loanContact?.lastName || ''
          : member.lastName
      }`,
      firstName:
        member['@type'] === 'Loan'
          ? member.loanContact.firstName
          : member.firstName,
      lastName:
        member['@type'] === 'Loan'
          ? member.loanContact.lastName
          : member.lastName,
      type: member['@type'],
      email: member.email || '',
      loanNumber: member.loanNumber || null,
      phone: member.mobilePhone || member.homePhone || null,
      address: {
        streetAddress: member.streetAddress || null,
        city: member.city || null,
        state: member.state || null,
        zipCode: member.zipCode || null,
      }
    });
  });

  return dataArr;
}

const styles = StyleSheet.create({
  createButton: {
    marginVertical: 16,
  },
});
