import React, { useEffect } from 'react';
import {
  IndexPath, ListItem, Select, SelectItem,
} from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View } from 'react-native';
import { useGetMeQuery, useGetUsersQuery } from '../../services/UserSlice';
import { ImpersonatingUser } from '../../services/ImpersonationSlice';

export default function ImpersonationSwitcher() : JSX.Element {
  const { data: users, isSuccess } = useGetUsersQuery({});
  const [data, setData] = React.useState<ImpersonatingUser[]>([]);

  const { data: user, isLoading } = useGetMeQuery({});
  const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>();
  const [displayValue, setDisplayValue] = React.useState<string>();

  useEffect(() => {
    if (isSuccess) {
      const filteredUsers = users.filter((filteredUser) => filteredUser.activeUser);
      const cleanedUsers: ImpersonatingUser[] = filteredUsers.map((cleanedUser) => ({
        email: cleanedUser.email,
        name: `${cleanedUser.firstName} ${cleanedUser.lastName}`,
        id: cleanedUser.id,
      }));
      cleanedUsers.sort((a, b) => a.name.localeCompare(b.name));
      setData(cleanedUsers);
    }
  }, [users, isSuccess]);

  useEffect(() => {
    if (selectedIndex) {
      setDisplayValue(data[selectedIndex.row].name);
    }
  }, [selectedIndex]);

  const indexSelected = (index) => {
    setSelectedIndex(index);
    AsyncStorage.setItem(
      '@impersonating',
      JSON.stringify(data[index.row]),
    ).then(() => {
      window.location.reload();
    });
  };

  if (!user?.roles?.includes('ROLE_ALLOWED_TO_SWITCH')) return <View />;

  return (
    <View style={{ maxWidth: 394 }}>
      <ListItem
        title="Impersonate"
        disabled={!isSuccess}
        accessoryRight={() => (
          <Select
            placeholder="Select user"
            selectedIndex={selectedIndex}
            value={displayValue}
            onSelect={(index) => indexSelected(index)}
          >
            {data.map((item) => (
              <SelectItem key={item.name} title={item.name} />
            ))}
          </Select>
        )}
      />
    </View>
  );
}
