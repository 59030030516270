import {
  Platform,
  StyleSheet,
  Keyboard,
  Modal as NativeModal,
} from 'react-native';
import { Modal as KittenModal } from '@ui-kitten/components';
import React from 'react';
//@ts-ignore
import ModalWrapper from './Modals/ModalWrapper';

export default function PopupModal({
  showModal,
  children,
  cancelAction,
  disableBackdrop = false,
}: any) {
  if (Platform.OS === 'web') {
    return (
      <KittenModal
        style={{ flex: 1, overflow: 'visible' }}
        visible={showModal}
        animationType="fade"
        backdropStyle={styles.backdrop}
        onBackdropPress={() => {
          if (!disableBackdrop) {
            cancelAction();
          }
        }}
      >
        <ModalWrapper>{children}</ModalWrapper>
      </KittenModal>
    );
  }

  return (
    <NativeModal
      style={{ flex: 1, overflow: 'visible' }}
      visible={showModal}
      animationType="slide"
    >
      <ModalWrapper>{children}</ModalWrapper>
    </NativeModal>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
});
