import { Input } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from '../../helpers/useEffectDebounce';
import { SavedView } from '../../interfaces/Table';
import { removeColumnFilter, setColumnFilter } from '../../services/TableSlice';

interface SearchFilterProps {
  columnName: string;
  savedView: SavedView;
  objectName: string;
}

let timeout: string | number | NodeJS.Timeout;

function SearchFilter({
  columnName,
  savedView,
  objectName,
}: SearchFilterProps) {
  const [searchText, setSearchText] = useState<string>('');
  const dispatch = useDispatch();
  const filter = savedView.filters.find((fil) => fil.column === columnName);
  const debouncedSearchText = useDebounce(searchText, 700);

  useEffect(() => {
    if (filter) {
      setSearchText(filter.value);
    } else {
      setSearchText('');
    }
  }, [filter]);

  useEffect(() => {
    if (debouncedSearchText === '') {
      dispatch(removeColumnFilter({ objectName, column: columnName }));
    } else {
      dispatch(
        setColumnFilter({
          objectName,
          filter: {
            column: columnName,
            value: debouncedSearchText,
            operator: 'eq',
          },
        })
      );
    }
  }, [debouncedSearchText]);

  function handleChangeText(nextValue: string) {
    setSearchText(nextValue);
    clearTimeout(timeout);
  }

  return (
    <Input
      onChangeText={(nextValue) => handleChangeText(nextValue)}
      value={searchText}
      size="small"
      style={{ backgroundColor: '#fff' }}
      status="basic"
      placeholder="Search.."
    />
  );
}

export default SearchFilter;
