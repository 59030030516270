import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopupModal } from '../components-ui';
import routes from '../navigation/routes';
import CreateContactFrom from './Forms/Contacts/CreateContactForm';
import CompleteEventForm from './Forms/Events/CompleteEventForm';
import EventForm from './Forms/Events/EventForm';
import CreateLeadForm from './Forms/Leads/CreateLeadForm';
import LogCallForm from './Forms/LogCall/LogCallForm';
import LogMeetingForm from './Forms/LogMeeting/LogMeetingForm';
import NoteForm from './Forms/Notes/NoteForm';
import CompleteTaskForm from './Forms/Tasks/CompleteTaskForm';
import TaskForm from './Forms/Tasks/TaskForm';
// @ts-ignore
import { useNavigation } from '../navigation/router';
import EditLeadForm from './Forms/Leads/EditLeadForm';
import ConvertToContactForm from './Forms/Contacts/ConvertToContactForm';
import BulkEmailForm from './Forms/Email/EmailForm';
import AssociateLoan from './Forms/Loans/AssociateLoan';
import AddReferral from './Forms/Loans/AddReferral';
import GoalEditor from './Forms/Profile/GoalEditor';
import ConnectAgentForm from './Forms/Contacts/ConnectAgentForm';
import CreateScenarioForm from './Forms/Loans/CreateScenarioForm';
import GeneratePreapprovalForm from './Forms/Loans/Preapproval/GeneratePreapprovalForm';
import CommissionDenyForm from './Forms/Loans/CommissionDenyForm';
import BorrowerPairForm from './Forms/Loans/BorrowerPairForm';
import PreviewPdfForm from './Forms/Loans/PreviewPdfForm';
import CreditRequest from './Forms/Loans/CreditRequest';
import CreateNewBorrower from './Forms/Loans/CreateNewBorrower';
import { RootState } from '../services/store';
import { hideModal as realHide, showModal as realShow } from '../services/FormSlice';

type Props = {};

function AllModals({}: Props) {

  const { isVisible, formType, defaultData } = useSelector((state: RootState) => state.form);
  const dispatch = useDispatch();

  const navigate = useNavigation();

  function hideModal() {
    dispatch(realHide());
  }

  function showModal(payload) {
    dispatch(realShow(payload));
  }

  function handleOpenRoute(data) {
    navigate.navigate(data.route, { id: data.id });
  }

  const memoizedForm = useMemo(() => {
    switch (formType) {
      case 'creditRequest':
        return (
          <CreditRequest
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'task':
        return (
          <TaskForm
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'note':
        return (
          <NoteForm
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'event':
        return (
          <EventForm
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'logCall':
        return (
          <LogCallForm
            defaultData={defaultData}
            showModal={showModal}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'logMeeting':
        return (
          <LogMeetingForm
            defaultData={defaultData}
            showModal={showModal}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'bulkEmail':
        return (
          <BulkEmailForm
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'completeTask':
        return (
          <CompleteTaskForm
            showModal={showModal}
            cancelAction={() => {
              hideModal();
            }}
            defaultData={defaultData}
          />
        );
      case 'completeEvent':
        return (
          <CompleteEventForm
            showModal={showModal}
            cancelAction={() => hideModal()}
            defaultData={defaultData}
          />
        );
      case 'createLead':
        return (
          <CreateLeadForm
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Lead });
              }
              hideModal();
            }}
          />
        );
      case 'editLead':
        return (
          <EditLeadForm
            defaultData={defaultData}
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'createContact':
        return (
          <CreateContactFrom
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Contact });
              }
              hideModal();
            }}
          />
        );
      case 'convertToContact':
        return (
          <ConvertToContactForm
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Contact });
              }
              hideModal();
            }}
          />
        );
      case 'createScenario':
        return (
          <CreateScenarioForm
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Loan });
              }
              hideModal();
            }}
          />
        );
      case 'associateLoan':
        return (
          <AssociateLoan
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Contact });
              }
              hideModal();
            }}
          />
        );
      case 'addReferral':
        return (
          <AddReferral
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Contact });
              }
              hideModal();
            }}
          />
        );
      case 'goalEditor':
        return (
          <GoalEditor
            cancelAction={() => {
              hideModal();
            }}
          />
        );
      case 'connectAgent':
        return (
          <ConnectAgentForm
            showModal={showModal}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Contact });
              }
              hideModal();
            }}
            defaultData={defaultData}
          />
        );
      case 'preapproval':
        return (
          <GeneratePreapprovalForm
            defaultData={defaultData}
            cancelAction={(data) => {
              if (data) {
                handleOpenRoute({ ...data, route: routes.ExtraRoutes.Lead });
              }
              hideModal();
            }}
          />
        );
      case 'commissionReject':
        return (
          <CommissionDenyForm
            defaultData={defaultData}
            cancelAction={() => hideModal()}
          />
        );
      case 'borrowerPair':
        return (
          <BorrowerPairForm
            defaultData={defaultData}
            cancelAction={() => hideModal()}
          />
        );
      case 'createBorrower':
        return (
          <CreateNewBorrower
            defaultData={defaultData}
            cancelAction={() => hideModal()}
          />
        );
      case 'PreviewPdf':
        return (
          <PreviewPdfForm
            defaultData={defaultData}
            cancelAction={() => hideModal()}
          />
        );
      default:
        return null;
    }
  }, [formType, defaultData]);

  return (
    <PopupModal
      showModal={isVisible}
      setShowModal={showModal}
      disableBackdrop={formType === 'preapproval'}
      cancelAction={() => {
        hideModal();
      }}
    >
      {memoizedForm}
    </PopupModal>
  );
}

export default AllModals;
