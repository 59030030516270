import { stringify, parse } from 'qs';
import { createBrowserHistory } from 'history';

export const setTopLevelNavigator = (navigatorRef) => {};

export const history = createBrowserHistory({});

export const navigate = (name, params) => {
  if (name) {
    return history.push({
      pathname: name,
      search: `?${stringify(params)}`,
    });
  }
};

// TODO
export const setParams = (options) => {};

export const goBack = () => history.goBack();

export const getParam = (key) => {
  let query = history.location.search;
  query = String(query).substring(1);
  const params = parse(query);
  const value = params && params[key];
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export const navigation = {
  navigate,
  goBack,
  getParam,
  setParams,
};

export const getNavigationProps = (props) => ({
  navigation,
});

export default {
  navigate,
  goBack,
  setTopLevelNavigator,
  getNavigationProps,
};
