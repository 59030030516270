import { apiSlice } from './ApiSlice';

export const ContactApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query({
      query: (query) => `contacts?${query}`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each contact in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'Contacts',
            id,
          })),
          { type: 'Contacts', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'Contacts', id: 'PARTIAL-LIST' }]),
    }),
    getReferredByContact: build.query({
      query: (id) => `/contacts/${id}/referrals`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each contact in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'Contacts',
            id,
          })),
          { type: 'Contacts', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'Contacts', id: 'PARTIAL-LIST' }]),
    }),
    getContact: build.query({
      query: (id) => `/contacts/${id}`,
      providesTags: (result, error, id) => [{ type: 'Contacts', id }],
    }),
    addNewContact: build.mutation({
      query: (body) => ({
        url: 'contacts',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Contacts', id: 'PARTIAL-LIST' }],
    }),
    updateContact: build.mutation({
      query: ({ id, ...put }) => ({
        url: `contacts/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Contacts', id }],
    }),
    deleteContact: build.mutation({
      query: (id) => ({
        url: `contacts/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this contact `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getContacts` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, id) => [
        { type: 'Contacts', id: 'PARTIAL-LIST' },
      ],
    }),
    patchContact: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `contacts/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Contacts', id }],
    }),
    getContactActivities: build.query({
      query: ({ id, dateRangeOption = 'all' }) => `/contacts/data/${id}/${dateRangeOption}`,
      providesTags: () => [{ type: 'ContactActivities', id: 'PARTIAL-LIST' }],
    }),
    inviteContactTLC: build.mutation({
      query: (id) => ({ url: `/contacts/invite/${id}`, method: 'POST', id }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Contacts', id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetReferredByContactQuery,
  useGetContactQuery,
  useAddNewContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  usePatchContactMutation,
  useGetContactActivitiesQuery,
  useInviteContactTLCMutation,
} = ContactApiSlice;
