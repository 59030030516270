import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Layout, Text } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import {
  Pressable,
  View,
  StyleSheet,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import { useDispatch } from 'react-redux';
import PopOver from '../components-ui/PopOver';
import { formatPhoneNumber } from '../helpers/dataTransformerHelper';
import { useGetTlcActivitiesQuery } from '../services/TlcActivitiesSlice';
import {
  useAddTlcAgentMutation,
  useGetTlcAgentQuery,
  useGetTlcAgentsQuery,
} from '../services/TlcAgentsSlice';
import {
  useAddNewTlcClientMutation,
  useGetMultiClientDataQuery,
  useGetTlcClientsQuery,
} from '../services/TlcClientsSlice';
import { useAddNewTlcInviteMutation } from '../services/TlcInvite';
import { showModal as realShow } from '../services/FormSlice';

type Props = {
  clientData: any;
};

type ItemProps = {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  status: string;
  createdAt: string;
  isAgent?: boolean;
  isActivity?: boolean;
  actionName?: string;
  actionType?: string;
  tlcClient?: any;
};

function ArkHomes(props: Props) {
  const { clientData } = props;
  const { data: tlcClients, isFetching: fetchingClient } =
    useGetTlcClientsQuery(`email=${clientData.email}`);

  return (
    <Layout style={styles.cardWrapper}>
      {fetchingClient ? (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <>
          <ClientSection clientData={clientData} tlcClients={tlcClients} />
          {!clientData.isPartner ? (
            <>
              <AgentSection clientData={clientData} tlcClients={tlcClients} />
              <ActivitySection
                clientData={clientData}
                tlcClients={tlcClients}
              />
            </>
          ) : (
            <AgentList clientData={clientData} tlcClients={tlcClients} />
          )}
        </>
      )}
    </Layout>
  );
}
const AgentList = ({ clientData, tlcClients }) => {
  const email = clientData.email;
  const [clientIDs, setClientIDs] = useState([]);

  const { data: tlcAgents, isFetching: isFetchingAgent } = useGetTlcAgentsQuery(
    `email=${email}`
  );
  const { data: clientsList } = useGetMultiClientDataQuery(
    clientIDs.length ? clientIDs : skipToken
  );

  useEffect(() => {
    if (tlcAgents && tlcAgents.length) {
      const clientIDs = tlcAgents[0].tlcClients.map(
        (client) => client.split('/')[2]
      );
      console.log(clientIDs);
      setClientIDs(clientIDs);
    }
  }, [tlcAgents]);

  return (
    <View>
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 4,
            paddingHorizontal: 12,
            backgroundColor: '#aab8c2',
          },
        ]}
      >
        <Text style={{ color: '#fff' }}>Client List</Text>
      </View>
      <View>
        {clientsList?.map((client) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: '#eee',
                }}
              >
                <Text
                  style={{
                    padding: 8,
                  }}
                  category="label"
                >
                  {client.firstName} {client.lastName}
                </Text>
                <Text
                  style={{
                    borderBottomWidth: 1,
                    borderColor: '#eee',
                    padding: 8,
                  }}
                  category="label"
                >
                  {client.email}
                </Text>
              </View>
            );
          })}
      </View>
    </View>
  );
};

const ClientSection = ({ clientData, tlcClients }: any) => {
  const { email, firstName, lastName, mobilePhone: phoneNumber } = clientData;
  const [reinviteSent, setRevinviteSent] = useState(false);

  const [
    createClient,
    {
      isSuccess: successCreatingClient,
      isError: errorCreatingClient,
      data: createClientData,
    },
  ] = clientData.isPartner
    ? useAddTlcAgentMutation()
    : useAddNewTlcClientMutation();

  const { data: tlcAgents } = useGetTlcAgentsQuery(`email=${clientData.email}`);

  const [addNewTlcInvite] = useAddNewTlcInviteMutation();

  const tlcData = clientData.isPartner ? tlcAgents : tlcClients;

  async function sendTlcInvite() {
    if (clientData.isPartner) {
      addNewTlcInvite({
        invitee: tlcData[0]['@id'],
        inviter: clientData.owner['@id'],
        inviterType: 'mlo',
        inviterMlo: clientData.owner['@id'],
        inviteeAgent: tlcData[0]['@id'],
        inviteeType: 'agent',
      });
      setRevinviteSent(true);
    } else {
      addNewTlcInvite({
        invitee: tlcData[0]['@id'],
        inviter: clientData.owner['@id'],
        inviterType: 'mlo',
        inviterMlo: clientData.owner['@id'],
        inviteeClient: tlcData[0]['@id'],
        inviteeType: 'client',
      });
      setRevinviteSent(true);
    }
  }

  useEffect(() => {
    if (successCreatingClient && createClientData) {
      if (clientData.isPartner) {
        addNewTlcInvite({
          invitee: createClientData['@id'],
          inviter: clientData.owner['@id'],
          inviterType: 'mlo',
          inviterMlo: clientData.owner['@id'],
          inviteeAgent: createClientData['@id'],
          inviteeType: 'agent',
        });
      } else {
        addNewTlcInvite({
          invitee: createClientData['@id'],
          inviter: clientData.owner['@id'],
          inviterType: 'mlo',
          inviterMlo: clientData.owner['@id'],
          inviteeClient: createClientData['@id'],
          inviteeType: 'client',
        });
      }
    }
  }, [successCreatingClient, errorCreatingClient, createClientData]);

  return (
    <View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Text style={styles.cardHeader} category="h6">
          Ark Homes
        </Text>
        {!tlcData?.length ? (
          <Pressable
            onPress={() => {
              createClient({
                firstName,
                lastName,
                email,
                phoneNumber,
                status: 'invited',
              });
            }}
            style={{ position: 'absolute', right: 16 }}
          >
            <Text category="label" style={{ color: 'white' }}>
              {clientData?.isPartner ? 'Invite Agent' : 'Invite'}
            </Text>
          </Pressable>
        ) : null}
        {tlcData?.length && tlcData[0].status === 'invited' ? (
          <>
            {reinviteSent ? (
              <View style={{ position: 'absolute', right: 16 }}>
                <Text category="label" style={{ color: 'white' }}>
                  Invite Sent!
                </Text>
              </View>
            ) : (
              <Pressable
                onPress={() => {
                  sendTlcInvite();
                }}
                style={{ position: 'absolute', right: 16 }}
              >
                <Text category="label" style={{ color: 'white' }}>
                  Resend Invite
                </Text>
              </Pressable>
            )}
          </>
        ) : null}
      </View>
      <View>
        {tlcData ? (
          <FlatList
            data={tlcData}
            renderItem={({ item }) => (
              <ClientItem {...item} tlcClient={tlcData[0] || null} />
            )}
            keyExtractor={(item) => item.id}
          />
        ) : (
          <View style={{ height: 24 }} />
        )}
      </View>
    </View>
  );
};

const AgentSection = ({ clientData, tlcClients }) => {
  if (!tlcClients?.length) return null;
  const agentID = tlcClients?.length
    ? tlcClients[0].assignedAgent?.split('/')[2]
    : null;

  const { data: tlcAgent, isFetching: isFetchingAgent } = useGetTlcAgentQuery(
    agentID || skipToken
  );

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));

  return (
    <View>
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 4,
            paddingHorizontal: 12,
            backgroundColor: '#aab8c2',
            position: 'relative',
          },
        ]}
      >
        <Text category="h6" style={{ color: 'white' }}>
          Agent
        </Text>
        {tlcAgent ? (
          <Pressable
            onPress={() => {
              showModal({
                formType: 'connectAgent',
                data: { tlcClient: tlcClients[0] },
              });
            }}
            style={{ position: 'absolute', right: 16 }}
          >
            <Text category="label" style={{ color: 'white' }}>
              Change Agent
            </Text>
          </Pressable>
        ) : null}
        {tlcClients && !tlcAgent && !isFetchingAgent ? (
          <Pressable
            style={{
              padding: 4,
              position: 'absolute',
              right: 8,
            }}
            onPress={() => {
              showModal({
                formType: 'connectAgent',
                data: { tlcClient: tlcClients[0] },
              });
            }}
          >
            <Text category="label" style={{ color: '#007aff' }}>
              Connect
            </Text>
          </Pressable>
        ) : null}
      </View>
      {tlcAgent ? (
        <AgentItem
          {...tlcAgent}
          tlcClient={tlcClients[0] || null}
          isAgent={true}
        />
      ) : (
        <View style={{ height: 24 }} />
      )}
    </View>
  );
};

const ActivitySection = ({ clientData, tlcClients }) => {
  if (!tlcClients?.length) return null;

  const { data: tlcActivities, isFetching: isFetchingActivities } =
    useGetTlcActivitiesQuery(`email=${tlcClients[0].email}`);

  return (
    <View>
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 4,
            paddingHorizontal: 12,
            backgroundColor: '#aab8c2',
          },
        ]}
      >
        <Text category="h6" style={{ color: 'white' }}>
          Last 5 TLC Activities
        </Text>
      </View>
      {tlcActivities ? (
        <FlatList
          data={tlcActivities}
          renderItem={({ item }) => (
            <ActivityItem
              {...item.contact}
              tlcClient={tlcClients[0] || null}
              isActivity={true}
            />
          )}
          keyExtractor={(item) => item.id}
        />
      ) : (
        <View style={{ height: 24 }} />
      )}
    </View>
  );
};

const ActivityItem = ({ actionName, actionType }: ItemProps) => {
  return (
    <View
      style={{
        padding: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text category="label" style={{ marginRight: 4, flex: 2 }}>
        {actionName} {actionType}
      </Text>
    </View>
  );
};

const ClientItem = ({ id, firstName, lastName, status }: ItemProps) => {
  return (
    <View
      style={{
        padding: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text category="label" style={{ marginRight: 8 }}>
        TLC ID: {id}
      </Text>
      <Text category="label" style={{ marginRight: 4, flex: 2 }}>
        {firstName} {lastName}
      </Text>
      <Text
        category="label"
        style={[
          {
            marginLeft: 'auto',
            marginRight: 10,
            textTransform: 'capitalize',
          },
          status !== 'invited'
            ? {
                padding: 4,
                backgroundColor: 'green',
                color: 'white',
                fontFamily: 'VerbBold',
                borderRadius: 4,
              }
            : {},
        ]}
      >
        {status}
      </Text>
    </View>
  );
};

const AgentItem = ({ firstName, lastName, email, phoneNumber }: ItemProps) => {
  return (
    <View
      style={{
        padding: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text category="label" style={{ marginRight: 4, flex: 2 }}>
        {firstName} {lastName}
      </Text>
      <PopOver toggleText="View Email">
        <Text
          category="label"
          style={{ marginRight: 4, flex: 1, color: 'white' }}
        >
          {email}
        </Text>
      </PopOver>
      <PopOver toggleText="View Phone Number">
        <Text
          category="label"
          style={{ marginRight: 4, flex: 1, color: 'white' }}
        >
          {formatPhoneNumber(phoneNumber)}
        </Text>
      </PopOver>
    </View>
  );
};

const styles = StyleSheet.create({
  cardWrapper: {
    shadowColor: '#000',
    flex: 2,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    minHeight: 150,
    elevation: 3,
    marginTop: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
});

export default ArkHomes;
