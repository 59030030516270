import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  Button,
  Card,
  Icon,
  IconElement,
  Layout,
  MenuItem,
  OverflowMenu,
  Text,
} from '@ui-kitten/components';
import { closedLoans } from '../../helpers/commonStatFilters';
import { isAfter, isBefore, startOfYear, sub } from 'date-fns';
import { useGetStatsQuery } from '../../services/StatsSlice';
import { useGetMeQuery, usePatchUserMutation } from '../../services/UserSlice';
import { useGetClubRanksQuery } from '../../services/ClubRankSlice';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';

const loansYTD = closedLoans(
  'closedLoansYTD',
  startOfYear(sub(new Date(), { years: 1 })),
  new Date()
);

export default function ClubRank(props) {
  const { data: user, isSuccess: userLoaded } = useGetMeQuery({});
  const [editUser] = usePatchUserMutation();

  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  const loansDataYTD = !loansData
    ? { units: 0, amount: 0 }
    : loansData['hydra:member'].reduce(
        (acc, loan) => {
          if (
            loan.closeStatus === 'Won' &&
            loan.phase === 'Closed Loan' &&
            loan.loanAmount &&
            isAfter(new Date(loan.closeDate), startOfYear(new Date())) &&
            isBefore(new Date(loan.closeDate), new Date())
          ) {
            return {
              units: acc.units + 1,
              amount: acc.amount + loan.loanAmount,
            };
          }
          return acc;
        },
        { units: 0, amount: 0 }
      );

  const { data: clubRankings, isSuccess: successRankings } =
    useGetClubRanksQuery(null);

  const formattedClosedLoans = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(loansDataYTD?.amount || 0);

  let clubRankHeight = 0;

  const clubRanks = clubRankings?.['hydra:member']?.length
    ? [...clubRankings['hydra:member']].sort((a, b) =>
        a.goal > b.goal ? 1 : -1
      )
    : [
        { id: 1, name: 'Platinum', goal: 37500000 },
        { id: 2, name: 'Gold', goal: 25000000 },
        { id: 3, name: 'Silver', goal: 18000000 },
        { id: 4, name: 'Diamond', goal: 50000000 },
      ].sort((a, b) => (a.goal > b.goal ? 1 : -1));
  if (user?.clubRank) {
    clubRankHeight = (loansDataYTD?.amount / user?.clubRank?.goal) * 100;
  }
  let Tiers;
  switch (user?.clubRank?.name) {
    case 'Gold':
      Tiers = (
        <>
          <View
            style={{
              marginBottom: `${(25000000 / 25000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 25,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (25000000 / 25000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Gold
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (25000000 / 25000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $25,000,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(18000000 / 25000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (18000000 / 25000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Silver
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (18000000 / 25000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $18,000,000
            </Text>
          </View>
        </>
      );
      break;
    case 'Platinum':
      Tiers = (
        <>
          <View
            style={{
              marginBottom: '100%',
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <Text
              style={{
                color: clubRankHeight > 100 ? 'white' : 'black',
                fontFamily: 'VerbBold',
              }}
            >
              Platinum
            </Text>
            <Text
              style={{
                color: clubRankHeight > 100 ? 'white' : 'black',
              }}
            >
              $37,500,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(25000000 / 37500000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 25,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (25000000 / 37500000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Gold
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (25000000 / 37500000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $25,000,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(18000000 / 50000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (18000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Silver
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (18000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $18,000,000
            </Text>
          </View>
        </>
      );
      break;
    case 'Diamond':
      Tiers = (
        <>
          <View
            style={{
              marginBottom: '100%',
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color: clubRankHeight > 100 ? 'white' : 'black',
              }}
            >
              Diamond
            </Text>
            <Text
              style={{
                color: clubRankHeight > 100 ? 'white' : 'black',
              }}
            >
              $50,000,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(37500000 / 50000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 5,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (37500000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Platinum
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (37500000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $37,500,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(25000000 / 50000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 25,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (25000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Gold
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (25000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $25,000,000
            </Text>
          </View>
          <View
            style={{
              marginBottom: `${(18000000 / 50000000) * 100}%`,
              width: '100%',
              backgroundColor: 'Transparent',
              alignItems: 'center',
              marginTop: 30,
            }}
          >
            <Text
              style={{
                fontFamily: 'VerbBold',
                color:
                  clubRankHeight >= (18000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              Silver
            </Text>
            <Text
              style={{
                color:
                  clubRankHeight >= (18000000 / 50000000) * 100
                    ? 'white'
                    : 'black',
              }}
            >
              $18,000,000
            </Text>
          </View>
        </>
      );
      break;
    default:
      Tiers = (
        <View
          style={{
            marginBottom: `${(18000000 / 18000000) * 100}%`,
            width: '100%',
            backgroundColor: 'Transparent',
            alignItems: 'center',
            marginTop: 30,
          }}
        >
          <Text
            style={{
              fontFamily: 'VerbBold',
              color:
                clubRankHeight >= (18000000 / 18000000) * 100
                  ? 'white'
                  : 'black',
            }}
          >
            Silver
          </Text>
          <Text
            style={{
              color:
                clubRankHeight >= (18000000 / 18000000) * 100
                  ? 'white'
                  : 'black',
            }}
          >
            $18,000,000
          </Text>
        </View>
      );
      break;
  }

  return (
    <Card
      style={{
        marginBottom: Platform.OS === 'web' ? 24 : 4,
        marginTop: Platform.OS === 'web' ? 0 : 4,
      }}
    >
      <View style={{ padding: 16, flex: 1, minHeight: '100%' }}>
        <View>
          {clubRanks && user ? (
            <DropdownMenu
              user={user}
              clubRanks={clubRanks}
              editUser={editUser}
            />
          ) : null}
        </View>
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'flex-start',
          }}
        >
          <Text style={{ fontSize: 30 }}>
            {formattedClosedLoans.slice(0, formattedClosedLoans.length - 3)}
          </Text>
          <Text
            style={{
              fontSize: 12,
            }}
          >
            IN CLOSED LOANS
          </Text>
        </View>
        <View
          style={{
            paddingBottom: 10,
            flexDirection: 'row',
            height: '80%',
            width: '100%',
            justifyContent: 'center',
            flex: 1,
            minHeight: 500,
          }}
        >
          <View
            style={{
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                height: `${clubRankHeight > 100 ? 100 : clubRankHeight}%`,
                minWidth: 100,
                marginBottom: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: 'VerbBold',
                }}
              >
                {clubRankHeight.toFixed(0)}% ►
              </Text>
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                {`To ${user?.clubRank ? user.clubRank.name : 'Goal'}`}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderRadius: 8,
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                borderRadius: 8,
                height: `${clubRankHeight > 100 ? 100 : clubRankHeight}%`,
                width: '100%',
                backgroundColor: 'blue',
              }}
            >
              <LinearGradient
                colors={['#65A3D8', '#224973']}
                style={{ flex: 1, borderRadius: 8 }}
              />
            </View>
            <View style={{ position: 'absolute', height: '100%' }}>
              {Tiers}
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
}

const DropdownMenu = ({ clubRanks, editUser, user }): React.ReactElement => {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [visible, setVisible] = React.useState(false);

  const onItemSelect = (index): void => {
    setSelectedIndex(index);
    editUser({ id: user.id, clubRank: clubRanks[index.row]['@id'] });
    setVisible(false);
  };
  const SettingsIcon = (props): IconElement => (
    <Icon {...props} name="settings-outline" />
  );
  const renderToggleButton = (): React.ReactElement => (
    <Button
      style={{ width: 32, height: 32, marginLeft: 'auto' }}
      appearance="ghost"
      status="danger"
      accessoryLeft={SettingsIcon}
      onPress={() => setVisible(true)}
    />
  );

  return (
    <Layout style={styles.container} level="1">
      <OverflowMenu
        anchor={renderToggleButton}
        visible={visible}
        selectedIndex={selectedIndex}
        onSelect={onItemSelect}
        onBackdropPress={() => setVisible(false)}
      >
        {clubRanks?.map((rank) => (
          <MenuItem key={rank.name} title={rank.name} />
        ))}
      </OverflowMenu>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 40,
  },
});
