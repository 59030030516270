import { apiSlice } from './ApiSlice';

export const TlcActivitiesSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTlcActivities: build.query({
      query: (query) => ({
        url: '/tlc_activities?' + query || '',
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'TlcActivities', id: 'PARTIAL-LIST' }],
    }),
    getTlcActivity: build.query({
      query: (id) => ({
        url: `/tlc_activities/${id}`,
        method: 'GET',
      }),
    }),
    addTlcActivity: build.mutation({
      query: (body) => ({
        url: '/tlc_activities',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'TlcActivities', id: 'PARTIAL-LIST' }],
    }),
    updateTlcActivity: build.mutation({
      query: ({ id, ...put }) => ({
        url: `/tlc_activities/${id}`,
        method: 'put',
        body: put,
      }),
      invalidatesTags: ({ id }) => [{ type: 'TlcActivities', id }],
    }),
    deleteTlcActivity: build.mutation({
      query: (id) => ({
        url: `/tlc_activities/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTlcActivitiesQuery,
  useGetTlcActivityQuery,
  useAddTlcActivityMutation,
  useUpdateTlcActivityMutation,
  useDeleteTlcActivityMutation,
} = TlcActivitiesSlice;
