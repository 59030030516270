import React, { useState } from 'react';
import {
  View,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { Card, Text } from '@ui-kitten/components';
import Colors from '../../helpers/Colors';
import TrendsChart from './TrendsChart';

export default function MyTrends() {
  const windowWidth = useWindowDimensions().width;
  const [activeChart, setActiveChart] = useState('Applications');

  return (
    <Card
      style={{
        maxWidth: Platform.OS === 'android' ? windowWidth * 0.94 : null,
        flex: 1,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '100%',
        marginVertical: Platform.OS === 'web' ? 0 : 24,
        height: Platform.OS === 'web' ? 400 : '100%',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 16,
          flex: 1,
        }}
      >
        <View
          style={{
            alignSelf: 'flex-start',
            flexDirection: 'row',
            marginBottom: Platform.OS === 'web' ? null : 25,
          }}
        >
          <TouchableOpacity
            onPress={() => setActiveChart('Applications')}
            style={{
              borderColor: Colors.darkBlue,
              backgroundColor:
                activeChart === 'Applications' ? Colors.darkBlue : Colors.white,
              margin: 2,
              alignItems: 'center',
              justifyContent: 'center',
              height: 30,
              width: null,
              borderRadius: 7,
              borderWidth: 1,
              paddingHorizontal: 5,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color:
                  activeChart === 'Applications'
                    ? Colors.white
                    : Colors.darkBlue,
              }}
            >
              Applications
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveChart('Loans')}
            style={{
              borderColor: Colors.darkBlue,
              backgroundColor:
                activeChart === 'Loans' ? Colors.darkBlue : Colors.white,
              margin: 2,
              alignItems: 'center',
              justifyContent: 'center',
              height: 30,
              width: null,
              borderRadius: 7,
              borderWidth: 1,
              paddingHorizontal: 5,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: activeChart === 'Loans' ? Colors.white : Colors.darkBlue,
              }}
            >
              Closings
            </Text>
          </TouchableOpacity>
          {Platform.OS === 'web' && (
            <TouchableOpacity
              onPress={() => setActiveChart('Apps & Loans')}
              style={{
                borderColor: Colors.darkBlue,
                backgroundColor:
                  activeChart === 'Apps & Loans'
                    ? Colors.darkBlue
                    : Colors.white,
                margin: 2,
                alignItems: 'center',
                justifyContent: 'center',
                height: 30,
                width: null,
                borderRadius: 7,
                borderWidth: 1,
                paddingHorizontal: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color:
                    activeChart === 'Apps & Loans'
                      ? Colors.white
                      : Colors.darkBlue,
                }}
              >
                Apps & Closings
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1 }}>
            <TrendsChart activeChart={activeChart} />
          </View>
        </View>
      </View>
    </Card>
  );
}
