import {
  Pressable,
  View,
  StyleSheet,
  SafeAreaView,
  Platform,
} from 'react-native';
import { Icon, Input, Layout, Text } from '@ui-kitten/components';
import { QuickActionsDropDown } from '../../components-global';
//@ts-ignore
import { useNavigation } from '../../navigation/router';
import moment from 'moment';
//@ts-ignore
import TasksListView from './TaskListView';
import { useState } from 'react';
import { CloseIcon, SearchIcon } from '../../components-ui/Icons';
import useDebounce from '../../helpers/useEffectDebounce';
import TaskListView from './TaskListView.web';

function TasksPage() {
  const [searchValue, setValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 800);
  const navigation = useNavigation();
 
  return (
    <>
      {Platform.OS === 'web' ? (
        <Layout style={{ flex: 1 }} level="1">
          <Layout level="1" style={[styles.shadow, styles.layoutContainer]}>
            <View style={{ flexDirection: 'row' }}>
              <Pressable
                style={{ marginRight: 32 }}
                onPress={() => navigation.goBack()}
              >
                <Icon
                  style={[{ width: 32, height: 32 }]}
                  name="arrow-ios-back-outline"
                  fill="red"
                />
              </Pressable>
              <Text category="h3">Tasks</Text>
            </View>
            <Input
              status="info"
              placeholder="Search"
              style={{ flex: 1, maxWidth: 500, marginHorizontal: 32 }}
              value={searchValue}
              onChangeText={(nextValue) => setValue(nextValue)}
              accessoryLeft={SearchIcon}
              accessoryRight={
                searchValue && searchValue !== ''
                  ? (props) => (
                      <Pressable onPress={() => setValue('')}>
                        <CloseIcon {...props} />
                      </Pressable>
                    )
                  : null
              }
            />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <QuickActionsDropDown
                type="createTask"
                icon="clipboard"
                title="Create Task"
                status="info"
                clientData={null}
              />
            </View>
          </Layout>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Layout level="1" style={{ flex: 1 }}>
              <TaskListView searchValue={debouncedSearchValue} setSearchValue={setValue} />
            </Layout>
          </View>
        </Layout>
      ) : (
        <SafeAreaView style={{ flex: 1 }}>
          <TasksListView searchValue={searchValue} debouncedSearchValue={debouncedSearchValue} setSearchValue={setValue} />
        </SafeAreaView>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  layoutContainer: {
    width: '100%',
    height: 79,
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  taskWrap: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: 16,
    borderTopWidth: 4,
  },
  headerText: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});

export default TasksPage;
