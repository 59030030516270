import React from 'react';
import { StyleSheet } from 'react-native';
import { Avatar, Button, Layout, Popover, Text } from '@ui-kitten/components';

 const PopOver = ({children, toggleText}: {children: React.ReactNode, toggleText: string}): React.ReactElement => {

  const [visible, setVisible] = React.useState(false);

  const renderToggleButton = (): React.ReactElement => (
    <Button size='tiny' appearance='outline' style={{marginHorizontal: 4}} onPress={() => setVisible(true)}>
     {toggleText}
    </Button>
  );

  return (
    <Popover
      visible={visible}
      anchor={renderToggleButton}
      onBackdropPress={() => setVisible(false)}
    >
      <Layout style={styles.content}>
        {children}
      </Layout>
    </Popover>
  );
};

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 16,
    position: 'relative',
    backgroundColor: 'black',
    borderRadius: 4,
  },
  avatar: {
    marginHorizontal: 4,
  },
});

export default PopOver;