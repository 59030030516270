const secureSet = async (key, value) => {
  window.sessionStorage.setItem(key, value);
};

const secureGet = async (key) => {
  return window.sessionStorage.getItem(key);
};

const secureRemove = async (key) => {
  window.sessionStorage.removeItem(key);
};

// eslint-disable-next-line import/prefer-default-export
export const secure = {
  set: secureSet,
  get: secureGet,
  remove: secureRemove,
};
