import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, Platform, View } from 'react-native';
import { Button, IndexPath, Input, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import {
  useAddNewLeadMutation,
  useLazyGetLeadsQuery,
} from '../../../services/LeadApiSlice';
import { useNavigation } from '../../../navigation/router';
import routes from '../../../navigation/routes';

const BOOLEANFIELDS = ['No', 'Yes'];
const PARTNERTYPE = [
  ' ',
  'Real Estate Agent',
  'CPA',
  'Attorney',
  'Title Company',
  'Insurance Company',
  'Financial Planner',
  'Other',
];
const PARTNERTYPEMAPPING = [
  { value: null, label: '' },
  { value: 'RE_AGENT', label: 'Real Estate Agent' },
  { value: 'CPA', label: 'CPA' },
  { value: 'ATTORNEY', label: 'Attorney' },
  { value: 'TC', label: 'Title Company' },
  { value: 'IC', label: 'Insurance Company' },
  { value: 'FP', label: 'Financial Planner' },
  { value: 'OTHER', label: 'Other' },
];

function CreateLeadForm({ cancelAction, defaultData }: any) {
  const navigate = useNavigation();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    status: 'New',
    isPartner: false,
    referralType: '',
    partnerType: '',
    leadSource: 'Self',
  });
  const [isPartnerIndex, setIsPartnerIndex] = React.useState(new IndexPath(0));
  const [isCompanyLead, setIsCompanyLead] = React.useState(new IndexPath(0));

  const [partnerTypeIndex, setPartnerTypeIndex] = React.useState(
    new IndexPath(0)
  );
  // Auto Complete
  const [createLead, { isSuccess: addSuccess, data: addData }] =
    useAddNewLeadMutation();
  const [getLead] = useLazyGetLeadsQuery();
  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [duplicateLeadId, setDuplicateLeadId] = useState<number | null>(null);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);

  useEffect(() => {
    if (!isFetchingContacts) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetchingContacts]);

  useEffect(() => {
    if (isPartnerIndex?.row === 1) {
      setFormData((prevData) => ({ ...prevData, isPartner: true }));
    } else {
      setFormData((prevData) => ({ ...prevData, isPartner: false }));
    }
  }, [isPartnerIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      partnerType: PARTNERTYPEMAPPING[partnerTypeIndex.row].value,
    }));
  }, [partnerTypeIndex]);

  useEffect(() => {
    if (selectedAssociate?.id && selectedAssociate['@id']) {
      setFormData((prevData) => ({
        ...prevData,
        referredBy: selectedAssociate['@id'],
        referralType: selectedAssociate.type,
      }));
      if (
        selectedAssociate?.email &&
        selectedAssociate.email.includes('@arkmortgage.com')
      ) {
        setIsCompanyLead(new IndexPath(1));
      }
    }
  }, [selectedAssociate]);

  useEffect(() => {
    if (isCompanyLead?.row === 0) {
      setFormData((prevData) => ({
        ...prevData,
        leadSource: 'Self',
      }));
    }
    if (isCompanyLead?.row === 1) {
      setFormData((prevData) => ({
        ...prevData,
        leadSource: 'Ark',
      }));
    }
  }, [isCompanyLead]);

  useEffect(() => {
    if (addSuccess && addData) {
      if (defaultData?.callback) {
        defaultData.callback(addData);
      }
      setTimeout(() => {
        cancelAction(addData);
      }, 2000);
    }
  }, [addSuccess, addData]);

  async function handleSubmit(): Promise<void> {
    try {
      setError(null);
      setDuplicateLeadId(null);
      setFormSubmitted(true);
      checkForRequiredFields();

      const data = await getLead(`&email=${formData.email}`).unwrap();
      if (data?.['hydra:member']?.length) {
        //check if person has same first and last name
        data['hydra:member'].forEach((lead) => {
          if (
            lead?.firstName?.toLowerCase() ===
              formData?.firstName?.toLowerCase() &&
            lead?.lastName?.toLowerCase() === formData?.lastName?.toLowerCase()
          ) {
            setDuplicateLeadId(lead.id);
            throw new Error('A lead with this email and name already exists');
          }
        });
      }

      createLead(formData);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  function checkForRequiredFields() {
    if (formData?.firstName === '') throw new Error('First Name is required');
    if (formData?.lastName === '') throw new Error('First Name is required');
    if (formData?.email === '' && formData?.mobilePhone === '')
      throw new Error('Please fill out a contact method(email, or phone)');

    if (isPartnerIndex?.row === 1 && partnerTypeIndex?.row === 0)
      throw new Error('Please Select Partner Type');
    if (formData?.email && !formData.email.includes('@'))
      throw new Error('Please enter a valid email address');
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Create A Lead'} />
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Is Partner ?" required={false}>
            <KittenSelect
              selectedIndex={isPartnerIndex}
              setSelectedIndex={setIsPartnerIndex}
              options={BOOLEANFIELDS}
            />
          </FormGroup>
        </View>
        {isPartnerIndex?.row === 1 ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Partner Type">
              <KittenSelect
                selectedIndex={partnerTypeIndex}
                setSelectedIndex={setPartnerTypeIndex}
                options={PARTNERTYPE}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      {/* Name */}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="First Name">
            <Input
              placeholder="First Name"
              value={formData['firstName']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['firstName']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Last Name">
            <Input
              placeholder="Last Name"
              value={formData['lastName']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['lastName']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      {/* Contact */}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Mobile Phone" required={false}>
            <Input
              keyboardType="numeric"
              placeholder="Mobile Phone"
              value={formData['mobilePhone']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['mobilePhone']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Email" required={false}>
            <Input
              placeholder="Email"
              value={formData['email']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['email']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Referred By" required={false}>
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetchingContacts}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
              selectedAssociate={selectedAssociate}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Company Lead?" required={false}>
            <KittenSelect
              selectedIndex={isCompanyLead}
              setSelectedIndex={setIsCompanyLead}
              options={BOOLEANFIELDS}
            />
          </FormGroup>
        </View>
      </View>
      <FormGroup label="Additional Info" required={false}>
        <Input
          placeholder="Additional Info"
          multiline={true}
          textStyle={{ minHeight: 64 }}
          value={formData['additionalInfo']}
          onChangeText={(nextValue) => {
            setFormData((prevData) => ({
              ...prevData,
              ['additionalInfo']: nextValue,
            }));
          }}
        />
      </FormGroup>
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {duplicateLeadId ? (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 4,
          }}
        >
          <Button
            size="small"
            appearance="outline"
            onPress={() => {
              cancelAction();
              navigate.navigate(routes.ExtraRoutes.Lead, {
                id: duplicateLeadId,
              });
            }}
          >
            View Lead
          </Button>
        </View>
      ) : null}
      {!!addSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default CreateLeadForm;
