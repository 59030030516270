import React from 'react';
import { Platform, View } from 'react-native';
import { Card, Icon, Text } from '@ui-kitten/components';
import {
  isAfter,
  isBefore, parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear, sub,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
import { useGetMeQuery } from '../../services/UserSlice';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';
import ActivityIndicator from '../../../components/Universal/ActivityIndicatorComponent';

const filters = {
  closed_qtd: {
    type: 'closing',
    from: startOfQuarter(new Date()),
    to: new Date(),
  },
  closed_mtd: {
    type: 'closing',
    from: startOfMonth(new Date()),
    to: new Date(),
  },
  closed_wtd: {
    type: 'closing',
    from: startOfWeek(new Date()),
    to: new Date(),
  },
  closed_ytd: {
    type: 'closing',
    from: startOfYear(new Date()),
    to: new Date(),
  },
  closed_all: {
    type: 'closing',
    from: new Date(0),
    to: new Date(),
  },
  applications_qtd: {
    type: 'application',
    from: startOfQuarter(new Date()),
    to: new Date(),
  },
  applications_mtd: {
    type: 'application',
    from: startOfMonth(new Date()),
    to: new Date(),
  },
  applications_wtd: {
    type: 'application',
    from: startOfWeek(new Date()),
    to: new Date(),
  },
  applications_ytd: {
    type: 'application',
    from: startOfYear(new Date()),
    to: new Date(),
  },
  applications_all: {
    type: 'application',
    from: new Date(0),
    to: new Date(),
  },
};

export default function ClosedQTDCard() {
  const { data: user } = useGetMeQuery({});
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);
  const timePeriod = user?.frontendSettings?.home?.cardDisplay || 'closed_qtd';
  const filter = filters[timePeriod];
  let filterFrom = sub(filter.from, { minutes: 1 });
  filterFrom = zonedTimeToUtc(filterFrom, '');
  const finalNumber = loansData?.['hydra:member'].reduce((acc, loan) => {
    const date = filter.type === 'closing' ? loan.closeDate : loan.applicationDate;
    const processedDate = parseISO(date);
    if (
      isAfter(processedDate, filterFrom)
      && isBefore(processedDate, filter.to)
      && (filter.type === 'closing' ? loan.closeStatus === 'Won' : true)
    ) {
      return acc + loan.loanAmount;
    }
    return acc;
  }, 0);

  return (
    <Card
      status="info"
      style={{
        minHeight: 150,
        minWidth: 300,
        maxHeight: Platform.OS === 'web' ? 'auto' : 200,
        flex: 1,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
      }}
    >
      <Link route={routes.ExtraRoutes.Visualizer}>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <View style={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              style={{
                width: 18,
                height: 18,
              }}
              fill="#8F9BB3"
              name="external-link-outline"
            />
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingVertical: 16,
            }}
          >
            <Text
              category="h6"
              style={{
                textAlign: 'center',
              }}
            >
              {user ? timePeriod.replace('_', ' ').toUpperCase() : ''}
            </Text>
            {loansData ? (
              <Text
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  fontSize: Platform.OS === 'web' ? 45 : 25,
                  marginTop: 8,
                }}
              >
                {currencyFormat(finalNumber || 0)}
              </Text>
            ) : (
              <ActivityIndicator />
            )}
          </View>
        </View>
      </Link>
    </Card>
  );
}
