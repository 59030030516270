import React from 'react';
import {
  add, isAfter, isBefore, isSameDay, startOfYear, sub,
} from 'date-fns';
import { ActivityIndicator } from 'react-native';
import { utcToZonedTime } from 'date-fns-tz';
import ApplicationsChart from '../../../components/homeComponents/TrendCharts/ApplicationsChart';
import ClosedLoansChart from '../../../components/homeComponents/TrendCharts/ClosedLoansChart';
import AppsAndLoansChart from '../../../components/homeComponents/TrendCharts/AppsAndLoansChart';
import DefaultChart from '../../../components/homeComponents/TrendCharts/DefaultChart';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';

const prevYear = startOfYear(sub(new Date(), { years: 1 }));
const currYear = startOfYear(new Date());
const nextYear = startOfYear(add(new Date(), { years: 1 }));
const months = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export default function TrendsChart({ activeChart }) {
  const { data: loansData, isSuccess: loansSuccess } = useGetLoansQuery(GETLOANSQUERY);

  if (!loansSuccess) return <ActivityIndicator style={{ paddingVertical: 48 }} size="large" />;

  const prevYearAllLoanTrend = !loansData
    ? []
    : combineLoanAmountsBasedOnYearAndMonth(
      loansData['hydra:member'].filter((loan) => {
        const appDate = utcToZonedTime(loan.applicationDate, '');
        return (
          (isBefore(appDate, currYear) || isSameDay(appDate, currYear))
            && isAfter(appDate, prevYear)
        );
      }),
    );

  const currYearAllLoanTrend = !loansData
    ? []
    : combineLoanAmountsBasedOnYearAndMonth(
      loansData['hydra:member'].filter((loan) => {
        const appDate = utcToZonedTime(loan.applicationDate, '');

        return (
          (isAfter(appDate, currYear) || isSameDay(appDate, currYear))
            && isBefore(appDate, nextYear)
        );
      }),
    );

  const prevYearClosedLoanTrend = !loansData
    ? []
    : combineLoanAmountsBasedOnYearAndMonth(
      loansData['hydra:member'].filter((loan) => {
        const closeDate = utcToZonedTime(loan.closeDate, '');

        return (
          (isAfter(closeDate, prevYear) || isSameDay(closeDate, prevYear))
            && isBefore(closeDate, currYear)
            && loan.closeStatus === 'Won'
        );
      }),
      'closeDate',
    );
  const currYearClosedLoanTrend = !loansData
    ? []
    : combineLoanAmountsBasedOnYearAndMonth(
      loansData['hydra:member'].filter((loan) => {
        const closeDate = utcToZonedTime(loan.closeDate, '');

        return (
          (isAfter(closeDate, currYear) || isSameDay(closeDate, currYear))
            && isBefore(closeDate, new Date())
            && loan.closeStatus === 'Won'
        );
      }),
      'closeDate',
    );

  if (
    !prevYearAllLoanTrend
    || !currYearAllLoanTrend
    || !prevYearClosedLoanTrend
    || !currYearClosedLoanTrend
  ) return <DefaultChart />;

  const reformat = (data, year) => {
    const formatted = {};
    Object.values(months).forEach((month, index) => {
      let report = data.find((item) => item.monthIndex === index + 1);
      if (!report) {
        report = {
          monthIndex: index,
          loanAmount: 0,
          loanCount: 0,
          year,
        };
      }
      formatted[month] = report;
    });
    return formatted;
  };

  const prevYearAllLoanTrendFormatted = reformat(
    prevYearAllLoanTrend,
    prevYear.getFullYear(),
  );
  const currYearAllLoanTrendFormatted = reformat(
    currYearAllLoanTrend,
    currYear.getFullYear(),
  );
  const prevYearClosedLoanTrendFormatted = reformat(
    prevYearClosedLoanTrend,
    prevYear.getFullYear(),
  );
  const currYearClosedLoanTrendFormatted = reformat(
    currYearClosedLoanTrend,
    currYear.getFullYear(),
  );

  switch (activeChart) {
    //  APPLICATIONS CHART
    case 'Applications':
      return (
        <ApplicationsChart
          prevTrend={prevYearAllLoanTrendFormatted}
          currTrend={currYearAllLoanTrendFormatted}
        />
      );
    //  LOANS CHART
    case 'Loans':
      return (
        <ClosedLoansChart
          prevTrend={prevYearClosedLoanTrendFormatted}
          currTrend={currYearClosedLoanTrendFormatted}
        />
      );
    //  APPS AND LOANS CHART
    case 'Apps & Loans':
      return (
        <AppsAndLoansChart
          currAppTrend={currYearAllLoanTrendFormatted}
          prevAppTrend={prevYearAllLoanTrendFormatted}
          prevLoanTrend={prevYearClosedLoanTrendFormatted}
          currLoanTrend={currYearClosedLoanTrendFormatted}
        />
      );
    default:
      return <DefaultChart />;
  }
}

function combineLoanAmountsBasedOnYearAndMonth(
  arr,
  fieldName = 'applicationDate',
) {
  return arr.reduce((acc, loan) => {
    const appDate = utcToZonedTime(loan[fieldName], '');

    const monthIndex = appDate.getMonth() + 1;
    const year = appDate.getFullYear();
    const month = months[monthIndex];
    // check if month exists in acc
    const monthExists = acc.find((m) => m.month === month && m.year === year);
    if (monthExists) {
      monthExists.loanCount += 1;
      monthExists.loanAmount += loan.loanAmount;
    } else {
      acc.push({
        loanCount: 1,
        loanAmount: loan.loanAmount,
        monthIndex,
        month,
        year,
      });
    }
    return acc;
  }, []);
}
