import { apiSlice } from './ApiSlice';

export const noteApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createNote: build.mutation({
      // eslint-disable-next-line no-shadow
      query: ({ contactId, ...body }) => ({
        url: 'notes',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: 'Notes', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    editNote: build.mutation({
      query: ({ id, contactId, ...put }) => ({
        url: `notes/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Notes', id },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnersActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    deleteNote: build.mutation({
      query: ({ id, contactId }) => ({
        url: `notes/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this tasks `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getTasks` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Notes', id },
        { type: 'Notes', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    patchNote: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `notes/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Notes', id },
        { type: 'Notes', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateNoteMutation,
  useEditNoteMutation,
  useDeleteNoteMutation,
  usePatchNoteMutation,
} = noteApiSlice;
