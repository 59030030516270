import { IndexPath } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { SavedView } from '../../interfaces/Table';
import { KittenSelect } from '../../components-ui';
import { removeColumnFilter, setColumnFilter } from '../../services/TableSlice';
import {useDispatch} from "react-redux";

interface DropdownFilterProps {
  columnName: string;
  savedView: SavedView;
  objectName: string;
  dropdownOptions: string[];
}

function DropdownFilter({
  columnName,
  savedView,
  objectName,
  dropdownOptions,
}: DropdownFilterProps) {
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
  const dispatch = useDispatch();

  useEffect(() => {
    const index: any = savedView.filters.find(
      (filter) => filter.column === columnName,
    )?.value;

    if (index) {
      // Fix for custom dropdowns
      if (typeof index === 'object') {
        // find index of Object
        dropdownOptions.forEach((option: any, i) => {
          if (typeof option === 'string') return;
          if (option?.props?.status === index?.props?.status) {
            setSelectedIndex(new IndexPath(i));
          }
        });
      } else {
        setSelectedIndex(new IndexPath(dropdownOptions.indexOf(index)));
      }
    } else {
      setSelectedIndex(new IndexPath(0));
    }
  }, [columnName, dropdownOptions, savedView]);

  return (
    <View style={{ marginTop: -2 }}>
      <KittenSelect
        level="0"
        size="small"
        selectedIndex={selectedIndex}
        setSelectedIndex={(row) => {
            setSelectedIndex(row);

          if (row.row === 0) {
            dispatch(removeColumnFilter({ objectName, column: columnName }));
          } else {
            dispatch(setColumnFilter({
              objectName,
              filter: {
                column: columnName,
                operator: '=',
                value: dropdownOptions[row.row],
              },
            }));
          }
        }}
        options={dropdownOptions}
      />
    </View>
  );
}

export default DropdownFilter;
