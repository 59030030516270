import React, { Dispatch, SetStateAction } from 'react';
import { Platform, View } from 'react-native';
import { Input, Layout } from '@ui-kitten/components';
import FormGroup from '../../../FormGroup';
import MaskedInput from '../../../../../components-ui/MaskedInput';
import {
  DOCUMENTATIONTYPEMAPPING,
  LOANOCCUPANCYMAPPING,
  LOANTERMMAPPING,
  LOANTYPEMAPPING,
  PROPERTYTYPEMAPPING,
} from '../../../../../helpers/constants';
import SimpleKittenSelect from '../../../../../components-ui/SimpleKittenSelect';

type Props = {
  setState: Dispatch<SetStateAction<any>>;
  state: any;
};
const numbers = new RegExp(/[0-9]/g);
const FinalStepLoan = ({ state, setState }: Props) => {
  return (
    <Layout style={{ paddingBottom: 0, minHeight: 500, flex: 1 }}>
      <View>
        <View
          style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}
        >
          <View style={{ flex: 1 }}>
            <FormGroup label="Borrower Name" required>
              <Input
                value={state['borrowerName'] || ''}
                onChangeText={(nextValue) => {
                  setState((prevData) => ({
                    ...prevData,
                    ['borrowerName']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Borrower Email" required>
              <Input
                placeholder="Email"
                value={state['borrowerEmail'] || ''}
                onChangeText={(nextValue) => {
                  setState((prevData) => ({
                    ...prevData,
                    ['borrowerEmail']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        </View>
        <View
          style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}
        >
          <View style={{ flex: 1 }}>
            <FormGroup label="Property Type" required>
              <SimpleKittenSelect
                options={PROPERTYTYPEMAPPING}
                defaultValue={state.propertyType}
                onChange={({ row, value }) =>
                  setState((prevState) => ({
                    ...prevState,
                    propertyType: value,
                  }))
                }
              />
            </FormGroup>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="Loan Type" required={true}>
              <SimpleKittenSelect
                options={LOANTYPEMAPPING}
                defaultValue={state.loanType}
                onChange={({ row, value }) =>
                  setState((prevState) => ({
                    ...prevState,
                    loanType: value,
                  }))
                }
              />
            </FormGroup>
          </View>
          <View style={{ flex: 3 }}>
            <FormGroup label="Documentation Type" required>
              <SimpleKittenSelect
                options={DOCUMENTATIONTYPEMAPPING}
                defaultValue={state.documentationType}
                onChange={({ row, value }) =>
                  setState((prevState) => ({
                    ...prevState,
                    documentationType: value,
                  }))
                }
              />
            </FormGroup>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="Occupancy" required>
              <SimpleKittenSelect
                options={LOANOCCUPANCYMAPPING}
                defaultValue={state.occupancy}
                onChange={({ row, value }) =>
                  setState((prevState) => ({
                    ...prevState,
                    occupancy: value,
                  }))
                }
              />
            </FormGroup>
          </View>
          <View style={{ flex: 1 }}>
            <FormGroup label="Fico" required={false}>
              <Input
                maxLength={3}
                keyboardType="numeric"
                placeholder="Fico"
                value={state['fico']?.toString() || null}
                onChangeText={(nextValue) => {
                  setState((prevData) => ({
                    ...prevData,
                    ['fico']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="Sales Price" required={true}>
              <MaskedInput
                keyboardType="numeric"
                placeholder="Sales Price"
                value={state['purchasePrice']}
                onChangeText={(nextValue) => {
                  setState((prevData) => ({
                    ...prevData,
                    ['purchasePrice']: nextValue?.length
                      ? parseInt(nextValue)
                      : '0',
                  }));
                }}
                mask={'currency'}
              />
            </FormGroup>
          </View>
          <View style={{ flex: 2 }}>
            <FormGroup label="Loan Amount" required={true}>
              <MaskedInput
                keyboardType="numeric"
                placeholder="Loan Amount"
                value={state['loanAmount']}
                onChangeText={(nextValue) => {
                  setState((prevData) => ({
                    ...prevData,
                    ['loanAmount']: nextValue?.length
                      ? parseInt(nextValue)
                      : '0',
                  }));
                }}
                mask={'currency'}
              />
            </FormGroup>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 3 }}>
            <FormGroup label="LTV" required={true}>
              <MaskedInput
                placeholder="LTV"
                value={state['ltv'] || 0}
                mask={'fullPercentage'}
                disabled={true}
                onChangeText={() => null}
              />
            </FormGroup>
          </View>
          <View style={{ flex: 3 }}>
            <FormGroup label="Interest Rate" required={false}>
              <Input
                inputMode="decimal"
                placeholder="Interest Rate"
                value={state['interestRate']?.toString() || '0'}
                onChangeText={(nextValue) => {
                  if (nextValue[0] === '0') {
                    nextValue = nextValue.slice(1);
                  }
                  //only 1 decimal
                  if (nextValue.split('.').length > 2) {
                    return;
                  }
                  const num = nextValue.replace(/[^0-9.]/g, '');
                  setState((prevData) => ({
                    ...prevData,
                    ['interestRate']: num,
                  }));
                }}
              />
            </FormGroup>
          </View>
          <View style={{ flex: 4 }}>
            <FormGroup label="Loan Term" required={true}>
              <SimpleKittenSelect
                options={LOANTERMMAPPING}
                defaultValue={state.loanTerm || 0}
                onChange={({ row, value }) =>
                  setState((prevState) => ({
                    ...prevState,
                    loanTerm: parseInt(value || '0', 10),
                  }))
                }
              />
            </FormGroup>
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default FinalStepLoan;
