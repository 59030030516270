import { AutocompleteItem, Icon, SelectItem } from '@ui-kitten/components';
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { StarIcon } from '../../components-ui/Icons';

export const RenderAutoCompleteOption = (item, index) => (
  <AutocompleteItem key={index} title={item.title} accessoryLeft={StarIcon} />
);

export const renderOption = (title: string, index: number) => (
  <SelectItem key={title} title={title} />
);

export const RenderCloseIcon = (props: { clearInput: any }) => (
  <TouchableWithoutFeedback onPress={() => props.clearInput()}>
    <Icon {...props} name="close" />
  </TouchableWithoutFeedback>
);
