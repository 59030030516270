import React from 'react';
import { VictoryBar, VictoryChart, VictoryGroup } from 'victory-native';
import Colors from '../../../src/helpers/Colors';

function DefaultChart(props) {
  return (
    <>
      <VictoryChart domainPadding={{ x: 50 }} width={800} height={400}>
        <VictoryGroup
          offset={15}
          style={{ data: { width: 15 } }}
          colorScale={[Colors.secondaryBlue, Colors.primaryRed]}
        >
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            data={[
              { x: 'Jan', y: 10000000 },
              { x: 'Feb', y: 10000000 },
              { x: 'Mar', y: 10000000 },
              { x: 'Apr', y: 10000000 },
              { x: 'May', y: 10000000 },
              { x: 'Jun', y: 10000000 },
              { x: 'Jul', y: 10000000 },
              { x: 'Aug', y: 10000000 },
              { x: 'Sep', y: 10000000 },
              { x: 'Oct', y: 10000000 },
              { x: 'Nov', y: 10000000 },
              { x: 'Dec', y: 10000000 },
            ]}
          />
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            data={[
              { x: 'Jan', y: 5000000 },
              { x: 'Feb', y: 5000000 },
              { x: 'Mar', y: 5000000 },
              { x: 'Apr', y: 5000000 },
              { x: 'May', y: 5000000 },
              { x: 'Jun', y: 5000000 },
              { x: 'Jul', y: 5000000 },
              { x: 'Aug', y: 5000000 },
              { x: 'Sep', y: 5000000 },
              { x: 'Oct', y: 5000000 },
              { x: 'Nov', y: 5000000 },
              { x: 'Dec', y: 5000000 },
            ]}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}

export default DefaultChart;
