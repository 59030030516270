import React, { useContext, useLayoutEffect, useState } from 'react';
import { ScrollView, View, Platform } from 'react-native';
import { Button, Layout, Text } from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../../components-global/ErrorBoundary';
import { QuickActionFAB } from '../../components-global';
import { useRoute, useNavigation } from '../../navigation/router';
import {
  FileIcon,
  PeopleIcon,
  StarIcon,
  UploadIcon,
} from '../../components-ui/Icons';
import { MobileNavigation } from '../../components-global/MobileNavigation';
import {
  useGetContactsQuery,
  useLazyGetContactsQuery,
} from '../../services/ContactApiSlice';
import Table from '../../components-global/Tables/Table';
import {
  selectLocalViewState,
  selectQueryString,
  setGlobalSearch,
  setPage,
  toggleCategoryFilter,
} from '../../services/TableSlice';
import ContactsMobilePills from '../../components-global/ContactsMobilePills';
import { showModal as realShow } from '../../services/FormSlice';
import { RootState } from '../../services/store';


type Props = {
  navigation: any;
};

const categoryFilterMap = [
  {
    objectName: 'Contact',
    filter: {
      column: 'isPartner',
      value: 'true',
      operator: 'eq',
    },
    selectedCategory: 0,
  },
  {
    objectName: 'Contact',
    selectedCategory: 1,
  },
];
let timeout: any = null;
export function ContactsLandingPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const localViewState = useSelector((state) => selectLocalViewState(state, 'Contact'));
  const { activeView: savedView } = localViewState;
  const selectedCategory = savedView?.selectedCategory || 0;
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { defaultData } = useSelector((state:RootState) => state.form);
  const showModal = (payload) => dispatch(realShow(payload));
  const queryString = useSelector((state) => selectQueryString(state, 'Contact'));
  const toggleCategory = (index: number) => {
    dispatch(toggleCategoryFilter(categoryFilterMap[index]));
  };
  const { data: contacts } = useGetContactsQuery(queryString);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setPage({ objectName: 'Contact', page: 1 }));
      dispatch(
        setGlobalSearch({ objectName: 'Contact', globalSearch: value || '' }),
      );
    }, 500);
  };

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      route.setOptions({ headerShown: false });
    }
  }, []);

  return (
    <Layout style={{ flex: 1 }}>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingHorizontal: 32,
              paddingVertical: 24,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text category="h1">Contacts</Text>
              <Button
                status={selectedCategory === 0 ? 'warning' : 'info'}
                style={{ marginLeft: 16 }}
                appearance="ghost"
                accessoryLeft={selectedCategory === 0 ? StarIcon : PeopleIcon}
                onPress={() => {
                  dispatch(toggleCategoryFilter(categoryFilterMap[selectedCategory === 0 ? 1 : 0]));
                }}
              >
                {selectedCategory === 0 ? 'Showing Partners' : 'Show Partners'}
              </Button>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
              }}
            >
              <Button
                status="basic"
                style={{ marginRight: 15 }}
                accessoryLeft={UploadIcon}
                onPress={() => navigation.navigate(
                  { path: 'contacts/import' },
                  { type: 'contacts' },
                )}
              >
                Import Contacts
              </Button>

              <Button
                status="danger"
                appearance="ghost"
                style={{ backgroundColor: '#ffefef' }}
                accessoryLeft={FileIcon}
                onPress={() => showModal({ formType: 'createContact' })}
              >
                Create Contact
              </Button>
            </View>
          </View>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{
              flex: 1,
              paddingTop: 24,
            }}
          >
            <ErrorBoundary>
              <Table
                dataHandler={useLazyGetContactsQuery}
                objectName="Contact"
              />
            </ErrorBoundary>
          </ScrollView>
        </View>
      ) : (
        <>
          <MobileNavigation
            totalItems={contacts ? contacts['hydra:totalItems'] : null}
            title="Contacts"
            searchTerm={searchTerm}
            setSearchTerm={handleSearch}
          />
          <ContactsMobilePills
            toggleFilter={selectedCategory}
            setToggleFilter={(nextValue: number) => toggleCategory(nextValue)}
          />
          <View style={{ flex: 1 }}>
            <ErrorBoundary>
              <Table
                dataHandler={useLazyGetContactsQuery}
                objectName="Contact"
              />
            </ErrorBoundary>
          </View>
        </>
      )}
      {Platform.OS !== 'web' ? (
        <>
          {defaultData?.contactList?.length ? null : (
            <QuickActionFAB
              onlyShow={['createContact', 'logCall', 'logMeeting']}
            />
          )}
        </>
      ) : null}
    </Layout>
  );
}
