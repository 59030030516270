import { useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { useLazyGetMeQuery } from '../../services/UserSlice';
import useAuth from '../../auth/useAuth';
// @ts-ignore
import { EXPO_PUBLIC_ONESIGNAL_APP_ID } from '@env';

const OneSignalInitializer = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [getMe, { data, isSuccess }] = useLazyGetMeQuery({});
  useEffect(() => {
    if (!isAuthenticated) return;
    getMe({});
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isSuccess) return;

    const {
      id, idHash, email, emailHash, phone, mobileHash,
    } = data;

    OneSignal.init({ appId: EXPO_PUBLIC_ONESIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true }).then(() => {
      // @ts-ignore
      OneSignal.Slidedown.promptPush();
      // OneSignal.push(() => {

      // });
      OneSignal.login(
        id.toString(),
      ).then(() => {
        console.error('set external user id');
        OneSignal.User.addEmail(email);
        if (phone) {
          OneSignal.User.addSms(phone);
        }
        // return () => {
        //   OneSignal.clearHandlers();
        // }
      }).catch((err) => {
        console.error('error setting external user id', err);
      });
    });
    // OneSignal.setLogLevel(6, 0);
  }, [data, isSuccess]);

  return children;
};

export default OneSignalInitializer;
