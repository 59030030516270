import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Datepicker,
  Icon,
  IconElement,
  Input,
  Layout,
  NativeDateService,
  Popover,
  TopNavigation,
  TopNavigationAction,
  Text,
} from '@ui-kitten/components';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import moment from 'moment';
import { ThemeContext } from '../../store/context/ThemeContext';
import DetailCreateButtonGroup from './DetailCreateButtonGroup';
import { useNavigation } from '../navigation/router.native';
import { useSelector } from 'react-redux';
import { RootState } from '../services/store';
import { hideModal } from '../services/FormSlice';

const useDatepickerState = (initialDate = null) => {
  const [date, setDate] = React.useState(initialDate);
  const clear = () => setDate(null);
  return { date, onSelect: setDate, clear };
};

export interface MobileNavigationProps {
  title: string;
  searchTerm?: string;
  setSearchTerm?: any;
  totalItems?: number;
  showBack?: boolean;
  disableSearch?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  setStartDate?: any;
  setEndDate?: any;
  enableDateFilter?: boolean;
}

function BackIcon(props): IconElement {
  const { theme } = props;
  return (
    <Icon
      {...props}
      fill={theme === 'light' ? '#eee' : 'white'}
      name="arrow-ios-back-outline"
    />
  );
}

function FilterIcon(props): IconElement {
  return <Icon {...props} fill="white" name="funnel-outline" />;
}

function SearchIcon(props) {
  const { theme } = props;
  return (
    <Icon
      {...props}
      fill={theme === 'light' ? '#eee' : 'white'}
      name="search-outline"
    />
  );
}

function BackAction(): React.ReactElement {
  const { goBack } = useNavigation();
  return <TopNavigationAction icon={BackIcon} onPress={() => goBack()} />;
}

export function MobileNavigation({
  title,
  searchTerm,
  setSearchTerm,
  totalItems,
  showBack = false,
  disableSearch = false,
  startDate = false,
  endDate = false,
  setStartDate = null,
  setEndDate = null,
  enableDateFilter = false,
}: MobileNavigationProps) {
  const { defaultData } = useSelector((state:RootState) => state.form);
  const fromDatePicker = useDatepickerState();
  const toDatePicker = useDatepickerState();
  const { theme } = useContext(ThemeContext);
  const [toggleSearch, setToggleSearch] = useState(false);

  useEffect(() => {
    if (!fromDatePicker.date && startDate) {
      setStartDate(null);
    }
    if (!toDatePicker.date && endDate) {
      setEndDate(null);
    }
    if (fromDatePicker.date) {
      const startDate = moment(fromDatePicker.date)
        .startOf('day')
        .format('YYYY-MM-DD');
      setStartDate(`dueDate[after]=${startDate}`);
    }
    if (toDatePicker.date) {
      const endDate = moment(toDatePicker.date)
        .endOf('day')
        .format('YYYY-MM-DD');
      setEndDate(`dueDate[before]=${endDate}`);
    }
  }, [fromDatePicker, toDatePicker]);

  const { goBack } = useNavigation();

  const renderIcon = (props) => (
    <TouchableWithoutFeedback onPress={() => setSearchTerm('')}>
      <Icon {...props} name="close" />
    </TouchableWithoutFeedback>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderDateFilter(props) {
    const [visible, setVisible] = React.useState(false);
    const formatDateService = new NativeDateService('en', {
      format: 'MM/DD/YYYY',
    });

    const renderToggleButton = (): React.ReactElement => (
      <Button
        onPress={() => setVisible(true)}
        appearance="ghost"
        status="info"
        accessoryLeft={FilterIcon}
      />
    );
    return (
      <Popover
        visible={visible}
        anchor={renderToggleButton}
        onBackdropPress={() => setVisible(false)}
      >
        <View style={{ padding: 16, flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text category="label">From: </Text>
            <Datepicker
              style={{
                marginVertical: 2,
                minWidth: 140,
              }}
              size="small"
              min={new Date('01/01/2002')}
              dateService={formatDateService}
              placeholder="MM/DD/YYYY"
              {...fromDatePicker}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 16,
            }}
          >
            <Text category="label">To: </Text>
            <Datepicker
              style={{
                marginVertical: 2,
                minWidth: 140,
              }}
              size="small"
              min={new Date('01/01/2002')}
              dateService={formatDateService}
              placeholder="MM/DD/YYYY"
              {...toDatePicker}
            />
          </View>
          <Button
            appearance="ghost"
            onPress={() => {
              setVisible(false);
              fromDatePicker.clear();
              toDatePicker.clear();
            }}
            style={{ marginTop: 16 }}
          >
            Clear
          </Button>
        </View>
      </Popover>
    );
  }

  // overflow menu actions
  // eslint-disable-next-line react/no-unstable-nested-components
  function RenderMainDropdowns() {
    return (
      <TopNavigationAction
        onPress={() => setToggleSearch(!toggleSearch)}
        icon={SearchIcon}
      />
    );
  }
  // return this if contacts are selected
  if (defaultData?.contactList?.length) {
    return (
      <Layout
        style={{
          backgroundColor: theme === 'light' ? 'rgb(34, 73, 115)' : '#2E3A59',
          height: 56,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <TopNavigationAction
          style={{ marginTop: 8, marginRight: 12 }}
          onPress={() => hideModal()}
          icon={BackIcon}
        />
        <Text
          category="h6"
          style={[{ marginTop: 8 }, theme === 'light' && { color: 'white' }]}
        >
          {defaultData.contactList.length}
        </Text>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'flex-end',
            paddingTop: 6,
          }}
        >
          <DetailCreateButtonGroup
            style={{
              alignItems: 'flex-end',
              flexDirection: 'row',
              marginRight: -8,
            }}
            mobile
            contacts={defaultData.contactList}
          />
        </View>
      </Layout>
    );
  }

  const renderRightActions = (): React.ReactElement => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {!disableSearch && <RenderMainDropdowns />}
      {enableDateFilter && <RenderDateFilter />}
    </View>
  );

  return (
    <Layout style={{ position: 'relative' }} level="3">
      {!toggleSearch ? (
        <TopNavigation
          style={{
            backgroundColor: theme === 'light' ? 'rgb(34, 73, 115)' : '#2E3A59',
          }}
          alignment="center"
          title={(props) => (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: -4,
              }}
            >
              <Text
                category="h6"
                style={{ color: 'white', fontFamily: 'VerbBold' }}
              >
                {title}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  marginBottom: -8,
                  color: 'white',
                  fontFamily: 'VerbBold',
                }}
              >
                {totalItems ? `Total Items: ${totalItems}` : ''}
              </Text>
            </View>
          )}
          accessoryRight={renderRightActions}
          accessoryLeft={showBack && BackAction}
        />
      ) : (
        <Layout
          style={{
            backgroundColor: theme === 'light' ? 'rgb(34, 73, 115)' : '#2E3A59',
            height: 56,
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: 8,
          }}
        >
          <>
            <TopNavigationAction
              style={{ marginTop: 8, marginRight: 12, paddingLeft: 8 }}
              onPress={() => {
                setSearchTerm('');
                setToggleSearch(!toggleSearch);
              }}
              icon={BackIcon}
            />
            <Input
              style={{ marginTop: 2, flex: 1 }}
              placeholder="Search"
              value={searchTerm}
              accessoryRight={renderIcon}
              onChangeText={(nextValue) => {
                setSearchTerm(nextValue);
              }}
            />
          </>
        </Layout>
      )}
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: 128,
  },
});
