import {
  OverflowMenu,
  MenuItem,
  Text,
  Layout,
  IndexPath, NativeDateService, Datepicker, Button,
} from '@ui-kitten/components';
import React, {useEffect, useState} from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { SavedView } from '../../interfaces/Table';
import { removeColumnFilter, setColumnFilter } from '../../services/TableSlice';
import {useDispatch} from "react-redux";

interface DateFilterProps {
  columnName: string;
  savedView: SavedView;
  objectName: string;
}

const MENU_ITEMS = ['=', '<', '<=', '>', '>=', '<>'];

function DateFilter({
  columnName, savedView, objectName,
}: DateFilterProps) {
  const [selectedIndex, setSelectedIndex] = useState(new IndexPath(0));
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const filter = savedView.filters.find((fil) => fil.column === columnName);
  const componentRef = React.createRef<Datepicker>();

  const dispatch = useDispatch();
  const clearField = () => {
    if (componentRef.current) {
      componentRef.current.clear();
    }
  };

  const renderClear = () => (
    <View>
      <Button onPress={clearField}>Clear</Button>
    </View>
  );

  const toISO = (date: Date) => date?.toISOString().split('T')[0];

  useEffect(() => {
    if (filter) {
      const date = filter.value.split('-');
      setSelectedDate(new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2])));
      setSelectedIndex(new IndexPath(MENU_ITEMS.indexOf(filter.operator)));
    } else {
      setSelectedDate(null);
      setSelectedIndex(new IndexPath(0));
    }
  }, [filter]);

  function updateSavedView(date : Date, index : IndexPath) {
    if (!date) {
      dispatch(removeColumnFilter({ objectName, column: columnName }));
    } else {
      dispatch(setColumnFilter({
        objectName,
        filter: {
          column: columnName,
          operator: MENU_ITEMS[index.row],
          value: toISO(date),
        },
      }));
    }
  }

  const onItemSelect = (index: IndexPath) => {
    setSelectedIndex(index);
    setVisible(false);
    updateSavedView(selectedDate, index);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    updateSavedView(date, selectedIndex);
  };

  const renderToggleButton = () => (
    <Pressable>
      <Text onPress={() => setVisible(true)}>
        {MENU_ITEMS[selectedIndex?.row || 0]}
      </Text>
    </Pressable>
  );

  return (
    <View style={{ flexDirection: 'row' }}>
      {/* Modifier */}
      <Layout style={styles.dateMenuContainer} level="1">
        <OverflowMenu
          style={{ width: 95 }}
          anchor={renderToggleButton}
          visible={visible}
          selectedIndex={selectedIndex}
          onSelect={onItemSelect}
          onBackdropPress={() => setVisible(false)}
        >
          {MENU_ITEMS.map((item) => <MenuItem key={item} style={styles.menuItem} title={item} />)}
        </OverflowMenu>
      </Layout>
      <Datepicker
        min={new Date(2010, 0, 1)}
        dateService={
          new NativeDateService('en', { format: 'MM/DD/YYYY' })
      }
        size="small"
        date={selectedDate}
        onSelect={(nextDate) => {
          handleDateChange(nextDate);
        }}
        ref={componentRef}
        renderFooter={renderClear}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  dateMenuContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 6,
    marginVertical: 1,
  },
  menuItem: {
    maxWidth: 95,
  },
});

export default DateFilter;
