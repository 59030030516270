import React, { useEffect, useState } from 'react';
import { View, Platform } from 'react-native';
import { Layout, MenuItem} from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  useBulkLoanApprovalMutation,
  useLazyGetLoansQuery,
} from '../../services/LoanApiSlice';
import { useGetMeQuery } from '../../services/UserSlice';
import BasicPageHeaderWeb from './BasicPageHeaderWeb';
import Table from '../../components-global/Tables/Table';
import {
  selectLocalViewState,
  toggleCategoryFilter,
} from '../../services/TableSlice';
import ErrorBoundary from '../../components-global/ErrorBoundary';
import CommissionsToggleNav from '../../components-global/CommissionsToggleNav';
import { returnPayPeriod } from '../../helpers/returnPayPeriod';
import { showModal as realShow } from '../../services/FormSlice';

const objectName = 'Commission';

const categoryFilterMap = [
  {
    objectName,
    filter: [
      {
        column: 'purchasedDate',
        operator: '>=',
        value: returnPayPeriod('CURRENT').start,
      },
      {
        column: 'purchasedDate',
        operator: '<',
        value: returnPayPeriod('CURRENT').end,
      },
    ],
    selectedCategory: 0,
  },
  {
    objectName,
    filter: [
      {
        column: 'purchasedDate',
        operator: '>=',
        value: returnPayPeriod('UPCOMING').start,
      },
      {
        column: 'purchasedDate',
        operator: '<',
        value: returnPayPeriod('UPCOMING').end,
      },
    ],
    selectedCategory: 1,
  },
  {
    objectName,
    filter: [
      {
        column: 'purchasedDate',
        operator: '>=',
        value: returnPayPeriod('ALL').start,
      },
      {
        column: 'purchasedDate',
        operator: '<',
        value: returnPayPeriod('ALL').end,
      },
    ],
    selectedCategory: 2,
  },
];

export default function CommissionApprovalScreen() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { data: user } = useGetMeQuery('');
  const [bulkLoanApproval, { isSuccess: isSuccessBulkApproval }] = useBulkLoanApprovalMutation();

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));

  const localViewState = useSelector((state) => selectLocalViewState(state, objectName));
  const { activeView } = localViewState;
  const selectedCategory: number = activeView?.selectedCategory || 0;

  const toggleCategory = (index: number) => {
    dispatch(toggleCategoryFilter(categoryFilterMap[index]));
  };

  useEffect(() => {
    if (user?.roles?.length) {
      setUserRole(user.roles.includes('ROLE_MANAGER') ? 'Admin' : 'Officer');
    }
  }, [user]);

  useEffect(() => {
    setSelectedRange(['CURRENT', 'UPCOMING', 'ALL'][selectedCategory]);
  }, [activeView]);

  useEffect(() => {
    if (isSuccessBulkApproval) {
      // @ts-ignore
      toast?.show('BULK APPROVAL SUCCESSFULLY!', { type: 'success' });
    }
  }, [isSuccessBulkApproval]);

  const handleCommissionApproval = (rows) => {
    if (userRole === 'Officer') {
      const loanIDs = rows.reduce((acc, current) => {
        if (
          !current.commissionApproval
          || current?.commissionApproval?.loApproval !== true
        ) {
          acc.push(current.id);
        }

        return acc;
      }, []);
      if (loanIDs.length === 0) return;
      bulkLoanApproval({
        loans: loanIDs,
        commissionApproval: { loApproval: true, loComment: 'approved' },
      });
    }
    if (userRole === 'Admin') {
      const loanIDs = rows.reduce((acc, current) => {
        if (
          !current.commissionApproval
          || current?.commissionApproval?.managerApproval !== true
        ) {
          acc.push(current.id);
        }

        return acc;
      }, []);
      if (loanIDs.length === 0) return;
      bulkLoanApproval({
        loans: loanIDs,
        commissionApproval: {
          managerApproval: true,
          managerComment: 'approved',
        },
      });
    }
  };

  return (
    <Layout
      style={[
        {
          flex: 1,
        },
      ]}
    >
      {Platform.OS === 'web' ? (
        <BasicPageHeaderWeb title="Commission" />
      ) : null}
      <View style={{ flex: 1 }}>
        {/* toggle button */}

        <View style={{ flexDirection: 'row', height: '100%' }}>
          <View
            style={{
              flexBasis: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {/*   search */}
            <View
              style={{
                flexDirection: 'row',
                paddingVertical: Platform.OS === 'web' ? 12 : 0,
                paddingHorizontal: Platform.OS === 'web' ? 44 : 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <CommissionsToggleNav
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                toggleCategory={toggleCategory}
              />
            </View>
            {/*  data table */}
            <Layout style={{ flex: 1 }}>
              <ErrorBoundary>
                <Table
                  defaultSavedView={defaultCommissionView}
                  disableFilters={['purchasedDate']}
                  objectName={objectName}
                  dataHandler={useLazyGetLoansQuery}
                  customActions={(selectedRows) => {
                    setSelectedRows(selectedRows);
                    const disableDeny = selectedRange !== 'CURRENT'
                      ? true
                      : !!(selectedRows.length > 1
                          || selectedRows.find(
                            (row) => row?.commissionApproval?.[
                              userRole === 'Officer'
                                ? 'loApproval'
                                : 'managerApproval'
                            ]
                              || row?.commissionApproval?.[
                                userRole === 'Officer'
                                  ? 'loApproval'
                                  : 'managerApproval'
                              ] === false,
                          )?.id);

                    const disableApprove = selectedRange !== 'CURRENT'
                      ? true
                      : !!selectedRows.find(
                        (row) => row?.commissionApproval?.[
                          userRole === 'Officer'
                            ? 'loApproval'
                            : 'managerApproval'
                        ],
                      )?.id;

                    return (
                      <>
                        <MenuItem
                          title="Approve Commission"
                          disabled={disableApprove}
                          onPress={() => {
                            handleCommissionApproval(selectedRows);
                          }}
                        />
                        <MenuItem
                          title={
                            selectedRows?.length > 1
                              ? 'Please select (1) row'
                              : 'Reject Commission'
                          }
                          disabled={disableDeny}
                          onPress={() => {
                            showModal({
                              formType: 'commissionReject',
                              data: { ...selectedRows[0], userRole },
                            });
                          }}
                        />
                      </>
                    );
                  }}
                />
              </ErrorBoundary>
            </Layout>
          </View>
        </View>
      </View>
    </Layout>
  );
}

const defaultCommissionView = [
  {
    name: 'Default',
    columns: [
      'commissionApproval.loApproval',
      'commissionApproval.loComment',
      'loanNumber',
      'loanAmount',
      'leadSource',
      'propertyAddress',
      'closeDate',
      'purchasedDate',
      'lockedAt',
      'loCommission',
      'commissionTier',
    ],
    additionalFilter: '',
    filters: [
      {
        column: 'purchasedDate',
        operator: '>=',
        value: returnPayPeriod('CURRENT').start,
      },
      {
        column: 'purchasedDate',
        operator: '<',
        value: returnPayPeriod('CURRENT').end,
      },
    ],
    order: [],
    isDefault: true,
    categoryFilter: [],
    selectedCategory: 0,
    isNative: false,
  },
  {
    name: 'Mobile',
    columns: [
      'loanContact.firstName',
      'propertyAddress',
      'loanNumber',
      'commissionApproval.loComment',
      'loCommission',
      'commissionApproval.loApproval',
    ],
    selectedCategory: 0,
    isDefault: false,
    isNative: true,
    order: [],
    filters: [
      {
        column: 'purchasedDate',
        operator: '>=',
        value: returnPayPeriod('CURRENT').start,
      },
      {
        column: 'purchasedDate',
        operator: '<',
        value: returnPayPeriod('CURRENT').end,
      },
    ],
    additionalFilter: '',
    categoryFilter: [
      // {
      //   column: 'phase',
      //   value: 'Application',
      //   operator: 'eq',
      // },
    ],
  },
];
