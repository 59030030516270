import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import { Card, Text } from '@ui-kitten/components';
import { useGetTiersQuery } from '../../../services/TierSlice';
import { useGetMeQuery } from '../../../services/UserSlice';
import { isAfter, isBefore, sub } from 'date-fns';
import { startOfQuarter } from 'date-fns/esm';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { currencyFormat } from '../../../helpers/dataTransformerHelper';

function ClosingsForCurrentTier() {
  const { data: user } = useGetMeQuery({});
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  let currentTier;
  if (user) {
    const tierHistory = user.tierHistory;
    const prevQuarter = sub(startOfQuarter(new Date()), { weeks: 1 });

    //find the tier history for the previous quarter
    currentTier = tierHistory.find((tier) => {
      return (
        isAfter(prevQuarter, new Date(tier.dateBegin)) &&
        isBefore(prevQuarter, new Date(tier.dateEnd))
      );
    });
  }

  const closedLoanAmountQTD = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.closeDate &&
          loan.loanAmount &&
          isAfter(new Date(loan.closeDate), startOfQuarter(new Date())) &&
          isBefore(new Date(loan.closeDate), new Date())
        ) {
          return acc + loan.loanAmount;
        }
        return acc;
      }, 0);

  let closingsNeededForCurrentTier = 0;

  if (user && loansData) {
    closingsNeededForCurrentTier = currentTier?.tier?.max
      ? currentTier.tier.max - closedLoanAmountQTD
      : -1;
  }

  return (
    <Card
      style={styles.card}
      status="info"
      header={(props) => (
        <View {...props}>
          <Text
            category="h6"
            style={{ textAlign: 'center', fontFamily: 'VerbBold' }}
          >
            Closings Needed to Reach Your Current Tier
          </Text>
        </View>
      )}
    >
      <View style={{ flex: 1 }}>
        {user && loansData ? (
          <Text
            category="h4"
            style={{ textAlign: 'center', fontFamily: 'verbBold' }}
          >
            {closingsNeededForCurrentTier > 0
              ? currencyFormat(closingsNeededForCurrentTier)
              : 'Tier Reached'}
          </Text>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    margin: 2,
    flex: 1,
    minWidth: 250,
  
  },
});

export default ClosingsForCurrentTier;
