import {
  useNavigation as useNativeNavigation,
  useRoute as useNativeRoute,
} from '@react-navigation/native';
import { useCallback } from 'react';

const useNavigation = () => {
  const { replace, goBack } = useNativeNavigation();
  const nativeNav = useNativeNavigation();

  const navigate = useCallback((route, routeParams = {}, queryParams = {}) => {
    nativeNav.navigate(route.name, { ...queryParams, ...routeParams });
  }, []);
  return {
    navigate,
    replace,
    goBack,
  };
};

const useRoute = () => {
  const { name, params } = useNativeRoute();
  const nav = useNativeNavigation();
  const getParam = useCallback((key) => params && params[key], []);

  const setParams = useCallback((paramsObj) => {
    nav.setParams(paramsObj);
  }, []);

  const setOptions = useCallback((options) => {
    nav.setOptions(options);
  }, []);

  const route = {
    path: name,
    params,
    getParam,
    setParams,
    setOptions,
  };

  return route;
};

export { useNavigation, useRoute };
