import {
  Button,
  DrawerGroup,
  DrawerItem,
  Layout,
  Text,
} from '@ui-kitten/components';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ActivityIndicator } from 'react-native-paper';
import InputField from '../Forms/react-hook-form/InputField';
import { useGetMeQuery, usePatchUserMutation } from '../../services/UserSlice';
import CheckboxField from '../Forms/react-hook-form/CheckboxField';

type FormProps = {
  control: any;
  register: any;
  isDirty: boolean;
  isLoading: boolean;
  handleSubmit: any;
  onSubmit: any;
};

function Form({
  control, register, isDirty, isLoading, handleSubmit, onSubmit,
} : FormProps) {

  return (
    <Layout level="1" style={{ flex: 1 }}>
      <InputField
        control={control}
        name="mobilePhone"
        label="Mobile Number"
        required={false}
        register={register}
      />

      <CheckboxField
        control={control}
        register={register}
        required
        name="agreeToTerms"
        label="By checking this box you agree to receive SMS messages from Ark Mortgage. Message and data rates may apply. Reply STOP to opt out."
      />
      <Button
        disabled={!isDirty || isLoading}
        size="small"
        onPress={handleSubmit(onSubmit)}
        style={{ alignSelf: 'flex-end' }}
      >
        Save
      </Button>
      {/* </View> */}
      {isLoading && (
      <ActivityIndicator
        style={{ position: 'absolute', top: '40%', left: '50%' }}
      />
      )}
    </Layout>
  );
}

function MobileNumberForm() {
  const { data: user } = useGetMeQuery({});
  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();

  const {
    control,
    handleSubmit,
    setFocus,
    register,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      mobilePhone: user?.mobilePhone || '',
      agreeToSms: false,
    },
  });

  async function onSubmit(data: any) {
    await patchUser({ id: user.id, ...data });
  }

  return (
    <DrawerGroup
      title={() => (
        <Text
          category="label"
          style={{ paddingLeft: 16, fontSize: 18, marginBottom: 8 }}
        >
          Mobile Number
        </Text>
      )}
    >
      <DrawerItem
        title={() => Form({
          control, register, isDirty, isLoading, handleSubmit, onSubmit,
        })}
      />
    </DrawerGroup>
  );
}

export default MobileNumberForm;
