import { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, StyleSheet } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import { FormSuccess } from '../../../components-ui';
//@ts-ignore
import BorrowerDND from './BorrowerDND';
import {
  useCreateBorrowerPairMutation,
  useDeleteBorrowerPairMutation,
  useGetAllBorrowerPairsQuery,
  usePatchBorrowerPairMutation,
} from '../../../services/BorrowerPairsApiSlice';
import { useGetLoanQuery } from '../../../services/LoanApiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAllBorrowersQuery, useGetMultipleBorrowersQuery } from '../../../services/BorrowerApiSlice';
import { original } from 'immer';

function BorrowerPairForm({ cancelAction, defaultData }: any) {
  const { loanData } = defaultData;
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [columns, setColumns] = useState({});
  const [finishedPatching, setFinishedPatching] = useState<boolean>(false);
  const [deleteBorrowerPair] = useDeleteBorrowerPairMutation();
  const [createBorrowerPair] = useCreateBorrowerPairMutation();
  const { data: allLoanBorrowers, isFetching: fetchingBorrowerData } = useGetAllBorrowersQuery({ loanId: loanData?.id });
  const { data: borrowerPairsData, isFetching: fetchingBorrowerPairsData } =
    useGetAllBorrowerPairsQuery(loanData ? { loanId: loanData.id } : skipToken);

  useEffect(() => {
    if (finishedPatching) {
      handleSuccess();
    }
  }, [finishedPatching]);

  async function handleSuccess() {
    if (defaultData?.callback) {
      await defaultData.callback();
    }
    setTimeout(() => {
      cancelAction();
    }, 2000);
  }

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      const originalBorrowerPairs = JSON.parse(
        JSON.stringify(borrowerPairsData || [])
      );

      const keys = Object.keys(columns).filter((key) => key !== 'allBorrowers');
      const borrowerPairs = [];

      for (let i = 0; i < keys.length; i++) {
        const borrowers = columns[keys[i]].items;
        const borrower = borrowers?.[0]?.['@id'] || null;
        const coBorrower = borrowers?.[1]?.['@id'] || null;
        const keyIndex = parseInt(keys[i]) - 1;
        //check if borrower Pair index exists in loanData
        if (borrowerPairs[keyIndex]) {
          if (borrower || coBorrower) {
            borrowerPairs[keyIndex].borrower = borrower || null;
            borrowerPairs[keyIndex].coBorrower = coBorrower || null;
          } else {
            borrowerPairs.splice(keyIndex, 1);
          }
        } else {
          if (borrower || coBorrower) {
            borrowerPairs.push({
              borrower: borrower || null,
              coBorrower: coBorrower || null,
            });
          }
        }
      }
      //deletes all pairs
      for (let i = 0; i < originalBorrowerPairs.length; i++) {
        let idToDelete = originalBorrowerPairs[i].id;
        await deleteBorrowerPair({
          loanId: loanData.id,
          borrowerPairId: idToDelete,
        }).unwrap();
      }

      for (let i = 0; i < borrowerPairs.length; i++) {
        const pair = borrowerPairs[i];
        await createBorrowerPair({
          loanId: loanData.id,
          borrowerPair: pair,
        }).unwrap();
      }
      setFinishedPatching(true);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  return (
    <Layout
      style={{
        flex: 1,
        position: 'relative',
        minHeight: Dimensions.get('screen').height - 500,
        maxHeight: 968,
        maxWidth: 768,
      }}
    >
      <Header heading={'Borrowers'} />
      {formSubmitted ? (
        <ActivityIndicator
          size={'large'}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 99,
            padding: 45,
            backgroundColor: 'rgba(255,255,255,0.8)',
            transform: [{ translateX: -45 }, { translateY: -45 }],
          }}
        />
      ) : null}
      {loanData && allLoanBorrowers && borrowerPairsData ? (
        <BorrowerDND
          loanData={loanData}
          borrowerPairs={borrowerPairsData}
          borrowerData={allLoanBorrowers}
          columns={columns}
          setColumns={setColumns}
        />
      ) : (
        <ActivityIndicator size={'large'} />
      )}
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {!!finishedPatching && <FormSuccess />}

      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'SAVE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default BorrowerPairForm;
