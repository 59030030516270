import { Button, IndexPath, Layout, Text } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { KittenSelect } from '../../components-ui';
import {
  FirstPage,
  PrevPage,
  NextPage,
  LastPage,
} from '../../components-ui/Icons';

type Props = { page: number; setPage: Function; data: any };

const Pagination = ({ page, setPage, data }: Props) => {
  const [pageData, setPageData] = useState({
    numberOfResults: 0,
    numberOfPages: 1,
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState(new IndexPath(page ? page - 1 : 0));

  useEffect(() => {
    if (data) {
      const results = data['hydra:totalItems'];
      const pages = Math.ceil(results / 30) || 1;
      setPageData({
        numberOfPages: pages,
        numberOfResults: results,
      });
    }
  }, [data]);

  useEffect(() => {
    setSelectedPageIndex(new IndexPath(page - 1));
  }, [page]);

  const pages = [];
  for (let i = 1; i < pageData.numberOfPages + 1; i++) {
    pages.push(i);
  }
  if (!pages.length) {
    pages.push(1);
  }

  useEffect(() => {
    setPage(selectedPageIndex.row + 1);
  }, [selectedPageIndex]);

  return (
    <Layout level="3" style={styles.paginationContainer}>
      <Text style={{ fontSize: 16, paddingTop: 2, color: '#242424' }}>
        Total Records:
      </Text>
      <Text
        category="label"
        style={{ fontSize: 16, paddingTop: 2, marginLeft: 8, color: '#242424' }}
      >
        {pageData.numberOfResults}
      </Text>
      <Text style={{ color: '#242424', fontSize: 32 }}> - </Text>
      <Text
        category="label"
        style={{
          fontSize: 16,
          paddingTop: 2,
          marginRight: 'auto',
          color: '#242424',
        }}
      >
        Page {page} of {pageData.numberOfPages}
      </Text>
      <View style={{ position: 'relative', marginHorizontal: 16 }}>
        <KittenSelect
          size="small"
          label=""
          level="3"
          selectedIndex={selectedPageIndex}
          setSelectedIndex={setSelectedPageIndex}
          options={pages}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Button
          style={styles.button}
          onPress={() => setPage(1)}
          disabled={page === 1}
          appearance="outline"
          size="tiny"
          status={'primary'}
          accessoryLeft={FirstPage}
        />
        <Button
          style={styles.button}
          onPress={() => setPage(page - 1)}
          disabled={page === 1}
          appearance="outline"
          size="tiny"
          status={'primary'}
          accessoryLeft={PrevPage}
        />
        <Button
          style={styles.button}
          onPress={() => setPage(page + 1)}
          disabled={page === pageData.numberOfPages}
          appearance="outline"
          size="tiny"
          status="primary"
          accessoryLeft={NextPage}
        />
        <Button
          style={styles.button}
          onPress={() => setPage(pageData.numberOfPages)}
          disabled={page === pageData.numberOfPages}
          appearance="outline"
          size="tiny"
          status="primary"
          accessoryLeft={LastPage}
        />
      </View>
    </Layout>
  );
};

export default Pagination;
const styles = StyleSheet.create({
  paginationContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: '#aaa',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: '#224973',
    // backgroundColor: 'rgb(0, 122, 255)'
  },

  button: {
    margin: 2,
  },
  text: { margin: 6, fontSize: 12, textAlign: 'left' },
});
