import React from 'react';
import { Platform, View } from 'react-native';
import { Card, Icon, Text } from '@ui-kitten/components';
import { isAfter, isBefore, startOfMonth, sub } from 'date-fns';
import ActivityIndicator from '../../../components/Universal/ActivityIndicatorComponent';
// @ts-ignore
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';

export default function CurrentAnnualIncomeCard() {
  const oneYearAgo = sub(new Date(), { months: 12 });
  //Current Annual Income 		= 	closed 1 year, sum of Lo commission
  const { data: loansData, isSuccess } = useGetLoansQuery(GETLOANSQUERY);

  const currentAnnualIncome = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.loCommission &&
          loan.closeDate &&
          isAfter(new Date(loan.closeDate), oneYearAgo) &&
          isBefore(new Date(loan.closeDate), startOfMonth(new Date()))
        ) {
          return acc + loan.loCommission;
        }
        return acc;
      }, 0);

  const formattedCurrentAnnualIncome = currencyFormat(currentAnnualIncome);

  const dateCaption = `${oneYearAgo.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })} - ${startOfMonth(new Date()).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })}`;

  return (
    <Card
      status="primary"
      style={{
        minHeight: 150,
        minWidth: 300,
        flex: 1,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
      }}
    >
        <Link route={routes.ExtraRoutes.CommissionApprovals}>
      <View style={{ alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
        <View style={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              style={{
                width: 18,
                height: 18,
              }}
              fill="#8F9BB3"
              name="external-link-outline"
            />
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingVertical: 16,
            }}
          >
            <Text
              category="h6"
              style={{ marginBottom: 8, textAlign: 'center' }}
            >
              CURRENT ANNUAL INCOME
            </Text>
            {isSuccess ? (
              <>
                <Text appearance="hint" style={{ marginBottom: 8 }}>
                  {dateCaption}
                </Text>
                <Text
                  style={{
                    marginBottom: 8,
                    fontFamily: 'VerbBold',
                    fontSize: 18,
                  }}
                >
                  {/* Stats Endpoint?? Need to request last 12 months Income? */}
                  {formattedCurrentAnnualIncome}
                  {/* Stats Endpoint?? Need to request last 12 months Income? */}
                </Text>
                <Text>Purchased Loans</Text>
              </>
            ) : (
              <View style={{ maxHeight: 80 }}>
                <ActivityIndicator />
              </View>
            )}
          </View>
      </View>
        </Link>
    </Card>
  );
}
