import { Rating } from '@material-ui/lab';
import React from 'react';
import { getWeekOfMonth, getWeeksInMonth, startOfMonth } from 'date-fns';
import EmojiIcon from '../../components/Home/EmojiIcon';
import { useGetMeQuery } from '../../services/UserSlice';
import { useGetStatsQuery } from '../../services/StatsSlice';
import { countOfApplications } from '../../helpers/commonStatFilters';

const today = new Date();
const filter = countOfApplications('appsThisMonth', startOfMonth(today), new Date());
export default function WeeklyProgress() {
  const { data: user, isSuccess: userLoaded } = useGetMeQuery({});
  const { data: appsThisMonth, isSuccess: statLoaded } = useGetStatsQuery({
    filter,
  });

  if (!userLoaded || !statLoaded) return null;

  const monthlyAppGoal = user?.monthlyAppManagementGoal;
  const numOfAppsPerWeekGoal = monthlyAppGoal / getWeeksInMonth(today);
  const rollingGoal = getWeekOfMonth(today) * numOfAppsPerWeekGoal;
  const weeklyProgress = appsThisMonth / rollingGoal;

  return (
    <>
      <EmojiIcon weeklyProgress={weeklyProgress} />
      <Rating precision={0.1} readOnly value={weeklyProgress * 5} />
    </>

  );
}
