import React, { useContext } from 'react';
import { Platform, View } from 'react-native';
import {
  Button,
  Card,
  Divider,
  List,
  ListItem,
  Text,
} from '@ui-kitten/components';
import routes from '../../navigation/routes';
import { useGetEventsQuery } from '../../services/EventApiSlice';// @ts-ignore
import { useNavigation } from '../../navigation/router';
import { useDispatch } from 'react-redux';
import { showModal as realShow } from '../../services/FormSlice';

function EventListItem({ event }) {
  const [hovered, setHovered] = React.useState(false);

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { navigate } = useNavigation();
  const linkType = event.loan || event.contact || event.lead;
  let peices; let id; let
    type;
  if (linkType) {
    peices = linkType.split('/');
    id = peices[peices.length - 1];
    type = peices[peices.length - 2].slice(0, -1).charAt(0).toUpperCase()
      + peices[peices.length - 2].slice(0, -1).slice(1);
  }

  const renderItemAccessoryRight = (event) => (
    <Button
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        showModal({ formType: 'completeEvent', data: event });
      }}
      status="danger"
      size="tiny"
    >
      Complete
    </Button>
  );

  return (
    <ListItem
      onPress={() => {
        if (id && type) {
          navigate(routes.ExtraRoutes[type], { id });
        }
      }}
      title={(props: any) => (
        <View style={{ flexDirection: 'row' }}>
          <Text {...props}>{event.subject} - </Text>
          <Text
            category="label"
            style={{
              fontSize: 13,
              color: 'blue',
              textDecorationLine: 'underline',
            }}
          >
            {event.contactName}
          </Text>
        </View>
      )}
      accessoryRight={() => renderItemAccessoryRight(event)}
      key={event.id}
      description={(props) => {
        const style: any = props.style[0] || {};
        return (
          <Text style={{ ...style, color: '#484848' }} numberOfLines={1}>
            {event.description}{' '}
          </Text>
        );
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ backgroundColor: hovered ? '#f5f5f5' : 'white', width: '100%' }}
    />
  );
}

export default function TodaysEvents() {
  const { data, error, isLoading } = useGetEventsQuery('today=eventDate');

  if (error) {
    return (
      <Card
        style={{
          minWidth: 350,
          padding: 16,
          marginVertical: Platform.OS === 'web' ? 0 : 4,
          flexGrow: 1,
        }}
      >
        <Text>Something went wrong!</Text>
      </Card>
    );
  }

  return (
    <Card
      style={{
        minWidth: 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        flex: 1,
      }}
      header={(props) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: Platform.OS === 'web' ? 0 : 8,
            marginBottom: 4,
          }}
        >
          <Text category="label" style={{ fontSize: 18 }}>
            Todays Events
          </Text>
        </View>
      )}
    >
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <List
          ItemSeparatorComponent={Divider}
          data={data['hydra:member']
            .filter((ev: any) => !ev.outcome)
            .slice(0, 5)}
          renderItem={({ item }) => <EventListItem event={item} />}
        />
      )}
    </Card>
  );
}
