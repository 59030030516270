import { Button, Layout, Modal, Text } from '@ui-kitten/components';
import React from 'react';
import { ActivityIndicator, View, StyleSheet, Dimensions } from 'react-native';

type Props = {
  showEmail: boolean;
  isSending: boolean;
  sendSuccess: boolean;
  borrowerName: string;
  borrowerEmail: string;
  error: string;
  setShowEmail: (showEmail: boolean) => void;
  handleSend: () => void;
};

export default function SendEmailModal({
  showEmail,
  isSending,
  sendSuccess,
  error,
  borrowerEmail,
  borrowerName,
  setShowEmail,
  handleSend,
}: Props) {
  function handleSendEmail() {
    handleSend();
  }

  return (
    <Modal
      visible={showEmail}
      backdropStyle={{ backgroundColor: 'black', opacity: 0.45 }}
    >
      <Layout style={{ padding: 32, maxWidth: Dimensions.get('window').width - 32, }}>
        {isSending ? (
          <ActivityIndicator size={'large'} />
        ) : (
          <View
            style={{
              flexDirection: 'row',
              padding: 16,
              justifyContent: 'center',
              maxWidth: Dimensions.get('window').width - 48,
              flexWrap: 'wrap',
            }}
          >
            {Boolean(sendSuccess) ? (
              <Text style={styles.textSize}>Email Sent!</Text>
            ) : (
              <>
                <Text style={styles.textSize}>
                  This will send a pre-approval to
                </Text>
                <Text style={styles.boldText}> {borrowerName} </Text>
                <Text style={styles.textSize}>at</Text>
                <Text style={styles.boldText}> {borrowerEmail}</Text>
              </>
            )}
          </View>
        )}

        {error ? (
          <Text style={{ color: 'red', fontSize: 19, marginTop: 16 }}>
            {error}
          </Text>
        ) : null}
        <View style={styles.buttonContainer}>
          <Button
            appearance="outline"
            status="basic"
            onPress={() => setShowEmail(false)}
          >
            CANCEL
          </Button>
          <Button
            appearance="outline"
            status="info"
            onPress={() => handleSendEmail()}
            disabled={Boolean(sendSuccess) || Boolean(isSending)}
          >
            SEND
          </Button>
        </View>
      </Layout>
    </Modal>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 24,
    paddingTop: 24,
    borderTopWidth: 1,
    borderTopColor: '#eee',
    justifyContent: 'flex-end',
    gap: 24,
  },
  container: {
    minHeight: 32,
    marginHorizontal: -24,
    position: 'relative',
    borderBottomColor: '#eee',
    borderBottomWidth: 1,
    marginBottom: 8,
  },
  textSize: {
    fontSize: 19,
  },
  boldText: {
    fontFamily: 'VerbBold',
    fontSize: 19,
  },
});
