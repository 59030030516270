import { addDays, startOfDay, startOfWeek, sub } from 'date-fns';
import moment from 'moment';

export const COLORMAPPINGPPILL = {
  High: 'rgb(255, 113, 91)',
  Medium: '#d1c641',
  Low: 'rgb(46, 134, 193)',
};
export const TODAY = moment(startOfDay(new Date())).toISOString();

export const LASTWEEK = moment(startOfDay(new Date()))
  .subtract(1, 'week')
  .startOf('week')
  .toISOString();
  export const SIXMONTHSPAST = moment(startOfDay(new Date()))
  .subtract(6, 'months')
  .toISOString();
export const NOW = moment().toISOString();
export const TOMORROW = moment(startOfDay(new Date())).add(1, 'days').toISOString();
export const DAYAFTERTOMORROW = moment(startOfDay(new Date())).add(2, 'days').toISOString();
export const STARTOFNEXTWEEK = moment(startOfDay(new Date()))
  .add(1, 'week')
  .startOf('week')
  .toISOString();

export const ENDOFNEXTWEEK = moment(startOfDay(new Date()))
  .add(1, 'week')
  .endOf('week')
  .toISOString();

  export const DATEMAPPING = {
    today: {
      startDate: startOfDay(new Date()),
      endDate: startOfDay(new Date()),
    },
    tomorrow: {
      startDate: startOfDay(addDays(new Date(), 1)),
      endDate: startOfDay(addDays(new Date(), 1)),
    },
    'next week': {
      startDate: startOfWeek(addDays(new Date(), 7)),
      endDate: addDays(startOfWeek(addDays(new Date(), 7)), 6),
    },
    'past week': {
      startDate:startOfWeek(sub(new Date(), { weeks: 1 })),
      endDate: addDays(startOfWeek(sub(new Date(), { weeks: 1 })), 6)
    },
  }