import { Icon, Layout, Text } from '@ui-kitten/components';
import React from 'react';

type Props = {};

export default function FormSuccess({}: Props) {
  return (
    <Layout
      level='2'
      style={{
        flexDirection: 'row',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      }}
    >
      <Icon
        style={{
          width: 48,
          height: 48,
          marginRight: 4,
        }}
        fill="green"
        name="star"
      />
      <Text style={{ color: 'green', fontFamily: 'VerbBold', fontSize: 22 }}>
        SUCCESS!
      </Text>
    </Layout>
  );
}
