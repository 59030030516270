import { Pressable, View, StyleSheet } from 'react-native';
import { Icon } from '@ui-kitten/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Column, Order } from '../../interfaces/Table';
import { toggleSort } from '../../services/TableSlice';

interface OrderFilterProps {
  column: Column;
  order?: Order;
  children: any;
  objectName: string;
}

function OrderFilter({
  column, order, children, objectName,
}: OrderFilterProps) {
  const dispatch = useDispatch();
  function toggleOrder() {
    if (!column.sortable) return;
    const property = column.name;
    const currentOrder = order?.direction || null;
    let nextOrder = null;
    switch (currentOrder) {
      case 'asc':
        nextOrder = 'desc';
        break;
      case 'desc':
        nextOrder = null;
        break;
      default:
        nextOrder = 'asc';
    }
    dispatch(toggleSort({ objectName, order: { column: property, direction: nextOrder } }));
  }

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        width: '100%',
      }}
      onPress={() => toggleOrder()}
    >
      {children}
      {column.sortable && (
        <View>
          <Icon
            style={styles.orderIcon}
            fill={
              order?.direction === 'desc'
                ? 'rgb(101, 163, 216)'
                : '#aaa'
            }
            name="arrow-up"
          />
          <Icon
            style={styles.orderIcon}
            fill={
              order?.direction === 'asc'
                ? 'rgb(101, 163, 216)'
                : '#aaa'
            }
            name="arrow-down"
          />
        </View>
      )}

    </Pressable>
  );
}

export default OrderFilter;

const styles = StyleSheet.create({
  orderIcon: {
    width: 14,
    height: 14,
    marginLeft: 6,
    marginVertical: -4,
  },
});
