import {View} from "react-native";
import {Avatar, Text} from "@ui-kitten/components";
import React, {useEffect} from "react";
import {useGetMeQuery} from "../../services/UserSlice";
import {useGetTiersQuery} from "../../services/TierSlice";
import { StyleSheet } from 'react-native';


export default function ProfileHeader() {
    const [clubRank, setClubRank] = React.useState({
        name: 'ark'
    });
    const [commissionTier, setCommissionTier] = React.useState({
        tier: '',
        percentage: '0.0'
    });
    let user: any, isLoading: boolean, error: any;
    let userLoaded: boolean = false;
    ({data: user, isLoading, error, isSuccess: userLoaded} = useGetMeQuery({}));
    const { data: tiers, isSuccess: tiersLoaded } = useGetTiersQuery({});

    const ark = require('../../../assets/images/SAIL-1024.png');
    const silver = require('../../../assets/images/BadgeSystem2021-01.png');
    const gold = require('../../../assets/images/BadgeSystem2021-02.png');
    const platinum = require('../../../assets/images/BadgeSystem2021-03.png');
    const diamond = require('../../../assets/images/BadgeSystem2021-04.png');

    const clubIcons = {
        'ark': ark,
        'silver': silver,
        'gold': gold,
        'platinum': platinum,
        'diamond': diamond
    }

    useEffect(() => {
        if(tiersLoaded && userLoaded) {
            console.log(tiers);
            tiers['hydra:member'].forEach((tier) => {
                if (user.qtdLoans >= tier.min && user.qtdLoans <= tier.max) {
                    setCommissionTier(tier);
                }
            });
        }
    }, [tiers, user]);

    if(isLoading) {
        return <Text>Loading...</Text>;
    }

    if(error) {
        return <Text status={'danger'}>{error}</Text>;
    }

    return <View style={styles.profileContainer}>
        <Avatar
            style={styles.profileAvatar}
            size='large'
            source={clubIcons[clubRank.name]}
        />
        <View style={styles.profileDetailsContainer}>
            <Text category='h4'>
                {`${user.firstName} ${user.lastName}`}
            </Text>
            <Text
                appearance='hint'
                category='s1'>
                {`Tier ${commissionTier.tier} (Comp ${commissionTier.percentage}%)`}
            </Text>
        </View>
    </View>
} ;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        padding: 16,
    },
    profileContainer: {
        flexDirection: 'row',
    },
    profileAvatar: {
        marginHorizontal: 8,
    },
    profileDetailsContainer: {
        flex: 1,
        marginHorizontal: 8,
    },
    rateBar: {
        marginTop: 24,
    },
    followButton: {
        marginTop: 24,
    },
    profileParametersContainer: {
        flexDirection: 'row',
        minHeight: 220,
        marginHorizontal: 8,
        marginTop: 24,
        marginBottom: 8,
    },
    profileSocialsSection: {
        marginHorizontal: 16,
    },
    profileSocialContainer: {
        flex: 1,
    },
    profileSectionsDivider: {
        width: 1,
        height: '100%',
        marginHorizontal: 8,
    },
    profileDescriptionSection: {
        flex: 1,
        marginHorizontal: 16,
    },
    profileParametersSection: {
        flexDirection: 'row',
        marginVertical: 16,
        marginHorizontal: 8,
    },
    profileParameter: {
        flex: 1,
        marginHorizontal: 8,
    },
});
