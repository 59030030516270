import { apiSlice } from './ApiSlice';

export const eventApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query({
      query: (query) => `events?${query}`,
      providesTags: (result, error, page) =>
        result && result['hydra:member']
          ? [
            // Provides a tag for each lead in the current page,
            // as well as the 'PARTIAL-LIST' tag.
            ...result['hydra:member'].map(({ id }) => ({
              type: 'Events',
              id,
            })),
            { type: 'Events', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Events', id: 'PARTIAL-LIST' }],
    }),
    createEvent: build.mutation({
      query: ({ contactId, ...body }) => ({
        url: 'events',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: 'Events', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    editEvent: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `events/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Events', id },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
    deleteEvent: build.mutation({
      query: ({ id }) => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this event `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getEvents` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Events', id },
        { type: 'Events', id: 'PARTIAL-LIST' },
        { type: 'ContactActivities', id: 'PARTIAL-LIST' },
        { type: 'LeadActivities', id: 'PARTIAL-LIST' },
        { type: 'LoanActivities', id: 'PARTIAL-LIST' },
        { type: 'PartnerActivities', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line max-len
export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useEditEventMutation,
  useDeleteEventMutation,
} = eventApiSlice;
