import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';
import {
  add,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns';
import { Card, Icon, Text } from '@ui-kitten/components';
import ActivityIndicator from '../../../components/Universal/ActivityIndicatorComponent';
// @ts-ignore
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';
import { useGetHomeDataQuery } from '../../services/HomeSlice';
import { useGetTiersQuery } from '../../services/TierSlice';

export default function EstimatedAnnualIncomeCard() {
  const { data: loansData, isSuccess } = useGetLoansQuery(GETLOANSQUERY);
  const { data: homeData } = useGetHomeDataQuery({});
  const { data: tiersData } = useGetTiersQuery({});
  const oneYearAgo = sub(new Date(), { months: 12 });
  const nextYear = add(new Date(), { months: 12 });

  const currentAnnualIncome = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.loCommission &&
          loan.closeDate &&
          isAfter(new Date(loan.closeDate), oneYearAgo) &&
          isBefore(new Date(loan.closeDate), startOfMonth(new Date()))
        ) {
          return acc + loan.loCommission;
        }
        return acc;
      }, 0);

  const lastSixMonthsLoanAmount = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.loanAmount &&
          loan.applicationDate &&
          isAfter(
            new Date(loan.applicationDate),
            sub(startOfMonth(new Date()), { months: 6 })
          ) &&
          isBefore(
            new Date(loan.applicationDate),
            endOfMonth(sub(new Date(), { months: 1 }))
          )
        ) {
          return acc + loan.loanAmount;
        }
        return acc;
      }, 0);

  //last 6 months application loan amount * pull thru * 2, to get the loan amount for a year
  const estimatedYearlyLoanAmount = homeData
    ? lastSixMonthsLoanAmount * homeData?.pullThru * 2
    : 0;

  //then divide the number in 4 to get a tier

  const currentTier = useMemo(() => {
    if (!tiersData) return null;
    return tiersData['hydra:member'].find((tier) => {
      const min = tier.min;
      let max = tier.max || 999999999999999;
      return (
        estimatedYearlyLoanAmount * 0.25 >= min &&
        estimatedYearlyLoanAmount * 0.25 <= max
      );
    });
  }, [tiersData, estimatedYearlyLoanAmount]);

  //then take the tier commission rate * full year loan amount
  const estimatedAnnualIncome = estimatedYearlyLoanAmount * currentTier?.rate;

  const formattedEstimatedAnnualIncome =
    homeData && estimatedAnnualIncome
      ? currencyFormat(estimatedAnnualIncome)
      : 0;

  const dateCaption = `${startOfMonth(new Date()).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })} - ${nextYear.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })}`;
  return (
    <Card
      status={
        Number(currentAnnualIncome) > Number(estimatedAnnualIncome)
          ? 'danger'
          : 'success'
      }
      style={{
        minHeight: 150,
        minWidth: 300,
        flex: 1,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
      }}
    >
      <Link
        route={routes.MenuItems.Pipeline}
        queryParams={{
         column: 'closeDate',
         operator: '>',
        value: format(startOfYear(new Date()), 'yyyy-MM-dd'),
        }}
      >
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <View style={{ position: 'absolute', right: 0, top: 0 }}>
            <Icon
              style={{
                width: 18,
                height: 18,
              }}
              fill="#8F9BB3"
              name="external-link-outline"
            />
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              minWidth: 100,
              paddingVertical: 16,
            }}
          >
            <Text
              category="h6"
              style={{ textAlign: 'center', marginBottom: 8 }}
            >
              ESTIMATED ANNUAL INCOME
            </Text>
            {isSuccess ? (
              <>
                <Text appearance="hint">{dateCaption}</Text>
                <View
                  style={{
                    height: Platform.OS === 'web' ? 40 : 25,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 8,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'VerbBold',
                      fontSize: 18,
                      color:
                        Number(currentAnnualIncome) >
                        Number(estimatedAnnualIncome)
                          ? 'red'
                          : 'green',
                    }}
                  >
                    {formattedEstimatedAnnualIncome}
                  </Text>

                  <Icon
                    style={{
                      marginLeft: -4,
                      height: 36,
                      width: 36,
                      marginTop: -2,
                    }}
                    name={
                      Number(currentAnnualIncome) >
                      Number(estimatedAnnualIncome)
                        ? 'arrow-down-outline'
                        : 'arrow-up-outline'
                    }
                    fill={
                      Number(currentAnnualIncome) >
                      Number(estimatedAnnualIncome)
                        ? 'red'
                        : 'green'
                    }
                  />
                </View>
                <Text style={{ textAlign: 'center' }}>
                  Estimate from Last 6 months of Applications
                </Text>
              </>
            ) : (
              <View style={{ maxHeight: 80 }}>
                <ActivityIndicator />
              </View>
            )}
          </View>
        </View>
      </Link>
    </Card>
  );
}
