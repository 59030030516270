import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import { Provider } from 'react-redux';
import { useFonts } from 'expo-font';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import Toast from 'react-native-fast-toast';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import * as eva from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { store } from './src/services/store';
import Colors from './src/helpers/Colors';
import NetworkInterceptor from './screens/secondary/NetworkInterceptor';
import initFeatures from './init-features';
import { default as kittenTheme } from './styles/custom-theme.json';
import { default as mapping } from './ui-kitten-font-mapping.json';
import { MaterialIconsPack } from './styles/MaterialIconsPack';
import AppNavigator from './src/navigation/AppNavigator.web';
import { MaterialCommunityIconsPack } from './styles/MaterialCommunityIconsPack';
import OneSignalInitializer from './src/components-global/OneSignal/OneSignalInitializer.web';
import SignInPage from './src/auth/signInPage';
import useAuthNative from './src/auth/useAuth';

export default function App() {
  const {
    isAuthenticated, setIdToken, error, isLoading: isAuthLoading, signOut,
  } = useAuthNative();
  /**
   * Initialize MSAL
   */
  // const configuration = {
  //   auth: {
  //     clientId: AD_CLIENT_ID,
  //     authority: `https://login.microsoftonline.com/${AD_TENANT_ID}`,
  //     redirectUri: REACT_URL,
  //     navigateToLoginRequestUrl: true,
  //     postLogoutRedirectUri: REACT_URL,
  //   },
  // };

  // const msalInstance = new PublicClientApplication(configuration);

  // Default to using the first account if no account is active on page load
  // if (
  //   !msalInstance.getActiveAccount()
  //   && msalInstance.getAllAccounts().length > 0
  // ) {
  //   // Account selection logic is app dependent. Adjust as needed for different use cases.
  //   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  // }

  // Optional - This will update account state if a user signs in from another tab or window
  // msalInstance.enableAccountStorageEvents();
  //
  // msalInstance.addEventCallback((event) => {
  //   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
  //     const { account } = event.payload;
  //     msalInstance.setActiveAccount(account);
  //     console.log('here after login we should call users and get the user');
  //     // store.dispatch(apiSlice.endpoints.getUsers.initiate());
  //   }
  // });

  /**
   * end of MSAL
   */

  useEffect(() => {
    initFeatures();
  }, []);

  const [loaded] = useFonts({
    VerbRegular: require('./assets/fonts/VerbRegular.otf'),
    VerbLight: require('./assets/fonts/VerbLight.otf'),
    VerbBold: require('./assets/fonts/VerbBold.otf'),
    VerbUltra: require('./assets/fonts/VerbUltra.otf'),
  });
  const theme = {
    ...DefaultTheme,
    fonts: {
      regular: { fontFamily: 'VerbRegular' },
      light: { fontFamily: 'VerbLight' },
      medium: { fontFamily: 'VerbBold' },
      thin: { fontFamily: 'VerbUltra' },
    },
    colors: {
      ...DefaultTheme.colors,
      primary: Colors.secondaryBlue,
      accent: Colors.primaryRed,
    },
  };
  console.log(isAuthenticated, 'isAuthenticated');

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <PaperProvider theme={theme}>
          <IconRegistry icons={[EvaIconsPack, MaterialIconsPack, MaterialCommunityIconsPack]} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ApplicationProvider {...eva} theme={{ ...eva.light, ...kittenTheme }} customMapping={mapping}>
            <NetworkInterceptor>
              {isAuthenticated ? (
                <>
                  <View
                    style={{
                      backgroundColor: Colors.appBackground,
                      flex: 1,
                      display: 'flex',
                      position: 'relative',
                      flexDirection: 'row',
                      minWidth: 768,
                    }}
                  >
                      <OneSignalInitializer>
                        <AppNavigator />
                      </OneSignalInitializer>
                  </View>
                  <Toast
                    placement={Platform.OS === 'web' ? 'top' : 'bottom'}
                    ref={(ref) => global.toast = ref}
                  />
                </>
              ) : (
                <SignInPage setIdToken={setIdToken} />
              )}
            </NetworkInterceptor>
          </ApplicationProvider>
        </PaperProvider>
      </Provider>
    </SafeAreaProvider>
  );
}
