import React, { useContext, useEffect } from 'react';
import {
  Platform, ScrollView,
  StyleSheet,
  View
} from 'react-native';
import {
  Button, Divider,
  Icon, Layout, Drawer,
} from '@ui-kitten/components';
import { useDispatch } from 'react-redux';
import ActivityIndicatorComponent from '../../components/Universal/ActivityIndicatorComponent';
import { useRoute, useNavigation } from '../navigation/router';
import { useGetMeQuery } from '../services/UserSlice';
import ProfileHeader from '../components-global/Profile/ProfileHeader';
import { SafeAreaLayout } from '../components-ui/Layout/SafeAreaLayout';
import GoalsSection from '../components-global/Profile/GoalsSection';
import NotificationSettings from '../components-global/Profile/NotificationSettings';
import ImpersonationSwitcher from '../components-global/Profile/ImpersonationSwitcher';
import XactusForm from '../components-global/Profile/XactusForm';
import TimeBlockSection from '../components-global/Profile/TimeBlockSection';
import ErrorBoundary from '../components-global/ErrorBoundary';
import { ThemeSwitcher } from '../components-global';
import CodePushSwitcher from '../components-global/CodePush/CodePushSwitcher';
import MobileNumberForm from '../components-global/Profile/MobileNumberForm';
import { showModal as realShow } from '../services/FormSlice';

export default function ProfileScreen() {
  const navigation = useNavigation();
  const { data: user, isLoading } = useGetMeQuery({});
  const route = useRoute();
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  // useEffect(() => {
  //   route.setParams({
  //     logOut,
  //     title: 'Profile'
  //   });
  // }, []);

  const editGoal = () => {
    showModal({ formType: 'goalEditor' });
  };

  if (isLoading) {
    return <ActivityIndicatorComponent />;
  }

  return (
    <SafeAreaLayout style={{ flex: 1, padding: 16 }}>
      <ErrorBoundary>
        <Divider />
        <ScrollView>
          <Layout
            // style={styles.container}
            level="1"
          >
            <Layout
              style={styles.header}
              level="1"
            >
              {user && <ProfileHeader user={user} />}
              {user && <GoalsSection user={user} />}
              <Button
                style={styles.followButton}
                onPress={() => editGoal()}
              >
                Edit Goal
              </Button>
            </Layout>
            <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}>
              <View style={{ flex: 1, marginRight: Platform.OS === 'web' ? 16 : 0 }}>
                <NotificationSettings />
                {Platform.OS === 'web' && <ImpersonationSwitcher />}
                <CodePushSwitcher roles={user.roles} />
                {Platform.OS !== 'web' ? <ThemeSwitcher /> : null}
                <Drawer
                  selectedIndex={selectedIndex}
                  onSelect={(index) => setSelectedIndex(index)}
                >
                  <XactusForm />
                  <MobileNumberForm />
                </Drawer>
                <ImpersonationSwitcher />
              </View>
              <View style={{ flex: 1, marginLeft: Platform.OS === 'web' ? 16 : 0 }}>
                <ErrorBoundary>
                  <TimeBlockSection user={user} />
                </ErrorBoundary>
              </View>
            </View>
          </Layout>
        </ScrollView>
      </ErrorBoundary>
    </SafeAreaLayout>
  );
}

function StarIcon(props) {
  return <Icon {...props} name="star" />;
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {

    paddingVertical: 24
  },
  profileContainer: {
    flexDirection: 'row'
  },
  profileAvatar: {
    marginHorizontal: 8
  },
  profileDetailsContainer: {
    flex: 1,
    marginHorizontal: 8
  },
  rateBar: {
    marginTop: 24
  },
  followButton: {
    // marginTop: 24,
  },
  profileSocialsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 24
  },
  profileSectionsDivider: {
    width: 1,
    height: '100%',
    marginHorizontal: 8
  },
  profileDescription: {
    marginTop: 24,
    marginBottom: 8
  },
  profileParameter: {
    flex: 1,
    marginHorizontal: 8
  },
  setting: {
    padding: 16
  }
});
