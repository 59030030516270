import React, { ReactNode, useState } from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Animated,
  Pressable,
} from 'react-native';
import Colors from '../helpers/Colors';

export interface ExpandableFabProps {
  closeIcon: ReactNode;
  openIcon: ReactNode;
  menuIcons: FabMenuElement[];
  mainColor?: string;
  secondaryColor?: string;
}

interface FabMenuElement {
  name: string;
  icon: ReactNode;
  text?: ReactNode;
  callback: () => void;
}

export default function ExpandableFloatingAction(props: ExpandableFabProps) {
  const [open, setOpen] = useState(false);
  const [fadeIn] = useState(new Animated.Value(0));
  const [topOffsetY] = useState(new Animated.Value(40));

  const closeFab = () => {
    Animated.parallel([
      Animated.timing(fadeIn, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(topOffsetY, {
        toValue: 50,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start(() => setOpen(false));
  };
  const openFab = () => {
    setOpen(true);
    Animated.parallel([
      Animated.timing(fadeIn, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(topOffsetY, {
        toValue: -12,
        duration: 300,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const toggleFab = () => {
    if (open) {
      closeFab();
    } else {
      openFab();
    }
  };

  const memoizedMenu = React.useMemo(() => {
    return (
      <>
        {props.menuIcons.map((menuElement) => {
          if (!menuElement) return null;

          return (
            <Animated.View
              key={menuElement.name}
              style={{
                ...styles.smallContainerStyle,
                opacity: fadeIn,
                transform: [{ translateY: topOffsetY }],
              }}
            >
              <Pressable
                onPress={() => {
                  menuElement.callback();
                  closeFab();
                }}
                style={(state: any) => [
                  styles.innerWrap,
                  state.hovered && styles.hovered,
                ]}
              >
                {Boolean(menuElement.text) ? (
                  <Text
                    style={{
                      marginRight: 16,
                      fontFamily: 'VerbRegular',
                      fontSize: 14,
                      color: 'white',
                    }}
                  >
                    {menuElement.text}
                  </Text>
                ) : null}
                <View
                  style={{
                    ...styles.mediumButton,
                  }}
                >
                  {menuElement.icon}
                </View>
              </Pressable>
            </Animated.View>
          );
        })}
      </>
    );
  }, [props.menuIcons]);

  return (
    <View style={styles.container}>
      {open ? memoizedMenu : null}
      <TouchableOpacity
        style={[styles.mainButton, styles.shadow]}
        onPress={() => toggleFab()}
      >
        {open ? props.closeIcon : props.openIcon}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    bottom: 24,
    right: 24,
  },
  hovered: {
    transform: [{ scale: 1.2 }],
  },
  mainButton: {
    width: 54,
    height: 54,
    borderRadius: 35,
    zIndex: 999,
    backgroundColor: Colors.primaryRed,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediumButton: {
    width: 24,
    height: 24,
    marginVertical: 4,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
    overflow: 'hidden',
  },
  innerWrap: {
    paddingVertical: 2,
    paddingRight: 6,
    paddingLeft: 16,
    borderRadius: 35,
    flexDirection: 'row',
    marginBottom: 16,
    alignItems: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 4.0,
    elevation: 4,
    width: 160,
    justifyContent: 'space-between',
    backgroundColor: Colors.accentBlue,
  },
  smallContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 4,
    borderRadius: 25,
  },
  shadow: {
    shadowColor: 'black',
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 6,
    elevation: 4,
  },
});
