import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  RefreshControl,
  Pressable,
  ActivityIndicator,
  Modal,
  Linking,
} from 'react-native';
import {
  Avatar,
  Icon,
  Layout,
  Tab,
  TabView,
  Text,
  TopNavigation,
  TopNavigationAction,
  Button,
  Divider,
} from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useContext, useEffect, useState } from 'react';
import { useRoute } from '../../navigation/router';
import {
  ActivityStatusColumn,
  QuickActionFAB,
  WebHeader,
} from '../../components-global';
import {
  useCreateEmpowerLoanMutation,
  useEditLoanMutation,
  useGetLoanQuery,
} from '../../services/LoanApiSlice';
import { ThemeContext } from '../../../store/context/ThemeContext';
import { useGetContactQuery } from '../../services/ContactApiSlice';
import LoanDetailColumn from '../../components-global/DetailPage/LoanDetailColumn';
import LoanDetailContactColumn from '../../components-global/DetailPage/LoanContactDetailColumn';
import { ModalsContext } from '../../../store/context/ModalContext';
import { openFileManagerTab } from '../../helpers/openFileManagerTab';
import { showModal as realShow } from '../../services/FormSlice';
import { useDispatch } from 'react-redux';


type Props = {
  navigation?: any;
};

const STATUS = {
  scenario: ['Scenario'],
  prospect: [
    'Prospect',
    'New',
    'Value Presentation',
    'Pre-Qualification',
    'App & Relationship',
    'Credit Scrub',
    'Pre-close',
    'Documentation',
    'Sales Preparation',
    'Sales Presentation',
    'Close & Disclose',
    'Lost(pre-app)',
  ],
  application: [
    'Application',
    'Processing',
    'Submittal',
    'Approval',
    'Re-Submittal',
    'Final Approval',
    'Clear to Close',
    'Docs Out',
    'Funding',
    'Shipped',
    'Purchased',
  ],
  'closed loan': ['Purchased'],
  adversed: ['Adversed (post application)', 'Lost Pre-Application'],
};

export function LoansDetailPage(props: Props) {
  const route = useRoute();
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const loanId = Platform.OS === 'web' ? route.getParam('id') : route.params.id;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: loanData, isFetching, refetch } = useGetLoanQuery(loanId);
  const { data: contactData } = useGetContactQuery(
    loanData?.contact?.id ? loanData.contact.id : skipToken
  );
  const [patchLoan, { isLoading: isPatching }] = useEditLoanMutation();
  const [modalVisible, setModalVisible] = useState(false);
  const [loanCreateSuccess, setLoanCreateSuccess] = useState({
    status: false,
    loanNumber: '',
  });
  const [createEmpowerLoan, { data, isLoading: empowerLoading }] =
    useCreateEmpowerLoanMutation();

  useEffect(() => {
    let refetchInteval;
    if (modalVisible) {
      refetchInteval = setInterval(() => {
        refetch();
        if (loanData?.loanNumber) {
          clearInterval(refetchInteval);
          if (Platform.OS === 'web') {
            setLoanCreateSuccess({
              status: true,
              loanNumber: loanData.loanNumber,
            });
          } else {
            try {
              setModalVisible(false);
              global.toast.show(
                `Loan ${loanData.loanNumber} created successfully`
              );
            } catch (e) {
              console.log(e);
            }
          }
        }
      }, 10000);
    }

    return () => {
      clearInterval(refetchInteval);
    };
  }, [modalVisible]);

  function BackIcon(props) {
    return <Icon {...props} fill="#fff" name="arrow-back" />;
  }

  const renderBackAction = () => {
    const nav = props.navigation;
    return (
      <TopNavigationAction
        onPress={() =>
          nav.canGoBack()
            ? nav.goBack()
            : nav.navigate('MenuItems', {
                screen: 'Pipeline',
                initial: false,
              })
        }
        icon={BackIcon}
      />
    );
  };

  const renderCreateLoanAction = () => (
    <Button
      onPress={(e) => {
        e.preventDefault();
        if (loanData?.id) {
          createEmpowerLoan(loanData.id);
          setModalVisible(true);
        }
      }}
      size="small"
      status="basic"
      appearance="filled"
      style={{ width: 105 }}
      accessoryLeft={() => (
        <Avatar
          style={{ height: 16, width: 16, marginRight: -4 }}
          source={require('../../../assets/images/blackknight.png')}
        />
      )}
    >
      Create Loan
    </Button>
  );

  function onRefresh() {
    refetch();
  }

  async function handleStatusChange(status) {
    if (loanData.phase.toLowerCase() !== 'prospect') return;

    if (status === 'Lost(pre-app)') {
      patchLoan({
        id: loanId,
        phase: 'Adversed',
        stage: 'Lost Pre-Application',
      });
    } else {
      patchLoan({ id: loanId, stage: status });
    }
  }

  if (!loanData) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1 }}>
          <WebHeader
            clientData={loanData}
            setModalVisible={setModalVisible}
            createEmpowerLoan={createEmpowerLoan}
          />
          <Layout level="1" style={styles.phaseWrapper}>
            <View style={{ paddingTop: 16 }}>
              {loanData.phase && STATUS[loanData.phase.toLowerCase()] ? (
                <ProgressBar
                  loanData={loanData}
                  step={STATUS[loanData.phase.toLowerCase()].indexOf(
                    loanData.stage
                  )}
                  handleStatusChange={handleStatusChange}
                />
              ) : null}
              {isPatching && (
                <View style={styles.activityIndicatorWrapper}>
                  <ActivityIndicator size="large" />
                </View>
              )}
            </View>
          </Layout>
          <Layout
            level="3"
            style={{
              paddingVertical: 16,
              paddingHorizontal: 24,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 24,
            }}
          >
            <Button
              style={{ backgroundColor: '#fff' }}
              size={'small'}
              status="danger"
              appearance="outline"
              onPress={() =>
                showModal({ formType: 'creditRequest', data: { loanData } })
              }
              disabled={!loanData}
            >
              Request Credit
            </Button>
           
          </Layout>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Layout level="3" style={{ flex: 3 }}>
              <LoanDetailContactColumn
                loanData={loanData}
                contactData={contactData}
                refetch={refetch}
              />
            </Layout>
            <ActivityStatusColumn clientId={loanId} type="loan" />
            <Layout
              level="3"
              style={{ flex: 3, borderLeftWidth: 1, borderLeftColor: '#aaa' }}
            >
              {/* Here */}
              <LoanDetailColumn loanData={loanData} />
            </Layout>
          </View>
        </View>
      ) : (
        <Layout style={{ flex: 1 }}>
          <TopNavigation
            style={styles.topNav}
            alignment="center"
            title={() => (
              <Text style={{ color: '#fff' }} category="h6">
                {loanData?.loanContact.firstName || ''}{' '}
                {loanData?.loanContact.lastName || ''}
              </Text>
            )}
            accessoryLeft={renderBackAction}
            accessoryRight={
              loanData?.loanNumber?.length === 9 ? null : renderCreateLoanAction
            }
          />

          <TabView
            selectedIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
          >
            <Tab style={styles.tab} title="Contact Details">
              <Layout style={styles.tabContainer}>
                <ScrollView>
                  <Layout
                    level="1"
                    style={[
                      styles.phaseWrapper,
                      {
                        marginHorizontal: -12,
                        borderTopWidth: 1,
                        borderTopColor: '#eee',
                      },
                    ]}
                  >
                    <View style={{ paddingTop: 16 }}>
                      <ProgressBar
                        loanData={loanData}
                        step={
                          loanData.phase
                            ? STATUS[loanData.phase.toLowerCase()].indexOf(
                                loanData.stage
                              )
                            : 0
                        }
                        handleStatusChange={handleStatusChange}
                      />
                      {isPatching && (
                        <View style={styles.activityIndicatorWrapper}>
                          <ActivityIndicator size="large" />
                        </View>
                      )}
                    </View>
                    <Layout
                      level="3"
                      style={{
                        paddingVertical: 16,
                        paddingHorizontal: 32,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 24,
                      }}
                    >
                      <Button
                        style={{ backgroundColor: '#fff' }}
                        size={'small'}
                        status="danger"
                        appearance="outline"
                        onPress={() =>
                          showModal({
                            formType: 'creditRequest',
                            data: { loanData },
                          })
                        }
                        disabled={!loanData}
                      >
                        Request Credit
                      </Button>
                    </Layout>
                  </Layout>
                  <RefreshControl
                    enabled
                    refreshing={isFetching}
                    onRefresh={onRefresh}
                  />
                  <LoanDetailContactColumn
                    loanData={loanData}
                    contactData={contactData}
                    refetch={refetch}
                  />
                </ScrollView>
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Activities">
              <Layout style={styles.tabContainer}>
                <ActivityStatusColumn clientId={loanId} type="loan" />
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Loan Details">
              <Layout style={styles.tabContainer}>
                <ScrollView style={{ flex: 1 }}>
                  <LoanDetailColumn loanData={loanData} />
                </ScrollView>
              </Layout>
            </Tab>
          </TabView>
          {loanData ? (
            <QuickActionFAB
              defaultData={{
                loan: loanData['@id'],
                '@type': 'Loan',
                '@id': loanData['@id'],
              }}
              onlyShow={[
                'createNewTask',
                'createNewNote',
                'createNewEvent',
                'logCall',
                'logMeeting',
              ]}
            />
          ) : null}
        </Layout>
      )}
      <Modal
        animationType="fade"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        transparent
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            {loanCreateSuccess.status ? (
              <View>
                <Text style={[styles.modalText, { fontFamily: 'VerbBold' }]}>
                  Loan Created Successfully!
                </Text>
                <Text style={[styles.modalText]}>
                  Loan #{loanCreateSuccess?.loanNumber}
                </Text>
                <Divider style={{ marginBottom: 24, borderColor: '#eee' }} />
                <View style={{ flexDirection: 'row' }}>
                  <Button
                    onPress={(e) => {
                      e.preventDefault();
                      Linking.openURL(
                        `https://los.p5.empower.bkicloud.com/ArkMortgage.LOSWeb/WebMain/Main.aspx?loannumber=${loanCreateSuccess?.loanNumber}`
                      );
                    }}
                    size="small"
                    status="info"
                    appearance="outline"
                    style={[styles.shadow, { marginRight: 24 }]}
                    accessoryLeft={() => (
                      <Avatar
                        style={{ marginRight: 16, height: 24, width: 24 }}
                        source={require('../../../assets/images/blackknight.png')}
                      />
                    )}
                  >
                    Open in Empower
                  </Button>
                  <Button
                    size="small"
                    appearance="outline"
                    onPress={() => {
                      setModalVisible(false);
                      setTimeout(() => {
                        setLoanCreateSuccess({ status: false, loanNumber: '' });
                      }, 300);
                    }}
                    status="danger"
                  >
                    Close Popup
                  </Button>
                </View>
              </View>
            ) : (
              <>
                <Text style={styles.modalText}>
                  We are creating your loan in Empower, please be patient.
                </Text>
                <ActivityIndicator size="large" />
              </>
            )}
          </View>
        </View>
      </Modal>
    </>
  );
}

function BarArrow({ active, index, currentStep, allSteps }) {
  const { theme } = useContext(ThemeContext);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
      }}
    >
      <View
        style={{
          borderWidth: 9,
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
          borderTopRightRadius: index === allSteps.length - 1 ? 24 : 0,
          borderBottomRightRadius: index === allSteps.length - 1 ? 24 : 0,
          borderColor:
            active || currentStep > index
              ? 'rgb(122, 183, 23)'
              : index === currentStep + 1
              ? 'rgb(0, 122, 255)'
              : '#eee',
          marginRight: 0,
          flex: 1,
          height: 1,
        }}
      />
      {/* rgb(122, 183, 23) */}

      {index !== allSteps.length - 1 ? (
        <>
          <View
            style={{
              borderBottomWidth: 12,
              borderBottomColor:
                active || currentStep > index
                  ? 'rgb(122, 183, 23)'
                  : index === currentStep + 1
                  ? 'rgb(0, 122, 255)'
                  : '#eee',
              borderLeftWidth: 8,
              borderLeftColor: 'transparent',
              borderRightWidth: 8,
              borderRightColor: 'transparent',
              transform: [{ rotate: '90deg' }],
              height: 0,
              width: 0,
              right: -13,
              top: 3,
              position: 'absolute',
            }}
          />
          <Icon
            style={{
              width: 60,
              height: 60,
              position: 'absolute',
              right: -34,
              top: -30,
              zIndex: 99,
            }}
            fill={theme === 'light' ? 'white' : 'rgb(34,43,69)'}
            name="chevron-right-outline"
          />
        </>
      ) : null}
      {index <= currentStep ? (
        <View
          style={{
            position: 'absolute',
            left: '48%',
            top: -1,
            zIndex: 100,
          }}
        >
          <Icon
            style={{
              width: 20,
              height: 20,
            }}
            fill="white"
            name="checkmark-outline"
          />
        </View>
      ) : null}
    </View>
  );
}

function ProgressBar({ step, handleStatusChange, loanData }) {
  return (
    <View style={styles.progressWrapper}>
      {STATUS[loanData.phase.toLowerCase()].map(
        (status: string, index: number) => (
          <Pressable
            key={status}
            onPress={() => handleStatusChange(status)}
            style={[styles.stepWrapper, { zIndex: 99 - index }]}
          >
            <BarArrow
              index={index}
              active={step === index}
              currentStep={step}
              allSteps={STATUS[loanData.phase.toLowerCase()]}
            />
            <Text category="label" style={styles.stepText}>
              {status}{' '}
            </Text>
          </Pressable>
        )
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  tabContainer: {
    minHieght: Dimensions.get('screen').height - 56,
    height: Dimensions.get('screen').height - 56,
    paddingBottom: 128,
  },
  phaseWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    position: 'relative',
  },
  activityIndicatorWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
  tab: {
    height: 48,
  },
  icon: {
    height: 18,
    width: 18,
    marginHorizontal: 4,
    backgroundColor: '#ff2503',
    borderRadius: 32,
    padding: 8,
  },
  divider: {
    height: 2,
    backgroundColor: 'white',
    width: '100%',
    marginVertical: 32,
  },
  label: {
    fontSize: 15,
    marginRight: 8,
  },
  value: {
    fontSize: 15,
    flexShrink: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 16,
    paddingHorizontal: 16,
    flex: 1,
  },
  cardWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginTop: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    flex: 1,
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  progressWrapper: {
    flexDirection: 'row',
    marginTop: 8,
    maxWidth: '100%',
    marginHorizontal: 16,
  },
  stepWrapper: {
    textAlign: 'center',
    justifyContent: 'center',
    flex: 1,
    height: 60,
    position: 'relative',
  },
  stepText: {
    textAlign: Platform.OS === 'web' ? 'center' : 'left',
    padding: 8,
    paddingLeft: Platform.OS === 'web' ? 0 : 32,
    marginTop: Platform.OS === 'web' ? 0 : 4,
    fontSize: Platform.OS === 'web' ? 12 : 9,
    transform: Platform.OS === 'web' ? null : [{ rotate: '15deg' }],
    width: Platform.OS === 'web' ? null : 130,
    marginLeft: Platform.OS === 'web' ? 0 : -16,
    height: 50,
  },
  topNav: {
    backgroundColor: '#007aff',
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
