import {
  Dimensions,
  FlatList,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import { Avatar, Layout, Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import routes from '../../navigation/routes';
import { Link } from '../../components/Navigation/Link';
import {
  useGetReferredByContactQuery,
} from '../../services/ContactApiSlice';
import { useGetPartnerRelationsByContactQuery } from '../../services/PartnerRelationsApiSlice';
import { useGetLoansByContactIDQuery } from '../../services/LoanApiSlice';
import ArkHomes from '../ArkHomes';
import PreapprovalList from './PreapprovalList';
import { showModal as realShow } from '../../services/FormSlice';

type Props = {
  clientData: any;
};

function LoansReferralsColumn({ clientData }: Props) {
  const {
    data: referredByContact,
    refetch: refetchReferrals,
    isFetching: fetchingReferrals,
  } = useGetReferredByContactQuery(clientData.id);

  const { data: partnerRelationships } = useGetPartnerRelationsByContactQuery(
    clientData.id
  );

  const { data: contactLoans, refetch: refetchLoans } =
    useGetLoansByContactIDQuery(clientData.id);

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));

  const Item = ({ item }) => {
    return (
      <Layout
        level="1"
        style={{ padding: 16, borderBottomWidth: 1, borderBottomColor: '#eee' }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Link
            route={routes.ExtraRoutes[item['@type']]}
            routeParams={{ id: item['@id'].split('/')[2] }}
          >
            <View>
              {item.name ? (
                <View>
                  <Text category="label">{item['@type']} </Text>
                  <Text
                    style={{
                      fontFamily: 'VerbBold',
                      color: 'blue',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {item.name}
                  </Text>
                  {item.isPartner ? (
                    <Text category="label" status="info">
                      Partner
                    </Text>
                  ) : null}
                </View>
              ) : null}
              {!item.name && item.firstName ? (
                <View>
                  <Text category="label">{item['@type']} </Text>
                  <Text
                    style={{
                      fontFamily: 'VerbBold',
                      color: 'blue',
                      textDecorationLine: 'underline',
                    }}
                  >
                    {item.firstName} {item.lastName}
                  </Text>
                  {item.isPartner ? (
                    <Text category="label" status="info">
                      Partner
                    </Text>
                  ) : null}
                </View>
              ) : null}
              {!item.name && !item.firstName ? (
                <Text
                  style={{
                    fontFamily: 'VerbBold',
                    color: 'blue',
                    textDecorationLine: 'underline',
                  }}
                >
                  ID: {item['@id'].split('/')[2]}
                </Text>
              ) : null}
            </View>
          </Link>
          {item.phase ? <Text>Phase: {item.phase}</Text> : null}
          {(item.status && item['@type'] === 'Lead') ? (
            <Text>Status: {item.status}</Text>
          ) : null}
        </View>
        {item?.owner?.firstName ? (
          <Text category="label">
            Owner: {item?.owner?.firstName || ''} {item?.owner?.lastName || ''}
          </Text>
        ) : null}
      </Layout>
    );
  };

  const PartnerItem = ({ item }) => {
    return (
      <Layout
        level="1"
        style={{ padding: 16, borderBottomWidth: 1, borderBottomColor: '#eee' }}
      >
        <Link
          route={routes.ExtraRoutes[item.partner['@type']]}
          routeParams={{ id: item.partner['@id'].split('/')[2] }}
        >
          <View>
            <Text category="label">{item.partner['@type']}</Text>
            <Text
              style={{
                color: 'blue',
                textDecorationLine: 'underline',
                fontFamily: 'VerbBold',
              }}
            >
              {item.partner.firstName} {item.partner.lastName}
            </Text>
          </View>
        </Link>
      </Layout>
    );
  };
  const LoanItem = ({ item }) => {
    return (
      <Layout
        level="1"
        style={{ padding: 8, borderBottomWidth: 1, borderBottomColor: '#eee' }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {item.stage ? (
            <Text category="label">Stage: {item.stage}</Text>
          ) : null}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 8,
              marginHorizontal: 16,
            }}
          >
            {item.loanNumber?.length === 9 ? (
              <Pressable
                onPress={(e) => {
                  e.preventDefault();
                  Linking.openURL(
                    `https://los.p5.empower.bkicloud.com/ArkMortgage.LOSWeb/WebMain/Main.aspx?loannumber=${item.loanNumber}`
                  );
                }}
              >
                <Avatar
                  size="tiny"
                  style={{ marginRight: 16 }}
                  source={require('../../../assets/images/blackknight.png')}
                />
              </Pressable>
            ) : null}

            <Link
              route={routes.ExtraRoutes[item['@type']]}
              routeParams={{ id: item['@id'].split('/')[2] }}
            >
              <Text
                style={{
                  color: 'blue',
                  textDecorationLine: 'underline',
                  fontFamily: 'VerbBold',
                  fontSize: 12,
                }}
              >
                {item.loanNumber || item['@id'].split('/')[2]}
              </Text>
            </Link>
          </View>
          {item.status ? (
            <Text category="label">Status: {item.status}</Text>
          ) : null}
        </View>
      </Layout>
    );
  };

  return (
    <Layout
      style={{
        flex: 2,
        minWidth: Platform.OS === 'web' ? 465 : Dimensions.get('screen').width,
        borderLeftWidth: 1,
        borderLeftColor: '#aaa',
        paddingBottom: Platform.OS === 'web' ? 0 : 64,

      }}
      level="2"
    >
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: 'transparent',
          paddingHorizontal: 24,
          paddingTop: 0,
          paddingBottom: 24,
        }}
        contentContainerStyle={{ minHeight: Dimensions.get('screen').height - 205, paddingBottom: Platform.OS === 'web' ? 0 : 64 }}
      >{/* Loan Prospects */}
        <Layout style={styles.cardWrapper}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Text style={styles.cardHeader} category="h6">
              Loans
            </Text>
            <Pressable
              onPress={() => {
                showModal({
                  formType: 'associateLoan',
                  data: {
                    clientID: clientData.id,
                    ['@id']: clientData['@id'],
                    callback: refetchLoans,
                  },
                });
              }}
              style={{ position: 'absolute', right: 16 }}
            >
              <Text category="label" style={{ color: 'white' }}>
                Add
              </Text>
            </Pressable>
          </View>
          <View style={{ flex: 1 }}>
            {Boolean(contactLoans) ? (
              <FlatList
                data={contactLoans['hydra:member']}
                renderItem={({ item }) => <LoanItem item={item} />}
                keyExtractor={(item) => item['@id']}
              />
            ) : null}
          </View>
        </Layout>
        {/* Preapprovals */}
        <PreapprovalList clientData={clientData} />
        {/* Partner Relations */}
        <Layout style={styles.cardWrapper}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Text style={styles.cardHeader} category="h6">
              Partner Relationships
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            {Boolean(partnerRelationships) ? (
              <FlatList
                data={partnerRelationships['hydra:member']}
                renderItem={({ item }) => <PartnerItem item={item} />}
                keyExtractor={(item) => item['@id']}
              />
            ) : null}
          </View>
        </Layout>
        {/* Ark Homes */}
        <ArkHomes clientData={clientData} />
        {/* Referrals */}
        <Layout style={styles.cardWrapper}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Text style={styles.cardHeader} category="h6">
              Referrals
            </Text>
            <Pressable
              onPress={() => {
                showModal({
                  formType: 'addReferral',
                  data: {
                    clientID: clientData.id,
                    ['@id']: clientData['@id'],
                    callback: refetchReferrals,
                    type: clientData.type || 'Contact',
                  },
                });
              }}
              style={{ position: 'absolute', right: 16 }}
            >
              <Text category="label" style={{ color: 'white' }}>
                Add
              </Text>
            </Pressable>
          </View>
          <View style={{ flex: 1 }}>
            {Boolean(referredByContact) ? (
              <FlatList
                data={referredByContact['hydra:member']}
                renderItem={({ item }) => <Item item={item} />}
                keyExtractor={(item) => item['@id']}
              />
            ) : null}
          </View>
        </Layout>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    shadowColor: '#000',
    flex: 1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
     minHeight: 120,
    elevation: 3,
    marginTop: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
});

export default LoansReferralsColumn;
