export enum MatchType {
  equals = 'equals',
  notEqual = 'notEqual',
  isNot = 'isNot',
  greaterThan = 'greaterThan',
  greaterThanOrEquals = 'greaterThanOrEquals',
  lessThan = 'lessThan',
  lessThanOrEquals = 'lessThanOrEquals',
  like = 'like',
}

export interface Filter {
  column: string;
  value: string;
  matchType: 'equals' | 'notEqual' | 'isNot' | 'greaterThan' | 'greaterThanOrEquals' | 'lessThan' | 'lessThanOrEquals' | 'like';
  precision: string;
}

export interface Aggregate {
  type: string;
  column: string;
  alias: string;
}

export interface GroupBy {
  column: string;
  alias: string;
}
export interface Stat {
  dataType: string;
  resource: string;
  filters: Filter[]
  aggregates: Aggregate[]
  groupBy?: GroupBy
}
