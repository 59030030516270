import { Card, Text } from '@ui-kitten/components';
import React from 'react';
import { Platform, View } from 'react-native';
import {
  endOfMonth,
  getMonth,
  isAfter,
  isBefore,
  startOfYear,
  sub,
} from 'date-fns';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import { useGetStatsQuery } from '../../services/StatsSlice';
import {
  countOfApplications,
  lastYearsIncome,
} from '../../helpers/commonStatFilters';
import { useGetHomeDataQuery } from '../../services/HomeSlice';
import { useGetTiersQuery } from '../../services/TierSlice';
import { useGetMeQuery } from '../../services/UserSlice';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../helpers/constants';

// These are defined outside to prevent them from changing on re-render
const monthsLeft = 12 - (getMonth(new Date()) + 1);

export default function ApplicationsStillNeededCard() {
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);
  const { data: homeData, isSuccess: homeDataLoaded } = useGetHomeDataQuery({});
  const { data: user, isSuccess: userLoaded } = useGetMeQuery({});
  const { data: tiers, isSuccess: tiersLoaded } = useGetTiersQuery({});

  const appsYTDStat = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.applicationDate &&
          isAfter(new Date(loan.applicationDate), startOfYear(new Date())) &&
          isBefore(new Date(loan.applicationDate), new Date())
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);

  const lastYearsIncomeStat = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.closeDate &&
          isAfter(new Date(loan.closeDate), sub(new Date(), { months: 12 })) &&
          isBefore(
            new Date(loan.closeDate),
            endOfMonth(sub(new Date(), { months: 1 }))
          )
        ) {
          return acc + loan.loCommission;
        }
        return acc;
      }, 0);

  const averageLoanAmount = homeData?.averageLoanAmount || 0;
  const pullThroughRate = homeData?.pullThru || 0;
  // tiers
  let sorted = [];
  if (tiersLoaded) {
    sorted = [...tiers['hydra:member']];
    sorted.sort((a, b) => b.tier - a.tier);
  }

  const getAssumedTier = (yearlyIncome) =>
    tiersLoaded && sorted.find((tier) => tier.year < yearlyIncome || sorted[0]);
  const getAppsByIncome = (income: number) =>
    income /
    (averageLoanAmount * getAssumedTier(income).rate) /
    pullThroughRate;
  const getFinalNumber = (number) => (number - appsYTDStat) / monthsLeft;

  let appsRemainingIncomeGoal = 0;
  let appsRemainingForGoalTier = 0;
  let monthlyApplicationsRemainingTabOne = 0;

  if (userLoaded && tiersLoaded && homeDataLoaded && loansSuccess) {
    /**
     * "appsToReachIncomeGOal": {
     *     "monthlyApplicationsRemaining": {
     *       "monthLeft": "calc",
     *       "minusEnteredLoans": {
     *         "applicationsNeededForYear": {
     *           "annualIncomeGoal": "user.incomeGoal",
     *           "avarageLoanAmount": "homeData",
     *           "projectedTierByIncomeGoal": {
     *             "tier": "tiers",
     *             "incomeGoal": "user.incomeGoal"
     *           },
     *           "pullThru": "homeData"
     *         }
     *       },
     *       "appsYtd": "stat"
     *     }
     *   },
     */
    const appsToReachIncomeGoal = getAppsByIncome(user.incomeGoal);
    appsRemainingIncomeGoal = getFinalNumber(appsToReachIncomeGoal);
    /**
     * "appsToReachGoalTier": {
     *     "monthsLeft": "calc",
     *     "minusAppsYtd": {
     *       "appsNeededForYear": {
     *         "user.profileGoalTIer": "user.profileGoalTier",
     *         "avarageLoanAmount": "homeData",
     *         "pullThru": "homeData"
     *       },
     *       "appsYtd": "stat"
     *     }
     *   },
     */

    const applicationsNeededForYearForGoal =
      tiers['hydra:member'].find((tier) => tier.tier === user.profileGoalTier)
        .min /
      averageLoanAmount /
      pullThroughRate;
    // changed closed loans to apps entered YTD
    appsRemainingForGoalTier = getFinalNumber(applicationsNeededForYearForGoal);

    /**
     * "appsToReachLastYearsIncome": {
     *     "monthlyApplicationsRemaining": {
     *       "monthLeft": "calc",
     *       "minusEnteredLoans": {
     *         "applicationsNeededForYear": {
     *           "lastYearsIncome": "stat",
     *           "avarageLoanAmount": "homeData",
     *           "pullThru": "homeData",
     *           "projectedTierByIncomeGoal": {
     *             "tier": "tiers",
     *             "lastYearsIncome": "stat"
     *           },
     *           "appsYtd": "stat"
     *         }
     *       }
     *     }
     *   }
     */
    const applicationsNeededForYearTabOne =
      getAppsByIncome(lastYearsIncomeStat);
    monthlyApplicationsRemainingTabOne = getFinalNumber(
      applicationsNeededForYearTabOne
    );
  }
  const appsRemaining = Math.max(
    appsRemainingIncomeGoal,
    appsRemainingForGoalTier,
    monthlyApplicationsRemainingTabOne
  );

  return (
    <Card
      status={'warning'}
      style={{
        minHeight: 150,
        minWidth: 300,
        flex: 1,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
      }}
    >
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingVertical: 16,
        }}
      >
        <Text category="h6" style={{ marginBottom: 16, textAlign: 'center' }}>
          APPLICATIONS STILL NEEDED THIS MONTH
        </Text>
        <Text
          style={{ marginBottom: 16, fontFamily: 'VerbBold', fontSize: 18 }}
        >
          {appsRemaining ? Math.ceil(appsRemaining) : '0'}
        </Text>
        <Text appearance="hint">
          Based on Average Loan ~{currencyFormat(averageLoanAmount)}
        </Text>
      </View>
    </Card>
  );
}
