import React from 'react';
import {
  Icon,
  Layout,
  Button,
  Text,
  Avatar,
  Spinner,
} from '@ui-kitten/components';
import {
  Pressable,
  View,
  StyleSheet,
  Linking,
  ImageProps,
  Platform,
} from 'react-native';
// eslint-disable-next-line import/extensions
// @ts-ignore
import { useNavigation } from '../../navigation/router';
import QuickActionsDropDown from '../QuickActionsDropDown';
import { useDeleteContactMutation } from '../../services/ContactApiSlice';
import routes from '../../navigation/routes';
import { useGetMeQuery } from '../../services/UserSlice';
import { useResyncLoanMutation } from '../../services/LoanApiSlice';
import { openFileManagerTab } from '../../helpers/openFileManagerTab';

function RefreshIcon(props) {
  return (
    <Icon
      {...props}
      style={[props.style, { marginRight: 16, width: 24, height: 24 }]}
      // fill="black"
      name="refresh-outline"
    />
  );
}

const LoadingIndicator = (props: ImageProps): React.ReactElement => (
  <View style={[props.style]}>
    <Spinner size="small" />
  </View>
);

function WebHeader({ clientData, setModalVisible, createEmpowerLoan }) {
  const navigation = useNavigation();
  const { data: user } = useGetMeQuery({});
  const [resyncLoan, { isLoading: resyncLoading }] = useResyncLoanMutation();

  const [deleteContact, { isSuccess, isLoading, error }] =
    useDeleteContactMutation();

  if (isSuccess) {
    navigation.navigate(routes.MenuItems.Contacts);
  }

  let errorMsg;
  if (error) {
    if ('status' in error) {
      // you can access all properties of `FetchBaseQueryError` here
      errorMsg = 'error' in error ? error.error : JSON.stringify(error.data);
    } else {
      // you can access all properties of `SerializedError` here
      errorMsg = error.message;
    }
  }

  const STATUSMAP = {
    New: 'success',
    'In Progress': 'info',
    'Contacted - No answer': 'basic',
    Qualified: 'success',
    Unqualified: 'danger',
  };

  return (
    <Layout
      level="1"
      style={[
        styles.shadow,
        {
          width: '100%',
          height: 79,
          alignItems: 'center',
          paddingHorizontal: 16,
          marginBottom: 2,
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Pressable
          style={{ marginRight: 32 }}
          onPress={() => navigation.goBack()}
        >
          <Icon
            style={[{ width: 32, height: 32 }]}
            name="arrow-ios-back-outline"
            fill="red"
          />
        </Pressable>
        {clientData && clientData['@type'] === 'Loan' ? (
          <>
            <Text style={{ marginRight: 24 }} category="h3">
              {clientData?.pbFirstName || ''} {clientData?.pbLastName || ''}
            </Text>

            <Text category="h6">Loan #{clientData?.loanNumber}</Text>
            <Button
              style={{ marginLeft: 24, borderRadius: 24 }}
              appearance="outline"
              status={
                clientData.phase.toLowerCase() === 'prospect'
                  ? 'warning'
                  : 'success'
              }
            >
              {(props) => (
                <Text
                  {...props}
                  style={[props.style, { textTransform: 'capitalize' }]}
                >
                  {clientData.phase} Phase
                </Text>
              )}
            </Button>
          </>
        ) : (
          <Text category="h3">{clientData?.name || ''}</Text>
        )}
        {/*{clientData && clientData['@type'] === 'Lead' ? (*/}
        {/*  <Button*/}
        {/*    style={{ marginLeft: 24, borderRadius: 40 }}*/}
        {/*    appearance="outline"*/}
        {/*    size="small"*/}
        {/*    status={*/}
        {/*      clientData?.status*/}
        {/*        ? STATUSMAP[clientData.status] || 'primary'*/}
        {/*        : 'basic'*/}
        {/*    }*/}
        {/*  >*/}
        {/*    {clientData ? `${clientData.status}` : null}*/}
        {/*  </Button>*/}
        {/*) : null}*/}
        {clientData?.isPartner ? (
          <View
            style={{
              marginLeft: 32,
              paddingHorizontal: 16,
              paddingVertical: 8,
              backgroundColor: 'rgb(101, 163, 216)',
              borderRadius: 8,
            }}
          >
            <Text category="h5" style={{ color: 'white' }}>
              Partner
            </Text>
          </View>
        ) : null}
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
        }}
      >
        {clientData && clientData['@type'] === 'Loan' && (
          <>
            {Platform.OS === 'web' ? (
              <Button
                style={{ ...styles.shadow, paddingVertical: 12 }}
                size={'small'}
                status="info"
                appearance="outline"
                onPress={() =>
                  openFileManagerTab({ loanData: clientData, type: 'loan' })
                }
              >
                Open File Manager
              </Button>
            ) : null}
            {user?.roles?.includes('ROLE_ADMIN') && (
              <Button
                onPress={() => {
                  resyncLoan({
                    loanNumber: clientData?.loanNumber,
                    id: clientData?.id,
                  });
                }}
                disabled={resyncLoading || !clientData?.loanNumber}
                size="small"
                status="info"
                appearance="outline"
                style={{ ...styles.shadow }}
                accessoryLeft={resyncLoading ? LoadingIndicator : RefreshIcon}
              >
                Resync from Empower
              </Button>
            )}
            {clientData?.loanNumber?.length === 9 ? (
              <Button
                onPress={(e) => {
                  e.preventDefault();
                  Linking.openURL(
                    `https://los.p5.empower.bkicloud.com/ArkMortgage.LOSWeb/WebMain/Main.aspx?loannumber=${clientData?.loanNumber}`
                  );
                }}
                size="small"
                status="info"
                appearance="outline"
                style={styles.shadow}
                accessoryLeft={() => (
                  <Avatar
                    style={{ marginRight: 16, height: 24, width: 24 }}
                    source={require('../../../assets/images/blackknight.png')}
                  />
                )}
              >
                View in Empower
              </Button>
            ) : (
              <Button
                onPress={(e) => {
                  e.preventDefault();
                  if (clientData?.id) {
                    createEmpowerLoan(clientData.id);
                    setModalVisible(true);
                  }
                }}
                size="small"
                status="danger"
                appearance="outline"
                style={styles.shadow}
                accessoryLeft={() => (
                  <Avatar
                    style={{ marginRight: 16, height: 24, width: 24 }}
                    source={require('../../../assets/images/blackknight.png')}
                  />
                )}
              >
                Create Empower Loan
              </Button>
            )}
          </>
        )}
        <QuickActionsDropDown
          type="task"
          icon="clipboard"
          title="Log Call/Meeting"
          status="info"
          clientData={clientData}
        />
        {clientData && clientData['@type'] === 'Contact' && (
          <Button
            status="danger"
            appearance="outline"
            onPress={() => deleteContact(clientData.id)}
            disabled={isLoading}
          >
            Delete
          </Button>
        )}
        {error && (
          <Text status="danger">
            Failed to delete contact with error:
            {errorMsg}
          </Text>
        )}
      </View>
    </Layout>
  );
}

export default WebHeader;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },

  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
