import { Icon } from '@ui-kitten/components';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import Colors from '../helpers/Colors';
import { ExpandableFloatingAction } from '../components-ui';
import { showModal as realShow } from '../services/FormSlice';

type Props = {
  onlyShow?: any[];
  defaultData?: any;
};

function QuickActionFAB({ onlyShow, defaultData = null }:Props) {
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const allMenuItems = [
    {
      name: 'createNewEvent',
      icon: (
        <Icon fill="white" style={styles.icon} name="calendar-outline" />
      ),
      text: 'Create Event',
      callback: () => {
        showModal({ formType: 'event', data: defaultData });
      },
    },
    {
      name: 'createNewTask',
      icon: (
        <Icon fill="white" style={styles.icon} name="calendar-outline" />
      ),
      text: 'Create Task',
      callback: () => {
        showModal({ formType: 'task', data: defaultData });
      },
    },
    {
      name: 'createNewNote',
      icon: (
        <Icon
          fill="white"
          name="file-add-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'Create Note',
      callback: () => {
        showModal({ formType: 'note', data: defaultData });
      },
    },
    {
      name: 'createLead',
      icon: (
        <Icon
          fill="white"
          name="person-add-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'New Lead',
      callback: () => {
        showModal({ formType: 'createLead' });
      },
    },
    {
      name: 'createContact',
      icon: (
        <Icon
          fill="white"
          name="person-add-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'New Contact',
      callback: () => {
        showModal({ formType: 'createContact' });
      },
    },
    {
      name: 'logCall',
      icon: (
        <Icon
          fill="white"
          name="phone-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'Log a Call',
      callback: () => {
        showModal({ formType: 'logCall', data: defaultData });
      },
    },
    {
      name: 'logMeeting',
      icon: (
        <Icon
          fill="white"
          name="people-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'Log a Meeting',
      callback: () => {
        showModal({ formType: 'logMeeting', data: defaultData });
      },
    },
    {
      name: 'createScenario',
      icon: (
        <Icon
          fill="white"
          name="file-add-outline"
          size={32}
          style={styles.icon}
        />
      ),
      text: 'Add Scenario',
      callback: () => {
        showModal({ formType: 'createScenario', data: { type: 'Contact', create: true, selectedAssociate: defaultData?.selectedAssociate || null } });
      },
    },
    // {
    //   name: 'generatePreapproval',
    //   icon: (
    //     <Icon fill="white" style={styles.icon} name="calendar-outline" />
    //   ),
    //   text: 'Pre-Approval',
    //   callback: () => {
    //     showModal({ formType: 'preapproval'});
    //   },
    // },
  ];

  const menuItems = useMemo(() => {
    if (!onlyShow) {
      return allMenuItems;
    }
    return allMenuItems.filter((item:any) => onlyShow.find((menuItem:any) => menuItem === item.name));
  }, [onlyShow]);

  return (
    <ExpandableFloatingAction
      mainColor="transparent"
      secondaryColor="white"
      closeIcon={(
        <View style={styles.mainIconWrap}>
          <Icon
            fill="white"
            name="minus-outline"
            style={{
              backgroundColor: Colors.primaryRed,
              width: 32,
              height: 32,
            }}
          />
        </View>
        )}
      openIcon={(
        <View style={styles.mainIconWrap}>
          <Icon
            fill="white"
            name="plus-outline"
            animation="pulse"
            style={{
              backgroundColor: Colors.primaryRed,
              width: 32,
              height: 32,
            }}
          />
        </View>
        )}
      menuIcons={menuItems}
    />
  );
}

const styles = StyleSheet.create({
  icon: {
    color: 'white',
    borderRadius: 32,
    width: 18,
    height: 18,
  },
  text: {
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
  },
  mainIconWrap: {
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 24,
    overflow: 'hidden',
  },
});

export default QuickActionFAB;
