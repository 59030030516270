import { Button } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';

type Props = {
  cancelAction?: () => void;
  submitAction?: () => void;
  disabled?: boolean;
  style?: any;
  submitText?: string;
  cancelText?: string;
  hideCancel?: boolean;
};

const Footer = (props: Props) => (
  <View {...props} style={[props.style, styles.footerContainer]}>
    {props.hideCancel ? null : (
      <Button
        onPress={() => props.cancelAction()}
        style={styles.footerControl}
        size="small"
        status="basic"
      >
        {props.cancelText ? props.cancelText : 'CANCEL'}
      </Button>
    )}
    <Button
      disabled={props.disabled}
      onPress={() => props.submitAction()}
      style={[
        styles.footerControl,
        props.disabled ? { backgroundColor: '#eee' } : null,
      ]}
      size="small"
    >
      {props.submitText || 'CREATE'}
    </Button>
  </View>
);

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    paddingTop: 24,
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  footerControl: {
    marginHorizontal: 2,
  },
});

export default Footer;
