// eslint-disable-next-line import/prefer-default-export
export const leadFieldsHelper = [
  { value: 'firstName', label: 'First Name', allowed: true },
  { value: 'lastName', label: 'Last Name', allowed: true },
  { value: 'propertyAddress', label: 'Street Address', allowed: true },
  { value: 'propertyAddress2', label: 'Address 2', allowed: true },
  { value: 'propertyCity', label: 'City', allowed: true },
  { value: 'propertyState', label: 'State', allowed: true },
  { value: 'propertyZip', label: 'Zipcode', allowed: true },
  { value: 'homePhone', label: 'Home Phone', allowed: true },
  { value: 'mobilePhone', label: 'Mobile Phone', allowed: true },
  { value: 'email', label: 'Email Address', allowed: true },
  { value: 'referredBy', label: 'Referred By', allowed: true },
  { value: 'description', label: 'Description', allowed: true },
  { value: 'additionalInfo', label: 'Additional Info', allowed: true },
  { value: 'loanAmount', label: 'Loan Amount', allowed: true },
];
