import moment from 'moment';

export function generateTime() {
  const locale = 'en'; // or whatever you want...
  const hours = [];

  moment.locale(locale); // optional - can remove if you are only dealing with one locale

  for (let hour = 5; hour < 24; hour++) {
    hours.push(moment({ hour }).format('h:mm A'));
    hours.push(
      moment({
        hour,
        minute: 15,
      }).format('h:mm A')
    );
    hours.push(
      moment({
        hour,
        minute: 30,
      }).format('h:mm A')
    );
    hours.push(
      moment({
        hour,
        minute: 45,
      }).format('h:mm A')
    );
  }
  return hours;
}

export function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
