import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
} from 'react-native';
import { CheckBox, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';
import GlobalSearchAutoComplete, {
  dataTransformer,//@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import {
  useAddNewContactMutation,
  useGetContactsQuery,
} from '../../../services/ContactApiSlice';
import {
  useAddTlcAgentMutation,
  useGetTlcAgentsQuery,
} from '../../../services/TlcAgentsSlice';
import { useUpdateTlcClientMutation } from '../../../services/TlcClientsSlice';
import { useAddNewTlcInviteMutation } from '../../../services/TlcInvite';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';


function ConnectAgentForm({ cancelAction, defaultData }: any) {
  const windowWidth = Dimensions.get('window').width;
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(true);
  const [createContactFromAgent, setCreateContactFromAgent] = useState(false);
  const [page, setPage] = useState(1);
  const [
    createContact,
    { isSuccess: createContactSuccess, data: createContactData },
  ] = useAddNewContactMutation();

  const [patchTlcClient, { isSuccess: patchSuccess, data: patchData }] =
    useUpdateTlcClientMutation();

  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery('isPartner=true' + query);
  const { data: tlcAgents, isFetching: isFetchingAgents } =
    useGetTlcAgentsQuery(query);
  const {
    data: selectedAssociateData,
    isFetching: isFetchingSelectedAssociateData,
  } = useGetContactsQuery(
    selectedAssociate
      ? 'isPartner=true&email=' + selectedAssociate.email
      : skipToken
  );

  const [addNewAgent, { isSuccess: addAgentSuccess, data: addAgentData }] =
    useAddTlcAgentMutation();
  const [sendTlcInvite, { isSuccess: inviteSuccess, data: inviteData }] =
    useAddNewTlcInviteMutation();

  useEffect(() => {
    if (!isFetching) {
      const mappedTlcAgents = tlcAgents?.map((agent: any) => {
        const newAgent = { ...agent };
        newAgent.type = 'TlcAgent';
        newAgent.title = newAgent.firstName + ' ' + newAgent.lastName;
        return newAgent;
      });

      //remove duplicates
      const filteredContacts = dataTransformer(contactsData).filter(
        (contact: any) => {
          if (!mappedTlcAgents?.length) return true;
          return mappedTlcAgents.find(
            (agent: any) => contact.email == agent.email
          )
            ? false
            : true;
        }
      );

      setAutoCompleteData([...filteredContacts, ...mappedTlcAgents]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingContacts && !isFetchingAgents) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingAgents]);
  //  End Auto Complete

  useEffect(() => {
    if (patchSuccess && patchData) {
      if (createContactFromAgent) {
        const { firstName, lastName, email, phoneNumber } = selectedAssociate;

        createContact({
          firstName,
          lastName,
          email,
          mobilePhone: phoneNumber,
          isPartner: true,
        });
      } else {
        setTimeout(() => {
          cancelAction();
        }, 2000);
      }
    }
  }, [patchSuccess, patchData]);

  useEffect(() => {
    if (createContactSuccess && createContactData) {
      setTimeout(() => {
        cancelAction(createContactData);
      }, 2000);
    }
  }, [createContactSuccess, createContactData]);

  useEffect(() => {
    if (addAgentSuccess && addAgentData) {
      sendTlcInvite({
        invitee: addAgentData['@id'],
        inviter: defaultData.tlcClient['@id'],
        inviterType: 'client',
        invitorClient: defaultData.tlcClient['@id'],
        inviteeAgent: addAgentData['@id'],
        inviteeType: 'agent',
      });

      patchTlcClient({
        id: defaultData.tlcClient.id,
        assignedAgent: addAgentData['@id'],
      });
    }
  }, [addAgentSuccess, addAgentData]);

  const isUserContact =
    selectedAssociateData &&
    selectedAssociateData['hydra:member'] &&
    selectedAssociateData['hydra:member'].length > 0
      ? true
      : false;

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      if (selectedAssociate?.type === 'TlcAgent') {
        patchTlcClient({
          id: defaultData.tlcClient.id,
          assignedAgent: selectedAssociate['@id'],
        });
      } else {
        //Add to TLC Agent Table
        const newAgent = {
          firstName: selectedAssociate.firstName,
          lastName: selectedAssociate.lastName,
          email: selectedAssociate.email,
          phoneNumber: selectedAssociate.phone,
          status: 'invited',
        };
        if (!newAgent.phoneNumber) {
          throw Error(
            "This selected partner doesn't have a phone number. Please update the partner's phone number or select another partner."
          );
        }
        addNewAgent(newAgent);
        //Update Tlc Client table
        //send invite to agent as pending
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Connect an Agent'} />
      <View style={{ minHeight: 350 }}>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 1 }}>
            <FormGroup label="Partners/Agents" required={false}>
              <GlobalSearchAutoComplete
                clearAutoCompleteData={() => setAutoCompleteData([])}
                autoCompleteData={autoCompleteData}
                isFetching={isFetching}
                setQuery={setQuery}
                handleOnChange={(e) => setSelectedAssociate(e)}
                listDirection="down"
                page={page}
                setPage={setPage}
                defaultValue={
                  defaultData?.referred
                    ? {
                        type: defaultData.referred.type,
                        id: defaultData.referred.id,
                        ['@id']: defaultData.referred['@id'],
                        contactId: defaultData.referred.id,
                        title: defaultData.referred.name,
                        name: defaultData.referred.name,
                      }
                    : null
                }
              />
            </FormGroup>
          </View>
          {selectedAssociateData && !isUserContact ? (
            <FormGroup label="" required={false}>
              <CheckBox
                checked={createContactFromAgent}
                onChange={(nextChecked) =>
                  setCreateContactFromAgent(nextChecked)
                }
              >
                The selected agent is not a contact. Create a contact from this
                agent?
              </CheckBox>
            </FormGroup>
          ) : null}
        </View>
      </View>
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}

      {false && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={
          formSubmitted || !selectedAssociate || isFetchingSelectedAssociateData
        }
        submitText={'CONNECT'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default ConnectAgentForm;
