import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import { Card, Text } from '@ui-kitten/components';
import { useGetTiersQuery } from '../../../services/TierSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { isAfter, isBefore, startOfQuarter, startOfYear } from 'date-fns';

function ProjectedTier() {
  const { data: tiers, isSuccess: tiersLoaded } = useGetTiersQuery({});
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  const closedLoanAmountQTD = !loansData
    ? 0
    : loansData['hydra:member'].reduce((acc, loan) => {
        if (
          loan.purchasedDate &&
          loan.purchasedDate !== '' &&
          loan.closeStatus === 'Won' &&
          loan.closeDate &&
          loan.loanAmount &&
          isAfter(new Date(loan.closeDate), startOfQuarter(new Date())) &&
          isBefore(new Date(loan.closeDate), new Date())
        ) {
          return acc + loan.loanAmount;
        }
        return acc;
      }, 0);

  let projectedTier;
  if (tiers?.['hydra:member']) {
    const sortedTiers = [...tiers['hydra:member']].sort(
      (a, b) => a.tier - b.tier
    );
    projectedTier =
      sortedTiers.length && closedLoanAmountQTD !== 0
        ? sortedTiers.find((tier) => {
            const min = tier.min;
            const max = tier.max || 99999999999999999;
            if (min <= closedLoanAmountQTD && max >= closedLoanAmountQTD) {
              return true;
            }
            return false;
          })
        : { percentage: 1 };
  }

  return (
    <Card
      style={styles.card}
      status="success"
      header={(props) => (
        <View {...props}>
          <Text
            category="h6"
            style={{ textAlign: 'center', fontFamily: 'VerbBold' }}
          >
            Projected Tier Based On Current Closed Loans
          </Text>
        </View>
      )}
    >
      <View style={{ flex: 1 }}>
        {tiersLoaded && loansSuccess ? (
          <Text
            category="h4"
            style={{ textAlign: 'center', fontFamily: 'verbBold' }}
          >
            {projectedTier.percentage}%
          </Text>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    margin: 2,
    flex: 1,
    minWidth: 250,
  },
});

export default ProjectedTier;
