import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './ApiSlice';
import { impersonationSlice } from './ImpersonationSlice';
import { tableSlice } from './TableSlice';
import { formSlice } from './FormSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    impersonation: impersonationSlice.reducer,
    table: tableSlice.reducer,
    form: formSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
