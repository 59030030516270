import { Button, Icon, Layout } from '@ui-kitten/components';
import React, { useContext } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { EventIcon, NoteIcon, TaskIcon } from '../components-ui/Icons';
import { showModal as realShow } from '../services/FormSlice';

type Props = {
  infoType?: string;
  clientId?: string;
  contacts?: any[];
  style?: any;
  mobile?: boolean;
};

function DetailCreateButtonGroup({
  infoType,
  clientId,
  contacts,
  style,
  mobile = false,
}: Props) {
  const defaultData = contacts
    ? { contactList: contacts }
    : {
        [infoType]: `/${infoType}s/${clientId}`,
      };
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  return (
    <View style={style ? style : styles.container}>
      <Button
        style={[styles.button,{marginRight: 8}]}
        size={mobile ? 'large' : 'medium'}
        appearance={mobile ? 'ghost' : 'outline'}
        status={mobile ? 'danger' : 'danger'}
        accessoryLeft={TaskIcon}
        onPress={() => showModal({ formType: 'task', data: defaultData })}
      >
        {mobile ? '' : 'New Task'}
      </Button>
      <Button
       style={[styles.button,{marginRight: 8}]}
        size={mobile ? 'large' : 'medium'}
        appearance={mobile ? 'ghost' : 'outline'}
        status={mobile ? 'info' : 'info'}
        accessoryLeft={NoteIcon}
        onPress={() => showModal({ formType: 'note', data: defaultData })}
      >
        {mobile ? '' : 'New Note'}
      </Button>
      <Button
        style={styles.button}
        size={mobile ? 'large' : 'medium'}
        appearance={mobile ? 'ghost' : 'outline'}
        status={mobile ? 'warning' : 'warning'}
        accessoryLeft={EventIcon}
        onPress={() => showModal({ formType: 'event', data: defaultData })}
      >
        {mobile ? '' : 'New Event'}
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: Platform.OS === 'web' ? 'space-around' : 'flex-end',
    width: '100%',
    paddingHorizontal: 6
  },
  button: {
    marginHorizontal: 0,
    flex: Platform.OS === 'web' ? 1 : 0,
    minWidth: Platform.OS === 'web' ? 155 : 25,
    backgroundColor: Platform.OS === 'web' ? '#fff' : null,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.35,
    shadowRadius: 4,
    elevation: 2,
  },
});

export default DetailCreateButtonGroup;
