import { Card, Layout, Text } from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  StyleSheet,
  Platform,
  Dimensions,
  RefreshControl,
} from 'react-native';
import moment from 'moment';
import {
  EventCard, NoteCard, StatusCard, TaskCard,
} from '../components-ui';
import DetailCreateButtonGroup from './DetailCreateButtonGroup';

type Props = {
  activities: any[];
  infoType?: string;
  clientId?: string;
  fetchingActivities?: boolean;
  refetch?: any;
};

export default function ActivityList({
  activities,
  infoType,
  clientId,
  refetch = () => null,
  fetchingActivities = false,
}: Props) {
  const [activitiesData, setActivities] = useState<any[] | null>(null);
  const [activityStats, setActivityStats] = useState<any>();
  const [filterByType, setFilterByType] = useState('');

  useEffect(() => {
    if (activities) setActivities(activities);
  }, [activities]);

  useEffect(() => {
    if (activities) {
      // tasks - total, completed, pastDue
      // notes - total
      // events - total, completed, pastdue
      const stats = {
        tasks: { total: 0, completed: 0, pastDue: 0 },
        notes: { total: 0 },
        events: { total: 0, completed: 0, pastDue: 0 },
      };
      const tasks = activities.filter((task) => task.activityType === 'task');
      const notes = activities.filter((task) => task.activityType === 'note');
      const events = activities.filter((task) => task.activityType === 'event');

      stats.tasks.total = tasks.length;
      stats.tasks.completed = tasks.filter((task) => task.isCompleted).length || 0;
      stats.tasks.pastDue = tasks.filter(
        (task) => task.isCompleted === false
            && moment(task.dueDate).isBefore(moment()),
      ).length || 0;
      stats.notes.total = notes.length;
      stats.events.total = events.length;
      stats.events.completed = events.filter((event) => event.outcome).length || 0;
      stats.events.pastDue = events.filter(
        (event) => !event.isCompleted
            && !event.outcome
            && !event.resolution
            && moment(event.endDatetime).isBefore(moment()),
      ).length || 0;
      setActivityStats(stats);
    }
  }, [activities]);

  useEffect(() => {
    if (filterByType.length) {
      const filteredActivities = activities.filter(
        (activity) => activity.activityType === filterByType,
      );
      setActivities(filteredActivities);
    } else {
      setActivities(activities);
    }
  }, [filterByType]);

  function handleFilter(type) {
    if (filterByType === type) {
      setFilterByType('');
    } else {
      setFilterByType(type);
    }
  }
  const { height } = Dimensions.get('screen');
  return (
    <ScrollView
      style={[
        {
          flex: 1,
          height: '100%',
        },
      ]}
    >
      <RefreshControl
        enabled
        refreshing={fetchingActivities}
        onRefresh={refetch}
      />
      <Layout
        style={{
          flex: 1,
          height: '100%',
          paddingBottom: 16,
          minHeight: Platform.OS == 'web' ? 500 : height,
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 16,
            paddingHorizontal: 10,
            paddingBottom: 8,
          }}
        >
          {Platform.OS === 'web' ? (
            <DetailCreateButtonGroup infoType={infoType} clientId={clientId} />
          ) : null}
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'stretch',
            justifyContent: 'center',
            marginBottom: 16,
            marginHorizontal: 0,
            paddingTop: 8,
            paddingHorizontal: Platform.OS === 'web' ? 12 : 0,
          }}
        >
          {activityStats && (
            <>
              <InfoCard
                isHighlighted={filterByType === 'task'}
                onPress={() => handleFilter('task')}
                label="Tasks"
                status="danger"
                total={activityStats.tasks.total}
                pastDue={activityStats.tasks.pastDue}
                completed={activityStats.tasks.completed}
              />
              <InfoCard
                isHighlighted={filterByType === 'note'}
                onPress={() => handleFilter('note')}
                label="Notes"
                status="primary"
                total={activityStats.notes.total}
              />
              <InfoCard
                isHighlighted={filterByType === 'event'}
                onPress={() => handleFilter('event')}
                label="Events"
                status="warning"
                total={activityStats.events.total}
                pastDue={activityStats.events.pastDue}
                completed={activityStats.events.completed}
              />
            </>
          )}
        </View>
        <View style={{ flex: 1, padding: Platform.OS === 'web' ? 16 : 0 }}>
          {activitiesData?.length === 0 ? (
            <Text style={{ fontStyle: 'italic' }}>No Activites..</Text>
          ) : null}
          {activitiesData
            && activitiesData.map((task, index) => (
              <React.Fragment key={`${task.createdAt}`}>
                {task.activityType === 'leadStatusLog' && (
                  <StatusCard key={`${task.createdAt}`} activity={task} />
                )}
                {task.activityType === 'note' && (
                  <NoteCard key={`${task.createdAt}`} activity={task} />
                )}
                {task.activityType === 'event' && (
                  <EventCard key={`${task.createdAt}`} activity={task} />
                )}
                {task.activityType === 'task' && (
                  <TaskCard key={`${task.createdAt}`} activity={task} />
                )}
                {activitiesData.length !== index + 1 ? (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: 1,
                        height: 16,
                        borderRightWidth: 1,
                        borderColor: '#b8b8b8',
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                    />
                  </View>
                ) : (
                  <View style={{ height: 32 }} />
                )}
              </React.Fragment>
            ))}
        </View>
      </Layout>
    </ScrollView>
  );
}

function InfoCard({
  label,
  status,
  total,
  completed,
  pastDue,
  onPress,
  isHighlighted,
}: any) {
  return (
    <Card
      style={[styles.card, isHighlighted ? styles.shadow : null, (isHighlighted && Platform.OS !== 'web') ? { backgroundColor: '#eaeaea' } : null]}
      status={status}
      onPress={onPress}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          marginHorizontal: Platform.OS === 'web' ? -8 : -12,
        }}
      >
        <Text category="h6">{label}</Text>
        <View
          style={{
            flex: 1,
            marginTop: 16,
            width: '100%',
          }}
        >
          {total !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Text
                style={{
                  flex: 2,
                  marginRight: 8,
                  fontSize: Platform.OS === 'web' ? 14 : 10,
                }}
                category="label"
              >
                Total:
              </Text>
              <Text
                style={{ flex: 1, fontSize: 14, textAlign: 'right' }}
                category="label"
              >
                {total}
              </Text>
            </View>
          )}
          {completed !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Text
                style={{
                  flex: 2,
                  marginRight: 8,
                  fontSize: Platform.OS === 'web' ? 14 : 10,
                }}
                category="label"
              >
                Completed:
              </Text>
              <Text
                style={{ flex: 1, fontSize: 14, textAlign: 'right' }}
                category="label"
              >
                {completed}
              </Text>
            </View>
          )}
          {pastDue !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <Text
                style={{
                  flex: 2,
                  marginRight: 8,
                  fontSize: Platform.OS === 'web' ? 14 : 10,
                }}
                category="label"
              >
                Past Due:
              </Text>
              <Text
                style={{
                  flex: 1,
                  color: pastDue ? 'red' : 'green',
                  fontSize: 14,
                  textAlign: 'right',
                }}
                category="label"
              >
                {pastDue}
              </Text>
            </View>
          )}
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  taskHeader: {
    fontSize: 14,
    padding: 16,
  },
  shadow: {
    shadowColor: '#aaa',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 4,
  },
  line: {
    flex: 1,
    height: 1,
    borderBottomColor: '#eee',
    borderBottomWidth: 1,
  },
  card: {
    marginHorizontal: 4,
    flex: 1,
    borderWidth: 1,
    borderColor: '#d5d5d5',
  },
});
