import { Platform } from 'react-native';
import { Card, Text, useTheme } from '@ui-kitten/components';
import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native';

type UserStat = {
  total: number;
  calls: number;
  advances: number;
  apps: number;
  locks: number;
  user: string;
  team: string;
};

const colorScheme = [
  '#67b7dc',
  '#6794dc',
  '#6771dc',
  '#8067dc',
  '#a367dc',
  '#c767dc',
  '#dc9467',
  '#dcb767',
];

export default function PointsByLoTop5({ userData }: { userData: UserStat[] }) {
  const datasetMap = {
    calls: [],
    advances: [],
    apps: [],
    locks: [],
  };
  const theme = useTheme();
  let temp = [...userData];
  temp = temp.filter((user) => Object.keys(user).length > 1);
  temp.sort((a, b) => b.total - a.total);
  temp = temp.slice(0, 5);
  temp.reverse();
  temp.forEach((user) => {
    Object.keys(datasetMap).forEach((key) => {
      datasetMap[key].push({ x: user.user, y: user[key] });
    });
  });
  const dataset = Object.values(datasetMap);
  const categories = Object.keys(datasetMap);
  return (
    <Card
      style={{
        flex: 1,
        minWidth: 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        maxWidth: 450,
      }}
      header={(props) => (
        <Text style={{ marginBottom: 10 }} category="h6">
          Top 5 in Points
        </Text>
      )}
    >
      <VictoryChart
        height={300}
        width={300}
        domainPadding={{ x: 30, y: 20 }}
        theme={VictoryTheme.material}
        horizontal
        containerComponent={
          <VictoryVoronoiContainer
            style={{}}
            labels={({ datum }) => {
              return `${datum.name}`;
            }}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => tick}
          style={{
            grid: {
              stroke: theme['color-basic-600'],
              strokeWidth: 0.5,
              strokeDasharray: '0',
            },
          }}
        />
        <VictoryAxis
          tickFormat={dataset[0].map((d) => d.x.split(' '))}
          style={{
            grid: { stroke: 'transparent' },
            tickLabels: { fontSize: 9, wordWrap: 'break-word' },
          }}
        />
        <VictoryStack
          animate={{
            duration: 1000,
            easing: 'bounce',
          }}
          colorScale={colorScheme}
        >
          {dataset.map((data, i) => {
            const newData = data.map((item) => ({
              ...item,
              name: `${categories[i]}: ${item.y}`,
            }));
            return <VictoryBar data={newData} key={categories[i]} />;
          })}
        </VictoryStack>
        <VictoryLegend
          // x={30}
          y={275}
          gutter={20}
          standalone={false}
          orientation="horizontal"
          style={{
            labels: {
              color: theme['text-basic-color'],
            },
          }}
          data={categories.map((item) => ({
            name: item.charAt(0).toUpperCase() + item.slice(1),
          }))}
          colorScale={colorScheme}
          theme={VictoryTheme.material}
        />
      </VictoryChart>
    </Card>
  );
}
