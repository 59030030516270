import { apiSlice } from './ApiSlice';

export const HomeSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getHomeData: build.query({
      query: () => ({
        url: '/homepage-data/data',
        method: 'GET',
      }),
      providesTags: () => [{
        type: 'HomeData',
      }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetHomeDataQuery,
} = HomeSlice;
