import { CheckBox, Icon, Spinner } from '@ui-kitten/components';
import React, { useContext, useEffect, useState } from 'react';
import {
  FlatList,
  Keyboard,
  Pressable,
  RefreshControl,
  StyleSheet,
  View,
} from 'react-native';
import { Text } from '@ui-kitten/components';
import { SingleActionFab } from '../../components-ui';
import { useDispatch, useSelector } from 'react-redux';
import { setClearData as clear, setDefaultData as setDefault } from '../../services/FormSlice';
import { RootState } from '../../services/store';

type Props = {
  dataset: any[];
  setPage: Function;
  page: number;
  isFetching: boolean;
  setSearchTerm?: Function;
  searchTerm?: string;
  navigation: any;
  renderRow: any;
  refetch: any;
};

const MobileListView = ({
  dataset,
  setPage,
  page,
  isFetching,
  navigation,
  renderRow,
  refetch,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const setDefaultData = (payload) => dispatch(setDefault(payload));
  const setClearData = (payload) => dispatch(clear(payload));
  const [allData, setAllData] = useState<any>();
  const { clearData } = useSelector((state: RootState) => state.form);

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return element.id === el.id;
      });
    });
    return res;
  };

  useEffect(() => {
    if (dataset) {
      if (page === 1) {
        setAllData(dataset);
      } else {
        setAllData((prev: any) => {
          if (prev && page !== 1) {
            const filteredAllData = filterByReference(prev, dataset);
            return [...filteredAllData, ...dataset];
          } else {
            return [...dataset];
          }
        });
      }
    }
  }, [dataset]);

  const COLORS = ['#00E096', '#3366FF', '#FFAA00', '#FF3D71', '#DB2C66', 'red'];
  const COLORMAPPING = ['abcd', 'efgh', 'ijklm', 'nopqrst', 'uvwxyz', ''];

  function getColorMap(character: string) {
    let color = 'red';
    COLORMAPPING.forEach((characters) => {
      if (characters.includes(character.toLowerCase())) {
        color = COLORS[COLORMAPPING.indexOf(characters)];
      }
    });
    return color;
  }

  const onRefresh = () => {
    setPage(1);
    refetch();
  };

  const renderItem = ({ item }: any) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          position: 'relative',
          width: '100%',
        }}
      >
        <View style={styles.itemRow}>
          <View style={styles.avatarWrap}>
            {!selectedRows?.find((contact) => contact['@id'] === item['@id'])
              ?.id ? (
              <Pressable
                onPress={() => {
                  if (
                    selectedRows?.find(
                      (contact) => contact['@id'] === item['@id']
                    )?.id
                  ) {
                    //remove
                    const newState = selectedRows.filter(
                      (contact) => contact['@id'] !== item['@id']
                    );
                    setSelectedRows(newState);
                    // setDefaultData({ contactList: newState });
                  } else {
                    const newState = [...selectedRows, item];
                    setSelectedRows(newState);
                    //setDefaultData({ contactList: newState });
                  }
                }}
                style={[
                  styles.avatar,
                  {
                    backgroundColor: getColorMap(
                      item?.name
                        ? item?.name[0]
                        : item.loanContact.firstName
                        ? item.loanContact.firstName[0]
                        : 'N/A'
                    ),
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: 'VerbBold',
                    color: 'white',
                    fontSize: 22,
                    marginTop: 4,
                    textTransform: 'uppercase',
                  }}
                >
                  {item.name
                    ? item.name[0]
                    : item.loanContact?.firstName
                    ? item.loanContact.firstName[0]
                    : 'N/A'}
                </Text>
              </Pressable>
            ) : (
              <Pressable
                onPress={() => {
                  if (
                    selectedRows?.find(
                      (contact) => contact['@id'] === item['@id']
                    )?.id
                  ) {
                    //remove
                    const newState = selectedRows.filter(
                      (contact) => contact['@id'] !== item['@id']
                    );
                    setSelectedRows(newState);
                    // setDefaultData({ contactList: newState });
                  } else {
                    const newState = [...selectedRows, item];
                    setSelectedRows(newState);
                    //setDefaultData({ contactList: newState });
                  }
                }}
                style={[styles.avatar, { backgroundColor: '#0095FF' }]}
              >
                <Icon
                  style={{ width: 24, height: 24 }}
                  fill="#fff"
                  name="checkmark-outline"
                />
              </Pressable>
            )}
          </View>
          {renderRow({ item })}
        </View>
      </View>
    );
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setSelectedRows([]);
      setDefaultData({ contactList: [] });
    });
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    Keyboard.dismiss();
  }, [dataset]);

  useEffect(() => {
    setDefaultData({ contactList: selectedRows });
  }, [selectedRows]);

  useEffect(() => {
    if (clearData) {
      setSelectedRows([]);
      setClearData(false);
    }
  }, [clearData]);

  function handleSelectAll() {
    if (selectedRows.length === allData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allData);
    }
  }

  return (
    <View style={styles.container}>
      {allData ? (
        <FlatList
          data={allData}
          renderItem={renderItem}
          onEndReached={() => setPage(page + 1)}
          onEndReachedThreshold={0.35}
          onRefresh={onRefresh}
          refreshing={isFetching}
          keyExtractor={(item, index) => item['@id'] + index}
        />
      ) : null}
      {isFetching ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            style={{ padding: 12, borderRadius: 4, backgroundColor: '#eee' }}
          >
            <Spinner size="giant" status="warning" />
          </View>
        </View>
      ) : null}
      {selectedRows?.length ? (
        <SingleActionFab icon={'done-all-outline'} action={handleSelectAll} />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  searchContainer: {
    marginBottom: 16,
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchWrap: {
    marginTop: 16,
    paddingHorizontal: 8,
    width: '100%',
    alignItems: 'center',
  },
  selectAllWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 16,
  },
  paginationWrap: {
    height: 48,
    // backgroundColor: 'rgb(34, 73, 115)',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    justifyContent: 'flex-end',
  },
  paginationText: {
    color: 'white',
    marginRight: 4,
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 8,
    borderBottomWidth: 1,
    borderColor: '#8F9BB3',
    width: '100%',
  },
  avatarWrap: {
    width: 48,
    height: 48,
    marginRight: 8,
    marginVertical: 8,
    position: 'relative',
  },
  avatar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'red',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  button: {
    margin: 2,
  },
});

export default MobileListView;
