import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  RefreshControl,
} from 'react-native';
import {
  Icon,
  Layout,
  Tab,
  TabView,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
import { useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { useRoute } from '../../navigation/router';
import { useGetLeadQuery } from '../../services/LeadApiSlice';
import {
  ActivityStatusColumn,
  ClientInformationColumn,
  LeadStatusLogsColumn,
  QuickActionFAB,
  WebHeader,
} from '../../components-global';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import { useGetLeadStatusLogsByLeadQuery } from '../../services/LeadStatusLogApiSlice';

type Props = {
  navigation?: any;
};

export function LeadsDetailPage(props: Props) {
  const route = useRoute();
  const leadId = Platform.OS === 'web' ? route.getParam('id') : route.params.id;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: leadData, isFetching, refetch } = useGetLeadQuery(leadId);

  const BackIcon = (props) => <Icon {...props} fill="#fff" name="arrow-back" />;

  const renderBackAction = () => {
    const nav = props.navigation;
    return (
      <TopNavigationAction
        onPress={() => (nav.canGoBack() ? nav.goBack() : nav.navigate('MenuItems', {
          screen: 'Leads',
          initial: false,
        }))}
        icon={BackIcon}
      />
    );
  };

  const {
    data: leadStatusLogs,
    isLoading: isLoadingStatusLogs,
    refetch: refetchStatusLogs,
  } = useGetLeadStatusLogsByLeadQuery(
    { id: leadId, dateRangeOption: 'all' },
    { refetchOnMountOrArgChange: true }
  );


  function onRefresh() {
    refetch();
    refetchStatusLogs();
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1 }}>
          <WebHeader clientData={leadData} setModalVisible={() => null} createEmpowerLoan={() => null} />
          <View style={{ flex: 1, flexDirection: 'row' }}>
            {leadData ? (
              <>
                <ClientInformationColumn
                  clientData={leadData}
                  refetch={refetch}
                />
                <ActivityStatusColumn clientId={leadId} type="lead" />
                <LeadStatusLogsColumn
                  leadStatusLogs={leadStatusLogs}
                  isLoadingStatusLogs={isLoadingStatusLogs}
                />
              </>
            ) : (
              <ActivityIndicatorComponent />
            )}
          </View>
        </View>
      ) : (
        <Layout style={{ flex: 1 }}>
          <TopNavigation
            style={{
              backgroundColor: '#007aff',
              height: 56,
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 8,
            }}
            alignment="center"
            title={() => (
              <Text style={{ color: '#fff' }} category="h5">
                {leadData?.name || ''}
              </Text>
            )}
            accessoryLeft={renderBackAction}
          />

          <TabView
            selectedIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
          >
            <Tab style={styles.tab} title="Lead Data">
              <Layout style={styles.tabContainer}>
                <ScrollView>
                  <RefreshControl
                    enabled
                    refreshing={isFetching || isLoadingStatusLogs}
                    onRefresh={onRefresh}
                  />
                  {leadData ? (
                    <ClientInformationColumn
                      clientData={leadData}
                      refetch={refetch}
                    />
                  ) : (
                    <ActivityIndicatorComponent />
                  )}
                </ScrollView>
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Activities">
              <Layout style={styles.tabContainer}>
                <ActivityStatusColumn clientId={leadId} />
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Status Logs">
              <Layout style={styles.tabContainer}>
                <ScrollView style={{ flex: 1 }}>
                  <RefreshControl
                    enabled
                    refreshing={isLoadingStatusLogs}
                    onRefresh={onRefresh}
                  />
                  <LeadStatusLogsColumn
                    leadStatusLogs={leadStatusLogs}
                    isLoadingStatusLogs={isLoadingStatusLogs}
                  />
                </ScrollView>
              </Layout>
            </Tab>
          </TabView>
          {Boolean(leadData) ? (
            <QuickActionFAB
              defaultData={{
                lead: leadData['@id'],
                '@type': 'Lead',
                '@id': leadData['@id'],
                selectedAssociate: leadData,
              }}
              onlyShow={[
                'createScenario',
                'createNewTask',
                'createNewNote',
                'createNewEvent',
                'logCall',
                'logMeeting',
              ]}
            />
          ) : null}
        </Layout>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  tabContainer: {
    minHieght: Dimensions.get('screen').height - 56,
    height: Dimensions.get('screen').height - 56,
    paddingBottom: 128,
  },
  tab: {
    height: 48,
  },
});
