import React from 'react';
import {
  MenuItem, Select, FormControl,
} from '@material-ui/core';
import { View } from 'react-native';

export default function LeadsMappingDropDown({ row, onSelect, fields }) {
  return (
    <View style={{ flex: 1, paddingHorizontal: 8, minWidth: 150 }}>
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <Select
          onChange={(val) => onSelect(val, row)}
          defaultValue={row.mapTo}
          value={row.mapTo}
          variant="outlined"
          style={{ fontSize: 13 }}
        >
          {fields.map((item) => (
            <MenuItem style={{ fontSize: 13 }} disabled={!item.allowed} key={item.value} value={item.value}>
              {String(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </View>
  );
}
