import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from '@ui-kitten/components';
import Colors from '../../src/helpers/Colors';
import { navigate, getParam } from '../../services/app/NavigationService';

function handleClick(code) {
  switch (code) {
    case 401:
       navigate('Login');
      break;
    case 406:
      return false;
    default:
      navigate('Home');
  }
}
export default function Error(props) {
  const code = getParam('code');
  const message = getParam('message');
  return (
    <View style={styles.container}>
      <Text>
        Error Code:
        {code}
      </Text>
      <Text>{message}</Text>
      <Button
        color={Colors.primaryRed}
        style={{
          borderColor: Colors.primaryRed,
          borderRadius: 4,
          borderWidth: 1,
          marginTop: 20,
        }}
        onPress={() => handleClick(code)}
      >
        <Text>Click Here</Text>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '90%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
