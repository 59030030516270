import { Button, Layout } from '@ui-kitten/components';
import React from 'react';
import { ScrollView } from 'react-native';
//@ts-ignore
import { useNavigation } from '../navigation/router';
import routes from '../navigation/routes';


type Props = {
  toggleFilter: number;
  setToggleFilter: Function;
};

function PipelineMobilePills({ setToggleFilter, toggleFilter }: Props) {
  const navigation = useNavigation();
  return (
    <Layout level="2">
      <ScrollView
        showsHorizontalScrollIndicator={false}
        style={{ maxHeight: 45 }}
        horizontal
        contentContainerStyle={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          paddingHorizontal: 6,
          paddingVertical: 8,
          height: 45,
        }}
      >
         <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status="basic"
          appearance="outline"
          onPress={() => {
            navigation.navigate(routes.ExtraRoutes.CommissionApprovals)
          }}
        >
          Commission
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 0 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(0);
          }}
        >
          Scenario
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 1 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(1);
          }}
        >
          Prospects
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 2 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(2);
          }}
        >
          Applications
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 3 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(3);
          }}
        >
          Closed Loans
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 4 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(4);
          }}
        >
          Adversed
        </Button>
        <Button
          style={{ borderRadius: 25, paddingTop: 8, marginRight: 12 }}
          size="tiny"
          status={toggleFilter === 5 ? 'info' : 'basic'}
          appearance="outline"
          onPress={() => {
            setToggleFilter(5);
          }}
        >
          All Loans
        </Button>
      </ScrollView>
    </Layout>
  );
}

export default PipelineMobilePills;
