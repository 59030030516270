import React, { useContext, useLayoutEffect, useState } from 'react';
import {
  ScrollView,
  View,
  StyleSheet,
  Platform,
  Dimensions,
} from 'react-native';
import { Button, ButtonGroup, Layout, Text } from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetLoansState,
  useLazyGetLoansQuery,
} from '../../services/LoanApiSlice';
import ErrorBoundary from '../../components-global/ErrorBoundary';
import {
  PipelineMobilePills,
  QuickActionFAB,
} from '../../components-global';
import { useRoute } from '../../navigation/router';
import { MobileNavigation } from '../../components-global/MobileNavigation';
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import Table from '../../components-global/Tables/Table';
import {
  selectLocalViewState,
  selectQueryString,
  setGlobalSearch,
  setPage,
  toggleCategoryFilter,
  ToggleCategoryFilterPayload,
} from '../../services/TableSlice';
import { FileIcon } from '../../components-ui/Icons';
//@ts-ignore
import { useNavigation } from '../../navigation/router';
import { openFileManagerTab } from '../../helpers/openFileManagerTab';
import { showModal as realShow } from '../../services/FormSlice';

const categoryFilters = [
  'Scenario',
  'Prospect',
  'Application',
  'Closed Loan',
  'Adversed',
  'All Loans',
];
let timeout: any = null;
export function PipelinePage() {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const handleSearch = (value: string) => {
    setSearchTerm(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setPage({ objectName: 'Loan', page: 1 }));
      dispatch(setGlobalSearch({ objectName: 'Loan', globalSearch: value }));
    }, 500);
  };
  const navigation = useNavigation();
  const route = useRoute();
  const localViewState = useSelector((state) =>
    selectLocalViewState(state, 'Loan')
  );
  const queryString = useSelector((state) => selectQueryString(state, 'Loan'));
  const { activeView: savedView } = localViewState;
  const selectedCategory = savedView?.selectedCategory || 0;
  const { data: loans } = useGetLoansState(queryString);
  const showModal = (payload) => dispatch(realShow(payload));
  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      route.setOptions({ headerShown: false });
    }

  }, [route]);

  function handlePhaseFilter(phase: number) {
    const payload: ToggleCategoryFilterPayload = {
      objectName: 'Loan',
      selectedCategory: phase,
    };
    if (phase !== 5) {
      payload.filter = {
        column: 'phase',
        operator: 'eq',
        value: categoryFilters[phase],
      };
    }
    dispatch(toggleCategoryFilter(payload));
  }


  return (
    <Layout style={{ flex: 1 }}>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 24,
              marginBottom: 24,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text category="h1">Pipeline</Text>
              <ButtonGroup style={{ marginLeft: 24 }} status="basic">
                <Button
                  style={selectedCategory === 0 && styles.selected}
                  onPress={() => handlePhaseFilter(0)}
                >
                  Scenario
                </Button>
                <Button
                  style={selectedCategory === 1 && styles.selected}
                  onPress={() => handlePhaseFilter(1)}
                >
                  Prospects
                </Button>
                <Button
                  style={selectedCategory === 2 && styles.selected}
                  onPress={() => handlePhaseFilter(2)}
                >
                  Applications
                </Button>
                <Button
                  style={selectedCategory === 3 && styles.selected}
                  onPress={() => handlePhaseFilter(3)}
                >
                  Closed Loans
                </Button>
                <Button
                  style={selectedCategory === 4 && styles.selected}
                  onPress={() => handlePhaseFilter(4)}
                >
                  Adversed
                </Button>

                <Button
                  style={selectedCategory === 5 && styles.selected}
                  onPress={() => handlePhaseFilter(5)}
                >
                  All Loans
                </Button>
              </ButtonGroup>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Button
                style={{
                  marginRight: 16, //@ts-ignore
                  textDecorationLine: 'underline',
                  textDecorationColor: 'blue'
                }}
                status="info"
                appearance="ghost"
                onPress={() =>
                  navigation.navigate(routes.ExtraRoutes.CommissionApprovals)
                }
              >
                Commission
              </Button>
              <Button
                status="danger"
                appearance="ghost"
                style={{ backgroundColor: '#ffefef' }}
                onPress={() => {
                  showModal({
                    formType: 'createScenario',
                    data: { type: 'Contact', create: true },
                  });
                }}
                accessoryLeft={FileIcon}
              >
                Create Scenario
              </Button>
            </View>
          </View>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{
              flex: 1,
            }}
          >
            <ErrorBoundary>
              <Table dataHandler={useLazyGetLoansQuery} objectName="Loan" />
            </ErrorBoundary>
          </ScrollView>
        </View>
      ) : (
        <>
          <MobileNavigation
            title="Pipeline"
            searchTerm={searchTerm}
            setSearchTerm={handleSearch}
            totalItems={loans ? loans['hydra:totalItems'] : 0}
          />
          <View style={{ flex: 1 }}>
            <PipelineMobilePills
              toggleFilter={selectedCategory}
              setToggleFilter={(nextValue: number) =>
                handlePhaseFilter(nextValue)
              }
            />
            <ErrorBoundary>
              <Table dataHandler={useLazyGetLoansQuery} objectName="Loan" />
            </ErrorBoundary>
          </View>
        </>
      )}
      {Platform.OS !== 'web' && (
        <QuickActionFAB
          onlyShow={['createLoan', 'logCall', 'logMeeting', 'createScenario']}
        />
      )}
    </Layout>
  );
}

const renderRow = ({ item }) => (
  <Link route={routes.ExtraRoutes.Loan} routeParams={{ id: item.id }}>
    <View
      style={{
        padding: 4,
        width: Dimensions.get('screen').width - 70 || '100%',
      }}
    >
      <Text category="label" style={{ fontSize: 16, fontFamily: 'VerbBold' }}>
        {item.loanContact?.firstName || ''} {item.loanContact?.lastName || ''}
        {!item.loanContact.firstName && !item.loanContact.lastName
          ? 'No Contact'
          : ''}
      </Text>
      {Boolean(item.propertyAddress) && (
        <Text category="p1">{item.propertyAddress}</Text>
      )}
      <Text category="p2" style={{ fontStyle: 'italic' }}>
        {item.loanNumber ? `#${item.loanNumber}  ` : null}{' '}
        {item.loanAmount ? currencyFormat(item.loanAmount) : ''}
      </Text>
      <Layout
        level="3"
        style={{
          position: 'absolute',
          right: 0,
          padding: 4,
          borderRadius: 4,
          top: '50%',
          transform: [{ translateY: -5 }],
        }}
      >
        <Text category="label" style={{ fontSize: 10 }}>
          {item.stage.includes('post application')
            ? 'Post Application'
            : item.stage}
        </Text>
      </Layout>
    </View>
  </Link>
);

const styles = StyleSheet.create({
  selected: {
    backgroundColor: '#c8def5',
  },
  centeredView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
  },
});
