import React, { useEffect } from 'react';
import { AuthManager } from './AuthManager';

const useAuth = () => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            idToken: action.idToken,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignOut: false,
            idToken: action.idToken,
            isAuthenticated: action.idToken !== null,
            error: action.error,
            isLoading: false,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignOut: true,
            idToken: null,
            isAuthenticated: false,
            isLoading: false,
          };
      }
    },
    {
      isLoading: true,
      isSignOut: false,
      idToken: null,
      isAuthenticated: false,
      error: null,
    },
  );

  useEffect(() => {
    const bootstrapAsync = () => {
      const manager = new AuthManager();
      manager.getAccessTokenAsync().then((res) => {
        const { idToken } = res;
        if (!idToken) {
          dispatch({ type: 'SIGN_OUT' });
          // signIn();
        } else {
          dispatch({ type: 'SIGN_IN', idToken });
        }
      }).catch(() => {
        dispatch({ type: 'SIGN_OUT' });
      });
    };
    bootstrapAsync();
  }, []);

  const setIdToken = async (idToken) => {
    dispatch({ type: 'SIGN_IN', idToken });
  };

  const signOut = async () => {
    const manager = new AuthManager();
    await manager.signOutAsync();
    dispatch({ type: 'SIGN_OUT' });
  };

  return {
    idToken: state.idToken,
    isAuthenticated: state.isAuthenticated === true,
    setIdToken,
    signOut,
    error: state.error,
    isLoading: state.isLoading,
  };
};

export default useAuth;
