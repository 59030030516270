import {
  IconElement,
  Layout,
  List,
  Radio,
  RangeCalendar, useTheme, Icon, ListItem, Text,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';
import {
  addDays,
  format,
  formatRelative,
  isAfter,
  parseISO,
  startOfDay,
  sub,
  endOfDay,
} from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import routes from '../../navigation/routes';
import { useGetTasksQuery } from '../../services/TaskApiSlice';
import Accordion from '../../components-ui/Accordion';
import { CalendarRange, IListItem, MenuItem } from '../../interfaces/TaskView';
import {
  COLORMAPPINGPPILL,
  DATEMAPPING,
  DAYAFTERTOMORROW,
  ENDOFNEXTWEEK,
  LASTWEEK,
  SIXMONTHSPAST,
  STARTOFNEXTWEEK,
  TODAY,
  TOMORROW,
  NOW,
} from './taskViewConsts';
import { showModal as realShow } from '../../services/FormSlice';
import { useNavigation } from '../../navigation/router';

function TaskListView({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (val: string) => void;
}) {
  const [selectedList, setSelectedList] = useState('today');
  const [range, setRange] = React.useState<CalendarRange>({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  });

  const [todayTaskData, setTodayTaskData] = useState<MenuItem>({
    name: 'today',
    title: "Today's Tasks",
    icon: 'clipboard-outline',
    onPress: () => setSelectedList('today'),
    count: 0,
    data: [],
    page: 1,
  });
  const [tomorrowTaskData, setTomorrowTaskData] = useState<MenuItem>({
    name: 'tomorrow',
    title: "Tomorrow's Tasks",
    icon: 'calendar-outline',
    onPress: () => setSelectedList('tomorrow'),
    count: 0,
    data: [],
    page: 1,
  });
  const [nextWeekTaskData, setNextWeekTaskData] = useState<MenuItem>({
    name: 'next week',
    title: "Next Week's Tasks",
    icon: 'calendar-outline',
    onPress: () => setSelectedList('next week'),
    count: 0,
    data: [],
    page: 1,
  });
  const [pastWeekTaskData, setPastWeekTaskData] = useState<MenuItem>({
    name: 'past week',
    title: "Past Week's Tasks",
    icon: 'calendar-outline',
    onPress: () => setSelectedList('past week'),
    count: 0,
    data: [],
    page: 1,
  });

  const [overdueTaskData, setOverdueTaskData] = useState<MenuItem>({
    name: 'overdue',
    title: 'Overdue Tasks',
    icon: 'alert-circle-outline',
    onPress: () => {
      setRange({
        startDate: null,
        endDate: null,
      });
      setSelectedList('overdue');
    },
    count: 0,
    data: [],
    page: 1,
  });

  const [filteredTaskData, setFiltereTaskData] = useState<MenuItem>({
    name: 'filtered',
    title: 'Filtered Tasks',
    icon: 'alert-circle-outline',
    onPress: () => setSelectedList('filtered'),
    count: 0,
    data: [],
    page: 1,
  });

  const { data: pastWeekTasks, isFetching: fetchingPastWeek } = useGetTasksQuery(
    `pagination=false&page=${pastWeekTaskData.page}&dueDate[before]=${TODAY}&dueDate[after]=${LASTWEEK}`,
  );

  const { data: filteredTasks, isFetching: fetchingFilteredTasks } = useGetTasksQuery(
    searchValue && searchValue !== ''
      ? `pagination=false&page=${filteredTaskData.page}&search=${searchValue}`
      : selectedList === 'filtered' && range.startDate && range.endDate
        ? `pagination=false&page=${
          filteredTaskData.page
        }&dueDate[before]=${moment(range.endDate)
          .add(1, 'day')
          .toISOString()}&dueDate[after]=${moment(
          range.startDate,
        ).toISOString()}`
        : skipToken,
  );

  const { data: todaysTasks, isFetching: fetchingToday } = useGetTasksQuery(
    `pagination=false&page=${todayTaskData.page}&dueDate[before]=${TOMORROW}&dueDate[after]=${TODAY}`,
  );
  const { data: tomorrowsTasks, isFetching: fetchingTomorrow } = useGetTasksQuery(
    `pagination=false&page=${tomorrowTaskData.page}&dueDate[after]=${TOMORROW}&dueDate[before]=${DAYAFTERTOMORROW}`,
  );
  // next week
  const { data: nextWeeksTasks, isFetching: fetchingNextWeek } = useGetTasksQuery(
    `pagination=false&page=${nextWeekTaskData.page}&dueDate[before]=${ENDOFNEXTWEEK}&dueDate[after]=${STARTOFNEXTWEEK}`,
  );

  const { data: overdueTasks, isFetching: fetchingOverdue } = useGetTasksQuery(
    `pagination=false&page=${overdueTaskData.page}&isCompleted=false&dueDate[after]=${SIXMONTHSPAST}&dueDate[before]=${NOW}`,
  );

  useEffect(() => {
    if (pastWeekTasks) {
      setPastWeekTaskData({
        ...pastWeekTaskData,
        count: pastWeekTasks['hydra:totalItems'],
        data: pastWeekTasks['hydra:member'],
      });
    }
  }, [pastWeekTasks]);

  useEffect(() => {
    if (overdueTasks) {
      setOverdueTaskData({
        ...overdueTaskData,
        count: overdueTasks['hydra:totalItems'],
        data: overdueTasks['hydra:member'],
      });
    }
  }, [overdueTasks]);

  useEffect(() => {
    if (todaysTasks) {
      setTodayTaskData({
        ...todayTaskData,
        count: todaysTasks['hydra:totalItems'],
        data: todaysTasks['hydra:member'],
      });
    }
  }, [todaysTasks]);

  useEffect(() => {
    if (tomorrowsTasks) {
      setTomorrowTaskData({
        ...tomorrowTaskData,
        count: tomorrowsTasks['hydra:totalItems'],
        data: tomorrowsTasks['hydra:member'],
      });
    }
  }, [tomorrowsTasks]);

  useEffect(() => {
    if (nextWeeksTasks) {
      setNextWeekTaskData({
        ...nextWeekTaskData,
        count: nextWeeksTasks['hydra:totalItems'],
        data: nextWeeksTasks['hydra:member'],
      });
    }
  }, [nextWeeksTasks]);

  useEffect(() => {
    if (filteredTasks) {
      setFiltereTaskData({
        ...filteredTaskData,
        count: filteredTasks['hydra:totalItems'],
        data: filteredTasks['hydra:member'],
      });
    }
  }, [filteredTasks]);

  const handleNextPage = () => {
    if (
      selectedList === 'today'
      && todayTaskData.count > todayTaskData.data.length
    ) {
      setTodayTaskData({
        ...todayTaskData,
        page: todayTaskData.page + 1,
      });
    }
    if (
      selectedList === 'tomorrow'
      && tomorrowTaskData.count > tomorrowTaskData.data.length
    ) {
      setTomorrowTaskData({
        ...tomorrowTaskData,
        page: tomorrowTaskData.page + 1,
      });
    }
    if (
      selectedList === 'next week'
      && nextWeekTaskData.count > nextWeekTaskData.data.length
    ) {
      setNextWeekTaskData({
        ...nextWeekTaskData,
        page: nextWeekTaskData.page + 1,
      });
    }
    if (
      selectedList === 'past week'
      && pastWeekTaskData.count > pastWeekTaskData.data.length
    ) {
      setPastWeekTaskData({
        ...pastWeekTaskData,
        page: pastWeekTaskData.page + 1,
      });
    }
    if (
      selectedList === 'overdue'
      && overdueTaskData.count > overdueTaskData.data.length
    ) {
      setOverdueTaskData({
        ...overdueTaskData,
        page: overdueTaskData.page + 1,
      });
    }
  };

  useEffect(() => {
    if (DATEMAPPING[selectedList]) {
      setRange({
        startDate: DATEMAPPING[selectedList].startDate,
        endDate: DATEMAPPING[selectedList].endDate,
      });
    }
  }, [selectedList]);

  useEffect(() => {
    // check if startDate and endDate match DATEMAPPING and switch selected List
    if (range.startDate && range.endDate) {
      if (searchValue && searchValue !== '') {
        setSearchValue('');
      }
      const selectedList = Object.keys(DATEMAPPING).find((key) => (
        format(DATEMAPPING[key].startDate, 'MM dd yy')
            === format(range.startDate, 'MM dd yy')
          && format(DATEMAPPING[key].endDate, 'MM dd yy')
            === format(range.endDate, 'MM dd yy')
      ));
      if (selectedList) {
        setSelectedList(selectedList);
      } else {
        setSelectedList('filtered');
      }
    }
  }, [range]);

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      setSelectedList('filtered');
      setRange({
        startDate: null,
        endDate: null,
      });
    } else {
      setSelectedList('today');
    }
  }, [searchValue]);

  const data = [
    todayTaskData,
    tomorrowTaskData,
    nextWeekTaskData,
    pastWeekTaskData,
    overdueTaskData,
    filteredTaskData,
  ].find((item) => item.name === selectedList);

  const completedTasks = data.data.filter((item) => item.isCompleted);
  const uncompletedTasks = data.data.filter((item) => !item.isCompleted);

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={{ flex: 1, minWidth: 390 }}>
        <View>
          <List
            style={{ backgroundColor: '#fff' }}
            data={[
              todayTaskData,
              tomorrowTaskData,
              nextWeekTaskData,
              pastWeekTaskData,
              overdueTaskData,
            ]}
            renderItem={(props) => (
              <RenderMenuItem {...props} selectedList={selectedList} />
            )}
          />
        </View>
        <View
          style={{
            borderBottomColor: '#eee',
            borderBottomWidth: 1,
            marginBottom: 16,
          }}
        />
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 16,
          }}
        >
          <Text style={{ fontSize: 12, marginBottom: 4 }}>
            Select a Start and End Date to filter by date
          </Text>
          <RangeCalendar
            range={range}
            onSelect={(nextRange) => setRange(nextRange)}
            min={sub(new Date(), { years: 3 })}
            max={addDays(new Date(), 365)}
          />
        </View>
      </View>
      <Layout level="2" style={{ flex: 4, marginBottom: 8 }}>
        {fetchingFilteredTasks
        || fetchingNextWeek
        || fetchingOverdue
        || fetchingPastWeek
        || fetchingToday
        || fetchingTomorrow ? (
          <ActivityIndicator size="large" style={{ paddingVertical: 16 }} />
          ) : (
            <ScrollView contentContainerStyle={{ flex: 1 }}>
              <List
                data={uncompletedTasks}
                style={{ backgroundColor: '#fafafa' }}
                renderItem={(props) => <RenderTaskItem {...props} />}
                onEndReached={() => handleNextPage()}
                onEndReachedThreshold={0.2}
                contentContainerStyle={{ paddingBottom: 24 }}
              />
              {completedTasks?.length ? (
                <Accordion title={`Completed (${completedTasks.length})`}>
                  <List
                    data={completedTasks}
                    renderItem={(props) => <RenderTaskItem {...props} />}
                    onEndReached={() => handleNextPage()}
                    onEndReachedThreshold={0.2}
                  />
                </Accordion>
              ) : null}
            </ScrollView>
          )}
      </Layout>
    </View>
  );
}

function RenderMenuItem({
  item,
  index,
  selectedList,
}: {
  item: MenuItem;
  index: number;
  selectedList: string;
}) {
  const renderItemAccessory = (): React.ReactElement => (
    <Text style={{ paddingRight: 16 }} category="label">
      {item.count}
    </Text>
  );

  const renderItemIcon = (props): IconElement => (
    <Icon {...props} name={item.icon} />
  );
  return (
    <ListItem
      title={`${item.title}`}
      accessoryLeft={renderItemIcon}
      accessoryRight={renderItemAccessory}
      style={{ backgroundColor: item.name === selectedList ? '#eee' : '#fff' }}
      onPress={item.onPress}
    />
  );
}

function RenderTaskItem({
  item,
  index,
}: {
  item: IListItem;
  index: number;
}) {
  const [hovered, setHovered] = React.useState(false);
  const { navigate } = useNavigation();
  const theme = useTheme();

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const isOverdue = isAfter(new Date(), parseISO(item.dueDate));

  const onPress = () => {
    const linkType = item.loan || item.contact || item.lead;
    let peices;
    let id;
    let type;
    if (linkType) {
      peices = linkType.split('/');
      id = peices[peices.length - 1];
      type = peices[peices.length - 2].slice(0, -1).charAt(0).toUpperCase()
        + peices[peices.length - 2].slice(0, -1).slice(1);
    }
    if (id && type) {
      navigate(routes.ExtraRoutes[type], { id });
    }
  };

  // outcome, resolution/ referralCount
  const renderItemIcon = (props): IconElement => (
    <View style={{ paddingHorizontal: 8 }}>
      <Radio
        checked={!!item.isCompleted}
        onChange={(nextChecked) => {
          if (!item.isCompleted) {
            showModal({ formType: 'completeTask', data: item });
          }
        }}
      />
    </View>
  );

  return (
    <ListItem
      title={() => (
        <View
          style={{
            flexDirection: 'row',
            paddingHorizontal: 8,
            paddingVertical: 4,
            alignItems: 'center',
          }}
        >
          <Text
            style={[
              { fontSize: 14 },
              item.isCompleted && {
                textDecorationLine: 'line-through',
                textDecorationStyle: 'solid',
              },
            ]}
          >
            {item.associatedName}
          </Text>
        </View>
      )}
      description={() => (
        <View style={{ paddingHorizontal: 8, paddingVertical: 4 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {item.isCompleted ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ fontSize: 12 }}>
                  {item.outcome}
                  {item.outcome === 'Referrals' || item.outcome === 'Prospect'
                    ? `(${item.referralCount})`
                    : ''}
                </Text>
                {item.resolution ? (
                  <>
                    {' '}
                    <Text
                      style={{
                        fontSize: 3,
                        marginHorizontal: 6,
                        color: '#605e5c',
                      }}
                    >
                      {'\u2B24'}
                    </Text>
                    <Text style={{ fontSize: 12 }}>
                      {item.resolution.replace(/\n/g, ', ')}
                    </Text>
                  </>
                ) : null}
              </View>
            ) : (
              <>
                <Text style={{ fontSize: 12, color: '#605e5c' }}>
                  {item?.description?.replace(/\n/g, ', ')
                      || 'No Description'}
                </Text>
                {item.dueDate ? (
                  <>
                    <Text
                      style={{
                        fontSize: 3,
                        marginHorizontal: 6,
                        color: '#605e5c',
                      }}
                    >
                      {'\u2B24'}
                    </Text>
                    <Icon
                      style={{
                        width: 14,
                        height: 14,
                        marginRight: 4,
                        marginTop: -2,
                      }}
                      fill={isOverdue ? theme['color-danger-600'] : '#605e5c'}
                      name="calendar"
                    />
                    <Text
                      style={{
                        color: isOverdue ? 'red' : '#605e5c',
                        fontSize: 12,
                        textTransform: 'capitalize',
                        // @ts-ignore
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.dueDate
                        ? formatRelative(parseISO(item.dueDate), new Date())
                        : ''}
                    </Text>
                    {item.priority ? (
                      <>
                        <Text
                          style={{
                            fontSize: 3,
                            marginHorizontal: 6,
                            color: '#605e5c',
                          }}
                        >
                          {'\u2B24'}
                        </Text>
                        <Text
                          style={{
                            color: COLORMAPPINGPPILL[item.priority],
                            fontSize: 12,
                          }}
                        >
                          {item.priority}
                        </Text>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </View>
        </View>
      )}
      accessoryLeft={renderItemIcon}
      // accessoryRight={renderItemAccessory}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onPress={onPress}
      style={{
        borderColor: '#eee',
        borderRadius: 6,
        borderWidth: 1,
        marginTop: 12,
        marginHorizontal: 12,
        backgroundColor: hovered
          ? theme['background-basic-color-2']
          : theme['background-basic-color-1'],
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 3,
      }}
    />
  );
}

export default TaskListView;
