import { Dimensions, StyleSheet, View } from 'react-native';
import {
  Button,
  Divider,
  Icon,
  IconElement,
  IndexPath,
  Input,
  useTheme,
  Text,
  Layout,
} from '@ui-kitten/components';
import React, { useEffect } from 'react';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import { useGetTiersQuery } from '../../../services/TierSlice';
import { useGetHomeDataQuery } from '../../../services/HomeSlice';
import {
  useGetMeQuery,
  usePatchUserMutation,
} from '../../../services/UserSlice';
import MaskedInput from '../../../components-ui/MaskedInput';
import Footer from '../Footer';
import Header from '../Header';
import Colors from '../../../helpers/Colors';

function LockIcon(props): IconElement {
  return <Icon {...props} name="lock-outline" />;
}

function LockButton({ setDisabled, disabled }) {
  return (
    <Button
      status="basic"
      accessoryLeft={LockIcon}
      appearance="ghost"
      size="small"
      onPress={() => setDisabled(!disabled)}
    />
  );
}

function LabelView({ children }) {
  const theme = useTheme();
  return (
    <View
      style={{
        paddingVertical: 12,
        paddingHorizontal: 16,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme['color-warning-300'],
        backgroundColor: theme['color-warning-100'],
      }}
    >
      {children}
    </View>
  );
}

export default function GoalEditor({ cancelAction }): JSX.Element {
  const windowWidth = Dimensions.get('window').width;
  const [selectedTier, setSelectedTier] = React.useState<IndexPath>(
    new IndexPath(0)
  );
  const { data: tiers, isSuccess: tiersLoaded } = useGetTiersQuery({});
  const { data: homeData } = useGetHomeDataQuery({});
  const [averageLoanAmount, setAverageLoanAmount] = React.useState<number>();
  const [loanAmountDisabled, setLoanAmountDisabled] =
    React.useState<boolean>(true);
  const [pullThruDisabled, setPullThruDisabled] = React.useState<boolean>(true);
  const [pullThru, setPullThru] = React.useState<string>();
  const { data: user } = useGetMeQuery({});
  const [incomeGoal, setIncomeGoal] = React.useState<number | null>(0);
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);
  const [patchUser, { isSuccess: successPatchingUser }] =
    usePatchUserMutation();
  const theme = useTheme();
  let sorted = [];

  if (tiersLoaded) {
    sorted = [...tiers['hydra:member']];
    sorted.sort((a, b) => b.tier - a.tier);
  }

  useEffect(() => {
    if (successPatchingUser) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [successPatchingUser]);

  // calculated fields
  const usableAverageLoanAmount =
    averageLoanAmount || homeData?.averageLoanAmount || 0;
  const usableStringPullThru = (
    pullThruDisabled
      ? (homeData?.pullThru || homeData?.company?.pullThru || 0) * 100
      : pullThru || 0
  ).toString();
  const usablePullThru = parseFloat(usableStringPullThru);
  const options = (tiersLoaded &&
    tiers['hydra:member'].map(
      (tier) => `Tier ${tier.tier} (${tier.percentage})`
    )) || [''];
  const assumedTier =
    tiersLoaded && sorted.find((tier) => tier.year < incomeGoal);
  const applicationGoal = Math.round(
    incomeGoal /
      (usableAverageLoanAmount * ((assumedTier?.rate || 0.01) / 100)) /
      usablePullThru
  );
  const closingGoal = Math.round(applicationGoal * (usablePullThru / 100));
  const volumeQtr: number =
    (tiersLoaded && tiers['hydra:member'][selectedTier.row]?.min?.toFixed(2)) ||
    0;
  const appsNeeded: number = Math.ceil(
    (volumeQtr / usableAverageLoanAmount) * (usablePullThru / 100)
  );

  useEffect(() => {
    if (user) {
      setIncomeGoal(user.incomeGoal || 100000);
      setSelectedTier(
        new IndexPath(
          tiersLoaded &&
            tiers['hydra:member'].findIndex(
              (tier) => tier.tier === user.currentTier.tier
            )
        )
      );
    }
  }, [user, tiersLoaded]);

  const handleSubmit = () => {
    setFormSubmitted(true);
    patchUser({
      id: user.id,
      incomeGoal,
      applicationGoal,
      closingGoal,
    });
  };

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <View>
        <Header heading="Edit Income Goal" />
        <View style={{ flexDirection: 'column', flex: 1, minHeight: 350 }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="Average Loan Amount" required={false}>
                <MaskedInput
                  value={usableAverageLoanAmount}
                  disabled={loanAmountDisabled}
                  onChangeText={(nextValue) =>
                    setAverageLoanAmount(parseInt(nextValue, 10))
                  }
                  mask="currency"
                  accessoryLeft={
                    <LockButton
                      disabled={loanAmountDisabled}
                      setDisabled={setLoanAmountDisabled}
                    />
                  }
                />
              </FormGroup>
            </View>
            <View style={{ flex: 1 }}>
              <FormGroup label="Pull-Thru" required={false}>
                <Input
                  value={usableStringPullThru}
                  disabled={pullThruDisabled}
                  onChangeText={(nextValue) => setPullThru(nextValue)}
                  accessoryLeft={
                    <LockButton
                      disabled={pullThruDisabled}
                      setDisabled={setPullThruDisabled}
                    />
                  }
                />
              </FormGroup>
            </View>
          </View>
          <Divider />
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                flex: 1,
                alignSelf: 'flex-start',
                paddingTop: 2,
                flexDirection: 'column',
              }}
            >
              <FormGroup label="Income Goal (USD)" required={false}>
                <MaskedInput
                  mask="currency"
                  value={incomeGoal?.toString()}
                  onChangeText={(nextValue) =>
                    setIncomeGoal(parseInt(nextValue, 10))
                  }
                />
              </FormGroup>
              <FormGroup label="Assumed Tier" required={false}>
                <LabelView>
                  <Text style={{ fontFamily: 'VerbBold' }}>
                    {assumedTier?.tier || 1}
                  </Text>
                </LabelView>
              </FormGroup>
              <FormGroup
                label="Applications needed to achieve goal (Units)"
                required={false}
              >
                <LabelView>
                  <Text style={{ fontFamily: 'VerbBold' }}>
                    {applicationGoal.toString()}
                  </Text>
                </LabelView>
              </FormGroup>
              <FormGroup
                label="Closings needed to achieve goal (Units)"
                required={false}
              >
                <LabelView>
                  <Text style={{ fontFamily: 'VerbBold' }}>
                    {closingGoal.toString()}
                  </Text>
                </LabelView>
              </FormGroup>
            </View>
            <View
              style={{
                width: 1,
                backgroundColor: theme['border-basic-color-3'],
              }}
            />
            <View style={{ flex: 1, alignSelf: 'flex-start' }}>
              <View>
                <FormGroup label="Tier" required={false}>
                  <KittenSelect
                    selectedIndex={selectedTier}
                    setSelectedIndex={setSelectedTier}
                    options={options}
                  />
                </FormGroup>
              </View>
              <View>
                <FormGroup label="Volume (QTR)" required={false}>
                  <LabelView>
                    <Text style={{ fontFamily: 'VerbBold' }}>
                      {volumeQtr?.toString()}
                    </Text>
                  </LabelView>
                </FormGroup>
              </View>
              <View>
                <FormGroup label="Apps Needed (QTR)" required={false}>
                  <LabelView>
                    <Text style={{ fontFamily: 'VerbBold' }}>
                      {appsNeeded.toString()}
                    </Text>
                  </LabelView>
                </FormGroup>
              </View>
            </View>
          </View>
        </View>
      </View>
      {!!successPatchingUser && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText="SAVE"
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});
