import { apiSlice } from './ApiSlice';

export const TlcInviteSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTlcClients: build.query({
      query: (query) => ({
        url: '/tlc_clients?' + query || '',
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'TlcClients', id: 'PARTIAL-LIST' }],
    }),
    getTlcClient: build.query({
      query: ({id = null}) => ({
        url: `/tlc_clients/${id}`,
        method: 'GET',
      })
    }),
    getMultiClientData: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const promiseArr = [];
        // return {data: {}}
        for (let i = 0; i < _arg.length; i++) {
          promiseArr.push(
            fetchWithBQ({
              url: `/tlc_clients/${_arg[i]}`,
              method: 'get',
            })
          );
        }
        const result = await Promise.all(promiseArr);
        const data = result.reduce((acc, clientData) =>{
          if(clientData.data){
            acc.push(clientData.data)
          }
          return acc
        },[])

        return { data };
      },
    }),
    addNewTlcClient: build.mutation({
      query: (body) => ({
        url: '/tlc_clients',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'TlcClients', id: 'PARTIAL-LIST' },
      ],
    }),
    updateTlcClient: build.mutation({
      query: ({id, ...put}) => ({
        url: `/tlc_clients/${id}`,
        method: 'put',
        body: put,
      }),
      invalidatesTags: ({id}) => [
        { type: 'TlcClients', id },
        {type: 'TlcClients', id: 'PARTIAL-LIST'},
        {type: 'TlcAgents', id: 'PARTIAL-LIST'}
      ],
    }),
    deleteTlcClient: build.mutation({
      query: (id) => ({
        url: `/tlc_clients/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTlcClientsQuery,
  useGetTlcClientQuery,
  useAddNewTlcClientMutation,
  useUpdateTlcClientMutation,
  useDeleteTlcClientMutation,
  useGetMultiClientDataQuery,
} = TlcInviteSlice;
