import { apiSlice } from './ApiSlice';

export const BorrowerPairsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllBorrowerPairs: build.query({
      query: ({ loanId }) => ({
        url: `/loans/${loanId}/borrower-pairs`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'BorrowerPairs', id: 'PARTIAL-LIST' }],
    }),
    getBorrowerPair: build.query({
      query: ({ loanId, borrowerPairId }) => ({
        url: `/loans/${loanId}/borrower-pairs/${borrowerPairId}`,
        method: 'GET',
      }),
    }),
    createBorrowerPair: build.mutation({
      query: ({ loanId, borrowerPair }) => ({
        url: `/loans/${loanId}/borrower-pairs`,
        method: 'POST',
        body: borrowerPair, //{ borrower, coBorrower} IRI
      }),
      invalidatesTags: (result, error, { borrowerPairId, loanId }) => {
        return [
          { type: 'BorrowerPairs', id: borrowerPairId },
          { type: 'BorrowerPairs', id: 'PARTIAL-LIST' },
          { type: 'Loans', id: loanId}
        ];
      },
    }),
    patchBorrowerPair: build.mutation({
      query: ({ loanId, borrowerPairId, borrowerPair }) => ({
        url: `/loans/${loanId}/borrower-pairs/${borrowerPairId}`,
        method: 'PATCH',
        body: borrowerPair, //{ borrower, coBorrower} IRI
      }),
      invalidatesTags: (result, error, { loanId, borrowerPairId }) => {
        return [{ type: 'BorrowerPairs', id: borrowerPairId }, { type: 'Loans', id: loanId}];
      },
    }),
    deleteBorrowerPair: build.mutation({
      query: ({ loanId, borrowerPairId }) => ({
        url: `/loans/${loanId}/borrower-pairs/${borrowerPairId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { loanId, borrowerPairId }) => {
        return [
          { type: 'BorrowerPairs', id: borrowerPairId },
          { type: 'BorrowerPairs', id: 'PARTIAL-LIST' },
          { type: 'Loans', id: loanId}
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBorrowerPairsQuery,
  useGetBorrowerPairQuery,
  useCreateBorrowerPairMutation,
  usePatchBorrowerPairMutation,
  useDeleteBorrowerPairMutation,
} = BorrowerPairsApiSlice;
