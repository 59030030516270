import { View, StyleSheet } from 'react-native';
import React from 'react';
import { NumberSection } from './NumberSection';

export default function GoalsSection({ user }) {
  return (
    <View style={styles.profileSocialsContainer}>
      <NumberSection
        hint="Income Goal"
        value={new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(user.incomeGoal)}
      />
      <NumberSection
        hint="Applications Goal"
        value={`${user.applicationGoal}`}
      />
      <NumberSection
        hint="Closings Goal"
        value={`${user.closingGoal}`}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  profileSocialsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 24,
    marginBottom: 8
  },
});
