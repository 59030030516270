import React, { useContext } from 'react';
import { View } from 'react-native';
import {
  Button,
  ButtonGroup,
  Divider,
  DrawerItem,
  Text,
} from '@ui-kitten/components';
import { ThemeContext } from '../../store/context/ThemeContext';

type Props = {};

function ThemeSwitcher({}: Props) {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <View>
      <Divider />
      <DrawerItem
        title={`Theme`}
        accessoryRight={() => (
          <>
            <Button
              onPress={() => toggleTheme('light')}
              appearance={theme === 'dark' ? 'outline' : 'filled'}
              size="tiny"
            >
              Light
            </Button>
            <Button
              onPress={() => toggleTheme('dark')}
              appearance={theme === 'light' ? 'outline' : 'filled'}
              size="tiny"
            >
              Dark
            </Button>
          </>
        )}
      />
    </View>
  );
}

export default ThemeSwitcher;
