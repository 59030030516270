import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import Colors from '../helpers/Colors';
import { Button } from '@ui-kitten/components';

export default function GoogleMap({ loanDetails }) {
  const [coordinates, setCoordinates] = useState();
  const { propertyAddress, propertyCity, propertyState, propertyZip } =
    loanDetails;

  useEffect(() => {
    (async () => {
      const longLat = await axios.get(
        `https://maps.google.com/maps/api/geocode/json?address=${propertyAddress}+${propertyCity}+${propertyZip}&key=AIzaSyBMWki9HE_RXNiCwV4ILWPpJJTUbgcR7Os`
      );
      if (longLat?.data?.results[0]?.geometry) {
        setCoordinates(longLat.data.results[0].geometry.location);
      }
    })();
  }, [loanDetails]);
  const defaultProps = {
    center: {
      lat: coordinates && coordinates.lat,
      lng: coordinates && coordinates.lng,
    },
    zoom: 13,
  };

  const Marker = (props) => (
    <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
      <Button
        icon={'map-marker'}
        color={Colors.primaryRed}
        size={30}
        style={{ padding: 0, margin: 0 }}
      />
    </div>
  );

  return coordinates ? (
    // Important! Always set the container height explicitly
    <div style={{ flex: 1 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBMWki9HE_RXNiCwV4ILWPpJJTUbgcR7Os' }}
        defaultCenter={defaultProps.center}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker {...defaultProps.center} />
      </GoogleMapReact>
    </div>
  ) : null;
}
