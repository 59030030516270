import {
  Icon,
  Layout,
  MenuItem,
  OverflowMenu,
  Text,
} from '@ui-kitten/components';
import { View, StyleSheet, Pressable } from 'react-native';
import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useDeleteNoteMutation } from '../../services/NoteApiSlice';
import { EditIcon } from '../Icons';
import { showModal as realShow } from '../../services/FormSlice';

export default function NoteCard({ activity }: any) {
  const [visible, setVisible] = useState(false);
  const [deleteNote, { isSuccess: deleteSuccess }] = useDeleteNoteMutation();
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));

  const handleDeleteNote = () => {
    deleteNote({ id: activity.id });
    setVisible(false);
  };

  const handleEditNote = () => {
    showModal({ formType: 'note', data: activity });
    setVisible(false);
  };

  const renderToggleButton = () => (
    <Pressable onPress={() => setVisible(true)}>
      <Icon
        name="more-horizontal-outline"
        fill="darkgray"
        style={{ width: 26, height: 26 }}
      />
    </Pressable>
  );

  function DangerIcon(props) {
    return <Icon {...props} fill="red" name="trash-2" />;
  }

  return (
    <Layout
      style={[
        styles.shadow,
        {
          padding: 16,
          borderWidth: 1,
          borderColor: '#d5d5d5',
          borderRadius: 4,
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={styles.headerWrap}>
          <Icon
            style={styles.icon}
            fill="rgb(101, 163, 216)"
            name="book-open-outline"
          />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: 12 }}>
              Note
            </Text>
          </View>
        </View>
        <View>
          <OverflowMenu
            visible={visible}
            anchor={renderToggleButton}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem
              accessoryLeft={EditIcon}
              title="Edit Note"
              onPress={handleEditNote}
            />
            <MenuItem
              accessoryLeft={DangerIcon}
              title="Delete Note"
              onPress={handleDeleteNote}
            />
          </OverflowMenu>
        </View>
      </View>
      {/* body */}
      <View style={{ paddingLeft: 8, paddingVertical: 8 }}>
        {Boolean(activity.title) && (
          <Text category="label" style={{ marginTop: 4 }}>
            {activity.title}
          </Text>
        )}
        {Boolean(activity.body) && (
          <Text category="p2" style={{ paddingVertical: 8 }}>
            {activity.body}
          </Text>
        )}
        <Text category="label" style={{ fontStyle: 'italic' }}>
          Note Created
          {' '}
          {moment(activity.createdAt).format('lll')}
        </Text>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  headerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerDate: {
    marginLeft: 4,
    fontSize: 14,
  },
  icon: {
    width: 22,
    height: 22,
    marginRight: 4,
  },
  label: {
    marginTop: 4,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 3,
    maxWidth: 84,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labeltext: {},
  shadow: {
    shadowColor: '#c3bcae',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3,
  },
});
