import React, { useState, useEffect } from 'react';
import { View, Linking, Platform } from 'react-native';
import moment from 'moment';
import { useGetLoansQuery } from '../../services/LoanApiSlice';
import { Text, Card, List, ListItem, Button } from '@ui-kitten/components';
import routes from '../../navigation/routes';
//@ts-ignore
import { PhoneIcon } from '../../components-ui/Icons';
//@ts-ignore
import { useNavigation } from '../../navigation/router';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import { GETLOANSQUERY } from '../../helpers/constants';
import { isAfter, isBefore, sub } from 'date-fns';

interface IListItem {
  name: string;
  closeDate: string;
  id: number;
}

export default function AnniversaryCard(props) {
  const navigate = useNavigation();
  const [allLoans, setAllLoans] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetchingAllContacts, setIsFetchingAllContacts] = useState(true);
  const [query, setQuery] = useState(
    `page=1&search=-${moment().format(
      'MM-DD'
    )}&closeDate[before]=${moment().subtract('1', 'year').format('YYYY-MM-DD')}`
  );
  const { data: loans, isFetching, isError } = useGetLoansQuery(query);
  // const { data: loansData, isSuccess: loansSuccess, isError, isFetching } =
  // useGetLoansQuery(GETLOANSQUERY);
  // const allLoans = !loansData
  //   ? []
  //   : loansData['hydra:member'].reduce((acc, loan) => {
  //       if (
  //         loan.purchasedDate &&
  //         loan.purchasedDate !== '' &&
  //         loan.closeStatus === 'Won' &&
  //         loan.closeDate &&
  //         loan.loanAmount &&
  //         isBefore(new Date(loan.closeDate), sub(new Date(), {years: 1})) &&
  //       ) {
  //         return acc + loan.loanAmount;
  //       }
  //       return acc;
  //     }, []);

  useEffect(() => {
    if (loans && loans['hydra:totalItems'] && !totalPages) {
      const allPages = Math.ceil(loans['hydra:totalItems'] / 30);
      setTotalPages(allPages);
    }

    if (loans && loans['hydra:member']) {
      const contactList = [];
      loans['hydra:member'].forEach((currContact) => {
        contactList.push({
          id: `${currContact.id}`,
          name: `${currContact?.loanContact?.firstName || ''} ${
            currContact?.loanContact?.lastName || ''
          }`,
          closeDate: currContact?.closeDate,
          mobilePhone: currContact?.loanContact?.mobilePhone,
        });
      });
      setAllLoans((prevState) => [...prevState, ...contactList]);
    }
  }, [loans]);

  useEffect(() => {
    if (currentPage >= 1 && !isFetching) {
      setQuery(
        `page=${currentPage}&search=-${moment().format(
          'MM-DD'
        )}&closeDate[before]=${moment()
          .subtract('1', 'year')
          .format('YYYY-MM-DD')}`
      );
    }
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, totalPages, isFetching]);

  useEffect(() => {
    if (loans && allLoans.length) {
      if (allLoans.length === loans['hydra:totalItems']) {
        setIsFetchingAllContacts(false);
      }
    }
  }, [allLoans, loans]);

  const renderItem = ({
    item,
    index,
  }: {
    item: IListItem;
    index: number;
  }): React.ReactElement => (
    <ListItem
      onPress={() =>
        navigate.navigate(
          routes.ExtraRoutes.Loan,
          {
            id: item.id,
          },
          { app: `${item['@type'] === 'LoanApplication'}` }
        )
      }
      title={`${item.name} ${index + 1}`}
      description={` ${moment().diff(moment(item.closeDate), 'years')} ${
        moment().diff(moment(item.closeDate), 'years') > 1 ? 'Years' : 'Year'
      }`}
      accessoryRight={({ style }) => (
        <RenderItemAccessory style={style} item={item} />
      )}
      style={{ borderTopWidth: 1, borderTopColor: '#e4e9f2', width: '100%' }}
      key={item.id}
    />
  );
  const RenderItemAccessory = ({ style, item }): React.ReactElement => {
    return (
      <Button
        appearance="ghost"
        onPress={(e) => {
          e.preventDefault();
          Linking.openURL(`tel:${item.mobilePhone}`);
        }}
        accessoryRight={PhoneIcon}
      />
    );
  };
  const from = (page - 1) * 5;
  const dataset = allLoans ? allLoans.slice(from, from + 5) : [];
  return isError ? (
    <View>
      <Text>Error loading anniversaries</Text>
    </View>
  ) : (
    <Card
      style={{
        marginTop: Platform.OS === 'web' ? 0 : 4,
        marginBottom: 120,
      }}
    >
      <View style={{ flex: 1, paddingVertical: 16 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 16,
          }}
        >
          <Text style={{ fontFamily: 'VerbBold', fontSize: 20, flex: 1 }}>
            Anniversaries
          </Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ marginRight: 16 }}
              disabled={page === 1}
              onPress={() => setPage(page - 1)}
              size="tiny"
            >
              Prev
            </Button>
            <Button
              disabled={dataset.length < 5}
              onPress={() => setPage(page + 1)}
              size="tiny"
            >
              Next
            </Button>
          </View>
        </View>
        {page && (
          dataset.map((item, index) => (
            renderItem({ item, index })
          ))
          // <List
          //   style={{ maxHeight: 750, minWidth: 250 }}
          //   data={dataset}
          //   renderItem={renderItem}
          //   nestedScrollEnabled={false}
          // />
        )}
        {isFetching ? (
          <View style={{ padding: 16 }}>
            <ActivityIndicatorComponent />
          </View>
        ) : null}
      </View>
    </Card>
  );
}
