import React, { createRef, useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  View,
  Platform,
  Keyboard,
  Pressable,
} from 'react-native';
import {
  Card,
  Select,
  IndexPath,
  Input,
  RadioGroup,
  Radio,
  Datepicker,
  NativeDateService,
  CheckBox,
  Text,
  Layout,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import { renderOption } from '../renderMethods';
import FormGroup from '../FormGroup';
import { generateTime } from '../../../helpers/generateTime';
import { FormSuccess, KittenSelect } from '../../../components-ui';

import GlobalSearchAutoComplete, {
  dataTransformer,//@ts-ignore - web and native
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import Colors from '../../../helpers/Colors';
import moment from 'moment';
import {
  useCreateTaskMutation,
  usePatchTaskMutation,
} from '../../../services/TaskApiSlice';
import { NOTIFICATIONTIME, TYPES } from '../../../helpers/constants';
import { useCreateBulkActionsMutation } from '../../../services/BulkApiSlice';
import RNTimePicker from '../../../components-ui/TimePicker/RNTimePicker';
const priorityArr = ['Low', 'Medium', 'High'];

function AddTaskForm({ cancelAction, defaultData }: any) {
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState<any>({
    type: new IndexPath(0),
    priority: null,
    dueDate: null,
    dueDateTime: moment().format('lll'),
    associateValue: '',
    description: '',
    notificationsEnabled: false,
  });
  const [
    patchTask,
    { isSuccess: patchSuccess, isError: patchHasError, error: patchError },
  ] = usePatchTaskMutation();

  const [notificationTimeIndex, setNotificationTimeIndex] = useState(
    new IndexPath(0)
  );

  const windowWidth = Dimensions.get('window').width;
  const displayValueTask = TYPES[state.type.row];
  const componentRef = createRef<Datepicker>();
  const [createTask, { isSuccess: createSuccess, data: createData }] =
    useCreateTaskMutation();

  const [createBulkActions, { isSuccess: bulkSuccess }] =
    useCreateBulkActionsMutation();

  // GlobalSearch Data Handler
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } =
    useGetLoansQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (patchHasError && patchError) {
      //@ts-ignore
      if (patchError?.data && patchError.data['hydra:description']) {
        //@ts-ignore
        setError(patchError?.data['hydra:description']);
      } else {
        setError('Something went wrong, please try again.');
      }
      setFormSubmitted(false);
    }
  }, [patchHasError, patchError]);

  useEffect(() => {
    if (createSuccess || patchSuccess || bulkSuccess) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [createSuccess, patchSuccess, bulkSuccess]);

  useEffect(() => {
    if (!isFetching) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  useEffect(() => {
    if (defaultData && !defaultData.length) {
      setState({
        type: new IndexPath(
          TYPES.indexOf(defaultData.type) >= 0
            ? TYPES.indexOf(defaultData.type)
            : 0
        ),
        priority: priorityArr.indexOf(defaultData.priority),
        dueDate: defaultData.dueDate ? new Date(defaultData.dueDate) : null,
        dueDateTime: defaultData.dueDate
          ? moment(defaultData.dueDate).format('lll')
          : moment().format('lll'),

        description: defaultData.description || '',
        notificationsEnabled: defaultData.notificationsEnabled || false,
      });
    }
  }, [defaultData]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      // Check for fields
      await checkForRequiredFields();

      const formattedDueDate = moment(state.dueDate).format('YYYY-DD-MM');

      const localDueDate = moment(
        formattedDueDate + ' ' + moment(state.dueDateTime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      let notificationFormattedTime: any = null;
      if (state.notificationsEnabled) {
        if (notificationTimeIndex.row === 0) {
          notificationFormattedTime = moment(localDueDate)
            .subtract('1', 'hour')
            .toISOString();
        }
        if (notificationTimeIndex.row === 1) {
          notificationFormattedTime = moment(localDueDate)
            .subtract('30', 'minute')
            .toISOString();
        }
        if (notificationTimeIndex.row === 2) {
          notificationFormattedTime = moment(localDueDate)
            .subtract('15', 'minute')
            .toISOString();
        }
      }

      const payload: any = {
        type: displayValueTask,
        subject: displayValueTask,
        description: state.description,
        dueDate: localDueDate,
        priority: ['Low', 'Medium', 'High'][state.priority],
        dueDateTime: localDueDate,
        notificationsEnabled: state.notificationsEnabled,
      };
      if (state.notificationsEnabled) {
        payload.reminderTime = '1';
      }
      if (notificationFormattedTime) {
        payload.notificationTime = notificationFormattedTime;
      }
      //check for defaultData.contactList
      if (defaultData?.contactList) {
        //bulk tasks
        const ids = [];
        defaultData.contactList.forEach((contact) => {
          ids.push(contact['@id']);
        });
        const bulkPayload = {
          ids: ids,
          uri: '/tasks',
          idField: defaultData.contactList[0]['@type'].toLowerCase(),
          body: payload,
        };
        createBulkActions(bulkPayload);
      } else {
        if (!defaultData?.lead && !defaultData?.contact && !defaultData?.loan) {
          if (selectedAssociate.type === 'Lead')
            payload.lead = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Loan')
            payload.loan = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Contact')
            payload.contact = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Partner')
            payload.partner = selectedAssociate['@id'];
        } else {
          if (defaultData.lead) {
            payload.lead = defaultData.lead;
          }
          if (defaultData.contact) {
            payload.contact = defaultData.contact;
          }
          if (defaultData.loan) {
            payload.loan = defaultData.loan;
          }
        }
        if (Boolean(!defaultData?.id)) {
          createTask(payload);
        } else {
          //edit task
          payload.id = defaultData.id;
          patchTask(payload);
        }
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  async function checkForRequiredFields() {
    if (!state.type || state.type.row === 0)
      throw new Error(
        'Missing task type, please make sure all required fields are filled'
      );

    if (state.priority === null)
      throw new Error(
        'Missing priority, please make sure all required fields are filled'
      );
    if (!state.dueDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.dueDateTime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!selectedAssociate)
      throw new Error('Please select someone to associate this task with');
  }

  const isCreate =
    !defaultData || !defaultData?.type || defaultData?.create === true;
  const showGlobalSearch =
    !defaultData ||
    (!defaultData.contactList &&
      !defaultData?.lead &&
      !defaultData?.loan &&
      !defaultData?.contact);

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={!isCreate ? 'Edit Task' : 'Create A Task'} />
      {defaultData?.contactList ? (
        <View style={{ marginVertical: 8 }}>
          <Text
            style={{ textAlign: 'center', fontStyle: 'italic' }}
            category="label"
          >
            Creating a task for {defaultData.contactList.length}{' '}
            {defaultData.contactList[0]['@id'].split('/')[1].slice(0, -1)}(s)
          </Text>
        </View>
      ) : null}
      {/* Task Type */}
      <FormGroup label="Select Task Type">
        <Select
          placeholder="Select Task Type"
          selectedIndex={state.type}
          onSelect={(index: any) =>
            setState((prevState) => ({ ...prevState, type: index }))
          }
          value={displayValueTask}
        >
          {TYPES.map(renderOption)}
        </Select>
      </FormGroup>
      {/* description */}
      <FormGroup label="Notes" required={false}>
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: 80 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      {/* Priority */}
      <FormGroup label="Priority">
        <RadioGroup
          style={{ flexDirection: 'row' }}
          selectedIndex={state.priority}
          onChange={(index) =>
            setState((prevState) => ({ ...prevState, priority: index }))
          }
        >
          <Radio style={{ marginRight: 8 }} status="primary">
            Low
          </Radio>
          <Radio style={{ marginRight: 8 }} status="success">
            Medium
          </Radio>
          <Radio status="danger">High</Radio>
        </RadioGroup>
      </FormGroup>
      {/* Due Date */}

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Due Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.dueDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({ ...prevState, dueDate: e }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select a time'}
                value={state.dueDateTime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    dueDateTime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1, paddingTop: 4 }}>
          <FormGroup required={false}>
            <CheckBox
              style={{ paddingTop: 8 }}
              checked={state.notificationsEnabled}
              onChange={(nextChecked) =>
                setState((prevState) => ({
                  ...prevState,
                  notificationsEnabled: nextChecked,
                }))
              }
            >
              {`Set Reminder`}
            </CheckBox>
          </FormGroup>
        </View>

        <View style={{ flex: 1 }}>
          {state.notificationsEnabled ? (
            <FormGroup label="Notification Time">
              <KittenSelect
                selectedIndex={notificationTimeIndex}
                setSelectedIndex={setNotificationTimeIndex}
                options={NOTIFICATIONTIME}
              />
            </FormGroup>
          ) : null}
        </View>
      </View>
      {/* Associate With */}
      {showGlobalSearch && (
        <FormGroup label=" Associate this task with: ">
          {autoCompleteData && (
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              selectedAssociate={selectedAssociate}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
            />
          )}
        </FormGroup>
      )}
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {(!!createSuccess || !!patchSuccess || !!bulkSuccess) && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={!isCreate ? 'SAVE' : 'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default AddTaskForm;
