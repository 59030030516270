import { Card, Text } from '@ui-kitten/components';
import React from 'react';
import { Platform } from 'react-native';
import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryTheme, VictoryTooltip,
} from 'victory-native';

export default function PointsByTeam({ teamData }) {
  const colorScheme = [
    '#6794dc',
    '#6771dc',
    '#a367dc',
    '#dc67d1',
    '#dc67a3',
    '#dc6771',
    '#dc9467',
    '#dcb767',
  ];
  return (
    <Card
      style={{
        flex: 1,
        minWidth: 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        maxWidth: 450,
      }}
      header={(props) => (
        <Text style={{ marginBottom: 10 }} category="h6">Points by Team</Text>
      )}
    >
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={30}
        animate={{
          duration: 1000,
          easing: 'bounce',
        }}
        maxDomain={{ y: 100 }}
        width={Platform.OS === 'web' ? 350 : 300}
      >
        <VictoryBar
          data={teamData}
          x="team"
          y="total"
          barRatio={0.9}
          colorScale={colorScheme}
          style={{
            data: {
              fill: ({ datum }) => colorScheme[datum._x - 1],
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => `${tick}%`}
          style={{
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          tickValues={teamData.map((d) => d.team)}
          style={{
            grid: { stroke: 'transparent' },
          }}
        />
      </VictoryChart>
    </Card>
  );
}
