import { apiSlice } from './ApiSlice';

export const BorrowerApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllBorrowers: build.query({
      query: ({ loanId }) => ({
        url: `/loans/${loanId}/borrowers`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'Borrowers', id: 'PARTIAL-LIST' }],
    }),
    getBorrower: build.query({
      query: ({ loanId, borrowerId }) => ({
        url: `/loans/${loanId}/borrowers/${borrowerId}`,
        method: 'GET',
      }),
      providesTags: (props) => {
        console.log(props, 'PROPS');
        return [{ type: 'Borrowers', id: props.borrowerId }];
      },
    }),
    getMultipleBorrowers: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const promiseArr = [];
        for (let i = 0; i < _arg.borrowers.length; i++) {
          const borrowerURI = _arg.borrowers[i];
          promiseArr.push(
            fetchWithBQ({
              url: borrowerURI,
              method: 'GET',
            })
          );
        }
        const result = await Promise.all(promiseArr);
        const data = result.reduce((acc, cur) => {
          acc.push(cur.data);
          return acc;
        }, []);

        return { data };
      },
      providesTags: (props) => {
        const borrowers = props.map(({ id }) => ({
          type: 'Borrowers',
          id: id,
        }));
        return borrowers;
      },
    }),
    createBorrower: build.mutation({
      query: ({ loanId, borrower }) => ({
        url: `/loans/${loanId}/borrowers`,
        method: 'POST',
        body: borrower,
      }),
      invalidatesTags: (result, error, { loanId }) => {
        return [
          { type: 'Borrowers', id: 'PARTIAL-LIST' },
          { type: 'Loans', id: loanId },
        ];
      },
    }),
    patchBorrower: build.mutation({
      query: ({ loanId, borrowerId, borrower }) => ({
        url: `/loans/${loanId}/borrowers/${borrowerId}`,
        method: 'PATCH',
        body: borrower,
      }),
      invalidatesTags: (result, error, { borrowerId }) => {
        return [
          { type: 'Borrowers', id: borrowerId },
          { type: 'Borrowers', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteBorrower: build.mutation({
      query: ({ loanId, borrowerId }) => ({
        url: `/loans/${loanId}/borrowers/${borrowerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { borrowerId }) => {
        return [
          { type: 'Borrowers', id: borrowerId },
          { type: 'Borrowers', id: 'PARTIAL-LIST' },
          { type: 'BorrowerPairs', id: 'PARTIAL-LIST' },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBorrowersQuery,
  useGetBorrowerQuery,
  useGetMultipleBorrowersQuery,
  useCreateBorrowerMutation,
  usePatchBorrowerMutation,
  useDeleteBorrowerMutation,
} = BorrowerApiSlice;
