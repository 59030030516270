import { Button, Icon, Layout, Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import {
  Platform,
  Pressable,
  ScrollView,
  View,
  StyleSheet,
  Dimensions,
} from 'react-native';
import ClosingsForCurrentTier from '../../components-global/Home/charts/ClosingsNeededForCurrentTier';
import ClosingsNeededForNextTier from '../../components-global/Home/charts/ClosingsNeededForNextTier';
import CurrentQuarterTier from '../../components-global/Home/charts/CurrentQuarterTier';
import GoalTier from '../../components-global/Home/charts/GoalTier';
import LoansToReachGoal from '../../components-global/Home/charts/LoansToReachGoal';
import ProjectedTier from '../../components-global/Home/charts/ProjectedTier';

import MyGoals from '../../components-global/Home/charts/MyGoals';
import MyTrends from '../../components-global/Home/MyTrendsCard';
import SimpleKittenSelect from '../../components-ui/SimpleKittenSelect';
import { useNavigation } from '../../navigation/router';
import {
  useGetMeQuery,
  useUpdateFrontendSettingsMutation,
} from '../../services/UserSlice';

type Props = {};
const dropdownOptions = [
  { name: 'Closed YTD', value: 'closed_ytd' },
  { name: 'Closed QTD', value: 'closed_qtd' },
  { name: 'Closed MTD', value: 'closed_mtd' },
  { name: 'Closed WTD', value: 'closed_wtd' },
  { name: 'Closed All Time', value: 'closed_all' },
  { name: 'Applications YTD', value: 'applications_ytd' },
  { name: 'Applications QTD', value: 'applications_qtd' },
  { name: 'Applications MTD', value: 'applications_mtd' },
  { name: 'Applications WTD', value: 'applications_wtd' },
  { name: 'Applications All Time', value: 'applications_all' },
];

function VisualizerPage({}: Props) {
  const { data: user } = useGetMeQuery({});
  const [updateFrontendSettings] = useUpdateFrontendSettingsMutation();
  const [selectedCardDisplay, setSelectedCardDisplay] = React.useState('');
  const navigation = useNavigation();
  const updateHomeCardDisplay = (value: string) => {
    if (!user) return;
    const currentSettings = JSON.parse(JSON.stringify(user.frontendSettings));
    currentSettings.home = { ...currentSettings.home, cardDisplay: value}
    updateFrontendSettings({ id: user.id, frontendSettings: currentSettings });
  };

  useEffect(() => {
    setSelectedCardDisplay(user?.frontendSettings?.home?.cardDisplay || '');
  }, [user?.frontendSettings?.home?.cardDisplay]);

  return (
    <Layout level="3" style={{ flex: 1 }}>
      <Layout
        level="1"
        style={[
          styles.shadow,
          styles.layoutContainer,
          Platform.OS !== 'web' && {
            flexDirection: 'column',
            height: 'auto',
            paddingBottom: 16,
            alignItems: 'flex-start',
            gap: 16,
          },
        ]}
      >
        <View style={{ flexDirection: 'row' }}>
          <Pressable
            style={{ marginRight: 32, marginTop: Platform.OS === 'android' ? 6 : 0 }}
            onPress={() => navigation.goBack()}
          >
            <Icon
              style={[{ width: 32, height: 32 }]}
              name="arrow-ios-back-outline"
              fill="red"
            />
          </Pressable>
          <Text category="h3" style={{paddingVertical: 0}}>Dashboard</Text>
        </View>

        <Layout
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginRight: Platform.OS === 'web' ? 32 : 0,
          }}
        >
          {user ? (
            <>
              <SimpleKittenSelect
                label={'Homepage Display'}
                options={dropdownOptions}
                defaultValue={selectedCardDisplay}
                onChange={(e) => setSelectedCardDisplay(e.value)}
              />
              <Button
                disabled={
                  user?.frontendSettings?.home?.cardDisplay ===
                  selectedCardDisplay
                    ? true
                    : false
                }
                onPress={() => updateHomeCardDisplay(selectedCardDisplay)}
                status="info"
                appearance="outline"
                style={{ marginBottom: 4, marginLeft: 16 }}
                size="small"
              >
                Save
              </Button>
            </>
          ) : null}
        </Layout>
      </Layout>
      <ScrollView contentContainerStyle={{ padding: 32 }}>
        <View
          style={{
            flexDirection: Platform.OS === 'web' ? 'row' : 'column',
            gap: 16,
            flexWrap: 'wrap',
          }}
        >
          <View
            style={[
              { minWidth: Platform.OS === 'web' ? 500 : '100%', gap: 16 },
              Dimensions.get('window').width > 728
                ? { flex: 1, flexDirection: 'row' }
                : { flexDirection: 'column' },
            ]}
          >
            <CurrentQuarterTier />
            <ProjectedTier />
          </View>
          <View
            style={[
              { minWidth: Platform.OS === 'web' ? 500 : '100%', gap: 16 },
              Dimensions.get('window').width > 728
                ? { flex: 1, flexDirection: 'row' }
                : { flexDirection: 'column' },
            ]}
          >
            <ClosingsNeededForNextTier />
            <ClosingsForCurrentTier />
          </View>
        </View>
        <View
          style={{
            flexDirection: Platform.OS === 'web' ? 'row' : 'column',
            gap: 20,
            marginTop: 20,
          }}
        >
          <View style={{ flex: 1 }}>
            <GoalTier />
          </View>
          <View style={{ flex: 1 }}>
            <LoansToReachGoal />
          </View>
        </View>
        <View>
        <MyGoals sharedStyles={{}} />
        </View>
        <View>
        <MyTrends />
        </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  layoutContainer: {
    width: '100%',
    height: 79,
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  taskWrap: {
    backgroundColor: 'white',
    flex: 1,
    paddingTop: 16,
    borderTopWidth: 4,
  },
  headerText: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
});

export default VisualizerPage;
