import { startOfDay, startOfYear, sub, subYears } from 'date-fns';

export const SALESTYPE = [
  ' ',
  'None',
  'Prospect',
  'Referrals',
  'Prospect & Referrals',
];

export const OTHERTYPE = [' ', 'None', 'Accomplished', 'Referrals'];

export const TYPES = [' ', 'Sales Call', 'Follow Up', 'Other'];

export const PIPELINESMOBILEFIELD = [
  {
    name: 'Name',
    mapping: 'contact.name',
  },
  {
    name: 'Address',
    mapping: 'propertyAddress',
  },
  {
    name: 'City',
    mapping: 'propertyCity',
  },
  { name: 'Loan Stage', mapping: 'stage' },
];

export const NOTIFICATIONTIME = [
  '1 Hour Before',
  '30 Minutes Before',
  '15 Minutes Before',
];

export const LOANPURPOSE = [
  ' ',
  'Purchase',
  'Cashout Refinance',
  'Rate and Term Refinance',
];
export const LOANOCCUPANCY = [
  ' ',
  'Owner Occupied',
  'Second Home',
  'Investment Property',
];
export const AMORTIAZATIONTYPE = [' ', 'Fixed', 'ARM'];
export const LOANTYPE = [
  ' ',
  'Conventional',
  'FHA',
  '2nd Lien',
  'DSCR',
  'Jumbo',
  'BankStatement',
];
//simple kitten select
export const LOANTYPEMAPPING = [
  { name: 'Conventional', value: 'conventional' },
  { name: 'FHA', value: 'fha' },
  { name: 'VA', value: 'va' },
];
export const LOANOCCUPANCYMAPPING = [
  { name: 'Owner Occupied', value: 'primary' },
  { name: 'Second Home', value: 'secondary' },
  { name: 'Investment Property', value: 'investment' },
];
export const LOANTERMMAPPING = [
  { name: '180', value: 180 },
  { name: '240', value: 240 },
  { name: '360', value: 360 },
];

export const PROPERTYTYPEMAPPING = [
  { name: 'SFR Detached', value: 'sfr_detached' },
  { name: 'SFR Attached', value: 'sfr_attached' },
  { name: 'Condo', value: 'condo' },
  { name: 'Modular', value: 'modular' },
  { name: 'Manufactured Home', value: 'manufactured_home' },
  { name: 'Mixed-Use', value: 'mixed_use' },
  { name: 'Co-Op', value: 'co_op' },
  { name: 'PUD', value: 'pud' },
  { name: 'Townhome', value: 'townhome' },
  { name: '2 Unit', value: 'two_unit' },
  { name: '3 Unit', value: 'three_unit' },
  { name: '4 Unit', value: 'four_unit' },
];
export const DOCUMENTATIONTYPEMAPPING = [
  { name: 'Full Doc', value: 'full_doc' },
  { name: 'Investor - No Ratio', value: 'investor_no_ratio' },
  { name: 'Investor - DSCR', value: 'investor_dscr' },
  {
    name: 'Bank Statements - Personal (12 mos)',
    value: 'bank_statements_personal_12',
  },
  {
    name: 'Bank Statements - Personal (24 mos)',
    value: 'bank_statements_personal_24',
  },
  {
    name: 'Bank Statements - Business (12 mos)',
    value: 'bank_statements_business_12',
  },
  {
    name: 'Bank Statements - Business (24 mos)',
    value: 'bank_statements_business_24',
  },
  { name: '1 Year Alt Doc', value: 'one_year_alt_doc' },
  { name: '2 Year Alt Doc', value: 'two_year_alt_doc' },
];

export const ADVANCEDOPTIONS = [
  ' ',
  'Scenario Info',
  'Soft Pull',
  'Income Docs',
  'Option or Meeting (Face to Face)',
  'Credit Pull',
];

export const GETLOANSQUERY = `pagination=false&applicationDate[after]=${startOfDay(
  sub(startOfYear(new Date()), { years: 1 })
).toISOString()}&properties[]=applicationDate&properties[]=closeDate&properties[]=phase&properties[]=loCommission&properties[]=closeStatus&properties[]=loanAmount&properties[]=purchasedDate&properties[]=stage&properties[]=lockDate&properties[]=lockStatus&properties[]=lockExpireDate`;
