import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import moment from 'moment';
import { useGetMeQuery } from '../../services/UserSlice';

function CommissionTierCustomFormatter({ rowData }) {
  const { data: user } = useGetMeQuery('');
  const tier = user?.tierHistory?.find((tier) =>
    moment(moment(rowData.closeDate).subtract(1, 'quarter')).isBetween(
      moment(tier.dateBegin),
      moment(tier.dateEnd)
    )
  );

  return (
    <View
      style={{
        flexDirection: 'row',
        gap: 8,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ fontFamily: 'VerbBold', fontSize: 14 }}
      >
        {tier?.tier?.tier
          ? `Tier - ${tier.tier.tier} (%${tier.tier.percentage})`
          : 'Tier - 1 (%1)'}
      </Text>
    </View>
  );
}

export default CommissionTierCustomFormatter;
