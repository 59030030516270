import { Platform, Text, View } from 'react-native';
import {
  Button,
  Card,
  Icon,
  IndexPath,
  Select,
  SelectItem,
} from '@ui-kitten/components';
import React, { useEffect } from 'react';
import produce from 'immer';

const times = [
  { label: '12:00 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '1:00 AM', value: '01:00' },
  { label: '1:30 AM', value: '01:30' },
  { label: '2:00 AM', value: '02:00' },
  { label: '2:30 AM', value: '02:30' },
  { label: '3:00 AM', value: '03:00' },
  { label: '3:30 AM', value: '03:30' },
  { label: '4:00 AM', value: '04:00' },
  { label: '4:30 AM', value: '04:30' },
  { label: '5:00 AM', value: '05:00' },
  { label: '5:30 AM', value: '05:30' },
  { label: '6:00 AM', value: '06:00' },
  { label: '6:30 AM', value: '06:30' },
  { label: '7:00 AM', value: '07:00' },
  { label: '7:30 AM', value: '07:30' },
  { label: '8:00 AM', value: '08:00' },
  { label: '8:30 AM', value: '08:30' },
  { label: '9:00 AM', value: '09:00' },
  { label: '9:30 AM', value: '09:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '1:00 PM', value: '13:00' },
  { label: '1:30 PM', value: '13:30' },
  { label: '2:00 PM', value: '14:00' },
  { label: '2:30 PM', value: '14:30' },
  { label: '3:00 PM', value: '15:00' },
  { label: '3:30 PM', value: '15:30' },
  { label: '4:00 PM', value: '16:00' },
  { label: '4:30 PM', value: '16:30' },
  { label: '5:00 PM', value: '17:00' },
  { label: '5:30 PM', value: '17:30' },
  { label: '6:00 PM', value: '18:00' },
  { label: '6:30 PM', value: '18:30' },
  { label: '7:00 PM', value: '19:00' },
  { label: '7:30 PM', value: '19:30' },
  { label: '8:00 PM', value: '20:00' },
  { label: '8:30 PM', value: '20:30' },
  { label: '9:00 PM', value: '21:00' },
  { label: '9:30 PM', value: '21:30' },
  { label: '10:00 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11:00 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' },
];

const activityTypes = [
  {
    label: 'Call realtors - Goal: Meeting',
    value: 'call_realtor_goal_meeting',
  },
  {
    label: 'Call potential partners - Goal: Meeting',
    value: 'call_partners_goal_meeting',
  },
  {
    label: 'Call past clients - Goal: Referrals',
    value: 'call_past_clients_goal_referrals',
  },
  {
    label: 'Call past prospects - Goal: Qualify/Referrals',
    value: 'call_past_prospects_goal_qualify_referrals',
  },
  {
    label: 'Call Predefined list of leads',
    value: 'call_predefined_list_of_leads',
  },
  {
    label: 'Meeting realtor - Goal: Monthly meetings',
    value: 'call_realtor_goal_monthly_meetings',
  },
  {
    label: 'Meeting partner - Goal: Monthly meeting',
    value: 'call_partner_goal_monthly_meeting',
  },
];

export default function TimeBlockDay({ timeBlock, changeHandler }) {
  const [timeFrom, setTimeFrom] = React.useState<IndexPath>();
  const [timeTo, setTimeTo] = React.useState<IndexPath>();
  const [activityType, setActivityType] = React.useState<IndexPath>();

  const [timeFromValue, setTimeFromValue] = React.useState<string>();
  const [timeToValue, setTimeToValue] = React.useState<string>();
  const [activityTypeValue, setActivityTypeValue] = React.useState<string>();

  const getIndex = (value, array) =>
    array.findIndex((item) => item.value === value);

  const handleChange = (value, index) => {
    const list = value === 'activityType' ? activityTypes : times;
    const copy = produce(timeBlock, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft[value] = list[index.row].value;
    });
    changeHandler(copy);
  };

  const clear = () => {
    const copy = produce(timeBlock, (draft) => {
      draft.timeFrom = null;
      draft.timeTo = null;
      draft.activityType = null;
    });
    changeHandler(copy);
  };

  const getTime = (time) => {
    if (time.length < 6) {
      return time;
    }
    const realTime = new Date(time);
    return realTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: 'UTC',
    });
  };

  useEffect(() => {
    let time;
    let index;
    if (timeBlock.timeFrom) {
      time = getTime(timeBlock.timeFrom);
      index = getIndex(time, times);
      setTimeFrom(new IndexPath(index));
      setTimeFromValue(times[index].label);
    } else {
      setTimeFrom(null);
      setTimeFromValue(null);
    }
    if (timeBlock.timeTo) {
      time = getTime(timeBlock.timeTo);
      setTimeTo(new IndexPath(getIndex(time, times)));
      setTimeToValue(times[getIndex(time, times)].label);
    } else {
      setTimeTo(null);
      setTimeToValue(null);
    }
    if (timeBlock.activityType) {
      setActivityType(
        new IndexPath(getIndex(timeBlock.activityType, activityTypes))
      );
      setActivityTypeValue(
        activityTypes[getIndex(timeBlock.activityType, activityTypes)].label
      );
    } else {
      setActivityType(null);
      setActivityTypeValue(null);
    }
  }, [timeBlock]);

  return (
    <View
      style={{
        flexDirection: 'column',
        width: Platform.OS === 'web' ? 'unset' : '100%',
        flexBasis: Platform.OS === 'web' ? '30%' : '100%',
        flex: 1,
        marginHorizontal: Platform.OS === 'web' ? 4 : 0,
        marginVertical: 4,
        padding: 12,
        borderWidth: 1,
        minWidth: 320,
        borderColor: '#eee',
      }}
      key={timeBlock.dayOfWeek}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'VerbBold',
            fontStyle: 'italic',
            color: '#224973',
            alignSelf: 'center',
          }}
        >
          {timeBlock.dayOfWeek.charAt(0).toUpperCase() +
            timeBlock.dayOfWeek.slice(1)}
        </Text>
        <Button
          style={{ margin: 2 }}
          appearance="ghost"
          status="danger"
          accessoryLeft={(props) => (
            <Icon
              {...props}
              name="calendar-remove-outline"
              pack="material-community"
            />
          )}
          onPress={clear}
        />
      </View>
      <View style={{ width: '50%', marginBottom: 8, backgroundColor: '#fff' }}>
        <Select
          selectedIndex={timeFrom}
          onSelect={(itemValue: IndexPath) =>
            handleChange('timeFrom', itemValue)
          }
          value={timeFromValue}
          placeholder="From"
        >
          {times.map((time) => (
            <SelectItem title={time.label} key={time.value} />
          ))}
        </Select>
      </View>
      <View style={{ width: '50%', marginBottom: 8, backgroundColor: '#fff' }}>
        <Select
          selectedIndex={timeTo}
          onSelect={(itemValue: IndexPath) => handleChange('timeTo', itemValue)}
          placeholder="To"
          value={timeToValue}
        >
          {times.map((time) => (
            <SelectItem title={time.label} key={time.value} />
          ))}
        </Select>
      </View>
      <View style={{ backgroundColor: '#fff' }}>
        <Select
          selectedIndex={activityType}
          onSelect={(itemValue: IndexPath) =>
            handleChange('activityType', itemValue)
          }
          placeholder="Activity Type"
          value={activityTypeValue}
        >
          {activityTypes.map((time) => (
            <SelectItem title={time.label} key={time.value} />
          ))}
        </Select>
      </View>
    </View>
  );
}
