import React, { useState } from 'react';
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory-native';
import { Platform, useWindowDimensions, View } from 'react-native';
import Svg from 'react-native-svg';
import { Text, Card, Button, ButtonGroup } from '@ui-kitten/components';

import {
  isAfter,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  isBefore,
  isSameDay,
} from 'date-fns';
import { useGetMeQuery } from '../../../services/UserSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { utcToZonedTime } from 'date-fns-tz';
import { currencyFormat } from '../../../helpers/dataTransformerHelper';

export default function MyGoals(props) {
  const { data: user, isSuccess: userLoaded } = useGetMeQuery({});
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  const startYear = startOfYear(new Date());

  const applicationData = !loansData
    ? {
        appsDataQTD: 0,
        appsDataYTD: 0,
        appsDataMTD: 0,
        appsDataWTD: 0,
      }
    : loansData['hydra:member'].reduce(
        (acc, loan) => {
          const appDate = utcToZonedTime(loan.applicationDate, '');
          if (appDate && isBefore(appDate, new Date())) {
            if (
              isAfter(appDate, startOfQuarter(new Date())) ||
              isAfter(appDate, startOfQuarter(new Date()))
            ) {
              acc.appsDataQTD += 1;
              acc.appsDataQTDSum += loan.loanAmount;
            }
            if (
              isAfter(appDate, startOfWeek(new Date())) ||
              isSameDay(appDate, startOfWeek(new Date()))
            ) {
              acc.appsDataWTD += 1;
              acc.appsDataWTDSum += loan.loanAmount;
            }
            if (
              isAfter(appDate, startOfMonth(new Date())) ||
              isSameDay(appDate, startOfMonth(new Date()))
            ) {
              acc.appsDataMTD += 1;
              acc.appsDataMTDSum += loan.loanAmount;
            }
            if (
              isSameDay(appDate, startOfYear(new Date())) ||
              isAfter(appDate, startOfYear(new Date()))
            ) {
              acc.appsDataYTD += 1;
              acc.appsDataYTDSum += loan.loanAmount;
            }
          }
          return acc;
        },
        {
          appsDataQTD: 0,
          appsDataQTDSum: 0,
          appsDataYTD: 0,
          appsDataYTDSum: 0,
          appsDataMTD: 0,
          appsDataMTDSum: 0,
          appsDataWTD: 0,
          appsDataWTDSum: 0,
        }
      );

  const closingData = !loansData
    ? {
        loansDataQTD: 0,
        loansDataYTD: 0,
        loansDataMTD: 0,
        loansDataWTD: 0,
      }
    : loansData['hydra:member'].reduce(
        (acc, loan) => {
          const closeDate = utcToZonedTime(loan.closeDate, '');
          if (
            loan.phase === 'Closed Loan' &&
            loan.closeStatus === 'Won' &&
            isBefore(closeDate, new Date())
          ) {
            if (
              isAfter(closeDate, startOfQuarter(new Date())) ||
              isSameDay(closeDate, startOfQuarter(new Date()))
            ) {
              acc.loansDataQTD += 1;
              acc.loansDataQTDSum += loan.loanAmount;
            }
            if (
              isAfter(closeDate, startOfWeek(new Date())) ||
              isSameDay(closeDate, startOfWeek(new Date()))
            ) {
              acc.loansDataWTD += 1;
              acc.loansDataWTDSum += loan.loanAmount;
            }
            if (
              isAfter(closeDate, startOfMonth(new Date())) ||
              isSameDay(closeDate, startOfMonth(new Date()))
            ) {
              acc.loansDataMTD += 1;
              acc.loansDataMTDSum += loan.loanAmount;
            }
            if (
              isAfter(closeDate, startOfYear(new Date())) ||
              isSameDay(closeDate, startOfYear(new Date()))
            ) {
              acc.loansDataYTD += 1;
              acc.loansDataYTDSum += loan.loanAmount;
            }
          }
          return acc;
        },
        {
          loansDataQTD: 0,
          loansDataQTDSum: 0,
          loansDataYTD: 0,
          loansDataYTDSum: 0,
          loansDataMTD: 0,
          loansDataMTDSum: 0,
          loansDataWTD: 0,
          loansDataWTDSum: 0,
        }
      );

  return (
    <View
      style={{
        flexDirection: Platform.OS === 'web' ? 'row' : 'column',
        flex: 1,
        marginVertical: Platform.OS === 'web' ? 24 : 4,
        flexWrap: 'wrap',
        gap: 24,
      }}
    >
      <View
        style={{
          flex: 1,
          minWidth: Platform.OS === 'web' ? 400 : 0,
          marginBottom: Platform.OS == 'web' ? 0 : 8,
          width: '100%'
        }}
      >
        <ChartCard
          title="Applications"
          units={{
            YTD: applicationData.appsDataYTD,
            MTD: applicationData.appsDataMTD,
            WTD: applicationData.appsDataWTD,
            QTD: applicationData.appsDataQTD,
            YTDSum: applicationData.appsDataYTDSum,
            MTDSum: applicationData.appsDataMTDSum,
            WTDSum: applicationData.appsDataWTDSum,
            QTDSum: applicationData.appsDataQTDSum,
          }}
          goal={user?.applicationGoal}
        />
      </View>
      <View
        style={{
          flex: 1,
          minWidth: Platform.OS === 'web' ? 400 : 0,
          width: '100%'
        }}
      >
        <ChartCard
          title="Closing"
          units={{
            YTD: closingData.loansDataYTD,
            MTD: closingData.loansDataMTD,
            WTD: closingData.loansDataWTD,
            QTD: closingData.loansDataQTD,
            YTDSum: closingData.loansDataYTDSum,
            MTDSum: closingData.loansDataMTDSum,
            WTDSum: closingData.loansDataWTDSum,
            QTDSum: closingData.loansDataQTDSum,
          }}
          goal={user?.closingGoal}
        />
      </View>
    </View>
  );
}

const goalDividerMap = [52, 12, 4, 1];

const goalTimeRangMap = ['WTD', 'MTD', 'QTD', 'YTD'];

function ChartCard({ title = '', units, goal = 0 }) {
  const chartSize = 250;

  const [timeRange, setTimeRange] = useState(0);
  const windowWidth = useWindowDimensions().width;

  const realGoal = goal / goalDividerMap[timeRange] || 0;

  const applications = units[goalTimeRangMap[timeRange]] || 0;
  const applicationsSum = units[`${goalTimeRangMap[timeRange]}Sum`] || 0;

  // appTime  0 = WTD, appTime 1 = mTD, appTime 2 = QTD
  const realPercent = (100 * applications) / realGoal || 0;
  const percent = realPercent > 100 ? 100 : realPercent;
  const data = [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];

  const isMobile = Platform.OS !== 'web';
  return (
    <Card
      style={{
        maxWidth: Platform.OS === 'android' ? windowWidth * 0.94 : '100%',
        flex: 1,
        padding: Platform.OS === 'web' ? 16 : 0,
        flexDirection: 'column',
        width: '100%',
        marginTop: Platform.OS === 'web' ? 0 : 24,
      }}
    >
      <View
        style={{
          width: '100%',
          marginBottom: 16,
          flexDirection: Platform.OS === 'web' ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'VerbBold',
            fontSize: 20,
          }}
        >
          {title}
        </Text>
        <View style={{ flexDirection: 'row', marginTop: Platform.OS === 'web' ? 0 : 16 }}>
          <ButtonGroup size="small" status="basic">
            <Button
              style={timeRange === 0 && { backgroundColor: '#b6daff' }}
              onPress={() => setTimeRange(0)}
            >
              W
            </Button>
            <Button
              style={timeRange === 1 && { backgroundColor: '#b6daff' }}
              onPress={() => setTimeRange(1)}
            >
              M
            </Button>
            <Button
              style={timeRange === 2 && { backgroundColor: '#b6daff' }}
              onPress={() => setTimeRange(2)}
            >
              Q
            </Button>
            <Button
              style={timeRange === 3 && { backgroundColor: '#b6daff' }}
              onPress={() => setTimeRange(3)}
            >
              Y
            </Button>
          </ButtonGroup>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          width: '100%',
        }}
      >
        <View style={{ flex: 1, flexBasis: '50%', paddingVertical: 24}}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 17 : 13, marginRight: 16 }}>
              Goal:
            </Text>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 20 : 15 }}>
              {Math.ceil(goal / goalDividerMap[timeRange])}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 17 : 13, marginRight: 16 }}>
              Units:
              {/* {units} */}
            </Text>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 20 : 15 }}>
              {applications}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 17 : 13, marginRight: 16 }}>
              Loan Amount:
            </Text>
            <Text category="label" style={{ fontSize: Platform.OS === 'web' ? 20 : 15 }}>
              {currencyFormat(applicationsSum)}
            </Text>
          </View>
        </View>
        <Svg
          style={{ justifyContent: 'flex-end'}}
          // viewBox={Platform.OS === 'android' ? '0 0 105 140' : '0 0 200 200'}
          width={isMobile ? '120' : '275'}
          height={isMobile ? '140' : '275'}
        >
          <VictoryPie
            standalone={false}
            animate={{ duration: 2000 }}
            easing="exp"
            width={isMobile ? 140 : 275}
            height={isMobile ? 140 : 275}
            data={data}
            innerRadius={isMobile ? 40 : 50}
            cornerRadius={0}
            labels={() => null}
            style={{
              // @ts-ignore
              backgroundColor: 'transparent',
              data: {
                fill: ({ datum }) => {
                  const color = '#6FAEDD';
                  return datum.x === 1 ? color : '#eee';
                },
              },
            }}
          />

          <VictoryAnimation duration={1000} data={data}>
            {() => (
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                x={isMobile ? 70 : 137.5}
                y={isMobile ? 70 : 137.5}
                text={percent >= 100 ? 'Achieved' : `${Math.round(percent)}%`}
                style={{
                  fontSize: realPercent >= 100 ? 14 : 16,
                  fontFamily: 'VerbRegular',
                }}
              />
            )}
          </VictoryAnimation>
        </Svg>
      </View>
    </Card>
  );
}
