import React from 'react';
import {
  ListItem, Spinner, Text, useTheme,
} from '@ui-kitten/components';
import {
  useGetJoinedStatsQuery,
  useGetStatsQuery,
} from '../../services/StatsSlice';
import {ViewProps} from "react-native";

interface ThisWeekCardProps {
  filter: any;
  alias: string;
  title: string;
  // eslint-disable-next-line react/require-default-props
  onPress?: () => void;
}

export default function ThisWeekCard({
  filter, alias, title, onPress,
}: ThisWeekCardProps) {
  const { currentData, error, isFetching, isError } = filter.length
    ? useGetJoinedStatsQuery({ filter })
    : useGetStatsQuery({ filter });
  const [hovered, setHovered] = React.useState(false);
  const theme = useTheme();

  const text = (isError ? JSON.stringify(error) : title);

  return (
    <ListItem
      title={(evaProps) => <Text {...evaProps}>{text}</Text>}
      accessoryRight={(evaProps: ViewProps) => (isFetching ? (
        <Spinner size="small" />
      ) : (
        <Text
          style={[
            { flex: 1, maxWidth: 75, textAlign: 'right' },
          ]}
        >
          {!isError && currentData[alias]}
        </Text>
      ))}
      style={{
        backgroundColor: hovered
          ? theme['background-basic-color-2']
          : theme['background-basic-color-1'],
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onPress={onPress}
    />
  );
}
