import React, { createRef, useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View, Keyboard } from 'react-native';
import {
  Select,
  IndexPath,
  Input,
  Datepicker,
  NativeDateService,
  CheckBox,
  Text,
  Layout,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import { renderOption } from '../renderMethods';
import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';

import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import Colors from '../../../helpers/Colors';
import moment from 'moment';
import { useCreateEventMutation } from '../../../services/EventApiSlice';
import { OTHERTYPE, TYPES } from '../../../helpers/constants';
import RNTimePicker from '../../../components-ui/TimePicker/RNTimePicker';

function LogMeetingForm({ cancelAction, defaultData, showModal }: any) {
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [followUp, setFollowUp] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState<any>({
    subject: new IndexPath(0),
    startDate: new Date(),
    endDate: new Date(),
    outcome: new IndexPath(0),
    startDatetime: moment().format('lll'),
    endDatetime: moment().format('lll'),
    associateValue: '',
    description: '',
    numOfReferrals: 0,
  });

  const displayOutcomeValue = OTHERTYPE[state.outcome.row];
  const displayValueEvent = TYPES[state.subject.row];
  const windowWidth = Dimensions.get('window').width;
  const componentRef = createRef<Datepicker>();
  const [
    createEvent,
    {
      isSuccess: createSuccess,
      data: createData,
      isError: createHasError,
      error: createError,
    },
  ] = useCreateEventMutation();

  // GlobalSearch Data Handler
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } =
    useGetLoansQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (createError) {
      if (createHasError && createError) {
        //@ts-ignore
        if (createError?.data && createError.data['hydra:description']) {
          //@ts-ignore
          setError(createError?.data['hydra:description']);
        } else {
          setError('Something went wrong, please try again.');
        }
        setFormSubmitted(false);
      }
    }
  }, [createError]);

  useEffect(() => {
    if (createSuccess) {
      setTimeout(() => {
        if (followUp) {
          const formattedDueDate = moment(state.startDate).format('YYYY-DD-MM');

          const payload: any = {
            type: 'Follow Up',
            subject: 'Follow Up',
            dueDate: moment(
              formattedDueDate +
                ' ' +
                moment(state.startDatetime).format('h:mm A'),
              'YYYY-DD-MM h:mm A'
            ).toISOString(),
            dueDateTime: moment(
              formattedDueDate +
                ' ' +
                moment(state.startDatetime).format('h:mm A'),
              'YYYY-DD-MM h:mm A'
            ).toISOString(),
          };

          if (!defaultData) {
            payload[selectedAssociate.type.toLowerCase()] =
              selectedAssociate['@id'];
          } else {
            payload[defaultData['@type'].toLowerCase()] = defaultData['@id'];
          }
          showModal({ formType: 'task', data: payload });
        } else {
          cancelAction();
        }
      }, 2000);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!isFetching) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      // Check for fields
      await checkForRequiredFields();

      const formattedStartDate = moment(state.startDate).format('YYYY-DD-MM');
      const formattedEndDate = moment(state.endDate).format('YYYY-DD-MM');

      const startDate = moment(
        formattedStartDate + ' ' + moment(state.startDatetime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      const endDate = moment(
        formattedEndDate + ' ' + moment(state.endDatetime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      const payload: any = {
        subject: displayValueEvent,
        eventDate: startDate,
        startTime: startDate,
        startDatetime: startDate,
        endDatetime: endDate,
        endTime: endDate,
        outcome: displayOutcomeValue,
        resolution: state.description,
        referralCount: parseInt(state.numOfReferrals) || 0,
      };

      if (!defaultData) {
        payload[selectedAssociate.type.toLowerCase()] =
          selectedAssociate['@id'];
      } else {
        payload[defaultData['@type'].toLowerCase()] = defaultData['@id'];
      }

      createEvent(payload);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  async function checkForRequiredFields() {
    if (!state?.subject || state?.subject?.row == 0 || displayValueEvent === '')
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.startDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.startDatetime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!state.endDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.endDatetime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!defaultData && !selectedAssociate)
      throw new Error('Please select someone to associate this task with');
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Log a Meeting'} />
      {/* Event Type */}
      <FormGroup label="Select Event Type">
        <Select
          placeholder="Select Event Type"
          selectedIndex={state.subject}
          onSelect={(index: any) =>
            setState((prevState) => ({ ...prevState, subject: index }))
          }
          value={displayValueEvent}
        >
          {TYPES.map(renderOption)}
        </Select>
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Outcome">
            <Select
              placeholder="Outcome"
              selectedIndex={state.type}
              onSelect={(index: any) =>
                setState((prevState) => ({ ...prevState, outcome: index }))
              }
              value={displayOutcomeValue}
            >
              {OTHERTYPE.map(renderOption)}
            </Select>
          </FormGroup>
        </View>
        {displayOutcomeValue.includes('Referrals') && (
          <View style={{ flex: 1 }}>
            <FormGroup label="Number of referrals">
              <Input
                keyboardType="numeric"
                onBlur={() => Keyboard.dismiss()}
                placeholder=""
                value={state.numOfReferrals.toString() || ''}
                onChangeText={(e) =>
                  setState((prevState) => ({ ...prevState, numOfReferrals: e }))
                }
              />
            </FormGroup>
          </View>
        )}
      </View>
      {/* description */}
      <FormGroup label="Notes" required={false}>
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: 80 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Start Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.startDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({ ...prevState, startDate: e }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select Start Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select start time'}
                value={state.startDatetime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    startDatetime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="End Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.endDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({ ...prevState, endDate: e }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select End Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select end time'}
                value={state.endDatetime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    endDatetime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>
      {/* Associate With */}
      {!defaultData ? (
        <FormGroup label=" Associate this event with: ">
          {autoCompleteData && (
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
              selectedAssociate={selectedAssociate}
            />
          )}
        </FormGroup>
      ) : null}
      <FormGroup label="" required={false}>
        <CheckBox
          checked={followUp}
          onChange={(nextChecked) => setFollowUp(nextChecked)}
        >
          Create Follow Up Task
        </CheckBox>
      </FormGroup>
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {!!createSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'Log Meeting'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default LogMeetingForm;
