import {
  OverflowMenu,
  MenuItem,
  Icon,
  Text,
  Button,
  Layout,
} from '@ui-kitten/components';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Pressable, View, StyleSheet, Platform,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { useDeleteEventMutation } from '../../services/EventApiSlice';
import { showModal as realShow } from '../../services/FormSlice';

export default function EventCard({ activity }: any) {
  const [visible, setVisible] = useState(false);
  const [deleteEvent, { isSuccess: patchSuccess }] = useDeleteEventMutation();
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));

  const isCompleted = activity.isCompleted || activity.outcome || activity.resolution;

  const handleEditTask = () => {
    showModal({ formType: 'event', data: activity });
    setVisible(false);
  };
  const handleEditResolution = () => {
    showModal({ formType: 'completeEvent', data: activity });
    setVisible(false);
  };

  const handleViewUserDetails = () => {
    setVisible(false);
    // Router link
  };

  const handleCompleteTask = () => {
    showModal({
      formType: 'completeEvent',
      data: activity,
    });
    setVisible(false);
  };

  const handleDeleteTask = () => {
    deleteEvent({ id: activity.id });
    setVisible(false);
  };

  const renderToggleButton = () => (
    <Pressable style={{ marginLeft: 16 }} onPress={() => setVisible(true)}>
      <Icon
        name="more-horizontal-outline"
        fill="darkgray"
        style={{ width: 26, height: 26 }}
      />
    </Pressable>
  );

  return (
    <Layout
      style={[
        styles.shadow,
        {
          padding: 16,
          borderWidth: 1,
          borderColor: '#d5d5d5',
          borderRadius: 4,
          position: 'relative',
        },
      ]}
    >
      {moment(activity.endDatetime).isBefore(moment()) && !isCompleted ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // color: '#ff000033',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'VerbBold',
              fontSize: Platform.OS === 'web' ? 60 : 36,
              textAlign: 'center',
              color: '#ffb3b359',
              transform: [{ rotate: '12deg' }],
            }}
          >
            {' '}
            Past Due
          </Text>
        </View>
      ) : null}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 4,
        }}
      >
        <View style={styles.headerWrap}>
          <Icon style={styles.icon} fill="rgb(255, 179, 2)" name="calendar" />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: 12 }}>
              Event
            </Text>
            <Text
              category="label"
              style={{
                fontSize: 12,
                marginLeft: 16,
                fontFamily: 'VerbBold',
                backgroundColor: 'rgb(255, 179, 2)',
                color: 'white',
                paddingVertical: 2,
                paddingHorizontal: 8,
                borderRadius: 8,
              }}
            >
              {activity.subject || ''}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {isCompleted && (
            <Layout
              style={[
                styles.label,
                {
                  marginLeft: 16,
                  marginBottom: 4,
                },
              ]}
            >
              <CompleteIcon />
              <Text
                style={{
                  fontFamily: 'VerbBold',
                  color: isCompleted ? 'rgb(122, 183, 23)' : '#af0000',
                  fontSize: 13,
                  marginLeft: 2,
                  marginRight: 2,
                }}
                category="p2"
              >
                {isCompleted ? 'Completed' : 'Pending'}
              </Text>
            </Layout>
          )}
          <OverflowMenu
            visible={visible}
            anchor={renderToggleButton}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem
              accessoryLeft={EditIcon}
              title="Edit Details"
              onPress={() => handleEditTask()}
            />
            {isCompleted ? (
              <MenuItem
                accessoryLeft={EditIcon}
                title="Edit Outcome"
                onPress={() => handleEditResolution()}
              />
            ) : null}
            <MenuItem
              accessoryLeft={DangerIcon}
              title="Delete Event"
              onPress={handleDeleteTask}
            />
          </OverflowMenu>
        </View>
      </View>
      {/* body */}
      <View style={{ padding: 8 }}>
        <Text
          category="label"
          style={{
            fontSize: Platform.OS === 'web' ? 12 : 12,
            fontStyle: 'italic',
          }}
        >
          Start Time:
          {' '}
          {moment(activity.startDatetime).format('lll')}
        </Text>
        <Text
          category="label"
          style={{
            fontSize: Platform.OS === 'web' ? 12 : 12,
            fontStyle: 'italic',
          }}
        >
          End Time:
          {' '}
          {moment(activity.endDatetime).format('lll')}
        </Text>
        {Boolean(activity.description) && (
          <Text category="p1" style={{ paddingVertical: 8 }}>
            {activity.description}
          </Text>
        )}
        {isCompleted && Boolean(activity.outcome) && (
          <Layout
            level="2"
            style={{
              padding: 8,
              marginVertical: 8,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                category="label"
                style={{ marginRight: 8, fontStyle: 'italic' }}
              >
                Resolution/Outcome:
              </Text>
              <Text category="label">{activity.outcome}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <Text
                category="label"
                style={{ marginRight: 8, fontStyle: 'italic' }}
              >
                Note:
              </Text>
              <Text category="p2">{activity.resolution}</Text>
            </View>
            {activity.outcome.includes('Referrals') ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 4,
                }}
              >
                <Text
                  category="label"
                  style={{ marginRight: 8, fontStyle: 'italic' }}
                >
                  Referrals:
                </Text>
                <Text category="label" style={{ fontFamily: 'VerbBold' }}>
                  {activity.referralCount}
                </Text>
              </View>
            ) : null}
          </Layout>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {Boolean(activity.notificationsEnabled) && (
            <>
              <Icon
                fill="#777777"
                name="bell"
                style={{ width: 14, height: 14, marginRight: 4 }}
              />
              <Text category="p2" style={{ fontStyle: 'italic', fontSize: 12 }}>
                {moment(activity.notificationTime).format('lll')}
              </Text>
            </>
          )}
        </View>
        <View style={{ flexDirection: 'row' }}>
          {!isCompleted && (
            <Button
              style={{ margin: 2 }}
              size={Platform.OS === 'web' ? 'small' : 'tiny'}
              status="warning"
              onPress={handleCompleteTask}
            >
              Complete Event
            </Button>
          )}
        </View>
      </View>
    </Layout>
  );
}
function ViewPersonIcon(props) {
  return <Icon {...props} fill="gray" name="person" />;
}

function DangerIcon(props) {
  return <Icon {...props} fill="red" name="trash-2" />;
}

function EditIcon(props) {
  return <Icon {...props} fill="gray" name="star" />;
}

function CompleteIcon(props) {
  return (
    <Icon
      {...props}
      style={[props.style, { width: 20, height: 20 }]}
      fill="green"
      name="checkmark-square"
    />
  );
}

const styles = StyleSheet.create({
  headerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerDate: {
    marginLeft: 4,
    fontSize: 14,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  label: {
    marginTop: 4,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 3,
    maxWidth: 84,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  labeltext: {},
  shadow: {
    shadowColor: '#c3bcae',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3,
  },
});
