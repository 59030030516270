import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { IndexPath, Input, Text, Layout } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import {
  useAddNewContactMutation,
  useGetContactsQuery,
} from '../../../services/ContactApiSlice';
import {
  useEditLoanMutation,
  useGetLoansQuery,
} from '../../../services/LoanApiSlice';

const BOOLEANFIELDS = [' ', 'Yes', 'No'];

const PARTNERTYPE = [
  ' ',
  'Real Estate Agent',
  'CPA',
  'Attorney',
  'Title Company',
  'Insurance Company',
  'Financial Planner',
  'Other',
];
const PARTNERTYPEMAPPING = [
  { value: null, label: '' },
  { value: 'RE_AGENT', label: 'Real Estate Agent' },
  { value: 'CPA', label: 'CPA' },
  { value: 'ATTORNEY', label: 'Attorney' },
  { value: 'TC', label: 'Title Company' },
  { value: 'IC', label: 'Insurance Company' },
  { value: 'FP', label: 'Financial Planner' },
  { value: 'OTHER', label: 'Other' },
];

function ConvertToContactForm({ cancelAction, defaultData }: any) {
  const windowWidth = Dimensions.get('window').width;
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formStep, setFormStep] = useState(defaultData?.isPartner ? 2 : 1);
  const [formData, setFormData] = useState({
    firstName: defaultData?.firstName || '',
    lastName: defaultData?.lastName || '',
    email: defaultData?.email || '',
    mobilePhone: defaultData?.mobilePhone || '',
    status: 'New',
    isPartner: defaultData?.isPartner || false,
    partnerType: '',
    streetAddress: defaultData?.propertyAddress || '',
    city: defaultData?.propertyCity || '',
    state: defaultData?.propertyState || '',
    zipCode: defaultData?.propertyZip || '',
    loanAmount: defaultData?.loanAmount || '',
    loanPurpose: defaultData?.loanPurpose || '',
    lead: defaultData
      ? defaultData['@type'] === 'Lead'
        ? defaultData['@id']
        : null
      : null,
  });
  const [isPartnerIndex, setIsPartnerIndex] = React.useState(new IndexPath(0));
  const [asssociateWithLoan, setAssociateWithLoan] = React.useState(
    new IndexPath(0)
  );
  const [partnerTypeIndex, setPartnerTypeIndex] = React.useState(
    new IndexPath(0)
  );

  // Auto Complete
  const [createContact, { isSuccess: addSuccess, data: addData }] =
    useAddNewContactMutation();
  const [patchLoan] = useEditLoanMutation();
  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [selectedLoan, setSelectedLoan] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [loansAutoCompleteData, setLoansAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [loanQuery, setLoanQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } = useGetLoansQuery(
    'exists[contact]=false' + loanQuery
  );

  useEffect(() => {
    if (!isFetching) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLoans) {
      setLoansAutoCompleteData([...dataTransformer(loansData)]);
    }
  }, [isFetchingLoans]);

  useEffect(() => {
    if (defaultData) {
      //leadId
      if (defaultData['@type'] === 'Lead') {
        setFormData((prevData: any) => ({
          ...prevData,
          leadId: defaultData.id,
        }));
      }

      if (defaultData.isPartner) {
        let partnerIndex = 0;
        PARTNERTYPEMAPPING.forEach((partner: any, index: number) => {
          if (partner.value === defaultData.partnerType) {
            partnerIndex = index;
          }
        });
        setIsPartnerIndex(new IndexPath(1));
        setPartnerTypeIndex(new IndexPath(partnerIndex));
      }
    }
  }, [defaultData]);

  useEffect(() => {
    if (!isFetchingContacts) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts]);
  //  End Auto Complete

  useEffect(() => {
    if (isPartnerIndex?.row === 1) {
      setFormData((prevData) => ({ ...prevData, isPartner: true }));
    } else {
      setFormData((prevData) => ({ ...prevData, isPartner: false }));
    }
  }, [isPartnerIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      partnerType: PARTNERTYPEMAPPING[partnerTypeIndex.row].value,
    }));
  }, [partnerTypeIndex]);

  useEffect(() => {
    if (selectedAssociate && selectedAssociate['@id'])
      setFormData((prevData) => ({
        ...prevData,
        referredBy: selectedAssociate['@id'],
        referralType: selectedAssociate.type,
      }));
  }, [selectedAssociate]);

  async function handleSuccess() {
    if (defaultData?.callback) {
      await defaultData.callback();
    }

    if (selectedLoan?.id) {
      patchLoan({ id: selectedLoan.id.split('/')[2], contact: addData['@id'] });
    }

    setTimeout(() => {
      cancelAction(addData);
    }, 2000);
  }

  useEffect(() => {
    if (addSuccess && addData) {
      handleSuccess();
    }
  }, [addSuccess, addData]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      checkForRequiredFields();
      createContact(formData);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  function handleLoanSearchQuery(text: string) {
    setLoanQuery(`search=${text}`);
  }

  function checkForRequiredFields() {
    if (formData?.firstName === '') throw new Error('First Name is required');
    if (formData?.lastName === '') throw new Error('First Name is required');
    if (formData?.email === '' && formData?.mobilePhone === '')
      throw new Error('Please fill out a contact method(email, or phone)');

    if (isPartnerIndex?.row === 1 && partnerTypeIndex?.row === 0)
      throw new Error('Please Select Partner Type');
    if (formData?.email && !formData.email.includes('@'))
      throw new Error('Please enter a valid email address');
  }

  const handleNextStep = () => {
    setError(null);
    if (formStep === 1) {
      if (asssociateWithLoan.row === 1 && !selectedLoan?.id) {
        //@ts-ignore
        setError('Please select a loan');
      } else {
        setFormStep(2);
      }
    }
  };
  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Create A Contact'} />
      {formStep === 1 ? (
        <View
          style={{
            flex: 1,
            minHeight: asssociateWithLoan.row === 1 ? 425 : 225,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="Associate With Loan?" required={false}>
                <KittenSelect
                  selectedIndex={asssociateWithLoan}
                  setSelectedIndex={setAssociateWithLoan}
                  options={BOOLEANFIELDS}
                />
              </FormGroup>
            </View>
          </View>
          {asssociateWithLoan.row === 1 ? (
            <View style={{ flexDirection: 'column' }}>
              <View style={{ flex: 1 }}>
                <FormGroup label="Select a loan" required={true}>
                  {/* <Table dataHandler={useGetLoansQuery} />   */}
                  <GlobalSearchAutoComplete
                    clearAutoCompleteData={() => setSelectedLoan([])}
                    autoCompleteData={loansAutoCompleteData}
                    isFetching={isFetchingLoans}
                    setQuery={handleLoanSearchQuery}
                    listDirection="down"
                    handleOnChange={(e) => setSelectedLoan(e)}
                    defaultValue={null}
                    disableAddNew={true}
                    page={1}
                    setPage={() => null}
                  />
                </FormGroup>
              </View>
            </View>
          ) : null}
        </View>
      ) : null}
      {formStep === 2 ? (
        <>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="Is Partner ?" required={false}>
                <KittenSelect
                  selectedIndex={isPartnerIndex}
                  setSelectedIndex={setIsPartnerIndex}
                  options={BOOLEANFIELDS}
                />
              </FormGroup>
            </View>
            {isPartnerIndex?.row === 1 ? (
              <View style={{ flex: 1 }}>
                <FormGroup label="Partner Type">
                  <KittenSelect
                    selectedIndex={partnerTypeIndex}
                    setSelectedIndex={setPartnerTypeIndex}
                    options={PARTNERTYPE}
                  />
                </FormGroup>
              </View>
            ) : null}
          </View>
          {/* Name */}
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="First Name">
                <Input
                  placeholder="First Name"
                  value={formData['firstName']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['firstName']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
            <View style={{ flex: 1 }}>
              <FormGroup label="Last Name">
                <Input
                  placeholder="Last Name"
                  value={formData['lastName']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['lastName']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
          </View>
          {/* Contact */}
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="Email" required={false}>
                <Input
                  placeholder="Email"
                  value={formData['email']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['email']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
            <View style={{ flex: 1 }}>
              <FormGroup label="Mobile Phone" required={false}>
                <Input
                  placeholder="Mobile Phone"
                  value={formData['mobilePhone']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['mobilePhone']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
          </View>
          {/* Referred Type */}
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flex: 1 }}>
              <FormGroup label="Referred By " required={false}>
                <GlobalSearchAutoComplete
                  clearAutoCompleteData={() => setAutoCompleteData([])}
                  autoCompleteData={autoCompleteData}
                  isFetching={isFetching}
                  setQuery={setQuery}
                  handleOnChange={(e) => setSelectedAssociate(e)}
                  defaultValue={
                    defaultData?.referred
                      ? {
                          type: defaultData.referred.type,
                          id: defaultData.referred.id,
                          ['@id']: defaultData.referred['@id'],
                          contactId: defaultData.referred.id,
                          title: defaultData.referred.name,
                          name: defaultData.referred.name,
                        }
                      : null
                  }
                />
              </FormGroup>
            </View>
          </View>
          {/* Address */}
          <FormGroup label="Street Address" required={false}>
            <Input
              placeholder="Street Address"
              value={formData['streetAddress']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['streetAddress']: nextValue,
                }));
              }}
            />
          </FormGroup>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 2 }}>
              <FormGroup label="City" required={false}>
                <Input
                  placeholder="City"
                  value={formData['city']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['city']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
            <View style={{ flex: 1 }}>
              <FormGroup label="State" required={false}>
                <Input
                  maxLength={2}
                  placeholder="State"
                  value={formData['state']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['state']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
            <View style={{ flex: 1 }}>
              <FormGroup label="Zipcode" required={false}>
                <Input
                  placeholder="Zipcode"
                  value={formData['zipCode']}
                  onChangeText={(nextValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      ['zipCode']: nextValue,
                    }));
                  }}
                />
              </FormGroup>
            </View>
          </View>
        </>
      ) : null}
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}

      {!!addSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={formStep == 2 ? handleSubmit : handleNextStep}
        disabled={formSubmitted}
        submitText={formStep === 2 ? 'CREATE' : 'NEXT'}
      />
    </Layout>
  );
}

// function RenderLoanItem({ item }) {
//   console.log('ITEM::::', item);
//   return (
//     <Layout level='2' style={{ padding: 16, flexDirection: 'row', alignItems: 'center', marginHorizontal: 4 }}>
//       <Text category='label' style={{marginRight: 8}}>Loan Number: {item.loanNumber}</Text>
//       <Text style={{marginRight: 8}}>{item.firstName} {item.lastName}</Text>
//       <Text>{item.email}</Text>
//     </Layout>
//   );
// }

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default ConvertToContactForm;
