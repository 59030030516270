// eslint-disable-next-line max-len
const countTaskCompleted = (tasks) => tasks.reduce((acc, { isCompleted }) => (isCompleted ? acc + 1 : acc), 0);

export function getTasksPerformanceData(tasks) {
  const tasksCompleted = countTaskCompleted(tasks);
  const progressBarData = tasksCompleted / tasks.length;
  const msg = `${tasksCompleted} of ${tasks.length} Tasks Complete`;
  return { progressBarData, msg };
}

export function pinnedNotesToTop(list) {
  const notesPinned = list.filter(
    (activity) => activity.activityType === 'note' && activity.pinned,
  );
  const otherActivities = list.filter(
    (activity) => !notesPinned.includes(activity),
  );
  return notesPinned.concat(otherActivities);
}

export function sortActivityList(lists) {
  // eslint-disable-next-line max-len
  return lists.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
}

export function getNotes({ notes, display }) {
  return notes.map((note) => ({
    ...note,
    activityDate: note.modifiedAt,
    activityType: 'note',
    display,
  }));
}

export function getTasks({ tasks, display }) {
  return tasks.map((task) => ({
    ...task,
    activityDate: task.dueDate,
    activityType: 'task',
    display,
  }));
}

export function getEvents({ events, display }) {
  return events.map((event) => ({
    ...event,
    activityDate: event.startDatetime,
    activityType: 'event',
    display,
  }));
}

export function getLoanApplications({ loans, loanApplicationsControl }) {
  return loans.map((app) => ({
    ...app,
    appDate: app.applicationDate,
    activityType: 'loanApplication',
    display: loanApplicationsControl,
  }));
}

export function getLoanProspects({ loans, loanProspectsControl }) {
  return loans.map((app) => ({
    ...app,
    appDate: app.createdAt,
    activityType: 'loanProspect',
    display: loanProspectsControl,
  }));
}

export function getLeadStatusLogs({ leadStatusLogs, display }) {
  return leadStatusLogs.map((log) => ({
    ...log,
    activityDate: log.modifiedAt,
    activityType: 'leadStatusLog',
    display,
  }));
}
