import React from 'react';
import {
  Pressable,
  View,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { Text, Layout, Icon } from '@ui-kitten/components';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import {
  useGetLoansPreApprovalQuery,
  useGetContactsPreApprovalsQuery,
} from '../../services/PreApprovalLoanSlice';
import { currencyFormat } from '../../helpers/dataTransformerHelper';
import Colors from '../../helpers/Colors';
import { showModal as realShow } from '../../services/FormSlice';

type Props = {
  clientData: {
    '@type': string;
    id: number;
    '@id': string;
    loanContact?: any;
    contact?: any;
  };
};

function PreapprovalList({ clientData }: Props) {
  const isLoan = clientData['@type'] === 'Loan';
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { data, isFetching, refetch } = isLoan
    ? useGetLoansPreApprovalQuery(clientData.id)
    : useGetContactsPreApprovalsQuery(clientData.id);

  function RenderItem({ item }) {
    const [isHovered, setIsHovered] = React.useState(false);
    const email = clientData?.loanContact?.email || clientData?.contact?.email;
    return (
      <Pressable
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        onPress={() => {
          showModal({ formType: 'preapproval', data: { ...item, email } });
        }}
        style={{
          padding: 8,
          borderBottomWidth: 1,
          borderBottomColor: '#eee',
          backgroundColor: isHovered ? '#c9e2ff61' : 'white',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text category="label" style={[styles.listText, Platform.OS !== 'web' && { fontSize: 10 }]}>
            {format(new Date(item.createdAt), 'P')}
          </Text>
          <Text category="label" style={styles.listText}>
            {currencyFormat(item.loanAmount)}
          </Text>
          <Text category="label" style={styles.listText}>
            {item.status || ''}
          </Text>
          <Text category="label" style={[styles.listText, Platform.OS !== 'web' && { fontSize: 10 }]}>
            {item.sentAt ? format(new Date(item.sentAt), 'P') : ''}
          </Text>
          <Text category="label" style={styles.listText}>
            {item.sentMethod || ''}
          </Text>
          <View style={{ flex: 1 }}>
            {!item.sentMethod ? (
              <Pressable onPress={(e) => {
                e.preventDefault();
                showModal({
                  formType: 'preapproval',
                  data: {
                    clientData,
                    preapprovalData: item,
                  },
                });
              }}
              >
                <Icon
                  style={{ width: 22, height: 22 }}
                  fill={Colors.darkBlue}
                  name="edit-outline"
                />
              </Pressable>
            ) : null}
          </View>
        </View>
      </Pressable>
    );
  }

  return (
    <Layout style={styles.cardWrapper}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Text style={styles.cardHeader} category="h6">
          Pre-Approvals
        </Text>
        {isLoan ? (
          <Pressable
            onPress={() => {
              showModal({
                formType: 'preapproval',
                data: {
                  clientData,
                },
              });
            }}
            style={{ position: 'absolute', right: 16 }}
          >
            <Text category="label" style={{ color: 'white' }}>
              Add
            </Text>
          </Pressable>
        ) : null}
      </View>
      <View style={{ flex: 1 }}>
        {isFetching ? (
          <ActivityIndicator style={{ paddingVertical: 16 }} />
        ) : null}
        {data ? (
          <FlatList
            ListHeaderComponent={() => (
              <Layout
                level="3"
                style={{
                  flexDirection: 'row',
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                  justifyContent: 'center',
                }}
              >
                <View style={{ flex: 3 }}>
                  <Text category="label">Created</Text>
                </View>
                <View style={{ flex: 3 }}>
                  <Text category="label">Amount</Text>
                </View>
                <View style={{ flex: 3 }}>
                  <Text category="label">Status</Text>
                </View>
                <View style={{ flex: 3 }}>
                  <Text category="label">Sent Date</Text>
                </View>
                <View style={{ flex: 3 }}>
                  <Text category="label">Method</Text>
                </View>
                <View style={{ flex: 1 }} />
              </Layout>
            )}
            data={data}
            renderItem={({ item }) => <RenderItem item={item} />}
            keyExtractor={(item) => item['@id']}
          />
        ) : null}
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    shadowColor: '#000',
    flex: 1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    minHeight: 120,
    elevation: 3,
    marginTop: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  listText: {
    flex: 3,
  },
});

export default PreapprovalList;
