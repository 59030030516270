import React, { useEffect, useMemo, useState } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Pressable,
  Dimensions,
  Linking,
  Platform,
  FlatList,
  RefreshControl,
} from 'react-native';
import {
  Avatar,
  CheckBox,
  Icon,
  Layout,
  Spinner,
  Text,
} from '@ui-kitten/components';
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import produce from 'immer';
import {
  currencyFormat,
  formatPhoneNumber,
} from '../../helpers/dataTransformerHelper';
// @ts-ignore
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import Pagination from './TablePagination';
import TableActions from './TableActions';
import DateFilter from './DateFilter';
import RangeFilter from './RangeFilter';
import { Filter, Order, SavedView } from '../../interfaces/Table';
import OrderFilter from './OrderFilter';
import DropdownFilter from './DropdownFilter';
import {
  selectLocalViewState,
  setActiveView as setActiveViewReducer,
  setColumnFilter,
  setEnhancedColumns,
  setPage,
  setQueryString,
} from '../../services/TableSlice';
import metadata, { defaultViews } from '../../helpers/ObjectMetadata';
import SearchFilter from './SearchFilter';
import { useRoute } from '../../navigation/router';

type Props = {
  dataHandler: Function;
  objectName: string;
  customActions?: Function;
  disableFilters?: string[] | null;
  defaultSavedView?: SavedView[];
};

const rangeFilterOptions = {
  '<': 'lt',
  '<=': 'lte',
  '>': 'gt',
  '>=': 'gte',
};

const dateFilterOptions = {
  '<': 'strictly_before',
  '<=': 'before',
  '>': 'strictly_after',
  '>=': 'after',
  '<>': 'not_equal',
  '=': 'equal',
};

const COLORS = ['#00E096', '#3366FF', '#FFAA00', '#FF3D71', '#DB2C66', 'red'];
const COLORMAPPING = ['abcd', 'efgh', 'ijklm', 'nopqrst', 'uvwxyz', ''];

function getColorMap(character: string) {
  let color = 'red';
  COLORMAPPING.forEach((characters) => {
    if (characters.includes(character.toLowerCase())) {
      color = COLORS[COLORMAPPING.indexOf(characters)];
    }
  });
  return color;
}

let allData = [];

function GlobalTable({
  dataHandler,
  objectName,
  customActions,
  disableFilters,
  defaultSavedView,
}: Props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const [getData, { data, isFetching, isSuccess, isLoading }] = dataHandler({});

  const localViewState = useSelector((state) =>
    selectLocalViewState(state, objectName)
  );
  const { activeView: savedView, enhancedColumns: columns } = localViewState;
  const route = useRoute();

  useEffect(() => {
    // checks route params and sets column filter
    if (!Object.keys(columns).length) return;
    if (!route.params) return;
    const { column, operator, value } = route.params;
    if (column && operator && value && Platform.OS === 'web') {
      const payload = {
        objectName,
        filter: {
          column,
          operator,
          value,
        },
      };
      dispatch(setColumnFilter(payload));
    }
  }, [columns]);

  // sets searchable fields
  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const newColumns = produce(metadata[objectName], (draft) => {
      return data['hydra:search']['hydra:mapping'].forEach(
        (field: { property: string; variable: string }) => {
          if (!draft[field.property]) {
            return;
          }
          if (field.variable.startsWith('order')) {
            draft[field.property].sortable = true;
            return;
          }
          let filterType = draft[field.property].filterType || null;

          if (draft[field.property].dropdownOptions) {
            filterType = 'dropdown';
          }
          if (field.variable.includes('[before]')) {
            filterType = 'dateFilter';
          }

          if (field.variable.includes('[gt]')) {
            filterType = 'rangeFilter';
          }
          if (filterType === null) {
            filterType = 'searchbar';
          }
          draft[field.property].filterType = filterType;
        }
      );
    });

    dispatch(setEnhancedColumns({ objectName, enhancedColumns: newColumns }));
  }, [isSuccess, objectName]);

  // sets default view for mobile
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const defaultView = defaultSavedView || defaultViews[objectName];
      dispatch(
        setActiveViewReducer({
          objectName,
          savedView: defaultView.find((view) => view.isNative),
        })
      );
    }
  }, [dispatch, objectName]);

  const combined = useMemo(() => {
    if (!data) {
      return [];
    }
    if (localViewState?.page === 1) {
      allData = data['hydra:member'];
    } else {
      allData = [...allData, ...data['hydra:member']];
    }
    return allData;
  }, [data, localViewState]);

  useEffect(() => {
    if (!savedView) {
      return;
    }
    allData = [];
  }, [savedView]);

  function handleColumnName(allFilters, column: string) {
    if (allFilters[column].length !== 0) {
      return `${column}[]`;
    }
    return column;
  }

  function handleFilterValue(filter) {
    return encodeURI(filter);
  }

  function isValidDate(dateString) {
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    return dateRegex.test(dateString);
  }

  // Compiles Filters and triggers refetch
  useEffect(() => {
    const params = [];
    if (!savedView) {
      return;
    }
    // storeTableData({ ...savedView, globalSearch: globalSearch || '' });
    let allFilters = [];
    if (savedView.filters) {
      allFilters = [...savedView.filters];
    }
    if (savedView.categoryFilter) {
      allFilters = [...allFilters, ...savedView.categoryFilter];
    }
    const filtersByColumn = {};
    allFilters.forEach((filter: Filter) => {
      if (!filtersByColumn[filter.column]) {
        filtersByColumn[filter.column] = [];
      }
      filtersByColumn[filter.column].push(filter);
    });
    allFilters.forEach((filter: Filter) => {
      const value = handleFilterValue(filter.value);
      const customFilterFunction = columns[filter.column]?.customFilterFunction;
      const filterType = isValidDate(value)
        ? 'dateFilter'
        : columns[filter.column]?.filterType || 'searchbar';
      if (customFilterFunction) {
        const customFilter = customFilterFunction(filter);
        Object.keys(customFilter).forEach((key) => {
          params.push(`${key}=${customFilter[key]}`);
        });
        return;
      }

      switch (filterType) {
        case 'searchbar':
          params.push(
            `${handleColumnName(filtersByColumn, filter.column)}=${value}`
          );
          break;
        case 'dropdown':
          params.push(
            `${handleColumnName(filtersByColumn, filter.column)}=${value}`
          );
          break;
        case 'dateFilter':
          // eslint-disable-next-line no-case-declarations
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
          const filterValue = dateRegex.test(filter.value)
            ? filter.value
            : moment(filter.value).format('L');

          if (filter.operator === '=') {
            params.push(`${filter.column}[strictly_after]=${filterValue}`);
            params.push(
              `${filter.column}[strictly_before]=${moment(filter.value)
                .add(2, 'days')
                .format('L')}`
            );
          } else if (filter.operator === '<>') {
            params.push(`${filter.column}[strictly_before]=${filterValue}`);
            params.push(`${filter.column}[strictly_after]=${filterValue}`);
          } else {
            params.push(
              `${filter.column}[${
                dateFilterOptions[filter.operator]
              }]=${filterValue}`
            );
          }
          break;
        case 'rangeFilter':
          params.push(
            `${filter.column}[${rangeFilterOptions[filter.operator]}]=${value}`
          );
          break;
        default:
          break;
      }
    });

    if (localViewState.globalSearch !== '') {
      params.push(`search=${handleFilterValue(localViewState.globalSearch)}`);
    }

    savedView.order.forEach((order: Order) => {
      params.push(`order[${order.column}]=${order.direction}`);
    });

    if (localViewState.page !== 1) {
      params.push(`page=${localViewState.page}`);
    }
    const queryString = params.join('&') || '';
    dispatch(setQueryString({ objectName, queryString }));
    getData(queryString, true);
  }, [savedView, localViewState.page, localViewState.globalSearch, getData]);
  // reset selected on page change
  useEffect(() => {
    if (isFetching && selectedRows.length > 0) {
      setSelectedRows([]);
    }
  }, [isFetching, selectedRows.length]);

  function handleChecked(rowData) {
    if (selectedRows.filter((x: any) => x['@id'] === rowData['@id']).length) {
      setSelectedRows((prevState) => [
        ...prevState.filter((x: any) => x['@id'] !== rowData['@id']),
      ]);
    } else {
      setSelectedRows((prevState) => [
        ...prevState,
        {
          ...rowData,
          '@id': rowData['@id'],
          '@type': rowData['@type'],
          email: rowData.email || null,
        },
      ]);
    }
  }

  function handleSelectAll() {
    const tableData = data ? data['hydra:member'] : [];
    if (Object.keys(selectedRows).length === tableData.length) {
      setSelectedRows([]);
    } else {
      const allSelected = tableData.reduce((acc: any, client: any) => {
        acc.push({
          '@id': client['@id'],
          '@type': client['@type'],
          email: client.email || null,
        });
        return acc;
      }, []);
      setSelectedRows(allSelected);
    }
  }

  const formatCell = (rowData: any, column: string) => {
    const pathPieces = column.split('.');
    let cellData;
    if (pathPieces.length > 1) {
      cellData = rowData;
      pathPieces.forEach((pathPiece) => {
        cellData = cellData?.[pathPiece];
      });
    } else {
      cellData = rowData[column];
    }

    switch (columns[`${column}`]?.type) {
      case 'custom':
        return columns[column].customFormatter(rowData);
      case 'phone':
        if (cellData) {
          return formatPhoneNumber(cellData);
        }
        return '';
      case 'percent':
        return `${cellData || ''}%`;
      case 'currency':
        if (cellData) {
          return `${currencyFormat(cellData, columns[`${column}`]?.decimalPlaces || 0)}`;
        }
        return '';
      case 'date':
        if (cellData) {
          return moment(cellData).format('MM/DD/YYYY');
        }
        return '';
      default:
        return !cellData ? '' : cellData;
    }
  };

  const renderMobileRow = ({ item }) => {
    let line1 = '';
    let line2 = '';
    let line3 = '';
    let rightSide = '';

    savedView.columns.forEach((columnName, index) => {
      // const value = item[columnName];
      const value = formatCell(item, columnName);
      if (index === 0 || index === 1) {
        line1 = line1 === '' ? value : `${line1} ${value}`;
        return;
      }
      if (index === 2) {
        line2 = value;
        return;
      }
      if (index === savedView.columns.length - 1) {
        rightSide = value;
        return;
      }
      line3 = line3 === '' ? value : `${line3} | ${value}`;
    });

    return (
      <Link
        route={
          routes.ExtraRoutes[objectName === 'Commission' ? 'Loan' : objectName]
        }
        routeParams={{ id: item.id }}
      >
        <View
          style={{
            padding: 4,
            width: Dimensions.get('screen').width - 70 || '100%',
          }}
        >
          <Text
            category="label"
            style={{ fontSize: 16, fontFamily: 'VerbBold' }}
          >
            {line1}
          </Text>
          <Text category="p2" style={{ fontStyle: 'italic', marginBottom: 2 }}>
            {line2}
          </Text>
          <Text category="p2" style={{ fontStyle: 'italic' }}>
            {line3}
          </Text>
          <Layout
            level="3"
            style={{
              position: 'absolute',
              right: 0,
              padding: 4,
              borderRadius: 4,
              top: '50%',
              transform: [{ translateY: -5 }],
            }}
          >
            <Text category="label" style={{ fontSize: 10 }}>
              {rightSide}
            </Text>
          </Layout>
        </View>
      </Link>
    );
  };

  const renderMobileItem = ({ item }: any) => (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        position: 'relative',
        width: '100%',
      }}
    >
      <View style={styles.itemRow}>
        <View style={styles.avatarWrap}>
          {!selectedRows?.find((contact) => contact['@id'] === item['@id'])
            ?.id ? (
            <Pressable
              onPress={() => {
                if (
                  selectedRows?.find(
                    (contact) => contact['@id'] === item['@id']
                  )?.id
                ) {
                  // remove
                  const newState = selectedRows.filter(
                    (contact) => contact['@id'] !== item['@id']
                  );
                  setSelectedRows(newState);
                  // setDefaultData({ contactList: newState });
                } else {
                  const newState = [...selectedRows, item];
                  setSelectedRows(newState);
                  // setDefaultData({ contactList: newState });
                }
              }}
              style={[
                styles.avatar,
                {
                  backgroundColor: getColorMap(
                    item?.name
                      ? item?.name[0]
                      : item.loanContact.firstName
                      ? item.loanContact.firstName[0]
                      : 'N/A'
                  ),
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: 'VerbBold',
                  color: 'white',
                  fontSize: 22,
                  marginTop: 4,
                  textTransform: 'uppercase',
                }}
              >
                {item.name
                  ? item.name[0]
                  : item.loanContact?.firstName
                  ? item.loanContact.firstName[0]
                  : 'N/A'}
              </Text>
            </Pressable>
          ) : (
            <Pressable
              onPress={() => {
                if (
                  selectedRows?.find(
                    (contact) => contact['@id'] === item['@id']
                  )?.id
                ) {
                  // remove
                  const newState = selectedRows.filter(
                    (contact) => contact['@id'] !== item['@id']
                  );
                  setSelectedRows(newState);
                  // setDefaultData({ contactList: newState });
                } else {
                  const newState = [...selectedRows, item];
                  setSelectedRows(newState);
                  // setDefaultData({ contactList: newState });
                }
              }}
              style={[styles.avatar, { backgroundColor: '#0095FF' }]}
            >
              <Icon
                style={{ width: 24, height: 24 }}
                fill="#fff"
                name="checkmark-outline"
              />
            </Pressable>
          )}
        </View>
        {renderMobileRow({ item })}
      </View>
    </View>
  );

  return Platform.OS === 'web' ? (
    <Layout style={{ flexDirection: 'row', flex: 1, paddingVertical: 0 }}>
      <View style={{ flex: 1 }}>
        <View
          style={{ flex: 1, maxWidth: Dimensions.get('window').width - 100 }}
        >
          <TableActions
            defaultSavedView={defaultSavedView}
            customActions={customActions}
            selectedRows={selectedRows}
            objectName={objectName}
            disableFilters={disableFilters}
          />
          {data && savedView && Object.keys(columns).length !== 0 ? (
            <ScrollView
              nestedScrollEnabled
              horizontal
              style={{ flex: 1, width: '100%', paddingBottom: 4 }}
              contentContainerStyle={{
                minWidth: Dimensions.get('window').width - 100,
              }}
            >
              <ScrollView style={{ flex: 1 }}>
                {isFetching ? (
                  <LinearProgress />
                ) : (
                  <View style={{ height: 4 }} />
                )}
                <Table
                  borderStyle={{ borderWidth: 1, borderColor: '#ddd', flex: 1 }}
                >
                  <TableWrapper
                    style={{
                      ...styles.row,
                      backgroundColor: '#ebebeb',
                    }}
                  >
                    <Cell
                      style={styles.selectAllCell}
                      data={
                        <CheckBox
                          checked={
                            selectedRows.length ===
                              (data ? data['hydra:member'].length : 0) &&
                            selectedRows.length > 0
                          }
                          onChange={() => handleSelectAll()}
                        />
                      }
                    />
                    {savedView?.columns.map((columnName) => {
                      const column = columns[columnName];
                      return (
                        <Cell
                          key={columnName}
                          style={{
                            justifyContent: 'flex-start',
                            padding: 6,
                            minWidth: column.width || 50,
                            maxWidth: column.maxWidth || 500,
                            flex: 1,
                          }}
                          textStyle={styles.headText}
                          data={
                            <View>
                              <View style={{ flexDirection: 'row' }}>
                                {/* sort */}
                                <OrderFilter
                                  order={savedView.order.find(
                                    (order: Order) =>
                                      order.column === columnName
                                  )}
                                  column={column}
                                  objectName={objectName}
                                >
                                  <Text
                                    style={{
                                      fontSize: 13,
                                      fontStyle: 'italic',
                                      fontFamily: 'VerbBold',
                                    }}
                                  >
                                    {column.label}
                                  </Text>
                                </OrderFilter>
                              </View>
                              {disableFilters?.length &&
                              disableFilters.includes(column.name) ? null : (
                                <>
                                  {column.filterType === 'dropdown' && (
                                    <DropdownFilter
                                      columnName={columnName}
                                      savedView={savedView}
                                      dropdownOptions={column.dropdownOptions}
                                      objectName={objectName}
                                    />
                                  )}
                                  {column.filterType === 'dateFilter' && (
                                    <DateFilter
                                      savedView={savedView}
                                      columnName={columnName}
                                      objectName={objectName}
                                    />
                                  )}

                                  {column.filterType === 'rangeFilter' && (
                                    <RangeFilter
                                      savedView={savedView}
                                      objectName={objectName}
                                      columnName={columnName}
                                    />
                                  )}

                                  {column.filterType === 'searchbar' && (
                                    <SearchFilter
                                      objectName={objectName}
                                      columnName={columnName}
                                      savedView={savedView}
                                    />
                                  )}
                                </>
                              )}
                            </View>
                          }
                        />
                      );
                    })}
                  </TableWrapper>
                </Table>
                {!isSuccess ? (
                  <ActivityIndicatorComponent />
                ) : (
                  <Table
                    borderStyle={{
                      borderWidth: 1,
                      borderColor: '#eee',
                      flex: 1,
                    }}
                  >
                    {data &&
                      data['hydra:member'].map((rowData, index) => (
                        <TableWrapper
                          key={rowData['@id']}
                          style={{
                            ...styles.row,
                            backgroundColor: index % 2 ? '#f2f2f2' : '#fff',
                          }}
                        >
                          <Cell
                            style={styles.selectAllCell}
                            data={
                              <CheckBox
                                checked={
                                  selectedRows.filter(
                                    (x: any) => x['@id'] === rowData['@id']
                                  ).length > 0
                                }
                                onChange={() => handleChecked(rowData)}
                              />
                            }
                          />
                          {savedView.columns.map((columnName) => (
                            <Cell
                              key={rowData.iri + columnName}
                              data={
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Link
                                    style={{ textDecorationLine: 'none' }}
                                    route={routes.ExtraRoutes[rowData['@type']]}
                                    routeParams={{ id: rowData.id }}
                                  >
                                    <Text>
                                      {formatCell(rowData, columnName)}
                                    </Text>
                                  </Link>
                                  {columnName === 'loanNumber' &&
                                  rowData[columnName]?.length === 9 ? (
                                    <Pressable
                                      onPress={(e) => {
                                        e.preventDefault();
                                        Linking.openURL(
                                          `https://los.p5.empower.bkicloud.com/ArkMortgage.LOSWeb/WebMain/Main.aspx?loannumber=${rowData[columnName]}`
                                        );
                                      }}
                                    >
                                      <Avatar
                                        size="tiny"
                                        style={{ marginRight: 16 }}
                                        source={require('../../../assets/images/blackknight.png')}
                                      />
                                    </Pressable>
                                  ) : null}
                                </View>
                              }
                              style={{
                                ...styles.headText,
                                flex: 1,
                                minWidth: columns[columnName]?.width,
                                maxWidth: columns[columnName]?.maxWidth || 500,
                              }}
                              textStyle={styles.text}
                            />
                          ))}
                        </TableWrapper>
                      ))}
                  </Table>
                )}
              </ScrollView>
            </ScrollView>
          ) : (
            <ActivityIndicatorComponent />
          )}
        </View>
        <Pagination
          page={localViewState.page}
          setPage={(e: number) => {
            dispatch(setPage({ objectName, page: e }));
          }}
          data={data}
        />
      </View>
    </Layout>
  ) : (
    // Mobile View
    <View style={styles.mobileContainer}>
      {isSuccess ? (
        <FlatList
          data={combined}
          renderItem={renderMobileItem}
          onEndReached={() => {
            if (data?.['hydra:view'] && data['hydra:view']['hydra:next']) {
              dispatch(setPage({ objectName, page: localViewState.page + 1 }));
            }
          }}
          onEndReachedThreshold={0.35}
          refreshing={isFetching}
          keyExtractor={(item, index) => item['@id'] + index}
        />
      ) : (
        <ActivityIndicatorComponent />
      )}
      {/* {selectedRows.length  ? (
      <SingleActionFab icon="done-all-outline" action={() => handleSelectAll()} />
      ) : null}  */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    justifyContent: 'flex-start',
    backgroundColor: '#eee',
  },
  mobileContainer: {
    flex: 1,
  },
  button: {
    margin: 2,
  },
  icons: {
    padding: 8,
    marginHorizontal: 8,
  },
  head: { height: 32, backgroundColor: 'rgb(0, 122, 255)' },
  headText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#fff',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  text: { margin: 2, fontSize: 12, textAlign: 'left' },
  row: { flexDirection: 'row', backgroundColor: '#fff' },
  selectAllCell: {
    width: 45,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderColor: '#ddd',
    backgroundColor: '#fff',
    borderBottomWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 12,
  },
  actionWrapper: {
    borderWidth: 1,
    borderColor: '#eee',
    padding: 4,
    minHeight: 45,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 38,
  },
  selectedText: {
    marginRight: 16,
    fontSize: 12,

    fontStyle: 'italic',
    paddingLeft: 12,
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 8,
    borderBottomWidth: 1,
    borderColor: '#8F9BB3',
    width: '100%',
  },
  avatarWrap: {
    width: 48,
    height: 48,
    marginRight: 8,
    marginVertical: 8,
    position: 'relative',
  },
  avatar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'red',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default GlobalTable;
