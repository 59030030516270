import { apiSlice } from './ApiSlice';

export const UserSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => '/users',
      transformResponse: (response: any) => response['hydra:member'],
    }),
    getAllUserNames: build.query({
      query: () => '/users?properties[]=firstName&properties[]=lastName',
      transformResponse: (response: any) => response['hydra:member'],
    }),
    getMe: build.query({
      query: () => ({
        url: 'users/me',
      }),
      transformResponse: async (response: any, meta:any) => {
        const idHash = meta?.response?.headers?.get('X-Crm-User-Id');
        const emailHash = meta?.response?.headers?.get('X-Crm-Email');
        const mobileHash = meta?.response?.headers?.get('X-Crm-Mobile');
        const data = await response;
        return {...data, idHash, emailHash, mobileHash};
      },
      providesTags: [{ type: 'User' }],
    }),
    patchUser: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: () => [
        { type: 'User' },
      ],
    }),
    updateNotificationSetting: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `users/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/ld+json',
        },
        body: patch,
      }),
      invalidatesTags: () => [
        { type: 'User' },
      ],
      onQueryStarted: ({ id, ...patch }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          UserSlice.util.updateQueryData('getMe', {}, (draft) => {
            Object.assign(draft.notificationSettings, patch.notificationSettings);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateTimeBlocks: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `users/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/ld+json',
        },
        body: patch,
      }),
      invalidatesTags: () => [
        { type: 'User' },
      ],
      onQueryStarted: ({ id, ...patch }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          UserSlice.util.updateQueryData('getMe', {}, (draft) => {
            Object.assign(draft.timeBlocks, patch.timeBlocks);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateFrontendSettings: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `users/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/ld+json',
        },
        body: patch,
      }),
      invalidatesTags: () => [
        { type: 'User' },
      ],
      onQueryStarted: ({ id, ...patch }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          UserSlice.util.updateQueryData('getMe', {}, (draft) => {
            Object.assign(draft?.frontendSettings || {}, patch?.frontendSettings);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetMeQuery,
  useGetAllUserNamesQuery,
  useLazyGetMeQuery,
  usePatchUserMutation,
  useUpdateNotificationSettingMutation,
  useUpdateTimeBlocksMutation,
  useUpdateFrontendSettingsMutation,
} = UserSlice;
