import { Button, Layout } from '@ui-kitten/components';
import React from 'react';
import { View } from 'react-native';

type Props = {
  toggleFilter: number;
  setToggleFilter: Function;
};

function LeadsMobilePills({ setToggleFilter, toggleFilter }: Props) {
  return (
    <Layout
      level="2"
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingHorizontal: 6,
        paddingVertical: 8,
      }}
    >
      <Button
        style={{ borderRadius: 25, paddingTop: 8 }}
        size="tiny"
        status={toggleFilter === 0 ? 'info' : 'basic'}
        appearance={'outline'}
        onPress={() => {
          setToggleFilter(0);
        }}
      >
        Partners
      </Button>
      <Button
        style={{ borderRadius: 25, paddingTop: 8 }}
        size="tiny"
        status={toggleFilter === 1 ? 'info' : 'basic'}
        appearance={'outline'}
        onPress={() => {
          setToggleFilter(1);
        }}
      >
        New Leads
      </Button>
      <Button
        style={{ borderRadius: 25, paddingTop: 8 }}
        size="tiny"
        status={toggleFilter === 2 ? 'info' : 'basic'}
        appearance={'outline'}
        onPress={() => {
          setToggleFilter(2);
        }}
      >
        Active Leads
      </Button>
      <Button
        style={{ borderRadius: 25, paddingTop: 8 }}
        size="tiny"
        status={toggleFilter === 3 ? 'info' : 'basic'}
        appearance={'outline'}
        onPress={() => {
          setToggleFilter(3);
        }}
      >
        Unqualified
      </Button>
    </Layout>
  );
}

export default LeadsMobilePills;
