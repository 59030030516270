import React, { useState, useRef, useEffect } from 'react';
import { Image, useWindowDimensions, View } from 'react-native';
import { Button, IconButton, ProgressBar, Text } from 'react-native-paper';
import { useNavigation, useRoute } from '../../../navigation/router';
import ColumnMapperWeb from './columnMapper.web.js';

export default function ImportLeadsWeb() {
  const [page, setPage] = useState(1);
  const [fileData, setFileData] = useState(null);
  const navigation = useNavigation();
  const route = useRoute();
  const { height, width } = useWindowDimensions();
  const draggable = useRef(null);
  // const type = navigation.getParam(type);

  useEffect(() => {
    draggable.current.addEventListener('dragover', handleDragOver);
    draggable.current.addEventListener('drop', handleDrop);

    return () => {
      try {
      draggable.current.removeEventListener('dragover', handleDragOver);
      draggable.current.removeEventListener('drop', handleDrop);
      }catch(err){
        console.log(err)
      }
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    fileValidation(files[0], 'drag');
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleBrowse = () => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    e.preventDefault();
    e.stopPropagation();
    fileValidation(fileUploaded, 'click');
  };

  const fileValidation = (file, type) => {
    const fileInput = type === 'click' ? document.getElementById('file') : file;

    const filePath = type === 'click' ? fileInput.value : fileInput.type;

    // Allowing file type
    const allowedExtensions = /(csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Invalid file type, please upload a CSV file');
      fileInput.value = '';
      return false;
    }
    setFileData(file);
  };

  return page === 1 ? (
    <View
      style={{
        flex: 1,
        height,
        backgroundColor: 'white',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: height * 0.1,
        }}
      >
        <IconButton
          icon="chevron-left"
          size={43}
          onPress={() => navigation.goBack()}
        />
        <Text
          style={{ fontSize: 43, fontFamily: 'VerbBold', color: '#346bb3' }}
        >
          Import {`${route.path.includes('leads') ? 'Leads' : 'Contacts'}`}
        </Text>
      </View>
      <View
        style={{
          height: height * 0.9,
          flex: 1,
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <View
            ref={draggable}
            style={{
              borderRadius: 20,
              borderStyle: 'dashed',
              borderWidth: 1,
              borderColor: 'black',
              padding: 20,
              paddingVertical: 100,
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '60%',
              minWidth: 350,
            }}
          >
            <IconButton icon="cloud-upload" color="#5E9BFF" size={90} />
            <Text style={{ fontSize: 24, textAlign: 'center', width: '60%' }}>
              Drag and drop files to upload
            </Text>
            <Text style={{ fontSize: 24, paddingVertical: 45 }}>OR</Text>

            <Button
              mode="contained"
              color="#224973"
              style={{ width: 180 }}
              onPress={() => handleBrowse()}
            >
              Browse
            </Button>
            <input
              id="file"
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </View>
        </View>
        <View
          style={{
            flex: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {fileData && (
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 38 }}>Your file is uploading...</Text>
              <Image
                resizeMode="contain"
                source={require('../../../../assets/images/fileUpload.png')}
                style={{
                  height: height * 0.3,
                  width: width * 0.3,
                }}
              />
              <View
                style={{
                  width: '95%',
                  height: 75,
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: '#C4C4C4',
                  justifyContent: 'center',
                  paddingHorizontal: '5%',
                  marginVertical: 10,
                  shadowOffset: { width: 1, height: 1 },
                  shadowColor: 'rgba(196, 196, 196, 0.5)',
                }}
              >
                <Text>{fileData.name}</Text>
                <ProgressBar progress={fileData ? 100 : 0} />
              </View>
              <Button
                mode="contained"
                color="#1976D2"
                icon="arrow-right"
                contentStyle={{ flexDirection: 'row-reverse' }}
                onPress={() => setPage(2)}
              >
                Continue
              </Button>
            </View>
          )}
        </View>
      </View>
    </View>
  ) : (
    <ColumnMapperWeb setPage={setPage} fileData={fileData} />
  );
}
