import React from 'react';
import { Divider, Layout, Text } from '@ui-kitten/components';
import { Platform, StyleSheet, View } from 'react-native';
import { format, parseISO } from 'date-fns';
import { useGetAllUserNamesQuery } from '../../services/UserSlice';

function MetadataCard({ loanData }) {
  const { data: users } = useGetAllUserNamesQuery({});
  function getUserName(userId) {
    const user = users?.find((item: string) => item['@id'] === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }
  return (
    <Layout style={styles.cardWrapper}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <Text
          style={[styles.cardHeader, { backgroundColor: '#aeaeae' }]}
          category="h6"
        >
          Metadata
        </Text>
      </View>
      <View
        style={{
          flexDirection: Platform.OS === 'web' ? 'row' : 'column',
        }}
      >
        <View style={[styles.row, { flex: 1 }]}>
          <View>
            <Text category="p1" style={styles.label}>
              Created At:
            </Text>
          </View>
          <View>
            <Text category="label" style={[styles.value, { fontSize: 12 }]}>
              {loanData?.createdAt && format(parseISO(loanData.createdAt), 'MM/dd/yyyy hh:mm a')}
            </Text>
          </View>
        </View>
        <View style={[styles.row, { flex: 1 }]}>
          <View>
            <Text category="p1" style={styles.label}>
              Created By:
            </Text>
          </View>
          <View>
            <Text category="label" style={styles.value}>
              {loanData?.createdBy && getUserName(loanData.createdBy)}
            </Text>
          </View>
        </View>
      </View>
      {Platform.OS === 'web' ? <Divider style={{ marginBottom: 8 }} /> : null}
      <View
        style={{
          flexDirection: Platform.OS === 'web' ? 'row' : 'column',
        }}
      >
        <View style={styles.row}>
          <View>
            <Text category="p1" style={styles.label}>
              Last Modified:
            </Text>
          </View>
          <View>
            <Text category="label" style={[styles.value, { fontSize: 12 }]}>
              {loanData?.modifiedAt && format(parseISO(loanData.modifiedAt), 'MM/dd/yyyy hh:mm a')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text category="p1" style={styles.label}>
              Modified By:
            </Text>
          </View>
          <View>
            <Text category="label" style={styles.value}>
              {loanData?.updatedBy && getUserName(loanData.updatedBy)}
            </Text>
          </View>
        </View>
      </View>
      {Platform.OS === 'web' ? <Divider style={{ marginBottom: 8 }} /> : null}
      <View style={styles.row}>
        <View>
          <Text category="p1" style={styles.label}>
            Owner:
          </Text>
        </View>
        <View>
          <Text category="label" style={styles.value}>
            {loanData?.owner && getUserName(loanData.owner)}
          </Text>
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 15,
    marginRight: 8,
  },
  value: {
    fontSize: 15,
    flexShrink: 1,
    fontFamily: 'VerbBold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 16,
    paddingHorizontal: 16,
    flex: 1,
  },
  cardWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginBottom: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    flex: 1,
    textAlign: 'center',
  },
});

export default MetadataCard;
