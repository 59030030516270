import { apiSlice } from './ApiSlice';

export const CreditRequestApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCreditRequests: build.query({
      query: ({ loanId }) => ({
        url: `/loans/${loanId}/credit-requests`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'CreditRequests', id: 'PARTIAL-LIST' }],
    }),
    getCreditRequest: build.query({
      query: ({ loanId, reqId }) => ({
        url: `/loans/${loanId}/credit-requests/${reqId}`,
        method: 'GET',
      }),
    }),
    getCreditDocument: build.query({
      query: ({ loanId, reqId }) => ({
        url: `/loans/${loanId}/credit-requests/${reqId}/document`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
        responseHandler: (response) => response.blob(),
      }),
      
    }),
    createCreditRequest: build.mutation({
      query: ({ loanId, data }) => ({
        url: `/loans/${loanId}/credit-requests`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { loanId, borrowerPairId }) => {
        return [
          { type: 'CreditRequests', id: 'PARTIAL-LIST' },
          { type: 'Loans', id: loanId },
        ];
      },
    }),
 
  }),
  overrideExisting: false,
});

export const {
  useGetCreditRequestsQuery,
  useGetCreditRequestQuery,
  useGetCreditDocumentQuery,
  useCreateCreditRequestMutation,

} = CreditRequestApiSlice;
