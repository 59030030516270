import Error from '../../screens/main/ErrorScreen';
import LandingScreen from '../components-page/Home/Home';
import CommissionApprovalScreen from '../components-page/CommissionApproval/CommissionApprovalPage';
import {
  ContactDetailPage,
  ContactsLandingPage,
  LeadsDetailPage,
  LeadsLandingPage,
  LoansDetailPage,
  PipelinePage,
} from '../components-page';
import ImportLeadsWeb from '../features/leads/LeadsImport/ImportLeads.web';
import ProfileScreen from '../components-page/ProfileScreen';
import TasksPage from '../components-page/Tasks/TasksPage';
import GoalEditor from '../components-global/Forms/Profile/GoalEditor';
import PointsScreen from '../components-page/Home/PointsScreen';
import VisualizerPage from '../components-page/Visualizer/VisualizerPage';

export default {
  CommissionApprovals: {
    screen: CommissionApprovalScreen,
    options: {
      headerShown: true,
      title: 'Commission Approvals',
      headerBackTitle: 'Back',
    },
  },
  GoalEditor: {
    screen: GoalEditor,
    options: {
      header: null,
      mode: 'modal',
    },
  },
  Loan: {
    screen: LoansDetailPage,
    options: {
      headerShown: false,
    },
  },
  Error: {
    screen: Error,
  },
  Home: {
    text: 'menu.Home',
    screen: LandingScreen,
    displaySearch: true,
    titleParam: 'Home',
    icon: 'home-outline',
  },
  Pipeline: {
    text: 'menu.Pipeline',
    screen: PipelinePage,
    titleParam: 'title',
    icon: 'attach-money',
    iconPack: 'material',
  },
  Leads: {
    text: 'menu.Leads',
    screen: LeadsLandingPage,
    displaySync: true,
    icon: 'funnel-outline',
  },
  Lead: {
    screen: LeadsDetailPage,
  },
  ImportLeads: {
    screen: ImportLeadsWeb,
  },
  ImportContacts: {
    screen: ImportLeadsWeb,
  },
  Contacts: {
    text: 'contacts.title',
    screen: ContactsLandingPage,
    displaySync: true,
    icon: 'people-outline',
  },
  Contact: {
    screen: ContactDetailPage,
  },

  Tasks: {
    text: 'menu.Task',
    screen: TasksPage,
    titleParam: 'title',
    icon: 'check-circle',
  },
  Visualizer: {
    text: 'menu.Visualizer',
    screen: VisualizerPage,
    titleParam: 'title',
    icon: 'settings-outline',
  },
  Profile: {
    text: 'menu.Profile',
    screen: ProfileScreen,
    titleParam: 'title',
    icon: 'settings-outline',
  },
  Points: {
    text: 'menu.PointsScreen',
    screen: PointsScreen,
    titleParam: 'title',
    icon: 'settings-outline',
  },
};
