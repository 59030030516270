import { apiSlice } from './ApiSlice';

export const BulkSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        createBulkActions: build.mutation({
            query: (payload) => ({
                url: '/batch',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateBulkActionsMutation,
} = BulkSlice;
