import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import { Card, Layout, Text } from '@ui-kitten/components';
import { useGetTiersQuery } from '../../../services/TierSlice';
import { useGetMeQuery } from '../../../services/UserSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { isAfter, isBefore, startOfYear, sub } from 'date-fns';
import { startOfQuarter } from 'date-fns/esm';

function CurrentQuarterTier() {
  const { data: user } = useGetMeQuery({});

  let currentTier;
  if ( user) {
    const tierHistory = user.tierHistory;
    const prevQuarter = sub(startOfQuarter(new Date()), { weeks: 1 });

    //find the tier history for the previous quarter
    currentTier = tierHistory.find((tier) => {
      return (
        isAfter(prevQuarter, new Date(tier.dateBegin)) &&
        isBefore(prevQuarter, new Date(tier.dateEnd))
      );
    });
  }

  return (
    <Card style={styles.card} status="primary"
    header={(props) => (
      <Layout level='3' {...props}>
        <Text category="h6" style={{ textAlign: 'center', fontFamily: 'VerbBold' }}>
          Current Quarter's Tier
        </Text>
      </Layout>
    )}
    >
      <View style={{ flex: 1, paddingVertical: 16 }}>
        {user ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {currentTier?.tier ? (
              <>
                <Text
                  category="h4"
                  style={{ textAlign: 'center', fontFamily: 'verbBold' }}
                >
                  {currentTier.tier.tier}
                </Text>
                <Text category="label" style={{ marginLeft: 4, fontSize: 14 }}>
                  ({currentTier.tier.percentage}%)
                </Text>
              </>
            ) : (
              <Text>No History</Text>
            )}
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    flex: 1,
    minWidth: 250,
  },
});

export default CurrentQuarterTier;
