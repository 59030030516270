import { createSlice } from '@reduxjs/toolkit';
import {
  Filter, LocalViewState, Order, SavedView,
} from '../interfaces/Table';

export type SetColumnFilterPayload = {
  objectName: string;
  filter: Filter | Filter[];
};

export type RemoveColumnFilterPayload = {
  objectName: string;
  column: string;
};

export type ToggleSortPayload = {
  objectName: string;
  order: Order;
};

export type ToggleCategoryFilterPayload = {
  filter?: Filter | Filter[];
  objectName: string;
  selectedCategory: number;
};

export type TableSliceState = {
  [key: string]: {
    localViewState: LocalViewState;
  }
};

const initialLocalViewState: LocalViewState = {
  globalSearch: '',
  page: 1,
  activeView: undefined,
  enhancedColumns: {},
  queryString: '',
};

const initialState: TableSliceState = {
  Loan: {
    localViewState: initialLocalViewState,
  },
  Lead: {
    localViewState: initialLocalViewState,
  },
  Contact: {
    localViewState: initialLocalViewState,
  },
  Commission: {
    localViewState: initialLocalViewState,
  },
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    saveView: (state, action) => {
      state[action.payload.objectName] = action.payload.savedView;
    },
    clearFilters: (state, action: { payload: { objectName: string } }) => {
      state[action.payload.objectName].localViewState.activeView.filters = [];
      state[action.payload.objectName].localViewState.activeView.order = [];
      state[action.payload.objectName].localViewState.globalSearch = '';
    },
    setActiveView: (state, action: { payload: { objectName: string; savedView: SavedView } }) => {
      state[action.payload.objectName].localViewState.globalSearch = '';
      state[action.payload.objectName].localViewState.page = 1;
      state[action.payload.objectName].localViewState.activeView = action.payload.savedView;
    },
    setColumnFilter: (state, action: { payload: SetColumnFilterPayload }) => {
      let column;
      if (Array.isArray(action.payload.filter)) {
        column = action.payload.filter[0].column;
      } else {
        column = action.payload.filter.column;
      }
      let { filters } = state[action.payload.objectName].localViewState.activeView;
      filters = filters.filter(
        (filter) => filter.column !== column,
      );
      if (Array.isArray(action.payload.filter)) {
        filters.push(...action.payload.filter);
      } else {
        filters.push(action.payload.filter);
      }
      state[action.payload.objectName].localViewState.activeView.filters = filters;
      state[action.payload.objectName].localViewState.page = 1;
    },
    removeColumnFilter: (state, action: { payload: RemoveColumnFilterPayload }) => {
      let { filters } = state[action.payload.objectName].localViewState.activeView;
      filters = filters.filter(
        (filter) => filter.column !== action.payload.column,
      );
      state[action.payload.objectName].localViewState.activeView.filters = filters;
      state[action.payload.objectName].localViewState.page = 1;
    },
    toggleSort: (state, action: { payload: ToggleSortPayload }) => {
      const { order } = state[action.payload.objectName].localViewState.activeView;
      const index = order.findIndex(
        (sort) => sort.column === action.payload.order.column,
      );
      if (index !== -1) {
        order.splice(index, 1);
      }
      if (action.payload.order.direction !== null) {
        order.push(action.payload.order);
      }
      state[action.payload.objectName].localViewState.page = 1;
    },
    toggleCategoryFilter(state, action: { payload: ToggleCategoryFilterPayload }) {
      let { categoryFilter } = state[action.payload.objectName].localViewState.activeView;
      categoryFilter = [];
      if (Array.isArray(action.payload.filter)) {
        categoryFilter.push(...action.payload.filter);
      } else if (action.payload.filter) {
        categoryFilter.push(action.payload.filter);
      }
      state[action.payload.objectName].localViewState.activeView.selectedCategory = action.payload.selectedCategory;
      state[action.payload.objectName].localViewState.activeView.categoryFilter = categoryFilter;
      state[action.payload.objectName].localViewState.page = 1;
    },
    setPage(state, action: { payload: { objectName: string; page: number } }) {
      state[action.payload.objectName].localViewState.page = action.payload.page;
    },
    setGlobalSearch(state, action: { payload: { objectName: string; globalSearch: string } }) {
      state[action.payload.objectName].localViewState.globalSearch = action.payload.globalSearch;
    },
    setEnhancedColumns(state, action: { payload: { objectName: string; enhancedColumns: { [key: string]: any } } }) {
      state[action.payload.objectName].localViewState.enhancedColumns = action.payload.enhancedColumns;
    },
    setQueryString(state, action: { payload: { objectName: string; queryString: string } }) {
      state[action.payload.objectName].localViewState.queryString = action.payload.queryString;
    },
  },
});

export const {
  saveView,
  setActiveView,
  toggleCategoryFilter,
  toggleSort,
  setColumnFilter,
  removeColumnFilter,
  setPage,
  setGlobalSearch,
  clearFilters,
  setEnhancedColumns,
  setQueryString,
} = tableSlice.actions;

export default tableSlice.reducer;

export const selectSavedView = (state, objectName: string): SavedView => state.table[objectName].localViewState.savedView;

export const selectLocalViewState = (state, objectName: string): LocalViewState => state.table[objectName].localViewState;

export const selectQueryString = (state, objectName: string): string => state.table[objectName].localViewState.queryString;
