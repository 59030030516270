import React, {useState} from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import EventSource from 'react-native-sse';
import { View, Image, useWindowDimensions } from 'react-native';
import routeDetails from './routeDetails';
import routes from './routes';
import Layout from '../components-global/Navigation/Layout.web';

export default function AppNavigator() {
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const [mercureStatus, setMercureStatus] = useState('idle');
  // useEffect(() => {
  //   if (mercureStatus === 'idle') {
  //     const es = new EventSource(
  //       `${MERCURE_HUB_URL}/.well-known/mercure?topic=*`,
  //     );
  //
  //     es.addEventListener('open', (event) => {
  //       console.log('Open SSE connection.');
  //     });
  //
  //     es.addEventListener('message', (event) => {
  //       if (event.data) {
  //         const eventData = JSON.parse(event.data);
  //         if (eventData.loanClosed) {
  //           toast.show('', {
  //             style: { backgroundColor: 'transparent' },
  //             duration: 3000,
  //             icon: (
  //               <View
  //                 style={{
  //                   marginTop: -50,
  //                   height: windowHeight,
  //                   width: windowWidth,
  //
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     position: 'absolute',
  //                     backgroundColor: '#aaa',
  //                     color: 'white',
  //                     padding: 5,
  //                     borderRadius: 8,
  //                     fontSize: 24,
  //                   }}
  //                 >
  //                   🎉 Congratulations a Loan has closed 🎉
  //                 </Text>
  //                 <Image
  //                   source={require('../../assets/images/confetti-gif.gif')}
  //                   style={{ height: windowHeight, width: windowWidth }}
  //                 />
  //               </View>
  //             ),
  //           });
  //         }
  //       }
  //     });
  //
  //     es.addEventListener('error', (event) => {
  //       setMercureStatus('error');
  //       if (event.type === 'error') {
  //         es.removeAllEventListeners();
  //         console.error('Connection error:', event.message);
  //       } else if (event.type === 'exception') {
  //         console.error('Error:', event.message, event.error);
  //       }
  //     });
  //
  //     es.addEventListener('close', (event) => {
  //       setMercureStatus('close');
  //       console.log('Close SSE connection.');
  //     });
  //   }
  //
  // }, [windowHeight, windowWidth, mercureStatus]);
  // Routing has been implemented with this idea
  // https://github.com/ythecombinator/react-native-web-monorepo-navigation
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {Object.values(routes.ExtraRoutes).map((route) => (
            <Route key={route.name} path={route.path}>
              {React.createElement(routeDetails[route.name].screen)}
            </Route>
          ))}
          {Object.values(routes.MenuItems).map((route) => (
            <Route key={route.name} exact path={route.path}>
              {React.createElement(routeDetails[route.name].screen)}
            </Route>
          ))}
          {Object.values(routes.Modals).map((route) => (
            <Route key={route.name} exact path={route.path}>
              {React.createElement(routeDetails[route.name].screen)}
            </Route>
          ))}
          <Redirect exact from="/" to="/home" />
          //TODO create a 404 page here
          {/* <Route path="*" key="404" /> */}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
