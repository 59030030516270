import {
  OverflowMenu,
  MenuItem,
  Icon,
  Text,
  Button,
  Layout,
} from '@ui-kitten/components';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Pressable, View, StyleSheet, Platform,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '../../navigation/router';
import routes from '../../navigation/routes';
import { useDeleteTaskMutation } from '../../services/TaskApiSlice';
import { showModal as realShow } from '../../services/FormSlice';

const priorityMap = {
  Low: '#2E86C1',
  Medium: 'green',
  High: '#CB4335',
};

function ViewPersonIcon(props) {
  return <Icon {...props} fill="gray" name="person" />;
}

function DangerIcon(props) {
  return <Icon {...props} fill="red" name="trash-2" />;
}

function EditIcon(props) {
  return <Icon {...props} fill="gray" name="star" />;
}

function CompleteIcon(props) {
  return (
    <Icon
      {...props}
      style={[props.style, { width: 20, height: 20 }]}
      fill="green"
      name="checkmark-square"
    />
  );
}

export default function TaskCard({ activity, showDetails = false }: any) {
  const [visible, setVisible] = useState(false);
  const [deleteTask, { isSuccess: deleteSuccess }] = useDeleteTaskMutation();

  const taskPriority = priorityMap[activity.priority];
  const { isCompleted } = activity;

  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const navigation = useNavigation();

  const handleEditTask = () => {
    showModal({ formType: 'task', data: activity });
    setVisible(false);
  };

  const handleEditTaskOutcome = () => {
    showModal({ formType: 'completeTask', data: activity });
    setVisible(false);
  };

  const handleCompleteTask = () => {
    showModal({
      formType: 'completeTask',
      data: activity,
    });
    setVisible(false);
  };

  const handleDeleteTask = () => {
    deleteTask({ id: activity.id });
    setVisible(false);
  };

  const handleViewUserDetails = ({ task }) => {
    let type;
    let id;

    if (task.lead) {
      type = 'Lead';
      id = task.lead.split('/')[2];
    }
    if (task.contact) {
      type = 'Contact';
      id = task.contact.split('/')[2];
    }
    if (task.loan) {
      type = 'Loan';
      id = task.loan.split('/')[2];
    }
    navigation.navigate(routes.ExtraRoutes[type], { id });
  };

  const renderToggleButton = () => (
    <Pressable style={{ marginLeft: 16 }} onPress={() => setVisible(true)}>
      <Icon
        name="more-horizontal-outline"
        fill="darkgray"
        style={{ width: 26, height: 26 }}
      />
    </Pressable>
  );
  return (
    <Layout
      style={[
        styles.shadow,
        {
          padding: 16,
          borderWidth: 1,
          borderColor: '#d5d5d5',
          borderRadius: 4,
          position: 'relative',
          overflow: 'hidden',
        },
      ]}
    >
      {moment(activity.dueDate).isBefore(moment()) && !activity.isCompleted ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // color: '#ff000033',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'VerbBold',
              fontSize: Platform.OS === 'web' ? 60 : 36,
              textAlign: 'center',
              color: '#ffb3b359',
              transform: [{ rotate: '12deg' }],
            }}
          >
            Past Due
          </Text>
        </View>
      ) : null}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 4,
        }}
      >
        <View style={styles.headerWrap}>
          <Icon
            style={styles.icon}
            fill="rgb(255, 113, 91)"
            name="clipboard-outline"
          />
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text category="label" style={{ fontSize: 12 }}>
              Task
            </Text>
            <Text
              category="label"
              style={{
                fontSize: 12,
                marginLeft: 16,
                fontFamily: 'VerbBold',
                color: 'white',
                backgroundColor:
                  activity.subject === 'Sales Call' ? '#ef5b51' : '#007aff',
                paddingVertical: 2,
                paddingHorizontal: 8,
                borderRadius: 8,
                maxWidth: 300,
              }}
            >
              {activity.subject || ''}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {isCompleted && (
            <View
              style={[
                styles.label,
                {
                  marginLeft: 16,
                  marginRight: 16,
                  marginBottom: 4,
                },
              ]}
            >
              <CompleteIcon />
              <Text
                style={{
                  fontFamily: 'VerbBold',
                  color: isCompleted ? 'rgb(122, 183, 23)' : '#af0000',
                  fontSize: Platform.OS === 'web' ? 14 : 12,
                  marginLeft: 2,
                  marginRight: 2,
                }}
                category="p2"
              >
                {isCompleted ? 'Completed' : 'Pending'}
              </Text>
            </View>
          )}
          <OverflowMenu
            visible={visible}
            anchor={renderToggleButton}
            onBackdropPress={() => setVisible(false)}
          >
            <MenuItem
              accessoryLeft={EditIcon}
              title="Edit Detaiils"
              onPress={handleEditTask}
            />
            {isCompleted ? (
              <MenuItem
                accessoryLeft={EditIcon}
                title="Edit Outcome"
                onPress={handleEditTaskOutcome}
              />
            ) : null}
            {showDetails ? (
              <MenuItem
                accessoryLeft={ViewPersonIcon}
                title="View Details"
                onPress={() => handleViewUserDetails({ task: activity })}
              />
            ) : null}
            <MenuItem
              accessoryLeft={DangerIcon}
              title="Delete Task"
              onPress={handleDeleteTask}
            />
          </OverflowMenu>
        </View>
      </View>
      {/* body */}
      <View style={{ padding: 8 }}>
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 8,
          }}
        >
          {showDetails ? (
            <Pressable
              onPress={() => handleViewUserDetails({ task: activity })}
            >
              <Text
                category="label"
                style={{ color: '#1f72ff', textDecorationLine: 'underline' }}
              >
                {activity.contactName
                  || activity.associatedName
                  || activity.lead}
              </Text>
            </Pressable>
          ) : null}
          <Text
            category="label"
            style={{
              fontSize: 12,
              fontStyle: 'italic',
            }}
          >
            Due:
            {' '}
            {moment(activity.dueDate).format('llll')}
          </Text>
        </View>
        {Boolean(activity.description) && (
          <Text category="p1" style={{ paddingVertical: 4 }}>
            {activity.description}
          </Text>
        )}
        {Boolean(activity.isCompleted) && Boolean(activity.outcome) && (
          <Layout
            level="2"
            style={{
              padding: 8,
              marginVertical: 8,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text
                category="label"
                style={{ marginRight: 8, fontStyle: 'italic' }}
              >
                Resolution/Outcome:
              </Text>
              <Text category="label">{activity.outcome}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 4,
              }}
            >
              <Text
                category="label"
                style={{ marginRight: 8, fontStyle: 'italic' }}
              >
                Note:
              </Text>
              <Text category="p2">{activity.resolution}</Text>
            </View>
            {activity.outcome.includes('Referrals') ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 4,
                }}
              >
                <Text
                  category="label"
                  style={{ marginRight: 8, fontStyle: 'italic' }}
                >
                  Referrals:
                </Text>
                <Text category="label" style={{ fontFamily: 'VerbBold' }}>
                  {activity.referralCount}
                </Text>
              </View>
            ) : null}
          </Layout>
        )}
        {Boolean(activity.priority) && (
          <Text category="label" style={{ color: taskPriority, marginTop: 8 }}>
            {activity.priority}
            {' '}
            Priority
          </Text>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 4,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {Boolean(activity.notificationsEnabled) && (
            <>
              <Icon
                fill="#777777"
                name="bell"
                style={{ width: 14, height: 14, marginRight: 4 }}
              />
              <Text category="p2" style={{ fontStyle: 'italic', fontSize: 12 }}>
                {moment(activity.notificationTime).format('lll')}
              </Text>
            </>
          )}
        </View>
        <View style={{ flexDirection: 'row' }}>
          {!isCompleted && (
            <Button
              style={{ margin: 2 }}
              size={Platform.OS === 'web' ? 'small' : 'tiny'}
              status="danger"
              onPress={handleCompleteTask}
            >
              Complete
            </Button>
          )}
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  headerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerDate: {
    marginLeft: 4,
    fontSize: 14,
    color: '#777777',
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 4,
  },
  label: {
    marginTop: 4,
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 3,
    maxWidth: 84,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  labeltext: {},
  shadow: {
    shadowColor: '#c3bcae',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.17,
    shadowRadius: 2.54,
    elevation: 3,
  },
});
