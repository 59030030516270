import { apiSlice } from './ApiSlice';

export const TlcAgentsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTlcAgents: build.query({
      query: (query) => ({
        url: '/tlc_agents?' + query || '',
        method: 'GET',
      }),
      transformResponse: (response: any) => response['hydra:member'],
      providesTags: () => [{ type: 'TlcAgents', id: 'PARTIAL-LIST' }],
    }),
    getTlcAgent: build.query({
      query: (id) => ({
        url: `/tlc_agents/${id}`,
        method: 'GET',
      })
    }),
    addTlcAgent: build.mutation({
      query: (body) => ({
        url: '/tlc_agents',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [
        { type: 'TlcAgents', id: 'PARTIAL-LIST' },
      ],
    }),
    updateTlcAgent: build.mutation({
      query: ({id, ...put}) => ({
        url: `/tlc_agents/${id}`,
        method: 'put',
        body: put,
      }),
      invalidatesTags: ({id}) => [
        { type: 'TlcAgents', id },
      ],
    }),
    deleteTlcAgent: build.mutation({
      query: (id) => ({
        url: `/tlc_agents/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTlcAgentsQuery,
  useGetTlcAgentQuery,
  useAddTlcAgentMutation,
  useUpdateTlcAgentMutation,
  useDeleteTlcAgentMutation,
} = TlcAgentsSlice;
