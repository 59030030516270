import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View, Keyboard, Platform } from 'react-native';
import { Input, Text, Layout } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import FormGroup from '../FormGroup';

import { FormSuccess } from '../../../components-ui';

import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import Colors from '../../../helpers/Colors';
import {
  useCreateNoteMutation,
  usePatchNoteMutation,
} from '../../../services/NoteApiSlice';
import { useCreateBulkActionsMutation } from '../../../services/BulkApiSlice';

function AddNoteForm({ cancelAction, defaultData }: any) {
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState<any>({
    description: defaultData?.body || '',
  });

  const windowWidth = Dimensions.get('window').width;
  const [createNewNote, { isSuccess: createSuccess, data: createData }] =
    useCreateNoteMutation();
  const [patchNote, { isSuccess: patchSuccess }] = usePatchNoteMutation();
  const [createBulkActions, { isSuccess: bulkSuccess }] =
    useCreateBulkActionsMutation();

  // GlobalSearch Data Handler
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } =
    useGetLoansQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (createSuccess || bulkSuccess || patchSuccess) {
      setTimeout(() => {
        cancelAction();
      }, 1500);
    }
  }, [createSuccess, bulkSuccess, patchSuccess]);

  useEffect(() => {
    if (!isFetching) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      // Check for fields
      if (!state.description)
        throw new Error(
          'Missing notes, please make sure all required fields are filled'
        );
      if (
        !defaultData?.lead &&
        !defaultData?.loan &&
        !defaultData?.contact &&
        !selectedAssociate
      )
        throw new Error('Please select someone to associate this task with');

      const payload: any = {
        title: '',
        body: state.description,
      };
      if (defaultData?.id) {
        patchNote({ id: defaultData.id, ...payload });
        return;
      }
      if (defaultData?.contactList) {
        const ids = [];
        defaultData.contactList.forEach((contact) => {
          ids.push(contact['@id']);
        });
        const bulkPayload = {
          ids: ids,
          uri: '/notes',
          idField: defaultData.contactList[0]['@type'].toLowerCase(),
          body: payload,
        };

        createBulkActions(bulkPayload);
      } else {
        if (!defaultData?.lead && !defaultData?.loan && !defaultData?.contact) {
          if (selectedAssociate.type === 'Lead')
            payload.lead = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Loan')
            payload.loan = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Contact')
            payload.contact = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Partner')
            payload.partner = selectedAssociate['@id'];
        } else {
          if (defaultData.lead) {
            payload.lead = defaultData.lead;
          }
          if (defaultData.contact) {
            payload.contact = defaultData.contact;
          }
          if (defaultData.loan) {
            payload.loan = defaultData.loan;
          }
        }

        createNewNote(payload);
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  let isCreate =
    Boolean(!defaultData) ||
    Boolean(defaultData?.contactList) ||
    Boolean(defaultData?.lead) ||
    Boolean(defaultData?.contact) ||
    Boolean(defaultData?.loan);
  if (defaultData?.id) {
    isCreate = false;
  }
  const showGlobalSearch =
    !defaultData ||
    (!defaultData?.lead &&
      !defaultData?.loan &&
      !defaultData?.contact &&
      !defaultData?.contactList);
  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={!isCreate ? 'Edit Note' : 'Create A Note'} />
      {defaultData?.contactList ? (
        <View>
          <Text style={{ textAlign: 'center' }} category="label">
            Creating a note for {defaultData.contactList.length}{' '}
            {defaultData.contactList[0]['@id'].split('/')[1].slice(0, -1)}(s)
          </Text>
        </View>
      ) : null}
      {/* description */}
      <FormGroup label="Note">
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: Platform.OS === 'web' ? 184 : 350 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      {/* Associate With */}
      {showGlobalSearch && (
        <FormGroup label=" Associate this note with:">
          {autoCompleteData && (
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
              selectedAssociate={selectedAssociate}
            />
          )}
        </FormGroup>
      )}
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {(!!createSuccess || !!patchSuccess || !!bulkSuccess) && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={!isCreate ? 'SAVE' : 'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default AddNoteForm;
