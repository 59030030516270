import { apiSlice } from './ApiSlice';

export const LoanApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLoans: build.query({
      query: (query) => `/loans?${query}`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each contact in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'Loans',
            id,
          })),
          { type: 'Loans', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'Loans', id: 'PARTIAL-LIST' }]),
    }),
    getLoansByContactID: build.query({
      query: (id) => `/contacts/${id}/loans`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each contact in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'Loans',
            id,
          })),
          { type: 'Loans', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'Loans', id: 'PARTIAL-LIST' }]),
    }),
    getLoan: build.query({
      query: (id) => `/loans/${id}`,
      providesTags: (result, error, id) => [{ type: 'Loans', id }],
    }),
    createLoan: build.mutation({
      query: ({ contactId, ...body }) => ({
        url: 'loans',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: 'Loans', id: 'PARTIAL-LIST' },
        { type: 'Contacts', id: contactId },
      ],
    }),
    createEmpowerLoan: build.mutation({
      query: (loanId) => ({
        url: `/loans/${loanId}/createInEmpower`,
        method: 'POST',
        body: {}
      }),
      invalidatesTags: (result, error, { contactId }) => [
        { type: 'Loans', id: 'PARTIAL-LIST' },
        { type: 'Contacts', id: contactId },
      ],
    }),
    editLoan: build.mutation({
      query: ({ id, contactId, ...put }) => ({
        url: `loans/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Loans', id },
        { type: 'Contacts', id: contactId },
      ],
    }),
    deleteLoan: build.mutation({
      query: ({ id }) => ({
        url: `loans/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this tasks `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getTasks` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, { id, contactId }) => [
        { type: 'Loans', id },
        { type: 'Loans', id: 'PARTIAL-LIST' },
        { type: 'Contacts', id: contactId },
      ],
    }),
    getLoanActivities: build.query({
      query: ({ id }) => `/loans/data/${id}`,
      providesTags: () => [{ type: 'LoanActivities', id: 'PARTIAL-LIST' }],
    }),
    patchLoanApproval: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `loans/commissionApproval/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Loans', id: 'PARTIAL-LIST' },
      ],
    }),
    bulkLoanApproval: build.mutation({
      query: ({ ...body }) => ({
        url: 'loans/commissionApproval',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Loans', id: 'PARTIAL-LIST' },
      ],
    }),
    resyncLoan: build.mutation({
      query: ({ ...body }) => ({
        url: 'loans/resync',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Loans', id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLoansQuery,
  useLazyGetLoansQuery,
  useGetLoansByContactIDQuery,
  useGetLoanQuery,
  useCreateLoanMutation,
  useCreateEmpowerLoanMutation,
  useEditLoanMutation,
  useDeleteLoanMutation,
  useGetLoanActivitiesQuery,
  usePatchLoanApprovalMutation,
  useBulkLoanApprovalMutation,
  useResyncLoanMutation,
} = LoanApiSlice;

export const useGetLoansState = apiSlice.endpoints.getLoans.useQueryState;
