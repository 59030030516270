import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, Platform, View } from 'react-native';
import { IndexPath, Input, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import {
  useGetContactsQuery,
  usePatchContactMutation,
} from '../../../services/ContactApiSlice';
import { usePatchLeadMutation } from '../../../services/LeadApiSlice';

const BOOLEANFIELDS = ['No', 'Yes'];
const LOANPURPOSE = [' ', 'Purchase', 'Refinance'];
const PARTNERTYPE = [
  ' ',
  'Real Estate Agent',
  'CPA',
  'Attorney',
  'Title Company',
  'Insurance Company',
  'Financial Planner',
  'Other',
];
const PARTNERTYPEMAPPING = [
  { value: null, label: '' },
  { value: 'RE_AGENT', label: 'Real Estate Agent' },
  { value: 'CPA', label: 'CPA' },
  { value: 'ATTORNEY', label: 'Attorney' },
  { value: 'TC', label: 'Title Company' },
  { value: 'IC', label: 'Insurance Company' },
  { value: 'FP', label: 'Financial Planner' },
  { value: 'OTHER', label: 'Other' },
];

function EditLeadForm({ cancelAction, defaultData }: any) {
  const windowWidth = Dimensions.get('window').width;

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    ...defaultData.data,
    referralType: '',
    partnerType: '',
    leadSource: 'Self',
  });

  const [isPartnerIndex, setIsPartnerIndex] = useState(
    defaultData?.data?.isPartner ? new IndexPath(1) : new IndexPath(0)
  );
  const [loanPurposeIndex, setLoanPurposeIndex] = useState(
    defaultData?.data?.loanPurpose === 'Purchase'
      ? new IndexPath(1)
      : defaultData?.data?.loanPurpose === 'Refinance'
      ? new IndexPath(2)
      : new IndexPath(0)
  );
  const [isCompanyLead, setIsCompanyLead] = useState(
    defaultData?.data?.leadSource === 'Self'
      ? new IndexPath(0)
      : new IndexPath(1)
  );
  const [partnerTypeIndex, setPartnerTypeIndex] = useState(new IndexPath(0));
  // Auto Complete

  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('&search=a');
  const [page, setPage] = useState(1);

  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);

  const [patchLead, { isSuccess: patchSuccess }] = usePatchLeadMutation();
  const [patchContact, { isSuccess: patchContactSuccess }] =
    usePatchContactMutation();

  useEffect(() => {
    if (!isFetchingContacts) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetchingContacts]);

  useEffect(() => {
    if (defaultData.data) {
      if (defaultData.data.isPartner) {
        let partnerIndex = 0;
        PARTNERTYPEMAPPING.forEach((partner: any, index: number) => {
          if (partner.value === defaultData.data.partnerType) {
            partnerIndex = index;
          }
        });
        setPartnerTypeIndex(new IndexPath(partnerIndex));
      }
    }
  }, [defaultData]);

  useEffect(() => {
    if (isPartnerIndex?.row === 1) {
      setFormData((prevData) => ({ ...prevData, isPartner: true }));
    } else {
      setFormData((prevData) => ({ ...prevData, isPartner: false }));
    }
  }, [isPartnerIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      partnerType: PARTNERTYPEMAPPING[partnerTypeIndex.row].value,
    }));
  }, [partnerTypeIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      loanPurpose: LOANPURPOSE[loanPurposeIndex.row],
    }));
  }, [loanPurposeIndex]);

  useEffect(() => {
    if (selectedAssociate?.id && selectedAssociate['@id']) {
      setFormData((prevData) => ({
        ...prevData,
        referredBy: selectedAssociate['@id'],
        referralType: selectedAssociate.type,
      }));
      if (
        selectedAssociate?.email &&
        selectedAssociate.email.includes('@arkmortgage.com')
      ) {
        setIsCompanyLead(new IndexPath(1));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        referredBy: null,
        referralType: null,
      }));
    }
  }, [selectedAssociate]);

  useEffect(() => {
    if (isCompanyLead?.row === 0) {
      setFormData((prevData) => ({
        ...prevData,
        leadSource: 'Self',
      }));
    }
    if (isCompanyLead?.row === 1) {
      setFormData((prevData) => ({
        ...prevData,
        leadSource: 'Ark',
      }));
    }
  }, [isCompanyLead]);

  useEffect(() => {
    if (patchSuccess || patchContactSuccess) {
      defaultData.callback && defaultData.callback();
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [patchSuccess, patchContactSuccess]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      const specificformData = {};
      defaultData.fields.forEach((field: string) => {
        if (formData[field] !== 'undefined') {
          specificformData[field] = formData[field];
        }
      });
      if (defaultData.data['@type'] === 'Contact') {
        patchContact({ id: defaultData.data.id, ...specificformData });
      } else {
        patchLead({ id: defaultData.data.id, ...specificformData });
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={`Edit ${defaultData.data['@type']}`} />
      {defaultData?.fields?.includes('isPartner') ? (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <FormGroup label="Is Partner ?" required={false}>
              <KittenSelect
                selectedIndex={isPartnerIndex}
                setSelectedIndex={setIsPartnerIndex}
                options={BOOLEANFIELDS}
              />
            </FormGroup>
          </View>
          {isPartnerIndex?.row === 1 ? (
            <View style={{ flex: 1 }}>
              <FormGroup label="Partner Type">
                <KittenSelect
                  selectedIndex={partnerTypeIndex}
                  setSelectedIndex={setPartnerTypeIndex}
                  options={PARTNERTYPE}
                />
              </FormGroup>
            </View>
          ) : null}
        </View>
      ) : null}
      {/* Name */}
      <View style={{ flexDirection: 'row' }}>
        {defaultData?.fields?.includes('firstName') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="First Name">
              <Input
                placeholder="First Name"
                value={formData['firstName']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['firstName']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('lastName') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Last Name">
              <Input
                placeholder="Last Name"
                value={formData['lastName']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['lastName']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      {/* Property Info */}
      <View style={{ flexDirection: 'row' }}>
        {defaultData?.fields?.includes('propertyAddress') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property Address" required={false}>
              <Input
                placeholder="Property Address"
                value={formData['propertyAddress']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['propertyAddress']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('streetAddress') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property Address" required={false}>
              <Input
                placeholder="Property Address"
                value={formData['streetAddress']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['streetAddress']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('propertyCity') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property City" required={false}>
              <Input
                placeholder="Property City"
                value={formData['propertyCity']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['propertyCity']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('city') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property City" required={false}>
              <Input
                placeholder="Property City"
                value={formData['city']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['city']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: 'row' }}>
        {defaultData?.fields?.includes('propertyState') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property State" required={false}>
              <Input
                maxLength={2}
                placeholder="Property State"
                value={formData['propertyState']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['propertyState']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('state') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property State" required={false}>
              <Input
                maxLength={2}
                placeholder="Property State"
                value={formData['state']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['state']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('propertyZip') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property Zipcode" required={false}>
              <Input
                maxLength={5}
                keyboardType="numeric"
                placeholder="Property Zipcode"
                value={formData['propertyZip']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['propertyZip']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('zipCode') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Property Zipcode" required={false}>
              <Input
                maxLength={5}
                keyboardType="numeric"
                placeholder="Property Zipcode"
                value={formData['zipCode']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['zipCode']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: 'row' }}>
        {defaultData?.fields?.includes('loanAmount') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Loan Amount" required={false}>
              <Input
                keyboardType="numeric"
                placeholder="Loan Amount"
                value={formData['loanAmount']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['loanAmount']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('loanPurpose') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="LoanPurpose" required={false}>
              <KittenSelect
                selectedIndex={loanPurposeIndex}
                setSelectedIndex={setLoanPurposeIndex}
                options={LOANPURPOSE}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      {/* Contact */}
      <View style={{ flexDirection: 'row' }}>
        {defaultData?.fields?.includes('mobilePhone') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Mobile Phone" required={false}>
              <Input
                keyboardType="numeric"
                placeholder="Mobile Phone"
                value={formData['mobilePhone']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['mobilePhone']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('email') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Email" required={false}>
              <Input
                placeholder="Email"
                value={formData['email']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['email']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}>
        {defaultData?.fields?.includes('referredBy') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Referred By" required={false}>
              <GlobalSearchAutoComplete
                clearAutoCompleteData={() => setAutoCompleteData([])}
                autoCompleteData={autoCompleteData}
                isFetching={isFetchingContacts}
                setQuery={setQuery}
                handleOnChange={(e) => setSelectedAssociate(e)}
                page={page}
                setPage={setPage}
                selectedAssociate={
                  selectedAssociate?.id
                    ? selectedAssociate
                    : defaultData?.data?.referred
                    ? {
                        type: defaultData.data.referred.type,
                        id: defaultData.data.referred.id,
                        ['@id']: defaultData.data.referred['@id'],
                        contactId: defaultData.data.referred.id,
                        title: defaultData.data.referred.name,
                        name: defaultData.data.referred.name,
                      }
                    : null
                }
                defaultValue={
                  defaultData.data.referred
                    ? {
                        type: defaultData.data.referred.type,
                        id: defaultData.data.referred.id,
                        ['@id']: defaultData.data.referred['@id'],
                        contactId: defaultData.data.referred.id,
                        title: defaultData.data.referred.name,
                        name: defaultData.data.referred.name,
                      }
                    : null
                }
              />
            </FormGroup>
          </View>
        ) : null}
        {defaultData?.fields?.includes('leadSource') ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Company Lead?" required={false}>
              <KittenSelect
                selectedIndex={isCompanyLead}
                setSelectedIndex={setIsCompanyLead}
                options={BOOLEANFIELDS}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      {defaultData?.fields?.includes('additionalInfo') ? (
        <FormGroup label="Additional Info" required={false}>
          <Input
            placeholder="Additional Info"
            multiline={true}
            textStyle={{
              minHeight: defaultData?.fields?.length === 1 ? 190 : 64,
            }}
            value={formData['additionalInfo']}
            onChangeText={(nextValue) => {
              setFormData((prevData) => ({
                ...prevData,
                ['additionalInfo']: nextValue,
              }));
            }}
          />
        </FormGroup>
      ) : null}
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {!!patchSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'SAVE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default EditLeadForm;
