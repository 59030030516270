import {
  Button,
  Drawer,
  DrawerGroup,
  DrawerItem,
  Layout,
  Text,
} from '@ui-kitten/components';
import React from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../Forms/react-hook-form/InputField';
import { View } from 'react-native';
import { useGetMeQuery, usePatchUserMutation } from '../../services/UserSlice';
import { ActivityIndicator } from 'react-native-paper';

type Props = {};

function XactusForm({}: Props) {
  const { data: user } = useGetMeQuery({});
  const [patchUser, { isSuccess, isLoading }] = usePatchUserMutation();
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const {
    control,
    handleSubmit,
    setFocus,
    register,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      xactusUsername: user?.xactusUsername || '',
      xactusPassword: user?.xactusPassword || '',
    },
  });

  async function onSubmit(data: any) {
    await patchUser({ id: user.id, ...data });
  }

  return (
    // <Drawer
    //   selectedIndex={selectedIndex}
    //   onSelect={(index) => setSelectedIndex(index)}
    // >
      <DrawerGroup
        title={() => (
          <Text
            category="label"
            style={{ paddingLeft: 16, fontSize: 18, marginBottom: 8 }}
          >
            Xactus Credentials
          </Text>
        )}
      >
        <DrawerItem
          title={() => {
            return (
              <Layout style={{ marginTop: 8, flex: 1 }} level="1">
                <View style={{ position: 'relative' }}>
                  <InputField
                    control={control}
                    name={'xactusUsername'}
                    label={'Username'}
                    required={false}
                    register={register}
                    nextField={() => setFocus('xactusPassword')}
                  />
                  <InputField
                    control={control}
                    name={'xactusPassword'}
                    label={'Password'}
                    required={false}
                    register={register}
                    secureTextEntry={true}
                  />
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      paddingHorizontal: 8,
                    }}
                  >
                    <Button
                      disabled={!isDirty || isLoading}
                      size="small"
                      onPress={handleSubmit(onSubmit)}
                    >
                      Save
                    </Button>
                  </View>
                  {isLoading ? (
                    <ActivityIndicator
                      style={{ position: 'absolute', top: '40%', left: '50%' }}
                    />
                  ) : null}
                </View>
              </Layout>
            );
          }}
        />
      </DrawerGroup>
    // </Drawer>
  );
}

export default XactusForm;
