import { Layout } from '@ui-kitten/components';
import { useState, useEffect } from 'react';
import {
  Platform,
  Dimensions,
  ScrollView,
  RefreshControl,
  View,
} from 'react-native';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import {
  getNotes,
  getTasks,
  getEvents,
  pinnedNotesToTop,
  sortActivityList,
} from '../../components/DetailsPage/SingleDetailsActivityHelper';
import { useGetContactActivitiesQuery } from '../../services/ContactApiSlice';
import { useGetLeadActivitiesQuery } from '../../services/LeadApiSlice';
import { useGetLoanActivitiesQuery } from '../../services/LoanApiSlice';
import ActivityList from '../ActivityList';

const ActivityStatusColumn = ({ clientId, type = 'lead' }) => {
  const [activityData, setActivityData] = useState();
  // activities
  const {
    data: activities,
    isFetching: fetchingActivities,
    refetch,
  } = type === 'lead'
    ? useGetLeadActivitiesQuery(
        { id: clientId, dateRangeOption: 'all' },
        { refetchOnMountOrArgChange: true }
      )
    : type === 'contact'
    ? useGetContactActivitiesQuery(
        { id: clientId, dateRangeOption: 'all' },
        { refetchOnMountOrArgChange: true }
      )
    : useGetLoanActivitiesQuery(
        { id: clientId, dateRangeOption: 'all' },
        { refetchOnMountOrArgChange: true }
      );

  useEffect(() => {
    let notes = [];
    let tasks = [];
    let events = [];
    // let logs = [];

    if (activities) {
      if (activities.notes.length > 0) {
        notes = getNotes({
          notes: activities.notes,
          display: true,
        });
      }

      if (activities.tasks.length > 0) {
        tasks = getTasks({
          tasks: activities.tasks,
          display: true,
        });
      }

      if (activities.events.length > 0) {
        events = getEvents({
          events: activities.events,
          display: true,
        });
      }
    }

    setActivityData(
      pinnedNotesToTop(sortActivityList([...notes, ...tasks, ...events]))
    );
  }, [activities]);
  return (
    <Layout
      level="1"
      style={{
        flex: 3,
        padding: Platform.OS === 'web' ? 0 : 4,
        minWidth: Platform.OS === 'web' ? 535 : Dimensions.get('screen').width,
      }}
    >
      <View style={{ flex: 1 }}>
        {activityData ? (
          <ActivityList
            refetch={refetch}
            fetchingActivities={fetchingActivities}
            activities={activityData}
            infoType={type}
            clientId={clientId}
          />
        ) : (
          <ActivityIndicatorComponent />
        )}
      </View>
    </Layout>
  );
};

export default ActivityStatusColumn;
