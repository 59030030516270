import AsyncStorage from '@react-native-async-storage/async-storage';
// import Analytics from 'appcenter-analytics';

const initFeatures = async () => {
  const features = [
    { name: 'GoalEditor', description: 'New Goal Editor', active: false },
    { name: 'GoalEditorA', description: 'Old Goal Editor', active: true },
  ];
  const eventTag = Object.assign(...features.map((f) => ({ [f.name]: f.active })));
  console.log('event tag: ', eventTag);
  try {
    await AsyncStorage.setItem('@flags', JSON.stringify([
      { name: 'GoalEditor', description: 'New Goal Editor', active: true },
      { name: 'GoalEditorA', description: 'Old Goal Editor', active: false },
      { name: 'ContactListNew', description: 'New Contact MainActivityList', active: true },
      { name: 'ContactList', description: 'Old Contact MainActivityList', active: false },
    ]));
    console.log('features saved');
  } catch (e) {
    console.log('error: ', e);
  }
};

export default initFeatures;
