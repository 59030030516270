import { Input, Text } from '@ui-kitten/components';
import { useController, useForm } from 'react-hook-form';
import SimpleKittenSelect from '../../../components-ui/SimpleKittenSelect';
import FormGroup from '../FormGroup';

function DropdownField({ control, name, label, required, options }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules: { required: required },
  });
  return (
    <FormGroup label={label} required={required}>
      <SimpleKittenSelect
        options={options}
        defaultValue={field?.value}
        onChange={(val) => field.onChange(val.value)}
      />
      {errors?.[name] && (
        <Text
          style={{
            fontSize: 10,
            color: 'red',
            marginLeft: 4,
          }}
        >
          This is required
        </Text>
      )}
    </FormGroup>
  );
}

export default DropdownField;
