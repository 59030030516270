import { createSlice } from '@reduxjs/toolkit';

type FormState = {
  formType: string
  isVisible: boolean
  defaultData: any
  clearData: boolean
};

const initialState: FormState = {
  formType: '',
  isVisible: false,
  defaultData: {},
  clearData: false,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    showModal: (state, action: { payload: { formType: string, data?: any } }) => {
      state.formType = action.payload.formType;
      state.defaultData = action.payload.data;
      state.isVisible = true;
    },
    hideModal: (state) => {
      state.isVisible = false;
    },
    setDefaultData: (state, action: { payload: any }) => {
      state.defaultData = action.payload;
    },
    setClearData: (state, action:{payload: boolean}) => {
      state.clearData = action.payload;
    },
    // formType,
    // isVisible,
    // setDefaultData,
    // defaultData,
    // setFormType,
    // setIsVisible,
  },
});

export const {
  showModal,
  hideModal,
  setDefaultData,
  setClearData,
} = formSlice.actions;

export default formSlice.reducer;
