import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Input, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';
import { usePatchLoanApprovalMutation } from '../../../services/LoanApiSlice';

function CommissionDenyForm({ cancelAction, defaultData }: any) {
  const { userRole } = defaultData;
console.log(defaultData, 'DEFAULT DATA')
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [patchLoanApproval, { isSuccess: isSuccessPatchingApproval, isError }] =
    usePatchLoanApprovalMutation();

  useEffect(() => {
    if (isError) {
      setError('Something went wrong. Please try again.');
      setFormSubmitted(false);
    }
    if (isSuccessPatchingApproval) {
      setTimeout(() => {
        cancelAction();
      }, 3000);
    }
  }, [isSuccessPatchingApproval, isError]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      checkForRequiredFields();
      const row = defaultData;
      console.log(row, 'ROW');
      if (userRole === 'Officer') {
        patchLoanApproval({
          id: row.id,
          commissionApproval: { loApproval: false, loComment: value },
        });
      }

      if (userRole === 'Admin') {
        patchLoanApproval({
          id: row.id,
          commissionApproval: { managerApproval: false, managerComment: value },
        });
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  function checkForRequiredFields() {
    if (!value || value?.trim() === '') {
      throw new Error('Please provide a reason for denial.');
    }
  }

  return (
    <Layout style={{ flex: 1, position: 'relative', width: '100%' }}>
      <Header heading={'Reject Commission'} />
      <Layout style={{ paddingBottom: 0, flex: 1 }}>
        {/* Address */}
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="Deny Reason" required>
              <Input
                multiline={true}
                textStyle={{ minHeight: 64 }}
                placeholder="Deny Reason"
                value={value}
                onChangeText={(nextValue) => setValue(nextValue)}
              />
            </FormGroup>
          </View>
        </View>
      </Layout>

      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}

      {!!isSuccessPatchingApproval && <FormSuccess />}
      <Footer
        cancelAction={() => cancelAction()}
        cancelText={'CANCEL'}
        submitAction={() => handleSubmit()}
        disabled={formSubmitted}
        submitText={'REJECT'}
      />
    </Layout>
  );
}

export default CommissionDenyForm;
