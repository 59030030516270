import { apiSlice } from './ApiSlice';

export const ClubRankSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getClubRanks: build.query({
      query: () => '/club_ranks',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClubRanksQuery,
} = ClubRankSlice;
