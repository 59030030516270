import { Card, Text } from '@ui-kitten/components';
import { endOfQuarter, isAfter, isBefore, startOfQuarter } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import Svg from 'react-native-svg';
import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTheme,
  VictoryTooltip,
} from 'victory-native';
import { GETLOANSQUERY } from '../../../helpers/constants';
import { useGetHomeDataQuery } from '../../../services/HomeSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import { useGetTiersQuery } from '../../../services/TierSlice';
import { useGetMeQuery } from '../../../services/UserSlice';

const chartSize = 450;
const chartWidth = Dimensions.get('window').width > 500 ? chartSize + 100 : 325;

const colorScale = [
  '#008000',
  '#6771dc',
  '#a367dc',
  '#dc67d1',
  '#dc67a3',
  '#dc6771',
  '#dc9467',
  '#dcb767',
];

const LoansToReachGoal = (props) => {
  const { data: user } = useGetMeQuery({});
  const { data: homeData, isSuccess: homeDataLoaded } = useGetHomeDataQuery({});
  const { data: tiers } = useGetTiersQuery({});
  const { data: loansData, isSuccess: loansSuccess } =
    useGetLoansQuery(GETLOANSQUERY);

  const [data3, setData3] = useState([
    { x: 'Closed Loans', y: 0 },
    { x: 'Locked', y: 0 },
    { x: 'Approval', y: 0 },
    { x: 'Active Apps', y: 0 },
  ]);

  const closedLoansQTD = !loansData
    ? { units: 0, amount: 0 }
    : loansData['hydra:member'].reduce(
        (acc, loan) => {
          if (
            loan.purchasedDate &&
            loan.purchasedDate !== '' &&
            loan.closeStatus === 'Won' &&
            loan.phase &&
            loan.phase === 'Closed Loan' &&
            loan.closeDate &&
            loan.loanAmount &&
            isAfter(new Date(loan.closeDate), startOfQuarter(new Date())) &&
            isBefore(new Date(loan.closeDate), new Date())
          ) {
            const units = acc.units + 1;
            const amount = acc.amount + loan.loanAmount;
            return { units, amount };
          }
          return acc;
        },
        { units: 0, amount: 0 }
      );

  // splits loans into categories - locked, approval, active, closed
  useEffect(() => {
    if (!loansData) return;
    //Locked = stage = final, ctc, resubmittal, approval
    //Approval = stage = final, ctc, resubmittal, approval
    //active = stage =  processing, submittal, application, approval, resubmittal, ctc
    const lockedStage = ['final', 'ctc', 'resubmittal', 'approval'];
    const approvalStage = ['final', 'ctc', 'resubmittal', 'approval'];
    const activeStage = [
      'processing',
      'submittal',
      'application',
      'approval',
      'resubmittal',
      'ctc',
    ];

    const locked = loansData['hydra:member'].filter((loan) =>
      lockedStage.includes(loan.stage?.toLowerCase()) &&
      isBefore(new Date(loan.applicationDate), endOfQuarter(new Date())) &&
      isAfter(new Date(loan.applicationDate), startOfQuarter(new Date()))
    ).length;

    const approval = loansData['hydra:member'].filter(
      (loan) =>
        approvalStage.includes(loan.stage?.toLowerCase()) && 
        isBefore(new Date(loan.applicationDate), endOfQuarter(new Date())) &&
        isAfter(new Date(loan.applicationDate), startOfQuarter(new Date()))
    ).length;

    const active = loansData['hydra:member'].filter(
      (loan) =>
        activeStage.includes(loan.stage?.toLowerCase()) &&
        isBefore(new Date(loan.applicationDate), endOfQuarter(new Date())) &&
        isAfter(new Date(loan.applicationDate), startOfQuarter(new Date()))
    ).length;
    const closed = loansData['hydra:member'].filter(
      (loan) => loan.stage?.toLowerCase() === 'closed' &&
      isBefore(new Date(loan.closeDate), endOfQuarter(new Date())) &&
      isAfter(new Date(loan.closeDate), startOfQuarter(new Date()))
    ).length;

    //loansToGo = closedLoansQTD - locked - approval
    setData3([
      { x: 'Closed Loans', y: closed },
      { x: 'Locked', y: locked },
      { x: 'Approval', y: approval },
      { x: 'Active Apps', y: active },
    ]);
  }, [loansData]);

  const profileTier = user?.profileGoalTier;
  let loansToGo = 0;
  if (tiers?.['hydra:member'] && homeData && user) {
    // calculate loans needed to reach goals
    const projectedIncomeForQuarter =
      tiers['hydra:member'][profileTier - 1].quarter;
    const averageLoanAmount = homeData?.averageLoanAmount || 0;
    const goalTierCommissionRate = tiers['hydra:member'][profileTier - 1].rate;
    const pullThruPercent = homeData?.pullThru || 0.25;
    const loansNeeded =
      projectedIncomeForQuarter /
      (averageLoanAmount * goalTierCommissionRate) /
      pullThruPercent;

    loansToGo = Math.round(
      loansNeeded - closedLoansQTD.units - data3[1].y - data3[2].y
    );
    if (loansToGo < 0) {
      loansToGo = 0;
    }
  }

  return (
    <Card
      style={styles.container}
      header={(props) => (
        <View {...props}>
          <Text style={{ textAlign: 'center' }} category="h5">
            Loans to Reach Goal
            {profileTier ? `(${profileTier})` : ''}
          </Text>
        </View>
      )}
      footer={(props) => (
        <View {...props}>
          {homeData && tiers ? (
            <>
              <Text
                style={{ textAlign: 'center', paddingVertical: 0 }}
                category="h5"
              >
                Estimated Loans Needed - {loansToGo}
              </Text>
              <Text
                appearance="hint"
                category="label"
                style={{ textAlign: 'center' }}
              >
                Based on current average loan amount and pull thru percentage
              </Text>
            </>
          ) : null}
        </View>
      )}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: -24,
          paddingBottom: Platform.OS === 'web' ? 36 : 0,
        }}
      >
        {loansSuccess && data3 ? (
          <Svg
            viewBox={`0 0 ${chartSize} ${chartSize}`}
            height={chartSize}
            width={chartWidth}
          >
            <VictoryPie
              standalone={false}
              height={chartSize}
              width={chartSize}
              padAngle={({ datum }) => 4}
              animate={{
                duration: 1000,
              }}
              innerRadius={chartSize / 3.5}
              labelRadius={chartSize / 3.5}
              labels={({ datum }) => {
                return `${datum.x}\n${datum.y}`;
              }}
              labelComponent={
                <VictoryTooltip dy={0} centerOffset={{ x: 25 }} />
              }
              colorScale={colorScale}
              data={data3}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fill: '#323232', fontFamily: 'VerbBold' }}
              x={chartSize / 2}
              y={chartSize / 2}
              text={`Total: ${data3.reduce((a, b) => a + (b.y || 0), 0)}`}
            />
            <VictoryLegend
              x={0}
              y={chartSize - 30}
              gutter={16}
              standalone={false}
              orientation="horizontal"
              style={{
                labels: {
                  color: '#eee',
                },
              }}
              data={data3.map((item) => ({
                name: `${item.x}: ${item.y}`,
              }))}
              colorScale={colorScale}
              theme={VictoryTheme.material}
            />
          </Svg>
        ) : (
          <View style={{ paddingVertical: 210, flex: 1, height: '100%' }}>
            <ActivityIndicator />
          </View>
        )}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default LoansToReachGoal;
