import { apiSlice } from './ApiSlice';

export const PartnerRelationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPartnerRelations: build.query({
      query: (query) => `partner_relations?${query}`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each lead in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'PartnerRelations',
            id,
          })),
          { type: 'PartnerRelations', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'PartnerRelations', id: 'PARTIAL-LIST' }]),
    }),
    getPartnerRelationsByContact: build.query({
      query: (id) => `contacts/${id}/partner_relations`,
      providesTags: (result, error, page) => (result && result['hydra:member']
        ? [
          // Provides a tag for each lead in the current page,
          // as well as the 'PARTIAL-LIST' tag.
          ...result['hydra:member'].map(({ id }) => ({
            type: 'PartnerRelations',
            id,
          })),
          { type: 'PartnerRelations', id: 'PARTIAL-LIST' },
        ]
        : [{ type: 'PartnerRelations', id: 'PARTIAL-LIST' }]),
    }),
    getPartnerRelation: build.query({
      query: (id) => `/partner_relations/${id}`,
      providesTags: (result, error, id) => [{ type: 'PartnerRelations', id }],
    }),
    addNewPartnerRelation: build.mutation({
      query: (body) => ({
        url: 'partner_relations',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { relatedTo }) => {
        // eslint-disable-next-line no-nested-ternary
        const currentType = relatedTo.includes('contacts')
          ? 'Contacts'
          : relatedTo.includes('leads')
            ? 'Leads'
            : 'Loans';
        return [
          { type: 'PartnerRelations', id: 'PARTIAL-LIST' },
          {
            type: currentType,
            id: relatedTo.split('/')[2],
          },
        ];
      },
    }),
    updatePartnerRelation: build.mutation({
      query: ({ id, ...put }) => ({
        url: `partner_relations/${id}`,
        method: 'PUT',
        body: put,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PartnerRelations', id },
      ],
    }),
    deletePartnerRelation: build.mutation({
      query: ({ id }) => ({
        url: `partner_relations/${id}`,
        method: 'DELETE',
      }),
      // Invalidates the tag for this lead `id`, as well as the `PARTIAL-LIST` tag,
      // causing the `getLeads` query to re-fetch if a component is subscribed to the query.
      invalidatesTags: (result, error, { id, type, typeId }) => [
        { type: 'PartnerRelations', id },
        { type: 'PartnerRelations', id: 'PARTIAL-LIST' },
        { type, id: typeId },
      ],
    }),
    patchPartnerRelation: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `partner_relations/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'PartnerRelations', id: 'PARTIAL-LIST' },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPartnerRelationsQuery,
  useGetPartnerRelationsByContactQuery,
  useGetPartnerRelationQuery,
  useAddNewPartnerRelationMutation,
  useUpdatePartnerRelationMutation,
  useDeletePartnerRelationMutation,
  usePatchPartnerRelationMutation,
} = PartnerRelationsApiSlice;
