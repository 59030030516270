import { CheckBox, Input, Text } from '@ui-kitten/components';
import { useController, useForm } from 'react-hook-form';
import { TextInputProps } from 'react-native/types';
import FormGroup from '../FormGroup';

type InputProps = {
  control: any;
  name: string;
  label: string;
  required?: boolean;
  register?: any;
  nextField?: any;
};

function CheckboxField({ control, name, label, required, register, nextField, ...rest}: InputProps & TextInputProps) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, errors },
  } = useController({
    name,
    control,
    rules: { required },
  });
  console.log('errors', errors);
  return (
    <FormGroup label={label} required={required}>
      <CheckBox
        {...register(name)}
        {...rest}
        onSubmitEditing={nextField}
        returnKeyType={nextField ? 'next' : ''}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        checked={field.value} // input value
      />
      {errors?.[name] && (
        <Text
          style={{
            fontSize: 10,
            color: 'red',
            marginLeft: 4,
          }}
        >
          This is required
        </Text>
      )}
    </FormGroup>
  );
}

export default CheckboxField;
