import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import Colors from '../../helpers/Colors';

type Props = {
  children: React.ReactNode;
  style?: any;
};

function ModalWrapper({ children, style = {} }: Props) {
  const windowWidth = Dimensions.get('window').width;
  return (
    <View
      style={[
        styles.container,
        {
          maxWidth: windowWidth - 32,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 768,
    padding: 32,
    backgroundColor: Colors.white,
  },
});
export default ModalWrapper;
