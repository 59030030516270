import moment from 'moment/moment';
import React from 'react';
import { Button, Platform, StyleSheet, View } from 'react-native';
import { ButtonGroup, Icon, Text } from '@ui-kitten/components';
import { Column, SavedView } from '../interfaces/Table';
import { returnPayPeriod } from './returnPayPeriod';
import { EditIcon } from '../components-ui/Icons';
import CommissionActionButtons from '../components-global/Commission/CommissionActionButtons';
import CommissionTierCustomFormatter from '../components-global/Commission/CommissionTierCustomFormatter';

//get first half of the month in moment

const styles = StyleSheet.create({
  selected: {
    backgroundColor: '#c8def5',
  },
  centeredView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
  },
  successApproval: {
    fontFamily: 'VerbBold',
    color: 'white',
    backgroundColor: 'green',
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
  },
  successApprovalMobile: {
    fontFamily: 'VerbBold',
    color: 'green',
  },
});

export function LockIcon({ status, rowData = null }) {
  if (status === 'locked') {
    return (
      <View style={styles.centeredView}>
        <Icon
          style={{ width: 22, height: 22 }}
          fill="blue"
          name="lock-outline"
        />
        {rowData ? (
          <Text category="label" style={{ marginTop: 6 }}>
            (
            {Math.floor(
              moment
                .duration(
                  moment(rowData.lockExpireDate)
                    .startOf('day')
                    .diff(moment().startOf('day'))
                )
                .asDays()
            )}
            )
          </Text>
        ) : null}
      </View>
    );
  }
  if (status === 'unlocked') {
    return (
      <View style={styles.centeredView}>
        <Icon
          style={{ width: 22, height: 22 }}
          fill="#aaa"
          name="unlock-outline"
        />
      </View>
    );
  }
  if (status === 'expired') {
    return (
      <View style={styles.centeredView}>
        <Icon
          style={{ width: 22, height: 22 }}
          fill="red"
          name="unlock-outline"
        />
      </View>
    );
  }
}



const metadata: { [key: string]: ObjectMetadataType } = {
  Lead: {
    firstName: {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      width: 150,
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      width: 150,
    },
    email: {
      name: 'email',
      label: 'Email',
      type: 'text',
      width: 250,
    },
    mobilePhone: {
      name: 'mobilePhone',
      label: 'Mobile Phone',
      type: 'phone',
      width: 200,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'text',
      width: 100,
      // textAlign: 'center',
      dropdownOptions: [
        ' ',
        'New',
        'In Progress',
        'Contacted - No Answer',
        'Unqualified',
        'Converted',
      ],
    },
    additionalInfo: {
      name: 'additionalInfo',
      label: 'Additional Info',
      type: 'text',
      width: 350,
    },
    createdAt: {
      name: 'createdAt',
      label: 'Created At',
      type: 'date',
      width: 200,
    },
    loanAmount: {
      name: 'loanAmount',
      label: 'Loan Amount',
      type: 'currency',
      width: 100,
    },
    propertyCity: {
      name: 'propertyCity',
      label: 'Property City',
      type: 'text',
      width: 100,
    },
    propertyState: {
      name: 'propertyState',
      label: 'Property State',
      type: 'text',
      width: 100,
    },
    propertyZip: {
      name: 'propertyZip',
      label: 'Property Zip',
      type: 'text',
      width: 100,
    },
    propertyCounty: {
      name: 'propertyCounty',
      label: 'Property County',
      type: 'text',
      width: 100,
    },
    loanPurpose: {
      name: 'loanPurpose',
      label: 'Loan Purpose',
      type: 'text',
      width: 100,
    },
    description: {
      name: 'description',
      label: 'Description',
      type: 'text',
      width: 100,
    },
    leadSource: {
      name: 'leadSource',
      label: 'Lead Source',
      type: 'text',
      width: 100,
    },
    homePhone: {
      name: 'homePhone',
      label: 'Home Phone',
      type: 'phone',
      width: 100,
    },
    closingDate: {
      name: 'closingDate',
      label: 'Closing Date',
      type: 'date',
      width: 100,
    },
    createdBy: {
      name: 'createdBy',
      label: 'Created By',
      type: 'text',
      width: 100,
    },
    updatedBy: {
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      width: 100,
    },
    modifiedAt: {
      name: 'modifiedAt',
      label: 'Modified At',
      type: 'date',
      width: 100,
    },
    isTLCMember: {
      name: 'isTLCMember',
      label: 'TLC Member',
      type: 'boolean',
      width: 100,
    },
    isPartner: {
      name: 'isPartner',
      label: 'Partner',
      type: 'boolean',
      width: 100,
    },
    partnerType: {
      name: 'partnerType',
      label: 'Partner Type',
      type: 'text',
      width: 100,
    },
    isTlcPartner: {
      name: 'isTlcPartner',
      label: 'TLC Partner',
      type: 'boolean',
      width: 100,
    },
    lastActivityDate: {
      name: 'lastActivityDate',
      label: 'Last Activity',
      type: 'date',
      width: 100,
    },
    lastSalesActivity: {
      name: 'lastSalesActivity',
      label: 'Last Sales Activity',
      type: 'date',
      width: 100,
    },
  },
  Loan: {
    Locked: {
      name: 'Locked',
      label: 'Locked',
      type: 'custom',
      customFormatter: (rowData: any) => {
        if (!rowData.lockExpireDate) {
          return <LockIcon status="unlocked" rowData={rowData} />;
        }
        if (
          rowData.lockExpireDate &&
          moment(rowData.lockExpireDate).isBefore(moment())
        ) {
          return <LockIcon status="expired" rowData={rowData} />;
        }
        return <LockIcon status="locked" rowData={rowData} />;
      },
      width: 80,
      maxWidth: 80,
      dropdownOptions: [
        ' ',
        <LockIcon status="unlocked" rowData={null} />,
        <LockIcon status="locked" rowData={null} />,
        <LockIcon status="expired" rowData={null} />,
      ],
      filterType: 'dropdown',
      customFilterFunction: (filter: any) => {
        const params: any = {};
        if (!filter.value.props.status || filter.value.props.status === '')
          return params;
        if (filter.value.props.status === 'expired') {
          params['lockExpireDate[strictly_before]'] = moment()
            .add(1, 'day')
            .startOf('day')
            .format('L');
        }
        if (filter.value.props.status === 'locked') {
          params['lockExpireDate[strictly_after]'] = moment()
            .startOf('day')
            .format('L');
        }
        if (filter.value.props.status === 'unlocked') {
          params.lockStatus = 'NotLocked';
        }

        return params;
      },
    },
    'pbFirstName': {
      name: 'pbFirstName',
      label: 'First Name',
      type: 'text',
      width: 110,
      maxWidth: 120,
    },
    'pbLastName': {
      name: 'pbLastName',
      label: 'Last Name',
      type: 'text',
      width: 110,
      maxWidth: 120,
    },
    loanNumber: {
      name: 'loanNumber',
      label: 'Loan #',
      type: 'loan',
      width: 100,
    },
    propertyAddress: {
      name: 'propertyAddress',
      label: 'Address',
      type: 'text',
      width: 150,
    },
    propertyCity: {
      name: 'propertyCity',
      label: 'City',
      type: 'text',
      width: 150,
    },
    propertyState: {
      name: 'propertyState',
      label: 'State',
      type: 'text',
      width: 80,
      maxWidth: 120,
      dropdownOptions: [
        ' ',
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
    propertyZip: {
      name: 'propertyZip',
      label: 'Zip',
      type: 'text',
      width: 80,
      maxWidth: 120,
    },
    stage: {
      name: 'stage',
      type: 'text',
      label: 'Stage',
      width: 190,
      dropdownOptions: [
        ' ',
        'Application',
        'Processing',
        'Submittal',
        'Approval',
        'Re-submittal',
        'Final Approval',
        'Clear to Close',
        'Docs Out',
        'Funding',
        'Shipped',
        'Adversed (post application)',
        'Lost Pre-Application',
      ],
    },
    loanType: {
      name: 'loanType',
      type: 'text',
      label: 'Loan Type',
      width: 110,
      maxWidth: 130,
      dropdownOptions: [' ', 'FHA', 'Conventional'],
    },
    interestRate: {
      name: 'interestRate',
      type: 'percent',
      label: 'Rate',
      width: 80,
      maxWidth: 80,
    },
    loanToValue: {
      name: 'loanToValue',
      type: 'percent',
      label: 'LTV',
      width: 80,
      maxWidth: 80,
    },
    loanAmount: {
      name: 'loanAmount',
      type: 'currency',
      label: 'Amount',
      width: 90,
      maxWidth: 80,
    },
    loanPurpose: {
      name: 'loanPurpose',
      type: 'text',
      label: 'Purpose',
      width: 140,
      maxWidth: 120,
      dropdownOptions: [' ', 'Refinance', 'Purchase'],
    },
    applicationDate: {
      name: 'applicationDate',
      type: 'date',
      label: 'App Date',
      width: 150,
      maxWidth: 150,
    },
    closeDate: {
      name: 'closeDate',
      label: 'Close Date',
      type: 'date',
      width: 150,
      maxWidth: 150,
    },
    lockExpireDate: {
      name: 'lockExpireDate',
      label: 'Lock Expire',
      type: 'date',
      width: 150,
      maxWidth: 150,
    },
    createdAt: {
      label: 'Created',
      name: 'createdAt',
      type: 'date',
      width: 100,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'text',
      width: 100,
    },
    propertyCounty: {
      name: 'propertyCounty',
      label: 'County',
      type: 'text',
      width: 100,
    },
    loanGeneratedBy: {
      name: 'loanGeneratedBy',
      label: 'Lead Source',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Self', 'Ark'],
    },
    loanSource: {
      name: 'loanSource',
      label: 'Source',
      type: 'text',
      width: 100,
    },
    loanSourceDetails: {
      name: 'loanSourceDetails',
      label: 'Source Details',
      type: 'text',
      width: 100,
    },
    closed: {
      name: 'closed',
      label: 'Is Closed',
      type: 'boolean',
      width: 50,
    },
    purchasedDate: {
      name: 'purchasedDate',
      label: 'Purchased Date',
      filterType: 'dateFilter',
      type: 'date',
      width: 100,
    },
    loCommission: {
      name: 'loCommission',
      label: 'LO Commission',
      type: 'currency',
      width: 100,
      decimalPlaces: 2,
    },
    closeStatus: {
      name: 'closeStatus',
      label: 'Close Status',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Won', 'Lost'],
    },
    purchasePrice: {
      name: 'purchasePrice',
      label: 'Purchase Price',
      type: 'currency',
      width: 100,
    },
    downPaymentAmount: {
      name: 'downPaymentAmount',
      label: 'Down Payment',
      type: 'currency',
      width: 100,
    },
    creditScore: {
      name: 'creditScore',
      label: 'Credit Score',
      type: 'number',
      width: 100,
    },
    lockStatus: {
      name: 'lockStatus',
      label: 'Lock Status',
      type: 'text',
      width: 100,
    },
    lockDate: {
      name: 'lockDate',
      label: 'Lock Date',
      type: 'date',
      width: 100,
    },
    lockDaysRemaining: {
      name: 'lockDaysRemaining',
      label: 'Lock Days Remaining',
      type: 'number',
      width: 100,
    },
    term: {
      name: 'term',
      label: 'Term',
      type: 'number',
      width: 100,
    },
    dti: {
      name: 'dti',
      label: 'DTI',
      type: 'percent',
      width: 100,
    },
    cashToClose: {
      name: 'cashToClose',
      label: 'Cash to Close',
      type: 'currency',
      width: 100,
    },
    appraisedValue: {
      name: 'appraisedValue',
      label: 'Appraised Value',
      type: 'currency',
      width: 100,
    },
    'owner.firstName': {
      name: 'owner.firstName',
      label: 'Owner First Name',
      type: 'text',
      width: 100,
    },
    'owner.lastName': {
      name: 'owner.lastName',
      label: 'Owner Last Name',
      type: 'text',
      width: 100,
    },
    'owner.email': {
      name: 'owner.email',
      label: 'Owner Email',
      type: 'text',
      width: 100,
    },
    occupancy: {
      name: 'occupancy',
      label: 'Occupancy',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Primary', 'Investment', 'Secondary'],
    },
    createdBy: {
      name: 'createdBy',
      label: 'Created By',
      type: 'text',
      width: 100,
    },
    updatedBy: {
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      width: 100,
    },
    modifiedAt: {
      name: 'modifiedAt',
      label: 'Modified',
      type: 'date',
      width: 100,
    },
  },
  Contact: {
    firstName: {
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      width: 120,
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      width: 120,
    },
    email: {
      name: 'email',
      label: 'Email',
      type: 'text',
      width: 250,
    },
    mobilePhone: {
      name: 'mobilePhone',
      label: 'Mobile Phone',
      type: 'phone',
      width: 140,
    },
    streetAddress: {
      name: 'streetAddress',
      label: 'Street Address',
      type: 'text',
      width: 200,
    },
    city: {
      name: 'city',
      label: 'City',
      type: 'text',
      width: 200,
    },
    state: {
      name: 'state',
      label: 'State',
      type: 'text',
      width: 90,
    },
    zipCode: {
      name: 'zipCode',
      label: 'Zip Code',
      type: 'text',
      width: 140,
    },
    additionalInfo: {
      name: 'additionalInfo',
      label: 'Additional Info',
      type: 'text',
      width: 250,
    },
    modifiedAt: {
      name: 'modifiedAt',
      label: 'Modified',
      type: 'date',
      width: 100,
    },
    createdAt: {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      width: 100,
    },
    homePhone: {
      name: 'homePhone',
      label: 'Home Phone',
      type: 'phone',
      width: 100,
    },
    createdBy: {
      name: 'createdBy',
      label: 'Created By',
      type: 'text',
      width: 100,
    },
    updatedBy: {
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      width: 100,
    },
    isTLCMember: {
      name: 'isTLCMember',
      label: 'TLC Member',
      type: 'boolean',
      width: 100,
    },
    isPartner: {
      name: 'isPartner',
      label: 'Partner',
      type: 'boolean',
      width: 100,
    },
    partnerType: {
      name: 'partnerType',
      label: 'Partner Type',
      type: 'text',
      width: 100,
    },
    isTlcPartner: {
      name: 'isTlcPartner',
      label: 'TLC Partner',
      type: 'boolean',
      width: 100,
    },
    lastActivityDate: {
      name: 'lastActivityDate',
      label: 'Last Activity',
      type: 'date',
      width: 100,
    },
    lastSalesActivity: {
      name: 'lastSalesActivity',
      label: 'Last Sales Activity',
      type: 'date',
      width: 100,
    },
  },
  Commission: {
    'commissionTier': {
      name: 'commissionTier',
      label: 'Tier',
      type: 'custom',
      width: 100,
      customFormatter: (rowData: any) => {
        return <CommissionTierCustomFormatter rowData={rowData}/>
      }
    },

    'commissionApproval.loApproval': {
      name: 'Action',
      label: '',
      type: 'custom',
      width: 120,
      maxWidth: 120,
      customFormatter: (rowData: any) => {
        return <CommissionActionButtons rowData={rowData} />;
      },
    },
    // 'commissionApproval.loApproval': {
    //   name: 'commissionApproval.loApproval',
    //   label: 'LO Approval',
    //   type: 'custom',
    //   width: 100,
    //   customFormatter: (rowData: any) => {
    //     if (rowData.commissionApproval) {
    //       return rowData?.commissionApproval?.loApproval ? (
    //         <Text
    //           style={
    //             Platform.OS === 'web'
    //               ? styles.successApproval
    //               : styles.successApprovalMobile
    //           }
    //           status={'control'}
    //         >
    //           {Platform.OS === 'web' ? '' : 'LO '}Approved
    //         </Text>
    //       ) : rowData?.commissionApproval?.loApproval === false ? (
    //         <Text style={{ fontFamily: 'VerbBold' }} status={'danger'}>
    //           {Platform.OS === 'web' ? '' : 'LO '}Rejected
    //         </Text>
    //       ) : (
    //         'Pending'
    //       );
    //     }
    //     return 'Pending';
    //   },
    // },
    // 'commissionApproval.managerApproval': {
    //   name: 'commissionApproval.managerApproval',
    //   label: 'Manager Approval',
    //   type: 'custom',
    //   width: 100,
    //   customFormatter: (rowData: any) => {
    //     if (rowData.commissionApproval) {
    //       return rowData?.commissionApproval?.managerApproval ? (
    //         <>
    //           <Text
    //             style={
    //               Platform.OS === 'web'
    //                 ? styles.successApproval
    //                 : styles.successApprovalMobile
    //             }
    //             status={'success'}
    //           >
    //             {Platform.OS === 'web' ? '' : 'Manager '}Approved
    //           </Text>
    //         </>
    //       ) : rowData?.commissionApproval?.managerApproval === false ? (
    //         <Text style={{ fontFamily: 'VerbBold' }} status={'danger'}>
    //           {Platform.OS === 'web' ? '' : 'Manager '}Rejected
    //         </Text>
    //       ) : (
    //         'Pending'
    //       );
    //     }
    //     return 'Pending';
    //   },
    // },
    'commissionApproval.loComment': {
      name: 'commissionApproval.loComment',
      label: 'LO Comment',
      type: 'text',
      width: 160,
      maxWidth: 200,
    },
    Locked: {
      name: 'Locked',
      label: 'Locked',
      type: 'custom',
      customFormatter: (rowData: any) => {
        if(Platform.OS !== 'web'){
          return rowData?.commissionApproval?.loApproval || ''
        }
        if (!rowData.lockExpireDate) {
          return <LockIcon status="unlocked" rowData={rowData} />;
        }
        if (
          rowData.lockExpireDate &&
          moment(rowData.lockExpireDate).isBefore(moment())
        ) {
          return <LockIcon status="expired" rowData={rowData} />;
        }
        return <LockIcon status="locked" rowData={rowData} />;
      },
      width: 80,
      maxWidth: 80,
      dropdownOptions: [
        ' ',
        <LockIcon status="unlocked" rowData={null} />,
        <LockIcon status="locked" rowData={null} />,
        <LockIcon status="expired" rowData={null} />,
      ],
      filterType: 'dropdown',
      customFilterFunction: (filter: any) => {
        const params: any = {};
        if (!filter.value.props.status || filter.value.props.status === '')
          return params;
        if (filter.value.props.status === 'expired') {
          params['lockExpireDate[strictly_before]'] = moment()
            .add(1, 'day')
            .startOf('day')
            .format('L');
        }
        if (filter.value.props.status === 'locked') {
          params['lockExpireDate[strictly_after]'] = moment()
            .startOf('day')
            .format('L');
        }
        if (filter.value.props.status === 'unlocked') {
          params.lockStatus = 'NotLocked';
        }

        return params;
      },
    },
    'loanContact.firstName': {
      name: 'loanContact.firstName',
      label: 'First Name',
      type: 'text',
      width: 110,
      maxWidth: 120,
    },
    'loanContact.lastName': {
      name: 'loanContact.lastName',
      label: 'Last Name',
      type: 'text',
      width: 110,
      maxWidth: 120,
    },
    loanNumber: {
      name: 'loanNumber',
      label: 'Loan #',
      type: 'loan',
      width: 120,
    },
    propertyAddress: {
      name: 'propertyAddress',
      label: 'Address',
      type: 'text',
      width: 150,
    },
    propertyCity: {
      name: 'propertyCity',
      label: 'City',
      type: 'text',
      width: 150,
    },
    propertyState: {
      name: 'propertyState',
      label: 'State',
      type: 'text',
      width: 80,
      maxWidth: 120,
      dropdownOptions: [
        ' ',
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
    propertyZip: {
      name: 'propertyZip',
      label: 'Zip',
      type: 'text',
      width: 80,
      maxWidth: 120,
    },
    stage: {
      name: 'stage',
      type: 'text',
      label: 'Stage',
      width: 190,
      dropdownOptions: [
        ' ',
        'Application',
        'Processing',
        'Submittal',
        'Approval',
        'Re-submittal',
        'Final Approval',
        'Clear to Close',
        'Docs Out',
        'Funding',
        'Shipped',
        'Adversed (post application)',
        'Lost Pre-Application',
      ],
    },
    loanType: {
      name: 'loanType',
      type: 'text',
      label: 'Loan Type',
      width: 110,
      maxWidth: 130,
      dropdownOptions: [' ', 'FHA', 'Conventional'],
    },
    interestRate: {
      name: 'interestRate',
      type: 'percent',
      label: 'Rate',
      width: 80,
      maxWidth: 80,
    },
    lockedAt: {
      name: 'lockedAt',
      type: 'date',
      label: 'Locked At',
      width: 80,
      maxWidth: 80,
    },
    loanToValue: {
      name: 'loanToValue',
      type: 'percent',
      label: 'LTV',
      width: 80,
      maxWidth: 80,
    },
    loanAmount: {
      name: 'loanAmount',
      type: 'currency',
      label: 'Amount',
      width: 130,
      maxWidth: 130,
    },
    loanPurpose: {
      name: 'loanPurpose',
      type: 'text',
      label: 'Purpose',
      width: 140,
      maxWidth: 120,
      dropdownOptions: [' ', 'Refinance', 'Purchase'],
    },
    applicationDate: {
      name: 'applicationDate',
      type: 'date',
      label: 'App Date',
      width: 150,
      maxWidth: 150,
    },
    closeDate: {
      name: 'closeDate',
      label: 'Close Date',
      type: 'date',
      width: 150,
      maxWidth: 150,
    },
    lockExpireDate: {
      name: 'lockExpireDate',
      label: 'Lock Expire',
      type: 'date',
      width: 150,
      maxWidth: 150,
    },
    createdAt: {
      label: 'Created',
      name: 'createdAt',
      type: 'date',
      width: 100,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'text',
      width: 100,
    },
    propertyCounty: {
      name: 'propertyCounty',
      label: 'County',
      type: 'text',
      width: 100,
    },
    loanGeneratedBy: {
      name: 'loanGeneratedBy',
      label: 'Lead Source',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Self', 'Ark'],
    },
    loanSource: {
      name: 'loanSource',
      label: 'Source',
      type: 'text',
      width: 100,
    },
    loanSourceDetails: {
      name: 'loanSourceDetails',
      label: 'Source Details',
      type: 'text',
      width: 100,
    },
    closed: {
      name: 'closed',
      label: 'Is Closed',
      type: 'boolean',
      width: 50,
    },
    purchasedDate: {
      name: 'purchasedDate',
      label: 'Purchased Date',
      filterType: 'dateFilter',
      type: 'date',
      width: 100,
    },
    loCommission: {
      name: 'loCommission',
      label: 'LO Commission',
      type: 'currency',
      width: 100,
    },
    closeStatus: {
      name: 'closeStatus',
      label: 'Close Status',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Won', 'Lost'],
    },
    purchasePrice: {
      name: 'purchasePrice',
      label: 'Purchase Price',
      type: 'currency',
      width: 100,
    },
    downPaymentAmount: {
      name: 'downPaymentAmount',
      label: 'Down Payment',
      type: 'currency',
      width: 100,
    },
    creditScore: {
      name: 'creditScore',
      label: 'Credit Score',
      type: 'number',
      width: 100,
    },
    lockStatus: {
      name: 'lockStatus',
      label: 'Lock Status',
      type: 'text',
      width: 100,
    },
    lockDate: {
      name: 'lockDate',
      label: 'Lock Date',
      type: 'date',
      width: 100,
    },
    lockDaysRemaining: {
      name: 'lockDaysRemaining',
      label: 'Lock Days Remaining',
      type: 'number',
      width: 100,
    },
    term: {
      name: 'term',
      label: 'Term',
      type: 'number',
      width: 100,
    },
    dti: {
      name: 'dti',
      label: 'DTI',
      type: 'percent',
      width: 100,
    },
    cashToClose: {
      name: 'cashToClose',
      label: 'Cash to Close',
      type: 'currency',
      width: 100,
    },
    appraisedValue: {
      name: 'appraisedValue',
      label: 'Appraised Value',
      type: 'currency',
      width: 100,
    },
    'owner.firstName': {
      name: 'owner.firstName',
      label: 'Owner First Name',
      type: 'text',
      width: 100,
    },
    'owner.lastName': {
      name: 'owner.lastName',
      label: 'Owner Last Name',
      type: 'text',
      width: 100,
    },
    'owner.email': {
      name: 'owner.email',
      label: 'Owner Email',
      type: 'text',
      width: 100,
    },
    occupancy: {
      name: 'occupancy',
      label: 'Occupancy',
      type: 'text',
      width: 100,
      dropdownOptions: [' ', 'Primary', 'Investment', 'Secondary'],
    },
    leadSource: {
      name: 'leadSource',
      label: 'Lead Source',
      type: 'custom',
      width: 100,
      customFormatter: (rowData: any) => {
        return rowData.branchConcession ? 'Company' : 'Self';
      },
    },
    createdBy: {
      name: 'createdBy',
      label: 'Created By',
      type: 'text',
      width: 100,
    },
    updatedBy: {
      name: 'updatedBy',
      label: 'Updated By',
      type: 'text',
      width: 100,
    },
    modifiedAt: {
      name: 'modifiedAt',
      label: 'Modified',
      type: 'date',
      width: 100,
    },
  },
};

export const defaultViews: { [key: string]: SavedView[] } = {
  Loan: [
    {
      name: 'Default',
      columns: [
        'Locked',
        'pbFirstName',
        'pbLastName',
        'loanNumber',
        'propertyCity',
        'propertyState',
        'stage',
        'loanType',
        'interestRate',
        'loanToValue',
        'loanAmount',
        'loanPurpose',
        'applicationDate',
        'closeDate',
        'lockExpireDate',
        'createdAt',
      ],
      additionalFilter: '',
      filters: [],
      order: [],
      isDefault: true,
      categoryFilter: [
        {
          column: 'phase',
          value: 'Application',
          operator: 'eq',
        },
      ],
      selectedCategory: 2,
      isNative: false,
    },
    {
      name: 'Mobile',
      columns: [
        'loanContact.firstName',
        'loanContact.lastName',
        'propertyAddress',
        'loanNumber',
        'loanAmount',
        'stage',
      ],
      selectedCategory: 2,
      isDefault: false,
      isNative: true,
      order: [],
      filters: [],
      additionalFilter: '',
      categoryFilter: [
        {
          column: 'phase',
          value: 'Application',
          operator: 'eq',
        },
      ],
    },
  ],
  Contact: [
    {
      name: 'Default',
      columns: [
        'firstName',
        'lastName',
        'email',
        'mobilePhone',
        'additionalInfo',
        'modifiedAt',
        'createdAt',
      ],
      isDefault: true,
      isNative: false,
      additionalFilter: '',
      filters: [],
      order: [
        {
          column: 'createdAt',
          direction: 'desc',
        },
      ],
      categoryFilter: [],
    },
    {
      name: 'Mobile',
      columns: [
        'firstName',
        'lastName',
        'email',
        'mobilePhone',
        'streetAddress',
        'city',
        'createdAt',
      ],
      selectedCategory: 1,
      isDefault: false,
      isNative: true,
      order: [],
      filters: [],
      additionalFilter: '',
      categoryFilter: [],
    },
  ],
  Lead: [
    {
      name: 'Default',
      columns: [
        'firstName',
        'lastName',
        'email',
        'mobilePhone',
        'status',
        'additionalInfo',
        'createdAt',
      ],
      additionalFilter: '',
      order: [
        {
          column: 'firstName',
          direction: 'asc',
        },
      ],
      filters: [],
      isDefault: true,
      isNative: false,
      categoryFilter: [
        {
          column: 'status',
          value: 'New',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'In Progress',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'Contacted - No answer',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'On Hold',
          operator: 'eq',
        },
      ],
      selectedCategory: 2,
    },
    {
      name: 'Mobile',
      columns: [
        'firstName',
        'lastName',
        'email',
        'mobilePhone',
        'status',
        'createdAt',
      ],
      selectedCategory: 2,
      isDefault: false,
      isNative: true,
      order: [],
      filters: [],
      additionalFilter: '',
      categoryFilter: [
        {
          column: 'status',
          value: 'New',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'In Progress',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'Contacted - No answer',
          operator: 'eq',
        },
        {
          column: 'status',
          value: 'On Hold',
          operator: 'eq',
        },
      ],
    },
  ],
  Commission: [
    {
      name: 'Default',
      columns: [
        'commissionApproval.loApproval',
        'commissionApproval.loComment',
        'loanNumber',
        'loanAmount',
        'propertyAddress',
        'closeDate',
        'purchasedDate',
        'leadSource',
        'lockedAt',
        'loCommission',
      ],
      additionalFilter: '',
      filters: [
        {
          column: 'purchasedDate',
          operator: '>=',
          value: returnPayPeriod('CURRENT').start,
        },
        {
          column: 'purchasedDate',
          operator: '<',
          value: returnPayPeriod('CURRENT').end,
        },
      ],
      order: [],
      isDefault: true,
      categoryFilter: [],
      selectedCategory: 2,
      isNative: false,
    },
    {
      name: 'Mobile',
      columns: [
        'loanContact.firstName',
        'loanContact.lastName',
        'propertyAddress',
        'loanNumber',
        'commissionApproval.loComment',
        'loanAmount',
        'commissionApproval.loApproval',
      ],
      selectedCategory: 2,
      isDefault: false,
      isNative: true,
      order: [],
      filters: [
        {
          column: 'purchasedDate',
          operator: '>=',
          value: returnPayPeriod('CURRENT').start,
        },
        {
          column: 'purchasedDate',
          operator: '<',
          value: returnPayPeriod('CURRENT').end,
        },
      ],
      additionalFilter: '',
      categoryFilter: [
        // {
        //   column: 'phase',
        //   value: 'Application',
        //   operator: 'eq',
        // },
      ],
    },
  ],
};

export interface ObjectMetadataType {
  [key: string]: Column;
}

export default metadata;
