import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthManager } from '../auth/AuthManager';
// eslint-disable-next-line import/no-unresolved
import { EXPO_PUBLIC_API_URL } from '@env';


const manager = new AuthManager();

const baseQuery = fetchBaseQuery({
  baseUrl: EXPO_PUBLIC_API_URL,
  isJsonContentType: (headers) => {
    const jsonTypes = ['application/json', 'application/ld+json', 'application/vnd.api+json'];
    const contentType = headers.get('Content-type');
    return contentType ? jsonTypes.includes(contentType) : false;
  },
  prepareHeaders: async (headers) => {
    const { idToken } = await manager.getAccessTokenAsync();

    if (idToken) {
      headers.set('x-authorization', `${idToken}`);
    }

    await AsyncStorage.getItem('@impersonating').then((res) => {
      if (res) {
        const impersonating = JSON.parse(res);
        headers.set('X-Switch-User', impersonating.email);
      }
      return null;
    });

    headers.set('Accept', 'application/json, text/plain, */*');

    return headers;
  },
});

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'Contacts',
    'Tasks',
    'Notes',
    'Events',
    'LoanApplications',
    'ContactActivities',
    'Leads',
    'LeadActivities',
    'LeadStatusLog',
    'LeadStatusLogsByLead',
    'AvailableLeads',
    'Loans',
    'LoanActivities',
    'Partners',
    'PartnerActivities',
    'PartnerRelations',
    'Stats',
    'User',
    'HomeData',
    'TlcInvites',
    'TlcClients',
    'TlcActivities',
    'TlcAgents',
    'PreApproval',
    'Borrowers',
    'BorrowerPairs',
    'CreditRequests',
  ],
  endpoints: () => ({}),
});
