import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Layout, Text } from '@ui-kitten/components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { ActivityIndicator, View, StyleSheet, Dimensions } from 'react-native';
import {
  useEditPreapprovalMutation,
  useGetPreApprovalPreviewQuery,
  useSendPreApprovalMutation,
} from '../../../../../services/PreApprovalLoanSlice';
import { PreviewHeader } from '../PreviewHeader.web';

type Props = {
  preapprovalId: number;
  borrowerName: string;
  isPreviewOnly?: boolean;
  cancelAction: () => void;
  borrowerEmail: string;
  isEdit?: boolean;
};

export default function PreviewActions({
  preapprovalId,
  borrowerName,
  isPreviewOnly = false,
  cancelAction,
  borrowerEmail,
  isEdit = false,
}: Props) {
  const [error, setError] = React.useState<null | string>(null);
  const [previewBase64, setPreviewBase64] = React.useState('');
  const {
    data: previewPreapprovalData,
    isFetching,
    isLoading,
    refetch,
  } = useGetPreApprovalPreviewQuery(preapprovalId || skipToken);
  const [
    sendPreapprovalEmail,
    {
      isSuccess: sendSuccess,
      isLoading: isLoadingSendEmail,
      isError: sendError,
    },
  ] = useSendPreApprovalMutation();

  const [patchPreapproval, { isLoading: isLoadingPatch }] =
    useEditPreapprovalMutation();

  useEffect(() => {
    if (isEdit) {
      refetch();
    }
  }, [isEdit]);

  useEffect(() => {
    if (previewPreapprovalData) {
      const url = window.URL || window.webkitURL;
      const blobPDF = url.createObjectURL(previewPreapprovalData);
      setPreviewBase64(blobPDF);
    }
    return () => {
      setPreviewBase64('');
    };
  }, [previewPreapprovalData]);

  useEffect(() => {
    if (sendError) {
      setError('Something went wrong sending email');
    }
  }, [sendSuccess, sendError]);

  async function handleDownload() {
    if (previewPreapprovalData) {
      patchPreapproval({
        id: preapprovalId,
        body: {
          status: 'sent',
          sentMethod: 'download',
          sentAt: new Date().toISOString(),
        },
      });
      const hiddenElement = document.createElement('a');
      const url = window.URL || window.webkitURL;
      const blobPDF = url.createObjectURL(previewPreapprovalData);
      hiddenElement.href = blobPDF;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${borrowerName.split(' ').join('_')}_${format(
        new Date(),
        't'
      )}_preapproval.pdf`;
      hiddenElement.click();
    }
  }

  async function handleSend() {
    sendPreapprovalEmail(preapprovalId);
  }

  if (!previewPreapprovalData || isLoading || isFetching)
    return (
      <View>
        <Text
          category="label"
          style={{ marginBottom: 16, fontSize: 16, textAlign: 'center' }}
        >
          {isPreviewOnly
            ? 'Retrieving Pre-Approval...'
            : ` Generating Pre-Approval for ${borrowerName}...`}
        </Text>
        <ActivityIndicator />
      </View>
    );

  return (
    <Layout>
      {previewBase64 ? (
        <View
          style={{
            flex: 1,
            width: Dimensions.get('window').width - 100,
            maxWidth: Dimensions.get('window').width - 100,
            marginHorizontal: -8,
            marginTop: -32,
            position: 'relative',
          }}
        >
          <PreviewHeader
            handleSend={handleSend}
            handleDownload={handleDownload}
            borrowerName={borrowerName}
            borrowerEmail={borrowerEmail}
            cancelAction={cancelAction}
            sendSuccess={sendSuccess}
            isSending={isLoadingSendEmail}
            error={error}
          />
          <iframe
            src={previewBase64}
            style={{
              width: '100%',
              minHeight: Dimensions.get('screen').height - 320,
            }}
          ></iframe>
        </View>
      ) : null}
    </Layout>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    marginTop: 16,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalView: {
    backgroundColor: 'white',
    padding: 16,
    alignItems: 'center',
    shadowColor: '#000',
    width: '70%',
    height: '70%',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
