import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Platform,
  Keyboard,
  ScrollView,
  View,
} from 'react-native';
import { Card, IndexPath, Input, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import {
  FormSuccess,
  KittenSelect,
} from '../../../components-ui';
//@ts-ignore
import GlobalSearchAutoComplete, { dataTransformer } from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useCreateLoanMutation } from '../../../services/LoanApiSlice';

const LOANPURPOSE = [' ', 'Purchase', 'Refinance'];
const LOANGENRATEDBY = [' ', 'ARK', 'Self'];
const LEADSOURCES = [
  ' ',
  'Advertisement',
  'Digital Marketing',
  'Employee Referral',
  'In source lead',
  'Partner/Client Referral',
  'Social',
  'Trade Show',
];

function CreateLoanForm({ cancelAction }: any) {
  const windowWidth = Dimensions.get('window').width;
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    propertyAddress: '',
    propertyCity: '',
    propertyState: '',
    propertyZip: '',
    loanSource: '',
    loanSourceDetails: '',
    loanPurpose: '',
    loanGeneratedBy: '',
    contact: '',
    contactId: null,
    firstName: '',
    lastName: '',
    stage: 'Prospect',
    status: 'New',
  });

  // Auto Complete
  const [createLoan, { isSuccess: addSuccess, data: addData }] =
    useCreateLoanMutation();
  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('&search=a');
  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const [loanStep, setLoanStep] = useState(1);

  const [loanPurposeIndex, setLoanPurposeIndex] = React.useState(
    new IndexPath(0)
  );
  const [loanGeneratedByIndex, setLoanGeneratedByIndex] = React.useState(
    new IndexPath(0)
  );
  const [leadSourceIndex, setLeadSourceIndex] = React.useState(
    new IndexPath(0)
  );

  useEffect(() => {
    if (!isFetching) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingContacts) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts]);
  //  End Auto Complete

  useEffect(() => {
    if (selectedAssociate && selectedAssociate.id) {
      console.log(selectedAssociate, 'SELECTED');
      setFormData((prevData) => ({
        ...prevData,
        contact: selectedAssociate['@id'],
        contactId: parseInt(selectedAssociate.id),
        firstName: selectedAssociate.firstName,
        lastName: selectedAssociate.lastName,
      }));
    }
  }, [selectedAssociate]);


  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      loanPurpose: LOANPURPOSE[loanPurposeIndex.row],
    }));
  }, [loanPurposeIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      loanGeneratedBy: LOANGENRATEDBY[loanGeneratedByIndex.row],
    }));
  }, [loanGeneratedByIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      leadSource: LEADSOURCES[leadSourceIndex.row],
    }));
  }, [leadSourceIndex]);

  useEffect(() => {
    if (addSuccess) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [addSuccess]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      checkForRequiredFields();
      createLoan(formData);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  function checkForRequiredFields() {
    if (formData?.propertyAddress === '')
      throw new Error('Address is required');
    if (formData?.propertyCity === '') throw new Error('City is required');
    if (formData?.propertyState === '') throw new Error('State is required');
    if (formData?.propertyZip === '') throw new Error('Zipcode is required');
    if (formData?.loanPurpose === '')
      throw new Error('Loan Purpose is required');
  }

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={[
        styles.cardContainer,
        {
          maxWidth: Platform.OS === 'web' ? windowWidth - 32 : windowWidth - 16,
          flex: 1,
        },
      ]}
    >
      <Card
        onPress={() => Platform.OS !== 'web' && Keyboard.dismiss()}
        style={[styles.card, Platform.OS !== 'web' && { marginBottom: 0 }]}
        footer={(props) => {
          return (
            <Footer
              {...props}
              cancelAction={() => cancelAction()}
              cancelText={'CANCEL'}
              submitAction={() => handleSubmit()}
              disabled={formSubmitted}
              submitText={loanStep === 0 ? 'NEXT' : 'CREATE'}
            />
          );
        }}
      >
        <Header heading={'Create A Loan'} />
        {loanStep === 1 ? (
          <Layout>
            <View style={{ zIndex: 3 }}>
              <FormGroup label="Select Contact" required={false}>
                <GlobalSearchAutoComplete
                  listDirection={'down'}
                  clearAutoCompleteData={() => setAutoCompleteData([])}
                  autoCompleteData={autoCompleteData}
                  isFetching={isFetching}
                  setQuery={setQuery}
                  handleOnChange={(e) => setSelectedAssociate(e)}
                  defaultValue={undefined}
                />
              </FormGroup>
            </View>

            {/* Address */}
            <FormGroup label="Street Address" required={false}>
              <Input
                placeholder="Street Address"
                value={formData['propertyAddress']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['propertyAddress']: nextValue,
                  }));
                }}
              />
            </FormGroup>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 2 }}>
                <FormGroup label="City" required={false}>
                  <Input
                    placeholder="City"
                    value={formData['propertyCity']}
                    onChangeText={(nextValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ['propertyCity']: nextValue,
                      }));
                    }}
                  />
                </FormGroup>
              </View>
              <View style={{ flex: 1 }}>
                <FormGroup label="State" required={false}>
                  <Input
                    maxLength={2}
                    placeholder="State"
                    value={formData['propertyState']}
                    onChangeText={(nextValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ['propertyState']: nextValue,
                      }));
                    }}
                  />
                </FormGroup>
              </View>
              <View style={{ flex: 1 }}>
                <FormGroup label="Zipcode" required={false}>
                  <Input
                    placeholder="Zipcode"
                    maxLength={5}
                    value={formData['propertyZip']}
                    onChangeText={(nextValue) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        ['propertyZip']: nextValue,
                      }));
                    }}
                  />
                </FormGroup>
              </View>
            </View>
            {/* Loan Purpose */}
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}>
                <FormGroup label="Loan Purpose" required>
                  <KittenSelect
                    selectedIndex={loanPurposeIndex}
                    setSelectedIndex={setLoanPurposeIndex}
                    options={LOANPURPOSE}
                  />
                </FormGroup>
              </View>
              <View style={{ flex: 1 }}>
                <FormGroup label="Loan Generated By" required={false}>
                  <KittenSelect
                    selectedIndex={loanGeneratedByIndex}
                    setSelectedIndex={setLoanGeneratedByIndex}
                    options={LOANGENRATEDBY}
                  />
                </FormGroup>
              </View>
            </View>
            <FormGroup label="Lead Source" required={false}>
              <KittenSelect
                selectedIndex={leadSourceIndex}
                setSelectedIndex={setLeadSourceIndex}
                options={LEADSOURCES}
              />
            </FormGroup>
            <FormGroup label="Lead Source Notes" required={false}>
              <Input
                placeholder="Notes"
                multiline={true}
                textStyle={{ minHeight: 64 }}
                value={formData['loanSourceDetails']}
                onChangeText={(nextValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['loanSourceDetails']: nextValue,
                  }));
                }}
              />
            </FormGroup>
          </Layout>
        ) : null}
        {loanStep === 2 ? <Text>Step 2</Text> : null}
        {error ? (
          <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
            {error}
          </Text>
        ) : null}

        {!!addSuccess && <FormSuccess />}
      </Card>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default CreateLoanForm;
