import React, { useState } from 'react';
import {
  SafeAreaView,
  Platform,
  ScrollView,
  RefreshControl,
  useWindowDimensions,
  View,
  StyleSheet,
  KeyboardAvoidingView,
} from 'react-native';
import { Text, Layout } from '@ui-kitten/components';
import Colors from '../../helpers/Colors';
import { useGetMeQuery } from '../../services/UserSlice';
import WeeklyProgress from '../../components-global/Home/WeeklyProgress';
import { QuickActionFAB, QuickActionsDropDown } from '../../components-global';
import TodaysTasks from '../../components-global/Tasks/TodaysTasks';
import TodaysEvents from '../../components-global/Events/TodaysEvents';
import ThisWeekCard from '../../components-global/Home/ThisWeekCard';
import CurrentAnnualIncomeCard from '../../components-global/Home/CurrentAnnualIncomeCard';
import EstimatedAnnualIncomeCard from '../../components-global/Home/EstimatedAnnualIncomeCard';
import ApplicationsStillNeededCard from '../../components-global/Home/ApplicationsStillNeededCard';
import ClosedQTDCard from '../../components-global/Home/ClosedQTDCard';
import RankingsTable from '../../components-global/Home/RankingsTable';
import ClubRank from '../../components-global/Home/ClubRank';
import AnniversaryCard from '../../components-global/Home/AnniversaryCard';
import PointsBreakdown from '../../components-global/Home/PointsBreakdown';
import ErrorBoundary from '../../components-global/ErrorBoundary';

export default function LandingScreen(props) {
  const windowWidth = useWindowDimensions().width;
  const [refreshing, setRefreshing] = useState(false);
  const { data: user } = useGetMeQuery({});

  const wait = (timeout) => new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(1000).then(() => setRefreshing(false));
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        height: useWindowDimensions().height,
      }}
    >
      <ErrorBoundary>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>
          <Layout level="2">
            <ScrollView
              style={[
                {
                  padding: 16,
                  height: useWindowDimensions().height,
                },
                Platform.OS === 'web' && windowWidth < 960
                  ? { paddingTop: 48 }
                  : null,
              ]}
            >
              <RefreshControl enabled refreshing={refreshing} onRefresh={onRefresh} />
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  height: 80,
                  paddingHorizontal: 4,
                }}
              >
                <Text
                  style={{
                    height: 40,
                    fontSize: Platform.OS === 'web' ? 36 : 24,
                    marginLeft: 16,
                    marginVertical: 32,
                    marginTop: Platform.OS === 'web' ? 30 : null,
                    fontFamily: 'VerbBold',
                    color: Colors.fontBlue,
                  }}
                >
                  {`Welcome back ${user?.firstName || ''}!`}
                </Text>
                {Platform.OS === 'web' && (
                  <WeeklyProgress />
                )}
                {Platform.OS === 'web' && (
                  <View style={{
                    marginLeft: 'auto', flexDirection: 'row', height: '100%', alignItems: 'center',
                  }}
                  >
                    <QuickActionsDropDown type="task" icon="clipboard" title="Add/Log" status="info" />
                    <QuickActionsDropDown type="contacts" title="Create" status="danger" icon="people-outline" />
                  </View>
                )}
              </View>
              <View>
                <View style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 24,
                  marginBottom: Platform.OS === 'web' ? 24 : 0,
                }}
                >
                  <View style={{
                    flex: 1, flexDirection: 'row', gap: 24, flexWrap: 'wrap', minWidth: Platform.OS === 'web' ? 800 : 200,
                  }}
                  >
                    <TodaysTasks />
                    <TodaysEvents />
                  </View>
                  <View style={{
                    flex: 1, flexDirection: 'row', gap: 24, flexWrap: 'wrap', minWidth: Platform.OS === 'web' ? 800 : 200,
                  }}
                  >
                    <ThisWeekCard />
                    <PointsBreakdown isHomePage />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 24,
                    marginBottom: Platform.OS === 'web' ? 24 : 0,
                  }}
                >
                  <View style={{
                    flex: 1, flexDirection: 'row', gap: 24, flexWrap: 'wrap', minWidth: Platform.OS === 'web' ? 600 : 200,
                  }}
                  >
                    <CurrentAnnualIncomeCard />
                    <EstimatedAnnualIncomeCard />
                  </View>
                  <View style={{
                    flex: 1, flexDirection: 'row', gap: 24, flexWrap: 'wrap', minWidth: Platform.OS === 'web' ? 600 : 200,
                  }}
                  >
                    <ApplicationsStillNeededCard />
                    <ClosedQTDCard />
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: Platform.OS === 'web' ? 'row' : 'column',
                  gap: 24,
                }}
              >
                {/* Left */}
                <View style={{ minWidth: 350, flex: 1 }}>
                  <View>
                    <View style={styles.containerRow}>
                      <RankingsTable />
                    </View>
                  </View>
                </View>
                {/*   Right */}
                <View
                  style={{
                    flexBasis: Platform.OS === 'web' ? '25%' : '100%',
                    minWidth: 300,
                    height: '100%',
                    marginBottom: 120,
                  }}
                >
                  <View style={[styles.container, { minWidth: 250 }]}>
                    <ClubRank user={user} />
                  </View>
                  <View style={[styles.container, { minWidth: 250 }]}>
                    <AnniversaryCard user={user} />
                  </View>
                </View>
              </View>
            </ScrollView>
          </Layout>
        </KeyboardAvoidingView>
        {Platform.OS !== 'web' ? <QuickActionFAB /> : null}
      </ErrorBoundary>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 300,
    minHeight: Platform.OS === 'web' ? 250 : 200,
  },
  containerRow: {
    minWidth: 300,
    minHeight: Platform.OS === 'web' ? 250 : 200,
    flex: 1,
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 24 : 4,
  },
});
