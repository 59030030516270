import {
  format,
  startOfMonth,
  endOfMonth,
  add,
  startOfYear, subMonths,
} from 'date-fns';

// const formatDate = 'MM-dd-yyyy'
const formatDate = 'MM/dd/yyyy'

export const returnPayPeriod = (period: string, today = new Date()) => {

  const todaysDate = parseInt(format(today, 'd'), 10);

  const startOfCurrentYear = startOfYear(today);
  const startOfYearFormatted = format(startOfCurrentYear, formatDate);

  const startOfCurrentMonth = startOfMonth(today);
  const startOfMonthFormatted = format(startOfCurrentMonth, formatDate);

  const midMonth = add(startOfCurrentMonth, { days: 15 });
  const midMonthFormatted = format(midMonth, formatDate);

  const endOfCurrentMonth = endOfMonth(today);
  const endOfMonthFormatted = format(endOfCurrentMonth, formatDate);

  const startOfPreviousMonth = startOfMonth(subMonths(today, 1));
  const midPrevMonth = add(startOfPreviousMonth, { days: 15 });
  const midPrevMonthFormatted = format(midPrevMonth, formatDate);
  // if period == current - return current 15 day spread(1 - 15 or 16 - end of month)
  if (period === 'UPCOMING') {
    if (todaysDate > 15) {
      return { start: midMonthFormatted, end: endOfMonthFormatted };
    }
    return { start: startOfMonthFormatted, end: midMonthFormatted };
  }
  if (period === 'CURRENT') {
    if (todaysDate > 15) {
      // return previous month 1 - 15
      return { start: startOfMonthFormatted, end: midMonthFormatted };
    }
    // return this previous's 15th - end of month
    return { start: midPrevMonthFormatted, end: startOfMonthFormatted };
  }
  if (period === 'ALL') {
    return { start: startOfYearFormatted, end: endOfMonthFormatted };
  }

  return { start: '', end: '' };

  // if period is upcomming - return UPCOMING 15 day spread
};
