import React from 'react';
import {
  StyleSheet,
  SafeAreaView,
} from 'react-native';

export default function NetworkInterceptor(props) {
  const { children } = props;

  return (
    <SafeAreaView style={styles.container}>
      {children}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative'
  },
});
