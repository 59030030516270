import {
  Divider,
  Icon,
  IndexPath,
  Layout,
  Spinner,
  Tooltip,
  Text,
} from '@ui-kitten/components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {
  Dimensions,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  View,
  StyleSheet,
} from 'react-native';
import { skipToken } from '@reduxjs/toolkit/query';
import { ThemeContext } from '../../../store/context/ThemeContext';
// @ts-ignore
import { Link } from '../../components/Navigation/Link';
import {
  formatPhoneNumber,
  currencyFormat,
} from '../../helpers/dataTransformerHelper';
import routes from '../../navigation/routes';
import { usePatchLeadMutation } from '../../services/LeadApiSlice';
import { useCreateLeadStatusLogMutation } from '../../services/LeadStatusLogApiSlice';
import { useGetContactQuery } from '../../services/ContactApiSlice';
import MetadataCard from './MetadataCard';
import { useDispatch } from 'react-redux';
import { showModal as realShow } from '../../services/FormSlice';

const STATUS = [
  'New',
  'Contacted - No Answer',
  'In Progress',
  'Unqualified',
  'Converted',
];

function ClientInformationColumn({ clientData, refetch }) {
  const [statusIndex, setStatusIndex] = useState(new IndexPath(0));
  const [patchLead, { isSuccess }] = usePatchLeadMutation();
  const [changeStatus] = useCreateLeadStatusLogMutation();
  const [isPatching, setIsPatching] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { data: referredByData, refetch: refetchReferred } = useGetContactQuery(
    clientData?.referredByContactId || skipToken,
  );

  useEffect(() => {
    if (clientData) {
      const indexOfStatus = STATUS.indexOf(clientData.status);
      setStatusIndex(new IndexPath(indexOfStatus));
    }
  }, [clientData]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsPatching(false);
      }, 1000);

      refetch();
    }
  }, [isSuccess]);

  async function handleConvertSuccess() {
    // patch lead
    setIsPatching(true);
    changeStatus({
      leadId: clientData.id,
      lead: `/leads/${clientData.id}`,
      status: 'Converted',
      date: new Date(),
    });
    await patchLead({
      id: clientData.id,
      converted: true,
      status: 'Converted',
    });
  }

  async function handleStatusChange(status) {
    // patch lead
    if (STATUS[statusIndex.row] === 'Converted') return null;

    if (status !== STATUS[statusIndex.row]) {
      if (status === 'Converted') {
        showModal({
          formType: 'convertToContact',
          data: {
            ...clientData,
            referred: clientData?.referredByContactId
              ? { ...referredByData }
              : null,
            callback: handleConvertSuccess,
          },
        });
      } else {
        setIsPatching(true);
        changeStatus({
          leadId: clientData.id,
          lead: `/leads/${clientData.id}`,
          status,
          date: new Date(),
        });
        await patchLead({
          id: clientData.id,
          converted: true,
          status,
        });
        if (status === 'Unqualified') {
          showModal({
            formType: 'task',
            data: {
              lead: `/leads/${clientData.id}`,
              dueDate: moment().add('6', 'months'),
              type: 'Follow Up',
              notificationsEnabled: true,
              description: '6 Month follow up',
              create: true,
            },
          });
        }
      }
    }
  }

  const renderToggleButton = () => (
    <View>
      <Text
        onPress={() => setVisible(true)}
        category="label"
        style={styles.value}
      >
        {clientData?.email && clientData.email.length > 17
          ? `${clientData.email.slice(0, 17)}...`
          : clientData.email || ''}
      </Text>
    </View>
  );

  return (
    <Layout
      level="2"
      style={{
        flex: 3,
        minWidth: Platform.OS === 'web' ? 565 : Dimensions.get('screen').width,
        borderRightWidth: 1,
        borderRightColor: '#aaa',
        paddingBottom: Platform.OS === 'web' ? 0 : 64,
      }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 24, paddingTop: 24 }}
      >
        <View style={{ flex: 1 }}>
          <Layout
            style={[
              styles.shadow,
              {
                alignItems: 'center',
                padding: 16,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              },
              Platform.OS === 'web' ? {} : { flex: 1 },
            ]}
          >
            <View>
              <Text category="h4">{clientData.name}</Text>
            </View>
            {/* contact methods */}
            <Layout
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 8,
              }}
            >
              {clientData.email ? (
                <Pressable
                  style={{ borderRadius: 64 }}
                  onPress={() => Linking.openURL(`mailto:${clientData.email}`)}
                >
                  <View
                    style={[
                      Platform.OS === 'web'
                        ? {}
                        : {
                          width: 40,
                          height: 40,
                          padding: 16,
                          borderRadius: 64,
                          backgroundColor: 'red',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 16,
                        },
                    ]}
                  >
                    <Icon
                      style={[styles.icon]}
                      fill="#fff"
                      name="email-outline"
                    />
                  </View>
                </Pressable>
              ) : null}
              {clientData.mobilePhone || clientData.homePhone ? (
                <Pressable
                  onPress={() => Linking.openURL(
                    `tel:${
                      clientData.mobilePhone
                        ? clientData.mobilePhone
                        : clientData.homePhone
                    }`,
                  )}
                >
                  <View
                    style={[
                      Platform.OS === 'web'
                        ? {}
                        : {
                          width: 40,
                          height: 40,
                          padding: 16,
                          borderRadius: 64,
                          backgroundColor: 'red',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 16,
                        },
                    ]}
                  >
                    <Icon
                      style={styles.icon}
                      fill="#fff"
                      name="phone-outline"
                    />
                  </View>
                </Pressable>
              ) : null}
              {clientData.mobilePhone ? (
                <Pressable
                  onPress={() => Linking.openURL(`sms:${clientData.mobilePhone}`)}
                >
                  <View
                    style={[
                      Platform.OS === 'web'
                        ? {}
                        : {
                          width: 40,
                          height: 40,
                          padding: 16,
                          borderRadius: 64,
                          backgroundColor: 'red',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 16,
                        },
                    ]}
                  >
                    <Icon
                      style={styles.icon}
                      fill="#fff"
                      name="message-square-outline"
                    />
                  </View>
                </Pressable>
              ) : null}
            </Layout>
            {clientData['@type'] === 'Contact' ? null : (
              <ProgressBar
                step={STATUS.indexOf(STATUS[statusIndex.row])}
                handleStatusChange={handleStatusChange}
              />
            )}
            {isPatching ? (
              <View
                style={{
                  padding: 12,
                  borderRadius: 4,
                  backgroundColor: '#eee',
                  position: 'absolute',
                  top: 70,
                }}
              >
                <Spinner size="giant" status="warning" />
              </View>
            ) : null}
          </Layout>
          {/* Lead/Contact Details */}
          <Layout style={styles.cardWrapper}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
                position: 'relative',
              }}
            >
              <Text style={styles.cardHeader} category="h6">
                {clientData['@type']}
                {' '}
                Details
              </Text>
              <Pressable
                onPress={() => {
                  if (STATUS[statusIndex.row] === 'Converted') return null;
                  showModal({
                    formType: 'editLead',
                    data: {
                      fields:
                        clientData['@type'] === 'Lead'
                          ? [
                            'isPartner',
                            'firstName',
                            'lastName',
                            'mobilePhone',
                            'email',
                            'homePhone',
                            'leadSource',
                            'referredBy',
                            'partnerType',
                          ]
                          : [
                            'firstName',
                            'lastName',
                            'mobilePhone',
                            'email',
                            'streetAddress',
                            'city',
                            'state',
                            'zip',
                            'referredBy',
                            'partnerType',
                          ],
                      data: {
                        ...clientData,
                        referred: clientData?.referredByContactId
                          ? { ...referredByData }
                          : null,
                      },
                      callback: refetch,
                    },
                  });
                }}
                style={{ position: 'absolute', right: 16 }}
              >
                <Text category="label" style={{ color: 'white' }}>
                  Edit
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              }}
            >
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Mobile:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {clientData.mobilePhone
                      ? formatPhoneNumber(clientData.mobilePhone)
                      : ''}
                  </Text>
                </View>
              </View>

              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Email:
                  </Text>
                </View>
                <View>
                  {Platform.OS === 'web' ? (
                    <Tooltip
                      anchor={renderToggleButton}
                      visible={visible}
                      placement="bottom end"
                      onBackdropPress={() => setVisible(false)}
                    >
                      <View
                        style={{
                          paddingHorizontal: 50,
                          backgroundColor: 'pink',
                        }}
                        // @ts-ignore
                        onMouseLeave={() => setVisible(false)}
                      >
                        <Text
                          style={{
                            fontSize: 19,
                            color: 'white',
                            paddingHorizontal: 16,
                          }}
                        >
                          {clientData.email || ''}
                        </Text>
                      </View>
                    </Tooltip>
                  ) : (
                    <Text category="label" style={styles.value}>
                      {clientData.email || ''}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            {Platform.OS === 'web' ? (
              <Divider style={{ marginBottom: 8 }} />
            ) : null}
            {/* User Details */}
            <View style={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column' }}>
              {clientData && clientData['@type'] === 'Lead' ? (
                <View style={styles.row}>
                  <View>
                    <Text category="p1" style={styles.label}>
                      Source:
                    </Text>
                  </View>
                  <View>
                    <Text category="label" style={styles.value}>
                      {clientData.leadSource || ''}
                    </Text>
                  </View>
                </View>
              ) : null}
              <View style={[styles.row, { flex: 1 }]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Referred By:
                  </Text>
                </View>
                <View>
                  {referredByData && clientData?.referredByContactId ? (
                    <Link
                      route={routes.ExtraRoutes.Contact}
                      routeParams={{ id: referredByData.id }}
                    >
                      <Text
                        category="label"
                        style={[
                          styles.value,
                          {
                            color: 'blue',
                            textDecorationLine: 'underline',
                          },
                        ]}
                      >
                        {referredByData?.name || ''}
                      </Text>
                    </Link>
                  ) : null}
                </View>
              </View>
              {clientData['@type'] === 'Contact' ? (
                <>
                  {Platform.OS === 'web' ? (
                    <Divider style={{ marginBottom: 8 }} />
                  ) : null}
                  <View style={[styles.row]}>
                    <View>
                      <Text category="p1" style={styles.label}>
                        Address:
                      </Text>
                    </View>
                    <View style={{flex: 1}}>
                      <Text category="label" style={[styles.value]}>
                        {clientData.propertyAddress || clientData.streetAddress
                          ? `${
                            clientData.propertyAddress
                              ? clientData.propertyAddress
                              : clientData.streetAddress
                          }, `
                          : ''}
                        {clientData.propertyCity || clientData.city || ''}
                        {' '}
                        {clientData.propertyState || clientData.state || ''}
                        {' '}
                        {clientData.propertyZip || clientData.zipCode || ''}
                      </Text>
                    </View>
                  </View>
                </>
              ) : null}
            </View>
          </Layout>
          {/* Loan Details */}
          {clientData['@type'] === 'Lead' ? (
            <Layout style={styles.cardWrapper}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 16,
                }}
              >
                <Text style={styles.cardHeader} category="h6">
                  Loan Details
                </Text>
                <Pressable
                  onPress={() => {
                    if (STATUS[statusIndex.row] === 'Converted') return null;
                    showModal({
                      formType: 'editLead',
                      data: {
                        fields:
                          clientData['@type'] === 'Lead'
                            ? [
                              'propertyAddress',
                              'propertyCity',
                              'propertyState',
                              'propertyZip',
                              'loanAmount',
                              'loanPurpose',
                            ]
                            : ['streetAddress', 'city', 'state', 'zipCode'],
                        data: {
                          ...clientData,
                          referred: { ...referredByData },
                        },

                        callback: refetch,
                      },
                    });
                  }}
                  style={{ position: 'absolute', right: 16 }}
                >
                  <Text category="label" style={{ color: 'white' }}>
                    Edit
                  </Text>
                </Pressable>
              </View>
              <View style={[styles.row]}>
                <View>
                  <Text category="p1" style={styles.label}>
                    Address:
                  </Text>
                </View>
                <View>
                  <Text category="label" style={styles.value}>
                    {clientData.propertyAddress || clientData.streetAddress
                      ? `${
                        clientData.propertyAddress
                          ? clientData.propertyAddress
                          : clientData.streetAddress
                      }, `
                      : ''}
                    {clientData.propertyCity || clientData.city || ''}
                    {' '}
                    {clientData.propertyState || clientData.state || ''}
                    {' '}
                    {clientData.propertyZip || clientData.zipCode || ''}
                  </Text>
                </View>
              </View>
              {Platform.OS === 'web' ? (
                <Divider style={{ marginBottom: 8 }} />
              ) : null}
              <View
                style={[
                  styles.row,
                  { paddingHorizontal: 0 },
                  Platform.OS !== 'web'
                    ? { flexDirection: 'column', marginBottom: 0 }
                    : null,
                ]}
              >
                <View style={[styles.row]}>
                  <View>
                    <Text category="p1" style={styles.label}>
                      Amount:
                    </Text>
                  </View>
                  <View>
                    <Text category="label" style={styles.value}>
                      {clientData.loanAmount
                        ? currencyFormat(clientData.loanAmount)
                        : ''}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View>
                    <Text category="p1" style={styles.label}>
                      Purpose:
                    </Text>
                  </View>
                  <View>
                    <Text category="label" style={styles.value}>
                      {clientData.loanPurpose || ''}
                    </Text>
                  </View>
                </View>
              </View>
            </Layout>
          ) : null}
          {/* Additional Info */}
          <Layout
            style={[
              styles.shadow,
              {
                marginTop: 24,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
                overflow: 'hidden',
                flex: 1,
              },
            ]}
          >
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 16,
              }}
            >
              <Text style={styles.cardHeader} category="h6">
                Additional Info
              </Text>
              <Pressable
                onPress={() => {
                  if (STATUS[statusIndex.row] === 'Converted') return null;
                  showModal({
                    formType: 'editLead',
                    data: {
                      fields: ['additionalInfo'],
                      data: { ...clientData, referred: { ...referredByData } },
                      leadId: clientData.id,
                      callback: refetch,
                    },
                  });
                }}
                style={{ position: 'absolute', right: 16 }}
              >
                <Text category="label" style={{ color: 'white' }}>
                  Edit
                </Text>
              </Pressable>
            </View>
            <ScrollView
              style={{ flex: 1, maxHeight: 130, minHeight: 100 }}
              contentContainerStyle={{ paddingHorizontal: 16 }}
            >
              <Text category="label" style={{ fontSize: 15 }}>
                {clientData?.additionalInfo || ''}
              </Text>
            </ScrollView>
          </Layout>
          <MetadataCard loanData={clientData} />
        </View>
      </ScrollView>
    </Layout>
  );
}

function BarArrow({ active, index, currentStep }) {
  const { theme } = useContext(ThemeContext);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
      }}
    >
      <View
        style={{
          borderWidth: 9,
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
          borderTopRightRadius: index === 4 ? 24 : 0,
          borderBottomRightRadius: index === 4 ? 24 : 0,
          borderColor:
            currentStep === 3
              ? 'red'
              : active || currentStep > index
                ? 'rgb(122, 183, 23)'
                : index === currentStep + 1
                  ? 'rgb(0, 122, 255)'
                  : '#eee',
          marginRight: 0,
          flex: 1,
          height: 1,
        }}
      />
      {/* rgb(122, 183, 23) */}

      {index !== 4 ? (
        <View
          style={{
            borderBottomWidth: 12,
            borderBottomColor:
              currentStep === 3
                ? 'red'
                : active || currentStep > index
                  ? 'rgb(122, 183, 23)'
                  : index === currentStep + 1
                    ? 'rgb(0, 122, 255)'
                    : '#eee',
            borderLeftWidth: 8,
            borderLeftColor: 'transparent',
            borderRightWidth: 8,
            borderRightColor: 'transparent',
            transform: [{ rotate: '90deg' }],
            height: 0,
            width: 0,
            right: -13,
            top: 3,
            position: 'absolute',
          }}
        />
      ) : null}
      {index !== 4 ? (
        <Icon
          style={{
            width: 60,
            height: 60,
            position: 'absolute',
            right: -34,
            top: -30,
            zIndex: 99,
          }}
          fill={theme === 'light' ? 'white' : 'rgb(34,43,69)'}
          name="chevron-right-outline"
        />
      ) : null}
      {index <= currentStep ? (
        <View
          style={{
            position: 'absolute',
            left: '48%',
            top: -1,
            zIndex: 100,
          }}
        >
          <Icon
            style={{
              width: 20,
              height: 20,
            }}
            fill="white"
            name="checkmark-outline"
          />
        </View>
      ) : null}
    </View>
  );
}

function ProgressBar({ step, handleStatusChange }) {
  return (
    <View style={styles.progressWrapper}>
      {STATUS.map((status: string, index: number) => (
        <Pressable
          key={status}
          onPress={() => handleStatusChange(status)}
          style={[styles.stepWrapper, { zIndex: 99 - index }]}
        >
          <BarArrow
            index={index}
            active={step === index}
            currentStep={step}
          />
          <Text category="label" style={styles.stepText}>
            {status}
          </Text>
        </Pressable>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    height: 18,
    width: 18,
    marginHorizontal: 4,
    backgroundColor: '#ff2503',
    borderRadius: 32,
    padding: 8,
  },
  divider: {
    height: 2,
    backgroundColor: 'white',
    width: '100%',
    marginVertical: 32,
  },
  label: {
    fontSize: 15,
    marginRight: 8,
  },
  value: {
    fontSize: 15,
    flexShrink: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 16,
    paddingHorizontal: 16,
    flex: 1,
  },
  cardWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginTop: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    flex: 1,
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  progressWrapper: {
    flexDirection: 'row',
    marginTop: 16,
    width: '100%',
  },
  stepWrapper: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '14.25%',
    flex: 1,
    height: 80,
    position: 'relative',
  },
  stepText: {
    textAlign: 'center',
    padding: 10,
    fontSize: Platform.OS === 'web' ? 12 : 9,
    height: 50,
  },
});

export default ClientInformationColumn;
