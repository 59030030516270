import React, { createRef, useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View, Keyboard } from 'react-native';
import {
  Select,
  IndexPath,
  Input,
  Datepicker,
  NativeDateService,
  CheckBox,
  Text,
  Layout,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import { renderOption } from '../renderMethods';
import FormGroup from '../FormGroup';
import { generateTime } from '../../../helpers/generateTime';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import Colors from '../../../helpers/Colors';
import moment from 'moment';
import {
  useCreateEventMutation,
  useEditEventMutation,
} from '../../../services/EventApiSlice';
import { NOTIFICATIONTIME, TYPES } from '../../../helpers/constants';
import { useCreateBulkActionsMutation } from '../../../services/BulkApiSlice';
import RNTimePicker from '../../../components-ui/TimePicker/RNTimePicker';

const times = generateTime();

function AddEventForm({ cancelAction, defaultData }: any) {
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState<any>({
    subject: new IndexPath(0),
    startDate: null,
    endDate: null,
    startDatetime: moment().format('lll'),
    endDatetime: moment().format('lll'),
    associateValue: '',
    description: '',
    notificationsEnabled: false,
  });
  const [
    patchEvent,
    { isSuccess: eventSuccess, isError: eventHasError, error: eventError },
  ] = useEditEventMutation();
  const displayValueEvent = TYPES[state.subject.row];
  const windowWidth = Dimensions.get('window').width;
  const componentRef = createRef<Datepicker>();
  const [createEvent, { isSuccess: createSuccess, data: createData }] =
    useCreateEventMutation();

  const [createBulkActions, { isSuccess: bulkSuccess }] =
    useCreateBulkActionsMutation();

  // GlobalSearch Data Handler
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [notificationTimeIndex, setNotificationTimeIndex] = useState(
    new IndexPath(0)
  );

  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } =
    useGetLoansQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (eventHasError && eventError) {
      //@ts-ignore
      if (eventError?.data && eventError.data['hydra:description']) {
        //@ts-ignore
        setError(eventError?.data['hydra:description']);
      } else {
        setError('Something went wrong, please try again.');
      }
      setFormSubmitted(false);
    }
  }, [eventHasError, eventError]);

  useEffect(() => {
    if (createSuccess || eventSuccess || bulkSuccess) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [createSuccess, eventSuccess, bulkSuccess]);

  useEffect(() => {
    if (!isFetching) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  useEffect(() => {
    if (defaultData) {
      setState({
        subject: new IndexPath(
          TYPES.indexOf(defaultData.subject) >= 0
            ? TYPES.indexOf(defaultData.subject)
            : 0
        ),
        startDate: defaultData.startDatetime
          ? new Date(defaultData.startDatetime)
          : null,
        startDatetime: defaultData.startDatetime
          ? moment(defaultData.startDatetime).format('lll')
          : moment().format('lll'),
        endDate: defaultData.endDatetime
          ? new Date(defaultData.endDatetime)
          : null,
        endDatetime: defaultData.endDatetime
          ? moment(defaultData.endDatetime).format('lll')
          : moment().format('lll'),
        description: defaultData.description || '',
        notificationsEnabled: defaultData.notificationsEnabled || false,
      });
    }
  }, [defaultData]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      // Check for fields
      await checkForRequiredFields();

      const formattedStartDate = moment(state.startDate).format('YYYY-DD-MM');
      const formattedEndDate = moment(state.endDate).format('YYYY-DD-MM');

      const startDate = moment(
        formattedStartDate + ' ' + moment(state.startDatetime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      const endDate = moment(
        formattedEndDate + ' ' + moment(state.endDatetime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      let notificationFormattedTime: any = null;
      if (state.notificationsEnabled) {
        if (notificationTimeIndex.row === 0) {
          notificationFormattedTime = moment(startDate)
            .subtract('1', 'hour')
            .toISOString();
        }
        if (notificationTimeIndex.row === 1) {
          notificationFormattedTime = moment(startDate)
            .subtract('30', 'minute')
            .toISOString();
        }
        if (notificationTimeIndex.row === 2) {
          notificationFormattedTime = moment(startDate)
            .subtract('15', 'minute')
            .toISOString();
        }
      }

      const payload: any = {
        subject: displayValueEvent,
        description: state.description,
        eventDate: startDate,
        startTime: startDate,
        startDatetime: startDate,
        endDatetime: endDate,
        endTime: endDate,
        notificationsEnabled: state.notificationsEnabled,
        notificationTime: notificationFormattedTime,
        reminderTime: state.notificationsEnabled ? '1' : null,
      };

      if (defaultData?.contactList) {
        //bulk  events
        const ids = [];
        defaultData.contactList.forEach((contact) => {
          ids.push(contact['@id']);
        });
        const bulkPayload = {
          ids: ids,
          uri: '/events',
          idField: defaultData.contactList[0]['@type'].toLowerCase(),
          body: payload,
        };

        createBulkActions(bulkPayload);
      } else {
        if (!defaultData?.lead && !defaultData?.contact && !defaultData?.loan) {
          if (selectedAssociate.type === 'Lead')
            payload.lead = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Loan')
            payload.loan = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Contact')
            payload.contact = selectedAssociate['@id'];
          if (selectedAssociate.type === 'Partner')
            payload.partner = selectedAssociate['@id'];
        } else {
          if (defaultData?.lead) {
            payload.lead = defaultData.lead;
          }
          if (defaultData?.contact) {
            payload.contact = defaultData.contact;
          }
          if (defaultData?.loan) {
            payload.loan = defaultData.loan;
          }
        }

        if (Boolean(!defaultData?.id)) {
          createEvent(payload);
        } else {
          //patch
          payload.id = defaultData.id;
          patchEvent(payload);
        }
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  async function checkForRequiredFields() {
    if (!state?.subject || state?.subject?.row == 0 || displayValueEvent === '')
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.startDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.startDatetime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!state.endDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.endDatetime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!selectedAssociate)
      throw new Error('Please select someone to associate this task with');
  }

  const isCreate = !defaultData || !defaultData?.id || defaultData?.contactList;
  const showGlobalSearch =
    !defaultData ||
    (!defaultData.contactList &&
      !defaultData?.lead &&
      !defaultData?.loan &&
      !defaultData?.contact);

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={!isCreate ? 'Edit Event' : 'Create An Event'} />
      {defaultData?.contactList ? (
        <View>
          <Text style={{ textAlign: 'center' }} category="label">
            Creating an event for {defaultData.contactList.length}{' '}
            {defaultData.contactList[0]['@id'].split('/')[1].slice(0, -1)}(s)
          </Text>
        </View>
      ) : null}
      {/* Event Type */}
      <FormGroup label="Select Event Type">
        <Select
          placeholder="Select Event Type"
          selectedIndex={state.subject}
          onSelect={(index: any) =>
            setState((prevState) => ({ ...prevState, subject: index }))
          }
          value={displayValueEvent}
        >
          {TYPES.map(renderOption)}
        </Select>
      </FormGroup>
      {/* description */}
      <FormGroup label="Notes" required={false}>
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: 80 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Start Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.startDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  startDate: e,
                  endDate: prevState.endDate || e,
                }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select Start Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select start time'}
                value={state.startDatetime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    startDatetime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="End Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.endDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({ ...prevState, endDate: e }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select End Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select end time'}
                value={state.endDatetime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    endDatetime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1, paddingTop: 4 }}>
          <FormGroup required={false}>
            <CheckBox
              style={{ paddingTop: 8 }}
              checked={state.notificationsEnabled}
              onChange={(nextChecked) =>
                setState((prevState) => ({
                  ...prevState,
                  notificationsEnabled: nextChecked,
                }))
              }
            >
              {`Set Reminder`}
            </CheckBox>
          </FormGroup>
        </View>

        <View style={{ flex: 1 }}>
          {state.notificationsEnabled ? (
            <FormGroup label="Notification Time">
              <KittenSelect
                selectedIndex={notificationTimeIndex}
                setSelectedIndex={setNotificationTimeIndex}
                options={NOTIFICATIONTIME}
              />
            </FormGroup>
          ) : null}
        </View>
      </View>

      {/* Associate With */}
      {showGlobalSearch && (
        <FormGroup label=" Associate this event with:">
          {autoCompleteData && (
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
              selectedAssociate={selectedAssociate}
            />
          )}
        </FormGroup>
      )}
      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {(!!createSuccess || !!eventSuccess || bulkSuccess) && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={!isCreate ? 'SAVE' : 'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default AddEventForm;
