import React from 'react';
import { stringify } from 'query-string';
import { generatePath, Link as DOMLink } from 'react-router-dom';

const Link = (props) => {
  const {
    route, routeParams, queryParams, style, children
  } = props;

  const params = `?${stringify(queryParams)}`;

  const finalPath = generatePath(route.path, routeParams);
  return (
    <DOMLink
      style={style || { textDecoration: 'none' }}
      to={{ pathname: finalPath, search: params }}
    >
      {children}
    </DOMLink>
  );
};

// Link.propTypes = {
//   route: PropTypes.object.isRequired,
//   routeParams: PropTypes.object,
//   queryParams: PropTypes.object,
//   style: PropTypes.object,
// };

export { Link };
