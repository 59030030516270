import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Platform,
  Keyboard,
  ScrollView,
  View,
} from 'react-native';
import {
  Button,
  Card,
  IndexPath,
  Input,
  Layout,
  Text,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';

import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import {
  useAddNewContactMutation,
  useGetContactsQuery,
  useLazyGetContactsQuery,
} from '../../../services/ContactApiSlice';
import routes from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/router';

const BOOLEANFIELDS = [' ', 'Yes', 'No'];

const PARTNERTYPE = [
  ' ',
  'Real Estate Agent',
  'CPA',
  'Attorney',
  'Title Company',
  'Insurance Company',
  'Financial Planner',
  'Other',
];
const PARTNERTYPEMAPPING = [
  { value: null, label: '' },
  { value: 'RE_AGENT', label: 'Real Estate Agent' },
  { value: 'CPA', label: 'CPA' },
  { value: 'ATTORNEY', label: 'Attorney' },
  { value: 'TC', label: 'Title Company' },
  { value: 'IC', label: 'Insurance Company' },
  { value: 'FP', label: 'Financial Planner' },
  { value: 'OTHER', label: 'Other' },
];

function CreateContactFrom({ cancelAction, defaultData }: any) {
  const navigate = useNavigation();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [duplicateContactId, setDuplicateContactId] = useState<number | null>(
    null
  );
  const [formData, setFormData] = useState({
    firstName: defaultData?.firstName || '',
    lastName: defaultData?.lastName || '',
    email: defaultData?.email || '',
    mobilePhone: defaultData?.mobilePhone || '',
    status: 'New',
    isPartner: defaultData?.isPartner || false,
    partnerType: '',
    streetAddress: defaultData?.propertyAddress || '',
    city: defaultData?.propertyCity || '',
    state: defaultData?.propertyState || '',
    zipCode: defaultData?.propertyZip || '',
    loanAmount: defaultData?.loanAmount || '',
    loanPurpose: defaultData?.loanPurpose || '',
    leadId: defaultData
      ? defaultData['@type'] === 'Lead'
        ? defaultData.id
        : null
      : null,
  });
  const [isPartnerIndex, setIsPartnerIndex] = React.useState(new IndexPath(0));
  const [partnerTypeIndex, setPartnerTypeIndex] = React.useState(
    new IndexPath(0)
  );

  useEffect(() => {
    if (defaultData) {
      //leadId
      if (defaultData['@type'] === 'Lead') {
        setFormData((prevData: any) => ({
          ...prevData,
          leadId: defaultData.id,
        }));
      }

      if (defaultData.isPartner) {
        let partnerIndex = 0;
        PARTNERTYPEMAPPING.forEach((partner: any, index: number) => {
          if (partner.value === defaultData.partnerType) {
            partnerIndex = index;
          }
        });
        setIsPartnerIndex(new IndexPath(1));
        setPartnerTypeIndex(new IndexPath(partnerIndex));
      }
    }
  }, [defaultData]);

  // Auto Complete
  const [createContact, { isSuccess: addSuccess, data: addData }] =
    useAddNewContactMutation();
  const [getContact] = useLazyGetContactsQuery();
  const [error, setError] = useState();
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);

  useEffect(() => {
    if (!isFetching) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingContacts) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts]);
  //  End Auto Complete

  useEffect(() => {
    if (isPartnerIndex?.row === 1) {
      setFormData((prevData) => ({ ...prevData, isPartner: true }));
    } else {
      setFormData((prevData) => ({ ...prevData, isPartner: false }));
    }
  }, [isPartnerIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      partnerType: PARTNERTYPEMAPPING[partnerTypeIndex.row].value,
    }));
  }, [partnerTypeIndex]);

  useEffect(() => {
    if (selectedAssociate && selectedAssociate['@id'])
      setFormData((prevData) => ({
        ...prevData,
        referredBy: selectedAssociate['@id'],
        referralType: selectedAssociate.type,
      }));
  }, [selectedAssociate]);

  useEffect(() => {
    if (addSuccess && addData) {
      if (defaultData?.callback) {
        defaultData.callback(addData);
      }
      setTimeout(() => {
        cancelAction(addData);
      }, 2000);
    }
  }, [addSuccess, addData]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      checkForRequiredFields();
      //search for contact by email
      const data = await getContact(`&email=${formData.email}`).unwrap();
      if (data?.['hydra:member']?.length) {
        //check if person has same first and last name
        data['hydra:member'].forEach((contact) => {
          if (
            contact?.firstName?.toLowerCase() ===
              formData?.firstName?.toLowerCase() &&
            contact?.lastName?.toLowerCase() ===
              formData?.lastName?.toLowerCase()
          ) {
            setDuplicateContactId(contact.id);
            throw new Error(
              'A contact with this email and name already exists'
            );
          }
        });
      }
      createContact(formData);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  function checkForRequiredFields() {
    if (formData?.firstName === '') throw new Error('First Name is required');
    if (formData?.lastName === '') throw new Error('First Name is required');
    if (formData?.email === '' && formData?.mobilePhone === '')
      throw new Error('Please fill out a contact method(email, or phone)');

    if (isPartnerIndex?.row === 1 && partnerTypeIndex?.row === 0)
      throw new Error('Please Select Partner Type');
    if (formData?.email && !formData.email.includes('@'))
      throw new Error('Please enter a valid email address');
  }

  return (
    <Layout level="1" style={{ flex: 1, position: 'relative', minWidth: 320 }}>
      <Header heading={'Create A Contact'} />
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Is Partner ?" required={false}>
            <KittenSelect
              selectedIndex={isPartnerIndex}
              setSelectedIndex={setIsPartnerIndex}
              options={BOOLEANFIELDS}
            />
          </FormGroup>
        </View>
        {isPartnerIndex?.row === 1 ? (
          <View style={{ flex: 1 }}>
            <FormGroup label="Partner Type">
              <KittenSelect
                selectedIndex={partnerTypeIndex}
                setSelectedIndex={setPartnerTypeIndex}
                options={PARTNERTYPE}
              />
            </FormGroup>
          </View>
        ) : null}
      </View>
      {/* Name */}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="First Name">
            <Input
              placeholder="First Name"
              value={formData['firstName']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['firstName']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Last Name">
            <Input
              placeholder="Last Name"
              value={formData['lastName']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['lastName']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      {/* Contact */}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Email" required={false}>
            <Input
              placeholder="Email"
              value={formData['email']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['email']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Mobile Phone" required={false}>
            <Input
              keyboardType="numeric"
              placeholder="Mobile Phone"
              value={formData['mobilePhone']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['mobilePhone']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      {/* Referred Type */}
      <View style={{ flexDirection: 'column' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Referred By " required={false}>
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              selectedAssociate={
                selectedAssociate
                  ? selectedAssociate
                  : defaultData?.referred
                  ? {
                      type: defaultData.referred.type,
                      id: defaultData.referred.id,
                      ['@id']: defaultData.referred['@id'],
                      contactId: defaultData.referred.id,
                      title: defaultData.referred.name,
                      name: defaultData.referred.name,
                    }
                  : null
              }
              page={page}
              setPage={setPage}
              defaultValue={
                defaultData?.referred
                  ? {
                      type: defaultData.referred.type,
                      id: defaultData.referred.id,
                      ['@id']: defaultData.referred['@id'],
                      contactId: defaultData.referred.id,
                      title: defaultData.referred.name,
                      name: defaultData.referred.name,
                    }
                  : null
              }
            />
          </FormGroup>
        </View>
      </View>
      {/* Address */}
      <FormGroup label="Street Address" required={false}>
        <Input
          placeholder="Street Address"
          value={formData['streetAddress']}
          onChangeText={(nextValue) => {
            setFormData((prevData) => ({
              ...prevData,
              ['streetAddress']: nextValue,
            }));
          }}
        />
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2 }}>
          <FormGroup label="City" required={false}>
            <Input
              placeholder="City"
              value={formData['city']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['city']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="State" required={false}>
            <Input
              maxLength={2}
              placeholder="State"
              value={formData['state']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['state']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Zipcode" required={false}>
            <Input
              keyboardType="numeric"
              placeholder="Zipcode"
              value={formData['zipCode']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['zipCode']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {duplicateContactId ? (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 4,
          }}
        >
          <Button
            size="small"
            appearance="outline"
            onPress={() => {
              cancelAction();
              navigate.navigate(routes.ExtraRoutes.Contact, {
                id: duplicateContactId,
              });
            }}
          >
            View Contact
          </Button>
        </View>
      ) : null}
      {!!addSuccess && <FormSuccess />}
      {/* </Card> */}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'CREATE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default CreateContactFrom;
