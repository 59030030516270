import React from 'react';
import {
  Drawer,
  DrawerGroup,
  DrawerItem,
  Toggle,
  Text,
} from '@ui-kitten/components';
import produce from 'immer';
import {
  useGetMeQuery,
  useUpdateNotificationSettingMutation,
} from '../../services/UserSlice';
import { View } from 'react-native';

export default function NotificationSettings() {
  const notificationTitles = {
    task: 'Tasks',
    event: 'Events',
    anniversary: 'Anniversary',
    claim_lead: 'Claim Lead',
  };
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const { data: user, isLoading } = useGetMeQuery({});
  const [updateNotificationSetting, {}] =
    useUpdateNotificationSettingMutation();

  const changeSetting = (setting, notificationType) => {
    const notificationSettings = produce(user.notificationSettings, (draft) => {
      const notificationSetting = draft.find(
        (newSetting) => newSetting['@id'] === setting['@id']
      );
      if (notificationSetting !== undefined) {
        notificationSetting[notificationType] = !setting[notificationType];
      } else {
        setting[notificationType] = !setting[notificationType];
        draft.push(setting);
      }
    });
    updateNotificationSetting({ id: user.id, notificationSettings });
  };

  if (isLoading) {
    return null;
  }

  const renderDrawerItem = (title, settingType, notificationType) => {
    let setting = user.notificationSettings.find(
      (setting) => setting.type === settingType
    );
    if (setting === undefined) {
      setting = {
        type: settingType,
        [notificationType]: false,
      };
    }
    const disabled: boolean =
      settingType === 'anniversary' && notificationType !== 'sendEmail';

    return (
      <DrawerItem
        key={title}
        title={title}
        accessoryRight={() => (
          <Toggle
            checked={setting[notificationType]}
            onChange={() => changeSetting(setting, notificationType)}
            disabled={disabled}
          />
        )}
      />
    );
  };

  return (
    <View>
      <Text category="label" style={{ paddingLeft: 16, fontSize: 18, marginBottom: 8 }}>
        Notifications
      </Text>
      <Drawer
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}
      >
        {Object.entries(notificationTitles).map(([settingType, title]) => (
          <DrawerGroup key={settingType} title={title}>
            {renderDrawerItem('Push', settingType, 'sendPush')}
            {renderDrawerItem('SMS', settingType, 'sendSms')}
            {renderDrawerItem('Email', settingType, 'sendEmail')}
          </DrawerGroup>
        ))}
      </Drawer>
    </View>
  );
}
