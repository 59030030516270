import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ImpersonatingUser {
  email: string;
  name: string;
  id: string;
}

export const impersonationSlice = createSlice({
  name: 'impersonating',
  initialState: {},
  reducers: {
    setImpersonation: (state, action: PayloadAction<ImpersonatingUser>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setImpersonation } = impersonationSlice.actions;

export default impersonationSlice.reducer;
