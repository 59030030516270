import { LinearGradient } from 'expo-linear-gradient';
import { View } from 'react-native';
import React from 'react';
//@ts-ignore
import { useNavigation } from '../../navigation/router';
import { Button, Layout, Text } from '@ui-kitten/components';
import Colors from '../../helpers/Colors';

const BasicPageHeaderWeb = ({ title }) => {
  const navigation = useNavigation();
  return (
    <Layout
      style={{
        justifyContent: 'center',
        padding: 24,
        marginBottom: 24,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text category="h1">{title}</Text>
        </View>
      </View>
    </Layout>
  );
};

export default BasicPageHeaderWeb;
