import React, { useEffect, useState } from 'react';
import { useWindowDimensions, View } from 'react-native';
import {
  Button,
  Checkbox,
  DataTable,
  IconButton,
  Text,
} from 'react-native-paper';
import Papa from 'papaparse';
import { useRoute } from '../../../navigation/router';
import LeadsMappingDropDown from '../../../components/Leads/LeadsMappingDropDown';
import { leadFieldsHelper } from '../../../components/Leads/leadFieldsHelper';
import HttpService from '../../../../services/app/HttpService';


 const contactFieldsHelper = [
  { value: 'firstName', label: 'First Name', allowed: true },
  { value: 'lastName', label: 'Last Name', allowed: true },
  { value: 'streetAddress', label: 'Street Address', allowed: true },
  { value: 'propertyAddress2', label: 'Address 2', allowed: true },
  { value: 'city', label: 'City', allowed: true },
  { value: 'state', label: 'State', allowed: true },
  { value: 'zipCode', label: 'Zipcode', allowed: true },
  { value: 'homePhone', label: 'Home Phone', allowed: true },
  { value: 'mobilePhone', label: 'Mobile Phone', allowed: true },
  { value: 'email', label: 'Email Address', allowed: true },
  { value: 'referredBy', label: 'Referred By', allowed: true },
  { value: 'additionalInfo', label: 'Additional Info', allowed: true },
   { value: 'isPartner', label: 'Is Partner', allowed: true },
   { value: 'partnerType', label: 'Partner Type', allowed: true }
];


export default function ColumnMapperWeb({ setPage, fileData }) {
  const route = useRoute();
  const { height, width } = useWindowDimensions();
  const [mappingData, setMappingData] = useState([]);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  // eslint-disable-next-line max-len
  const [availableLeadFields, setAvailableLeadFields] = useState(
    JSON.parse(
      JSON.stringify(
        route.path.includes('leads') ? leadFieldsHelper : contactFieldsHelper,
      ),
    ),
  );

  useEffect(() => {
    if (fileData) {
      Papa.parse(fileData, {
        header: true,
        skipEmptyLines: true,
        complete(results) {
          // console.log(results.data[0]);
          const data = [];
          // grab only the first entry
          const keys = Object.keys(results.data[0]);
          for (let i = 0; i < Object.keys(results.data[0]).length; i++) {
            data.push({
              fieldName: keys[i],
              preview: results.data[0][keys[i]],
              mapTo: '',
              mapThisField: false,
            });
          }
          setMappingData(data);
        },
      });
    }
  }, [fileData]);

  useEffect(() => {
    const newAvailableMapping = JSON.parse(
      JSON.stringify(
        route.path.includes('leads') ? leadFieldsHelper : contactFieldsHelper,
      ),
    );

    mappingData.forEach((row) => {
      if (row.mapTo && row.mapThisField) {
        // find mapTo and disable
        const foundIndex = newAvailableMapping.findIndex(
          (x) => x.value === row.mapTo,
        );
        newAvailableMapping[foundIndex].allowed = false;
      }
    });
    setAvailableLeadFields([...newAvailableMapping]);
  }, [mappingData]);

  function bytesToSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  // toggle button to map
  const handleMapThisField = (field) => {
    setMappingData((prevState) => prevState.map((row) => {
      if (row.fieldName === field.fieldName && row.mapTo) {
        // eslint-disable-next-line no-param-reassign
        row.mapThisField = !row.mapThisField;
        // eslint-disable-next-line no-param-reassign
        row.mapTo = '';
      }
      return row;
    }));
  };
  // sets mapTo to fieldName
  const handleMapping = (e, row) => {
    const { value } = e.target;
    setMappingData((prevState) => prevState.map((currentRow) => {
      if (row.fieldName === currentRow.fieldName) {
        // eslint-disable-next-line no-param-reassign
        currentRow.mapTo = value;
        // eslint-disable-next-line no-param-reassign
        if (!currentRow.mapThisField) currentRow.mapThisField = true;
      }
      return currentRow;
    }));
  };

  const handleSubmit = () => {
    let canSubmit = false;

    mappingData.forEach((row) => {
      if (row.mapThisField && row.mapTo.length) {
        canSubmit = true;
      }
    });

    if (canSubmit) {
      const formData = new FormData();
      formData.append('map_array', JSON.stringify(mappingData));
      formData.append('file', fileData);
      formData.append(
        'list_type',
        route.path.includes('leads') ? 'lead' : 'contact',
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      }
      const httpClient = new HttpService({
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      httpClient.client
        .post(
          '/uploads',
          formData,
        )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      setDataSubmitted(true);
    } else {
      alert('Nothing to insert, please make sure to map your columns.');
    }
  };

  return (
    <View
      style={{
        flex: 1,
        height,
        backgroundColor: 'white',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: height * 0.1,
        }}
      >
        <IconButton icon="chevron-left" size={43} onPress={() => setPage(1)} />
        <Text
          style={{ fontSize: 43, fontFamily: 'VerbBold', color: '#346bb3' }}
        >
          Map the Columns
        </Text>
      </View>
      {!dataSubmitted ? (
        <View
          style={{
            height: height * 0.9,
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flex: 2,
              marginTop: 96,
            }}
          >
            <Text>
              Please select and map all the columns for the file uploaded.
            </Text>
            <View
              style={{
                marginTop: 24,
                marginBottom: 48,
                paddingHorizontal: 16,
                paddingVertical: 8,
                borderWidth: 1,
                borderRadius: 10,
                borderColor: '#C4C4C4',
                justifyContent: 'center',
                shadowOffset: { width: 1, height: 1 },
                shadowColor: 'rgba(196, 196, 196, 0.35)',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ fontSize: 20 }}>{fileData.name}</Text>
                <IconButton icon="check-circle" color="#06C00D" />
              </View>
              <Text
                style={{
                  fontSize: 16,
                  color: '#8c8c8c',
                  fontFamily: 'VerbLight',
                }}
              >
                {bytesToSize(fileData.size)}
              </Text>
              {/* <ProgressBar /> */}
            </View>
            <Button
              style={{ width: 150 }}
              mode="contained"
              color="#1976D2"
              icon="arrow-right"
              contentStyle={{ flexDirection: 'row-reverse' }}
              onPress={() => handleSubmit()}
            >
              Continue
            </Button>
          </View>
          <View
            style={{
              flex: 3,
              alignItems: 'center',
              minWidth: 650,
              paddingHorizontal: 16,
            }}
          >
            <DataTable>
              <DataTable.Header style={{ backgroundColor: '#5E9BFF' }}>
                <DataTable.Title>
                  <Text style={{ color: 'white' }}>HEADER FROM FILE</Text>
                </DataTable.Title>
                <DataTable.Title>
                  <Text style={{ color: 'white' }}> PREVIEW INFO</Text>
                </DataTable.Title>
                <DataTable.Title>
                  <Text style={{ color: 'white' }}>MAP TO</Text>
                </DataTable.Title>
                <DataTable.Title numeric style={{ maxWidth: 60 }}>
                  <Text style={{ color: 'white', marginRight: 4 }}>MAP</Text>
                </DataTable.Title>
              </DataTable.Header>
              {mappingData
                && mappingData.map((row) => (
                  <DataTable.Row key={row.fieldName}>
                    <DataTable.Cell>{row.fieldName}</DataTable.Cell>
                    <DataTable.Cell>{row.preview}</DataTable.Cell>
                    <DataTable.Cell>
                      <LeadsMappingDropDown
                        row={row}
                        onSelect={handleMapping}
                        fields={availableLeadFields}
                      />
                    </DataTable.Cell>
                    <DataTable.Cell numeric style={{ maxWidth: 60 }}>
                      <Checkbox.Android
                        onPress={() => handleMapThisField(row)}
                        status={row.mapThisField ? 'checked' : 'unchecked'}
                      />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
            </DataTable>
          </View>
        </View>
      ) : (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: height * 0.75,
          }}
        >
          <Text style={{ fontSize: 32, fontFamily: 'VerbBold' }}>
            Your data is now being imported and will be available shortly.
          </Text>
        </View>
      )}
    </View>
  );
}
