import {
  View,
  StyleSheet,
  Dimensions,
  Platform,
  RefreshControl,
} from 'react-native';
import {
  Icon,
  Layout,
  Tab,
  TabView,
  Text,
  TopNavigation,
  TopNavigationAction,
} from '@ui-kitten/components';
//@ts-ignore
import { useRoute } from '../../navigation/router';
import {
  ActivityStatusColumn,
  ClientInformationColumn,
  LoansReferralsColumn,
  QuickActionFAB,
  WebHeader,
} from '../../components-global';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import { useEffect, useState } from 'react';

import { useGetContactQuery } from '../../services/ContactApiSlice';
//@ts-ignore
import { ScrollView } from 'react-native-gesture-handler';

type Props = {
  navigation?: any;
};

export function ContactDetailPage(props: Props) {
  const route = useRoute();
  const [contactId, setContactId] = useState(
    Platform.OS === 'web' ? route.getParam('id') : route.params.id
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    data: clientData,
    isFetching,
    refetch,
  } = useGetContactQuery(contactId);


  const BackIcon = (props) => <Icon {...props} fill="#fff" name="arrow-back" />;

  useEffect(() => {
    const newID = route.params.id;
    if (newID !== contactId) {
      setContactId(newID);
    }
  }, [route]);

  const renderBackAction = () => {
    const nav = props.navigation;
    return (
      <TopNavigationAction
        onPress={() => (nav.canGoBack() ? nav.goBack() : nav.navigate('MenuItems', {
          screen: 'Contacts',
          initial: false,
        }))}
        icon={BackIcon}
      />
    );
  };

  function onRefresh() {
    refetch();
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={{ flex: 1 }}>
          <WebHeader clientData={clientData} setModalVisible={() => null} createEmpowerLoan={() => null} />
          <View style={{ flex: 1, flexDirection: 'row' }}>
            {clientData ? (
              <>
                <ClientInformationColumn
                  clientData={clientData}
                  refetch={refetch}
                />
                <ActivityStatusColumn clientId={contactId} type="contact" />
                <LoansReferralsColumn clientData={clientData} />
              </>
            ) : (
              <ActivityIndicatorComponent />
            )}
          </View>
        </View>
      ) : (
        <Layout style={{ flex: 1 }}>
          <TopNavigation
            style={{
              backgroundColor: '#007aff',
              height: 56,
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 8,
              paddingRight: 8,
            }}
            alignment="center"
            title={() => (
              <Text style={{ color: '#fff' }} category="h5">
                {clientData?.name || ''}
              </Text>
            )}
            accessoryLeft={renderBackAction}
          />
          <TabView
            selectedIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
          >
            <Tab style={styles.tab} title="Contact Data">
              <Layout style={styles.tabContainer}>
                <ScrollView>
                  <RefreshControl
                    enabled
                    refreshing={isFetching}
                    onRefresh={onRefresh}
                  />
                  {clientData ? (
                    <ClientInformationColumn
                      clientData={clientData}
                      refetch={refetch}
                    />
                  ) : (
                    <ActivityIndicatorComponent />
                  )}
                </ScrollView>
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Activities">
              <Layout style={styles.tabContainer}>
                <ActivityStatusColumn type="contact" clientId={contactId} />
              </Layout>
            </Tab>
            <Tab style={styles.tab} title="Loans & Referrals">
              <Layout style={styles.tabContainer}>
                <View style={{ flex: 1 }}>
                  {clientData ? (
                    <LoansReferralsColumn clientData={clientData} />
                  ) : (
                    <ActivityIndicatorComponent />
                  )}
                </View>
              </Layout>
            </Tab>
          </TabView>
          {Boolean(clientData) ? (
            <QuickActionFAB
              defaultData={{
                contact: clientData['@id'],
                '@type': 'Contact',
                '@id': clientData['@id'],
                selectedAssociate: clientData
              }}
              onlyShow={[
                'createScenario',
                'createNewTask',
                'createNewNote',
                'createNewEvent',
                'logCall',
                'logMeeting',
              ]}
            />
          ) : null}
        </Layout>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  tabContainer: {
    minHieght: Dimensions.get('screen').height - 56,
    height: Dimensions.get('screen').height - 56,
    paddingBottom: 128,
  },
  tab: {
    height: 48,
  },
});
