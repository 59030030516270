import { Icon } from '@ui-kitten/components';
import {
  View,
  StyleSheet,
  Pressable,
} from 'react-native';

export default function SingleActionFab({ action, icon }: any) {
  return (
    <View style={styles.container}>
      <Pressable
        style={[styles.mainButton, styles.shadow]}
        onPress={() => action()}
      >
        <Icon style={{ width: 32, height: 32 }} fill="#fff" name={icon} />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    bottom: 24,
    right: 24,
  },
  hovered: {
    transform: [{ scale: 1.2 }],
  },
  mainButton: {
    width: 54,
    height: 54,
    borderRadius: 35,
    zIndex: 999,
    backgroundColor: '#00E096',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadow: {
    shadowColor: 'black',
    shadowOffset: { height: 1, width: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 6,
    elevation: 4,
  },
});
