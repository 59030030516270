import { parse } from 'query-string';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useCallback } from 'react';

const buildUrl = (pathname, routeParams, queryParams) => {
  const base = window.location.href;
  const newPath = generatePath(pathname, routeParams);

  const url = new URL(newPath, base);

  const searchParams = new URLSearchParams(queryParams);
  url.search = searchParams.toString();
  const newUrl = url.toString();

  return newUrl.replace(url.origin, '');
};

const useNavigation = () => {
  const { push, replace: historyReplace, goBack } = useHistory();

  const navigate = useCallback(
    (route, routeParams = {}, queryParams = {}) => {
      const path = buildUrl(route.path, routeParams, queryParams);
      push(path);
    },
    [],
  );

  const replace = useCallback(
    (to, qsParams = {}) => {
      const path = buildUrl(to, qsParams);
      historyReplace(path);
    },
    [],
  );

  return {
    navigate,
    replace,
    goBack,
  };
};

const useRoute = () => {
  const { pathname, search } = useLocation();
  const routeParams = useParams();

  const queryParams = parse(search);
  const params = { ...queryParams, ...routeParams };

  const getParam = useCallback(
    (key) => params[key],
    [],
  );

  // need to implement?
  const setParams = useCallback(
    (params) => {

    }, [],
  );

  const setOptions = useCallback(
    (options) => {

    }, [],
  );

  return {
    path: pathname,
    params,
    getParam,
    setParams,
    setOptions,
  };
};

export { useNavigation, useRoute };
