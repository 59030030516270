import {
  CheckBox,
  Text,
  Icon,
  Button,
  Divider,
  Toggle,
  Input,
} from '@ui-kitten/components';
import React, { useMemo } from 'react';
import {
  View, ScrollView, Pressable, FlatList, TouchableWithoutFeedback,
} from 'react-native';
import produce from 'immer';
import DraggableFlatList from '../DraggableFlatlist';
import { Column, SavedView } from '../../interfaces/Table';
import {ObjectMetadataType} from "../../helpers/ObjectMetadata";

type Props = {
  close: Function;
  savedView: SavedView;
  setSavedView: (value: any) => void;
  columns: ObjectMetadataType;
  allViews: SavedView[];
  handleSetDefault: Function;
};

const sort = (a: string, b: string, columns: {}) => {
  const nameA = columns[a].label.toUpperCase(); // ignore upper and lowercase
  const nameB = columns[b].name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

function Item({ view, isDefault, setDefault }: any) {
  return (
    <View style={{ flexDirection: 'row', paddingVertical: 8 }}>
      <View style={{ flex: 1, paddingVertical: 4 }}>
        <Text>{view.name}</Text>
      </View>
      <View style={{ paddingLeft: 16 }}>
        <Toggle
          checked={isDefault.name === view.name}
          onChange={(nextChecked) => {
            if (nextChecked) {
              setDefault(view.name);
            }
          }}
        >
          {null}
        </Toggle>
      </View>
    </View>
  );
}

function TableSettings({
  close,
  savedView,
  setSavedView,
  columns,
  allViews,
  handleSetDefault,
}: Props) {
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>(
    savedView.columns,
  );
  const [searchValue, setSearchValue] = React.useState('');

  const handleSave = () => {
    setSavedView({ ...savedView, columns: visibleColumns });
    close();
  };

  const isDefault = useMemo(() => {
    const isDefault = allViews.find(
      (view: SavedView) => view.isDefault && view.name !== 'Default',
    );
    return isDefault || allViews[0];
  }, [allViews]);

  const renderIcon = (props): React.ReactElement => (
    <TouchableWithoutFeedback onPress={() => setSearchValue('')}>
      <Icon
        {...props}
        name="close-outline"
      />
    </TouchableWithoutFeedback>
  );

  return (
    <View
      style={{
        minWidth: 925,
        maxHeight: 725,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <View style={{ paddingTop: 16, paddingBottom: 24, position: 'relative' }}>
        <Text category="label" style={{ textAlign: 'center', fontSize: 18 }}>
          Table Settings
        </Text>
        <View style={{ position: 'absolute', right: 0, top: 8 }}>
          <Pressable onPress={() => close()}>
            <Icon
              style={{ width: 32, height: 32 }}
              fill="#8F9BB3"
              name="close-outline"
            />
          </Pressable>
        </View>
      </View>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View
          style={{
            flex: 1,
            borderWidth: 1,
            borderColor: '#eee',
            paddingTop: 16,
          }}
        >
          <Text
            category="label"
            style={{ textAlign: 'center', fontSize: 16, marginBottom: 16 }}
          >
            Select your visible columns.
          </Text>
          <View style={{ paddingHorizontal: 16 }}>
            <Input
              placeholder="Column Search..."
              value={searchValue}
              accessoryRight={searchValue.length ? renderIcon : null}
              onChangeText={(nextValue) => setSearchValue(nextValue)}
            />
          </View>
          <ScrollView style={{ minHeight: 520 }} contentContainerStyle={{ padding: 16 }}>
            {Object.keys(columns)
              ?.sort((a, b) => sort(a, b, columns)).map((field: string) => {
                if (searchValue && !columns[field].label.toLowerCase().includes(searchValue.toLowerCase())) {
                  return null;
                }
                return (
                  <View
                    key={field}
                    style={{ flexDirection: 'row', paddingVertical: 8 }}
                  >
                    <CheckBox
                      style={{ marginRight: 8 }}
                      checked={visibleColumns.includes(field)}
                      onChange={(checked) => {
                        if (checked) {
                          setVisibleColumns([...visibleColumns, field]);
                        } else {
                          setVisibleColumns(
                            visibleColumns.filter((column) => column !== field),
                          );
                        }
                      }}
                    />
                    <Text>{columns[field].label}</Text>
                  </View>
                );
              })}
          </ScrollView>
        </View>
        <View
          style={{
            flex: 1,
            paddingVertical: 16,
            borderWidth: 1,
            borderColor: '#eee',
            marginHorizontal: 16,
          }}
        >
          <Text category="label" style={{ textAlign: 'center', fontSize: 16 }}>
            Order your columns
          </Text>
          <Text
            category="label"
            style={{
              fontStyle: 'italic',
              textAlign: 'center',
              marginBottom: 16,
              color: '#aaa',
            }}
          >
            Click and hold to drag
          </Text>
          {savedView.columns && (
            <DraggableFlatList
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              allColumns={columns}
            />
          )}
        </View>
        {/* Views */}
        <View
          style={{
            flex: 1,
            borderWidth: 1,
            borderColor: '#eee',
            padding: 16,
            marginRight: 8,
          }}
        >
          <Text
            category="label"
            style={{ textAlign: 'left', fontSize: 16, marginBottom: 16 }}
          >
            Views(
            {allViews.length}
            )
          </Text>
          <ScrollView>
            <View style={{ flexDirection: 'row', paddingVertical: 8 }}>
              <View style={{ flex: 2 }}>
                <Text style={{ fontFamily: 'VerbBold' }}>Name</Text>
              </View>
              <View>
                <Text style={{ fontFamily: 'VerbBold', paddingLeft: 4 }}>
                  Default
                </Text>
              </View>
            </View>
            {isDefault && allViews ? (
              <FlatList
                data={allViews}
                renderItem={({ item }) => (
                  <Item
                    view={item}
                    isDefault={isDefault}
                    allViews={allViews}
                    setDefault={(viewName: string) => handleSetDefault(viewName)}
                  />
                )}
                keyExtractor={(item) => item.name}
              />
            ) : null}
          </ScrollView>
        </View>
      </View>
      <View style={{ marginTop: 5, alignItems: 'flex-end' }}>
        <Divider />
        <Button onPress={handleSave}>Save</Button>
      </View>
    </View>
  );
}

export default TableSettings;
