import React from 'react';
import { Platform, View } from 'react-native';
import {
  Button,
  Card,
  Divider,
  List,
  ListItem,
  Text,
  useTheme,
} from '@ui-kitten/components';
import { useDispatch } from 'react-redux';
import { useGetTasksQuery } from '../../services/TaskApiSlice';
import { Link } from '../../components/Navigation/Link';
import routes from '../../navigation/routes';
// @ts-ignore
import { useNavigation } from '../../navigation/router';
import { showModal as realShow } from '../../services/FormSlice';

const colorMappingPill = {
  High: 'rgb(255, 113, 91)',
  Medium: '#d1c641',
  Low: 'rgb(46, 134, 193)',
};
const colorMappingBackground = {
  High: '#ff000026',
  Medium: '#ffef833b',
  Low: '#007aff29',
};

function TaskListItem({ task }) {
  const [hovered, setHovered] = React.useState(false);
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { navigate } = useNavigation();
  const theme = useTheme();
  const linkType = task.loan || task.contact || task.lead;
  let peices;
  let id;
  let type;
  if (linkType) {
    peices = linkType.split('/');
    id = peices[peices.length - 1];
    type = peices[peices.length - 2].slice(0, -1).charAt(0).toUpperCase()
      + peices[peices.length - 2].slice(0, -1).slice(1);
  }

  const renderItemAccessoryRight = (taskObj) => (
    <Button
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        showModal({ formType: 'completeTask', data: taskObj });
      }}
      status="danger"
      size="tiny"
    >
      Complete
    </Button>
  );
  const renderItemAccessoryLeft = (taskObj) => {
    if (!taskObj.priority) {
      return null;
    }
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 18,
          width: 18,
          height: 18,
          paddingTop: 2,
          backgroundColor: colorMappingPill[taskObj.priority],
          marginTop: -8,
        }}
      >
        <Text
          category="label"
          style={{ fontSize: 12, color: '#fff', fontFamily: 'VerbBold' }}
        >
          {taskObj.priority[0]}
        </Text>
      </View>
    );
  };

  return (
    <ListItem
      onPress={() => {
        if (id && type) {
          navigate(routes.ExtraRoutes[type], { id });
        }
      }}
      title={(props: any) => (
        <View style={{ flexDirection: 'row' }}>
          <Text {...props}>
            {task.subject}
            {' '}
            -
            {' '}
          </Text>
          <Text
            category="label"
            style={{
              fontSize: 13,
              color: 'blue',
              textDecorationLine: 'underline',
            }}
          >
            {task.contactName
              || task.associatedName
              || task.contact
              || task.lead}
          </Text>
        </View>
      )}
      key={task.id}
      accessoryRight={() => renderItemAccessoryRight(task)}
      accessoryLeft={() => renderItemAccessoryLeft(task)}
      description={(props) => {
        const style: any = props.style[0] || {};
        return (
          <Text style={{ ...style, color: '#484848' }} numberOfLines={1}>
            {task.description}
            {' '}
          </Text>
        );
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        backgroundColor: hovered
          ? theme['background-basic-color-2']
          : theme['background-basic-color-1'],
        // backgroundColor: hovered ? '#f5f5f5' : colorMappingBackground[task.priority],
        width: '100%',
      }}
    />
  );
}

export default function TodaysTasks() {
  const { data, error, isLoading } = useGetTasksQuery(
    'today=dueDate&isCompleted=false',
  );

  if (error) {
    return (
      <Card
        style={{
          minWidth: 350,
          padding: 16,
          flexGrow: 1,
          marginVertical: Platform.OS === 'web' ? 0 : 4,
        }}
      >
        <Text>Something went wrong!</Text>
      </Card>
    );
  }

  return (
    <Card
      style={{
        minWidth: 350,
        padding: 16,
        marginVertical: Platform.OS === 'web' ? 0 : 4,
        // flexGrow: 1,
        // flexBasis: 0,
        // flexShrink: 0,
        flex: 1,
      }}
      header={(props) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            // padding: Platform.OS === 'web' ? 0 : 8,
            marginBottom: 10,
          }}
        >
          <Text category="label" style={{ fontSize: 18 }}>
            Todays Tasks
          </Text>
          <Link route={routes.ExtraRoutes.Tasks}>
            <Text
              style={{ fontSize: 14, fontStyle: 'italic', color: '#656565' }}
              category="h6"
            >
              View All
            </Text>
          </Link>
        </View>
      )}
    >
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <List
          ItemSeparatorComponent={Divider}
          data={data['hydra:member'].slice(0, 5)}
          renderItem={({ item }) => <TaskListItem task={item} />}
        />
      )}
    </Card>
  );
}
