import { StyleSheet, View } from 'react-native';
import { IndexPath, Layout, Select, SelectItem } from '@ui-kitten/components';
import React, { useEffect } from 'react';

export default function SimpleKittenSelect({
  options,
  defaultValue,
  onChange,
  size = 'medium',
  label = '',
}) {
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const displayValue = selectedIndex?.row
    ? options[selectedIndex.row - 1].name
    : ' ';

  useEffect(() => {
    if (defaultValue) {
      const index = options.findIndex(
        (option) => option.value === defaultValue || option.name === defaultValue,
      );
      setSelectedIndex(new IndexPath(index + 1));
    }
  }, [defaultValue]);

  const renderOption = ({ name, value }) => {
    return <SelectItem key={value} title={name} />;
  };

  return (
    <View style={styles.container}>
      <Select
        size={size}
        label={label}
        style={styles.select}
        placeholder="Default"
        value={displayValue}
        selectedIndex={selectedIndex}
        onSelect={(index: any) => {
          if (index.row === 0) {
            setSelectedIndex(new IndexPath(0));
            onChange({ row: index.row, value: null });
            return;
          }
          setSelectedIndex(index);
          onChange({ row: index.row - 1, value: options[index.row - 1].value });
        }}
      >
        <SelectItem title={' '} />
        {options.map(renderOption)}
      </Select>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1
  },
  select: {
    flex: 1,
    margin: 2,
  },
});
