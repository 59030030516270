import React, { useEffect } from 'react';
import {
  Platform, ScrollView, View, StyleSheet,
} from 'react-native';
import {
  Button, Layout, Spinner, Text,
} from '@ui-kitten/components';
import produce from 'immer';
import ActivityIndicatorComponent from '../../../components/Universal/ActivityIndicatorComponent';
import TimeBlockDay from './TimeBlockDay';
import {
  useGetMeQuery,
  useUpdateTimeBlocksMutation,
} from '../../services/UserSlice';

interface TimeBlock {
  id: number;
  timeFrom: string;
  timeTo: string;
  activityType: string;
  dayOfWeek: string;
}

function LoadingIndicator({ style }) {
  return (
    <View style={[style, styles.indicator]}>
      <Spinner size="small" />
    </View>
  );
}

export default function TimeBlockSection() {
  const { data: user, isLoading } = useGetMeQuery({});
  const [updateTimeBlocks, { isLoading: isUpdatingTimeBlocks }] = useUpdateTimeBlocksMutation();

  const [timeBlocks, setTimeBlocks] = React.useState([]);

  useEffect(() => {
    if (!isLoading) {
      setTimeBlocks(user.timeBlocks);
    }
  }, [isLoading, user]);
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
  ];

  const changeHandler = (timeBlock: TimeBlock) => {

    const newTimeBlocks = [...timeBlocks];
    const index = newTimeBlocks.findIndex(
      (tb) => tb.dayOfWeek === timeBlock.dayOfWeek,
    );
    if (index === -1) {
      newTimeBlocks.push(timeBlock);
    } else {
      newTimeBlocks[index] = timeBlock;
    }

    setTimeBlocks(newTimeBlocks);
  };

  const saveHandler = () => {
    const toUpdate = produce(timeBlocks, (draft) => {
      draft.forEach((tb) => {
        if (
          tb.timeFrom === null
          || tb.timeTo === null
          || tb.activityType === null
        ) {
          draft.splice(timeBlocks.indexOf(tb), 1);
        }
      });
    });

    updateTimeBlocks({ id: user.id, timeBlocks: toUpdate });
  };

  const renderDay = (dayName: PropertyKey) => {
    let timeBlock;
    if (timeBlocks) {
      timeBlock = timeBlocks.find(
        (timeBlockDb) => timeBlockDb.dayOfWeek === dayName,
      );
    }

    if (timeBlock === undefined) {
      timeBlock = {
        dayOfWeek: dayName,
        activityType: '',
        timeFrom: '',
        timeTo: '',
      };
    }

    return (
      <TimeBlockDay
        timeBlock={timeBlock}
        key={timeBlock.dayOfWeek}
        changeHandler={changeHandler}
      />
    );
  };

  return (
    <Layout style={{ flex: 1 }}>
      <Text
        category="label"
        style={{ paddingLeft: 16, fontSize: 18 }}
      >
        Time Blocks
      </Text>
      {isLoading ? (
        <ActivityIndicatorComponent />
      ) : (
        <ScrollView>
          <View
            style={{
              flexDirection: Platform.OS === 'web' ? 'row' : 'column',
              flexWrap: 'wrap',
              flex: 1,
            }}
          >
            {days.map((dayName) => renderDay(dayName))}
          </View>
          <View
            style={{
              alignSelf: 'center',
              marginTop: 12,
              shadowOffset: { width: 3, height: 3 },
              shadowOpacity: 0.2,
              borderRadius: 4,
              shadowRadius: 4,
              paddingBottom: Platform.OS === 'web' ? 0 : 24,
            }}
          >
            <Button
              onPress={saveHandler}
              accessoryLeft={isUpdatingTimeBlocks ? LoadingIndicator : null}
            >
              Save
            </Button>
          </View>
        </ScrollView>
      )}
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: {
    margin: 2,
  },
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
