import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Button, Layout, Text } from '@ui-kitten/components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import {
  ActivityIndicator,
  View,
  StyleSheet,
  Dimensions,
  Platform,
} from 'react-native';
import { useGetCreditDocumentQuery } from '../../../services/CreditRequestApiSlice';
import PdfReader from '../../PdfReader';
import Footer from '../Footer';
import Header from '../Header';

type Props = {
  cancelAction: () => void;
  defaultData: any;
};

export default function PreviewPdfForm({ defaultData, cancelAction }: Props) {
  const { loanId, creditDocumentId } = defaultData;
  const [error, setError] = React.useState<null | string>(null);
  const [previewBase64, setPreviewBase64] = React.useState('');

  const {
    data: previewPDFData,
    isFetching: isFetchingCreditDocument,
    isError,
    error: errorData,
  } = useGetCreditDocumentQuery({loanId, reqId: creditDocumentId });

  useEffect(() => {
    if (previewPDFData) {
      if (Platform.OS === 'web') {
        const url = window.URL || window.webkitURL;
        const blobPDF = url.createObjectURL(previewPDFData);
        setPreviewBase64(blobPDF);
      } else {
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(previewPDFData);
        fileReaderInstance.onload = async () => {
          //@ts-ignore
          setPreviewBase64(fileReaderInstance.result);
        };
      }
    }
    return () => {
      setPreviewBase64('');
    };
  }, [previewPDFData]);

  if (isError) {
    return (
      <View>
        <Text
          category="label"
          style={{
            marginBottom: 16,
            fontSize: 16,
            textAlign: 'center',
            color: 'red',
          }}
        >
          Something went wrong fetching the document.
        </Text>
        <Button onPress={() => cancelAction()}>Close</Button>
      </View>
    );
  }

  if (!previewPDFData || isFetchingCreditDocument)
    return (
      <View>
        <Text
          category="label"
          style={{ marginBottom: 16, fontSize: 16, textAlign: 'center' }}
        >
          Loading...
        </Text>
        <ActivityIndicator />
      </View>
    );

  return (
    <Layout>
      {previewBase64 ? (
        <View>
          <Header heading={'Credit Report'}></Header>
          {Platform.OS === 'web' ? (
            <View
              style={{
                flex: 1,
                width: Dimensions.get('window').width - 100,
                maxWidth: Dimensions.get('window').width - 100,
                position: 'relative',
              }}
            >
              <iframe
                src={previewBase64}
                style={{
                  width: '100%',
                  minHeight: Dimensions.get('screen').height - 300,
                }}
              ></iframe>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                minHeight: Dimensions.get('screen').height - 280,
                marginHorizontal: -24,
              }}
            >
              <PdfReader
                style={{ width: '100%', height: '100%' }}
                source={{ base64: previewBase64 }}
                onError={(err) => console.log(err)}
              />
            </View>
          )}
          <View>
            <Footer submitText="CLOSE" submitAction={cancelAction} hideCancel />
          </View>
        </View>
      ) : null}
    </Layout>
  );
}
