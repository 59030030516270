import axios from 'axios';
import { isString, get } from 'lodash';
import { navigate } from './NavigationService';
import {AuthManager} from '../../src/auth/AuthManager';
import { EXPO_PUBLIC_API_URL } from '@env';
class HttpService {
  constructor(options = {}) {
    if (!options.baseURL) {
      options.baseURL = EXPO_PUBLIC_API_URL;
    }
    if (!options.headers) {
      // console.log('setting new headers')
      options.headers = {
        'Content-Type': 'application/json'
      }
    }
    this.client = axios.create(options);
    this.client.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
    this.unauthorizedCallback = () => {};
    this.expiredCallback = () => {};
    this.setupAuthorizationHeader();
  }

  attachHeaders(headers) {
    Object.assign(this.client.defaults.headers, headers);
  }

  removeHeaders(headerKeys) {
    headerKeys.forEach((key) => delete this.client.defaults.headers[key]);
  }

  handleSuccessResponse(response) {
    return response;
  }

  handleErrorResponse = async (error) => {
    const { response, request } = error;
    const { status } = response || {};
    error.message = this.humanifyError(error);

    switch (status) {
      case 401:
        navigate('Error', {
          code: '401',
          message:
            'Please login to access this content, if you require login information please contact IT.',
        });
        break;
      case 403:
        navigate('Error', {
          code: '403',
          message:
            'Access denied if you think you should be able to access this content please contact IT.',
        });
        break;
      case 404:
        navigate('Error', {
          code: '404',
          message:
            'Page not found, if you think this is a mistake please contact IT.',
        });
        break;
      case 406:
        navigate('Error', {
          code: '406',
          message: 'User does not exist please contact IT',
        });
        break;
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
        navigate('Error', {
          code: status,
          message:
            'Server error please wait a moment and refresh the page, if the issue persists please contact IT.',
        });
        break;
      default:
        if (
          request.responseURL.includes('/encompass/loanPipeline/loansInUse')
        ) {
          toast.show('Encompass Error', {
            type: 'danger',
          });
          return false;
        }
        navigate('Error', { message: 'Please Contact IT' });
        break;
    }

    return Promise.reject(error);
  };
  humanifyError(err) {
    let { message, response } = err;
    let resDataMsg = get(response, 'data.0.message');
    if (resDataMsg && isString(resDataMsg)) return resDataMsg;
    if (message && isString(message)) return message;
    return 'Error occurred on Api call';
  }

  setUnauthorizedCallback(callback) {
    this.unauthorizedCallback = callback;
  }
  setExpiredCallback(callback) {
    this.expiredCallback = callback;
  }

  async setupAuthorizationHeader() {

    this.client.interceptors.request.use(async (config) => {
      const manager = new AuthManager();
      const { idToken } = await manager.getAccessTokenAsync();
      config.headers['x-authorization'] = `${idToken}`;
      return config;
    });
  }
}

export default HttpService;
