import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Platform,
  Keyboard,
  ScrollView,
  SafeAreaView,
  View,
} from 'react-native';
import { Card, IndexPath, Input, Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess, KittenSelect } from '../../../components-ui';
import {
  useCreateLoanMutation,
  useEditLoanMutation,
} from '../../../services/LoanApiSlice';
import {
  ADVANCEDOPTIONS,
  LOANOCCUPANCY,
  LOANTYPE,
  LOANPURPOSE,
  AMORTIAZATIONTYPE,
} from '../../../helpers/constants';
import MaskedInput from '../../../components-ui/MaskedInput';
//@ts-ignore
import GlobalSearchAutoComplete from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
//@ts-ignore
import { dataTransformer } from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useCreateBorrowerMutation } from '../../../services/BorrowerApiSlice';
import { useCreateBorrowerPairMutation } from '../../../services/BorrowerPairsApiSlice';

var numbers = new RegExp('^[0-9]+$');

function CreateScenarioForm({ cancelAction, defaultData }: any) {
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    propertyCity:
      defaultData?.loanData?.propertyCity || defaultData?.propertyCity || '',
    propertyState:
      defaultData?.loanData?.propertyState || defaultData?.propertyState || '',
    propertyZip:
      defaultData?.loanData?.propertyZip || defaultData?.propertyZip || '',
    loanPurpose: defaultData?.loanData?.loanPurpose || '',
    loanType: defaultData?.loanData?.loanType || '',
    occupancy: defaultData?.loanData?.occupancy || '',
    stage: 'Scenario',
    status: 'New',
    phase: 'Scenario',
    advances: defaultData?.loanData?.advances || [],
    creditScore: defaultData?.loanData?.creditScore || null,
    loanAmount: defaultData?.loanData?.loanAmount || null,
    loanToValue: defaultData?.loanData?.loanToValue || 0,
    purchasePrice: defaultData?.loanData?.purchasePrice || null,
    estimatedHomeValue: defaultData?.loanData?.estimatedHomeValue || null,
    amortizationType: defaultData?.loanData?.amortizationType || '',
    additionalInfo: defaultData?.loanData?.additionalInfo || '',
    [defaultData.type.toLowerCase()]: defaultData?.uri || null,
    loanContact: {
      firstName: defaultData?.firstName || '',
      lastName: defaultData?.lastName || '',
      email: defaultData?.email || '',
      mobilePhone: defaultData?.mobilePhone || '',
      streetAddress: defaultData?.streetAddress || '',
      city: defaultData?.loanData?.propertyCity || defaultData?.city || '',
      state:
        defaultData?.loanData?.propertyZip ||
        defaultData?.loanData?.propertyState ||
        defaultData?.state ||
        '',
      zipCode: defaultData?.zipCode || '',
    },
  });

  // Auto Complete
  const [
    createLoan,
    { isSuccess: addSuccess, data: addData, error: addError },
  ] = useCreateLoanMutation();

  const [
    patchLoan,
    { isSuccess: patchSuccess, data: patchData, error: patchError },
  ] = useEditLoanMutation();

  const [createBorrower] = useCreateBorrowerMutation();
  const [createBorrowerPair] = useCreateBorrowerPairMutation();
  const [error, setError] = useState();
  const [statusMessage, setStatusMessage] = useState<string | null>();

  async function handleAddSuccess(addData) {
    try {

      //split formData.streetAddress into houseNumber and street
      let houseNumber = null
      let street = null
      if(selectedAssociate?.address?.streetAddress){
        const splitAddress = selectedAssociate.address.streetAddress.split(' ')
        houseNumber = splitAddress[0]
        street = splitAddress.slice(1).join(' ')

      }
      const borrowerData = {
        firstName: formData.loanContact.firstName,
        lastName: formData.loanContact.lastName,
        email: formData.loanContact.email,
        homePhone: selectedAssociate?.phone || null,
        borrowerAddresses: [
          {
            city: selectedAssociate?.address?.city || null,
            state: selectedAssociate?.address?.state || null,
            zipCode: selectedAssociate?.address?.zipCode || null,
            houseNumber,
            street
          },
        ],
      };
      setStatusMessage('Creating New Borrower...');
      const borrowerCreated = await createBorrower({
        loanId: addData.id,
        borrower: borrowerData,
      }).unwrap();
      setStatusMessage('Creating New Borrower Pair...');
      await createBorrowerPair({
        loanId: addData.id,
        borrowerPair: { borrower: borrowerCreated['@id'] },
      }).unwrap();
      if (defaultData?.callback) {
        defaultData.callback(addData);
      }
      setStatusMessage('Success!  Redirecting to scenario...');
      cancelAction(addData);
    } catch (e: any) {
      setError(e?.message || e);
    }
  }

  useEffect(() => {
    if (addSuccess && addData) {
      handleAddSuccess(addData);
    }
  }, [addSuccess, addData]);

  useEffect(() => {
    if (patchSuccess && patchData) {
      if (defaultData?.callback) {
        defaultData.callback(patchData);
      }
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  }, [patchSuccess, patchData]);

  useEffect(() => {
    if (addError) {
      //@ts-ignore
      setError(addError?.data['hydra:description']);
    }
    if (patchError) {
      //@ts-ignore
      setError(patchError?.data['hydra:description']);
    }
    setFormSubmitted(false);
  }, [addError, patchError]);

  useEffect(() => {
    //calculate LTV Should Calculate % Loan Amount/ Lesser of Assessed Value or Sales Price
    if (
      formData?.loanAmount &&
      (formData?.estimatedHomeValue || formData?.purchasePrice)
    ) {
      const minValue =
        formData?.estimatedHomeValue && formData?.purchasePrice
          ? Math.min(
              parseInt(formData.estimatedHomeValue),
              parseInt(formData.purchasePrice)
            )
          : formData?.estimatedHomeValue || formData?.purchasePrice;

      const ltv = (parseFloat(formData.loanAmount) / minValue) * 100;

      setFormData((prevData) => ({
        ...prevData,
        loanToValue: ltv,
      }));
    }
  }, [
    formData.estimatedHomeValue,
    formData.purchasePrice,
    formData.loanAmount,
  ]);

  async function handleSubmit(): Promise<void> {
    try {
      setFormSubmitted(true);
      setError(null);
      setStatusMessage('Creating New Loan...');
      checkForRequiredFields();
      if (defaultData?.loanData) {
        const loanId = defaultData.loanData.id;

        await patchLoan({ id: loanId, formData });
      } else {
        createLoan(formData);
      }
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
      setStatusMessage(null);
    }
  }

  function checkForRequiredFields() {
    if (formData?.propertyCity === '') throw new Error('City is required');
    if (formData?.propertyState === '') throw new Error('State is required');
    if (formData?.propertyZip === '') throw new Error('Zipcode is required');
    if (!formData?.['lead'] && !formData?.['contact'])
      throw new Error('Contact or Lead Required');
    if (formData?.loanPurpose.trim() === '')
      throw new Error('Loan Purpose is required');
    if (formData?.occupancy.trim() === '')
      throw new Error('Occupancy is required');
  }

  return (
    <Layout style={{ flex: 1, position: 'relative', width: '100%' }}>
      <Header heading={'Create A Scenario'} />
      <ScenarioForm
        formData={formData}
        setFormData={setFormData}
        defaultData={defaultData}
        selectedAssociate={selectedAssociate}
        setSelectedAssociate={setSelectedAssociate}
      />
      {statusMessage ? (
        <Text category="label" style={{ textAlign: 'center', color: 'blue' }}>
          {statusMessage}
        </Text>
      ) : null}
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {!!addSuccess || (!!patchSuccess && <FormSuccess />)}
      <Footer
        cancelAction={() => cancelAction()}
        cancelText={'CANCEL'}
        submitAction={() => handleSubmit()}
        disabled={formSubmitted}
        submitText={'CREATE'}
      />
    </Layout>
  );
}

export const ScenarioForm = ({
  formData,
  setFormData,
  defaultData,
  selectedAssociate,
  setSelectedAssociate,
}) => {
  const [loanPurposeIndex, setLoanPurposeIndex] = React.useState(
    new IndexPath(0)
  );
  const [loanTypeIndex, setLoanTypeIndex] = React.useState(new IndexPath(0));
  const [loanOccupancyIndex, setLoanOccupancyIndex] = React.useState(
    new IndexPath(0)
  );

  const [amortizationTypeIndex, setAmortizationTypeIndex] = React.useState(
    new IndexPath(0)
  );
  const [advancedIndex, setAdvancedIndex] = React.useState(new IndexPath(0));

  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const { data: contactsData, isFetching } = useGetContactsQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (!isFetching && !isFetchingLeads) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching, isFetchingLeads]);

  useEffect(() => {
    if (defaultData?.selectedAssociate) {
      const selectedCopy = JSON.parse(
        JSON.stringify(defaultData?.selectedAssociate)
      );

      selectedCopy.title = selectedCopy.name;
      setSelectedAssociate(selectedCopy);
      setFormData((prevData) => ({
        ...prevData,
        [`${selectedCopy['@type'].toLowerCase()}`]: selectedCopy['@id'],
        loanContact: {
          firstName: selectedCopy.firstName,
          lastName: selectedCopy.lastName,
          email: selectedCopy.email,
          mobilePhone: selectedCopy.mobilePhone || '',
        },
      }));
    }
    if (defaultData?.loanData) {
      if (defaultData.loanData.loanPurpose) {
        const loanPurpose = LOANPURPOSE.findIndex(
          (item) => item === defaultData.loanData.loanPurpose
        );
        setLoanPurposeIndex(new IndexPath(loanPurpose));
      }
      if (defaultData.loanData.loanType) {
        const loanType = LOANTYPE.findIndex(
          (item) => item === defaultData.loanData.loanType
        );
        setLoanTypeIndex(new IndexPath(loanType));
      }
      if (defaultData.loanData.occupancy) {
        const loanOccupancy = LOANOCCUPANCY.findIndex(
          (item) => item === defaultData.loanData.occupancy
        );
        setLoanOccupancyIndex(new IndexPath(loanOccupancy));
      }
      if (defaultData.loanData.advances.length) {
        const advanced = [
          ' ',
          'scenario_info',
          'soft_pull',
          'income_docs',
          'options',
          'credit',
        ].findIndex(
          (item) => item === defaultData.loanData.advances[0].advanceType
        );
        setAdvancedIndex(new IndexPath(advanced));
      }
      if (defaultData.loanData.amortizationType) {
        const amortizationType = AMORTIAZATIONTYPE.findIndex(
          (item) => item === defaultData.loanData.amortizationType
        );
        setAmortizationTypeIndex(new IndexPath(amortizationType));
      }
    }
  }, [defaultData]);

  useEffect(() => {
    const advancedSelected = ADVANCEDOPTIONS[advancedIndex.row];
    let advanceType = null;
    switch (advancedSelected) {
      case 'Scenario Info':
        advanceType = 'scenario_info';
        break;
      case 'Soft Pull':
        advanceType = 'soft_pull';
        break;
      case 'Income Docs':
        advanceType = 'income_docs';
        break;
      case 'Option or Meeting (Face to Face)':
        advanceType = 'options';
        break;
      case 'Credit Pull':
        advanceType = 'credit';
        break;
      default:
        advanceType = null;
        break;
    }
    setFormData((prevData) => ({
      ...prevData,
      advances: advanceType ? [{ advanceType: advanceType }] : [],
    }));
  }, [advancedIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      loanPurpose: LOANPURPOSE[loanPurposeIndex.row],
    }));
  }, [loanPurposeIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      occupancy: LOANOCCUPANCY[loanOccupancyIndex.row],
    }));
  }, [loanOccupancyIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      loanType: LOANTYPE[loanTypeIndex.row],
    }));
  }, [loanTypeIndex]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      amortizationType: AMORTIAZATIONTYPE[amortizationTypeIndex.row],
    }));
  }, [amortizationTypeIndex]);

  return (
    <Layout style={{ paddingBottom: 0, flex: 1 }}>
      {/* Address */}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2 }}>
          <FormGroup label="City" required>
            <Input
              placeholder="City"
              value={formData['propertyCity']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['propertyCity']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="State" required>
            <Input
              maxLength={2}
              placeholder="State"
              value={formData['propertyState']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['propertyState']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="ZIP Code" required>
            <Input
              maxLength={5}
              keyboardType="numeric"
              placeholder="ZIP Code"
              value={formData['propertyZip']}
              onChangeText={(nextValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  ['propertyZip']: nextValue,
                }));
              }}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Loan Type" required={false}>
            <KittenSelect
              selectedIndex={loanTypeIndex}
              setSelectedIndex={setLoanTypeIndex}
              options={LOANTYPE}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Loan Purpose" required>
            <KittenSelect
              selectedIndex={loanPurposeIndex}
              setSelectedIndex={setLoanPurposeIndex}
              options={LOANPURPOSE}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Occupancy" required>
            <KittenSelect
              selectedIndex={loanOccupancyIndex}
              setSelectedIndex={setLoanOccupancyIndex}
              options={LOANOCCUPANCY}
            />
          </FormGroup>
        </View>

        <View style={{ flex: 1 }}>
          <FormGroup label="Advanced?" required={false}>
            <KittenSelect
              selectedIndex={advancedIndex}
              setSelectedIndex={setAdvancedIndex}
              options={ADVANCEDOPTIONS}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Fico" required={false}>
            <Input
              maxLength={3}
              keyboardType="numeric"
              placeholder="Fico"
              value={formData['creditScore']}
              onChangeText={(nextValue) => {
                if (numbers.test(nextValue)) {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['creditScore']: parseInt(nextValue),
                  }));
                }
              }}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Sales Price(When Purchase)" required={false}>
            <MaskedInput
              keyboardType="numeric"
              disabled={formData?.loanPurpose !== 'Purchase' ? true : false}
              placeholder="Sales Price"
              value={formData['purchasePrice']}
              onChangeText={(nextValue) => {
                if (numbers.test(nextValue)) {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['purchasePrice']: parseFloat(nextValue),
                  }));
                }
              }}
              mask={'currency'}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Assessed Value" required={false}>
            <MaskedInput
              keyboardType="numeric"
              placeholder="Assessed Value"
              value={formData['estimatedHomeValue']}
              onChangeText={(nextValue) => {
                if (numbers.test(nextValue)) {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['estimatedHomeValue']: parseFloat(nextValue),
                  }));
                }
              }}
              mask={'currency'}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Loan Amount" required={false}>
            <MaskedInput
              keyboardType="numeric"
              placeholder="Loan Amount"
              value={formData['loanAmount']}
              onChangeText={(nextValue) => {
                if (numbers.test(nextValue)) {
                  setFormData((prevData) => ({
                    ...prevData,
                    ['loanAmount']: parseFloat(nextValue),
                  }));
                }
              }}
              mask={'currency'}
            />
          </FormGroup>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="LTV" required={false}>
            <MaskedInput
              placeholder="LTV"
              value={formData['loanToValue'] || 0}
              mask={'fullPercentage'}
              disabled={true}
              onChangeText={() => null}
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Amortization Type" required={false}>
            <KittenSelect
              selectedIndex={amortizationTypeIndex}
              setSelectedIndex={setAmortizationTypeIndex}
              options={AMORTIAZATIONTYPE}
            />
          </FormGroup>
        </View>
      </View>
      <FormGroup label="Additional Info" required={false}>
        <Input
          multiline={true}
          textStyle={{ minHeight: Platform.OS === 'web' ? 96 : 56 }}
          placeholder="Additional Info"
          value={formData['additionalInfo']}
          onChangeText={(nextValue) => {
            setFormData((prevData) => ({
              ...prevData,
              ['additionalInfo']: nextValue,
            }));
          }}
        />
      </FormGroup>
      {defaultData?.create ? (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 2 }}>
            <FormGroup label="Contact/Lead" required>
              <GlobalSearchAutoComplete
                clearAutoCompleteData={() => setAutoCompleteData([])}
                autoCompleteData={autoCompleteData}
                isFetching={isFetching}
                setQuery={setQuery}
                hideLead={false}
                handleOnChange={(e) => {
                  if(!e) return
                  const firstName = e?.firstName || '';
                  const lastName = e?.lastName || '';
                  const email = e?.email || '';
                  const iri = e?.['@id'] || null;
                  const type = e?.type === 'Contact' ? 'contact' : 'lead';
                  setSelectedAssociate(e);
                  console.log(e)
                  setFormData((prevData) => ({
                    ...prevData,
                    [`${type}`]: iri,
                    loanContact: {
                      firstName,
                      lastName,
                      email,
                      mobilePhone: e?.mobilePhone || '',
                    },
                  }));
                }}
                defaultValue={undefined}
                page={page}
                setPage={setPage}
                selectedAssociate={selectedAssociate}
              />
            </FormGroup>
          </View>
        </View>
      ) : null}
    </Layout>
  );
};

export default CreateScenarioForm;
