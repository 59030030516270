import { AuthManager } from '../auth/AuthManager';
//@ts-ignore
import { EXPO_PUBLIC_DOC_MANAGER_URL } from '@env';

export async function openFileManagerTab({loanData, type}: {loanData: any, type: string}) {
  console.log(loanData)
  const manager = new AuthManager();
  const { idToken } = await manager.getAccessTokenAsync();
  const targetWindow = window.open(EXPO_PUBLIC_DOC_MANAGER_URL, '_blank');

  let tokenInterval = setInterval(() => {
    targetWindow.postMessage({ idToken, folderId: loanData.id, type, name: loanData.loanName || '' }, '*');
  }, 1000);

  window.addEventListener('message', (event) => {
    if (event.data === 'success') {
      clearInterval(tokenInterval);
    }
  });
}
