import { Button, ButtonGroup, Card, Layout, Text } from '@ui-kitten/components';

import React from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import { closedLoanCommissionAmount } from '../helpers/commonStatFilters';
import { currencyFormat } from '../helpers/dataTransformerHelper';
import { returnPayPeriod } from '../helpers/returnPayPeriod';
import { useGetStatsQuery } from '../services/StatsSlice';

type Props = {
  selectedRange: string;
  setSelectedRange: Function;
  toggleCategory: Function;
};

const currentPayPeriodStats = closedLoanCommissionAmount(
  'current',
  returnPayPeriod('CURRENT').start,
  returnPayPeriod('CURRENT').end
);
const nextPayPeriodStats = closedLoanCommissionAmount(
  'upcoming',
  returnPayPeriod('UPCOMING').start,
  returnPayPeriod('UPCOMING').end
);
const YTDPayPeriodStats = closedLoanCommissionAmount(
  'ytd',
  returnPayPeriod('ALL').start,
  returnPayPeriod('ALL').end
);

function CommissionsToggleNav({
  selectedRange,
  setSelectedRange,
  toggleCategory,
}: Props) {
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
  //pull current, next, and year to date
  const { data: currentStats, isSuccess: currentStatsSuccess } =
    useGetStatsQuery({
      filter: currentPayPeriodStats,
    });
  const { data: upcomingStats, isSuccess: upcomingStatsSuccess } =
    useGetStatsQuery({
      filter: nextPayPeriodStats,
    });
  const { data: ytdStats, isSuccess: ytdStatsSuccess } = useGetStatsQuery({
    filter: YTDPayPeriodStats,
  });

  const statsArr = [
    { title: 'Current Pay Period', selector: 'CURRENT', data: currentStats },
    { title: 'Next Pay Period', selector: 'UPCOMING', data: upcomingStats },
    { title: 'YTD Pay Period', selector: 'ALL', data: ytdStats },
  ];

  return (
    <Layout
      level="1"
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingHorizontal: 6,
        paddingVertical: 8,
        width: '100%',
      }}
    >
      {isMobile ? (
        <>
          <Button
            style={isMobile ? styles.mobilePill : styles.desktopPill}
            size={isMobile ? 'tiny' : 'small'}
            status={selectedRange === 'CURRENT' ? 'basic' : 'info'}
            appearance={'outline'}
            onPress={() => {
              toggleCategory(0);
              setSelectedRange('CURRENT');
            }}
          >
            Current Pay Period
          </Button>
          <Button
            style={isMobile ? styles.mobilePill : styles.desktopPill}
            size={isMobile ? 'tiny' : 'small'}
            status={selectedRange === 'UPCOMING' ? 'basic' : 'info'}
            appearance={'outline'}
            onPress={() => {
              toggleCategory(1);
              setSelectedRange('UPCOMING');
            }}
          >
            Next Pay Period
          </Button>
          <Button
            style={isMobile ? styles.mobilePill : styles.desktopPill}
            size={isMobile ? 'tiny' : 'small'}
            status={selectedRange === 'ALL' ? 'basic' : 'info'}
            appearance={'outline'}
            onPress={() => {
              toggleCategory(2);
              setSelectedRange('ALL');
            }}
          >
            Year To Date
          </Button>
        </>
      ) : (
        <Layout
          level="1"
          style={{
            width: '100%',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 48,
          }}
        >
          {statsArr.map((stat, index) => {
            return (
              <Card
                onPress={() => {
                  toggleCategory(index);
                  setSelectedRange(stat.selector);
                }}
                style={{
                  flex: 1,
                  backgroundColor:
                    selectedRange === stat.selector
                      ? '#b1d8f014'
                      : 'transparent',
                }}
                status={selectedRange === stat.selector ? 'info' : 'basic'}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 12,
                    paddingBottom: 12,
                    borderBottomColor: '#eee',
                    borderBottomWidth: 2,
                    gap: 16,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 24,
                      fontFamily: 'VerbBold',
                    }}
                  >
                    {stat.title}
                  </Text>
                  <View
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Text
                      style={{
                        fontSize: 32,
                        color: 'gray',
                        fontFamily: 'VerbBold',
                      }}
                    >
                      {stat?.data?.count}
                    </Text>
                    <Text style={{ fontSize: 12, color: 'gray' }}>Loans</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 16,
                    paddingVertical: 8,
                  }}
                >
                  <Text status="basic">Loan Amount:</Text>
                  {stat.data ? (
                    <Text status="basic">
                      {stat?.data?.loanAmount
                        ? currencyFormat(stat?.data?.loanAmount)
                        : 0}
                    </Text>
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 16,
                  }}
                >
                  <Text status="basic">Commission Amount:</Text>
                  {stat.data ? (
                    <Text status="success">
                      {stat?.data?.loCommission
                        ? currencyFormat(stat?.data?.loCommission)
                        : 0}
                    </Text>
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              </Card>
            );
          })}
        </Layout>
        // <ButtonGroup appearance="outline" style={{ margin: 2 }}>
        //   <Button
        //     style={isMobile ? styles.mobilePill : styles.desktopPill}
        //     size={isMobile ? 'tiny' : 'small'}
        //     disabled={selectedRange === 'CURRENT'}
        //     onPress={() => setSelectedRange('CURRENT')}
        //   >
        //     Current Pay Period
        //   </Button>
        //   <Button
        //     style={isMobile ? styles.mobilePill : styles.desktopPill}
        //     size={isMobile ? 'tiny' : 'small'}
        //     disabled={selectedRange === 'UPCOMING'}
        //     onPress={() => setSelectedRange('UPCOMING')}
        //   >
        //     Next Pay Period
        //   </Button>
        //   <Button
        //     style={isMobile ? styles.mobilePill : styles.desktopPill}
        //     size={isMobile ? 'tiny' : 'small'}
        //     disabled={selectedRange === 'ALL'}
        //     onPress={() => setSelectedRange('ALL')}
        //   >
        //     Year To Date
        //   </Button>
        // </ButtonGroup>
      )}
    </Layout>
  );
}

const styles = StyleSheet.create({
  mobilePill: { borderRadius: 25, paddingTop: 8 },
  desktopPill: { borderRadius: 4, paddingTop: 8 },
});

export default CommissionsToggleNav;
