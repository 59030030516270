import React, { createRef, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Platform,
  Keyboard,
  Modal as NativeModal,
} from 'react-native';
import {
  Select,
  IndexPath,
  Input,
  Datepicker,
  NativeDateService,
  Text,
  CheckBox,
  Modal as KittenModal,
  Button,
  Layout,
} from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';
import { renderOption } from '../renderMethods';
import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';

import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import { useGetContactsQuery } from '../../../services/ContactApiSlice';
import { useGetLeadsQuery } from '../../../services/LeadApiSlice';
import { useGetLoansQuery } from '../../../services/LoanApiSlice';
import Colors from '../../../helpers/Colors';
import moment from 'moment';
import { useCreateTaskMutation } from '../../../services/TaskApiSlice';
import { OTHERTYPE, SALESTYPE, TYPES } from '../../../helpers/constants';
import RNTimePicker from '../../../components-ui/TimePicker/RNTimePicker';
import CreateScenarioForm from '../Loans/CreateScenarioForm';
//@ts-ignore
import ModalWrapper from '../../../components-ui/Modals/ModalWrapper';

function LogCallForm({ cancelAction, defaultData, showModal }: any) {
  const Modal = Platform.OS === 'web' ? KittenModal : NativeModal;
  const [error, setError] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [followUp, setFollowUp] = useState<boolean>(false);

  const [state, setState] = useState<any>({
    type: new IndexPath(0),
    outcome: new IndexPath(0),
    dueDate: new Date(),
    dueDateTime: moment().format('lll'),
    associateValue: '',
    description: '',
    notificationsEnabled: false,
    numOfReferrals: 0,
    advanced: false,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const displayValueTask = TYPES[state.type.row];
  const outcomes = displayValueTask === 'Sales Call' ? SALESTYPE : OTHERTYPE;
  const displayOutcomeValue = outcomes[state.outcome.row];
  const componentRef = createRef<Datepicker>();

  const [
    createTask,
    {
      isSuccess: createSuccess,
      data: createData,
      isError: createHasError,
      error: createError,
    },
  ] = useCreateTaskMutation();

  // GlobalSearch Data Handler
  const [selectedAssociate, setSelectedAssociate] = useState<any>({});
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const [isFetching, setIsFetching] = useState(false);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery(query);
  const { data: loansData, isFetching: isFetchingLoans } =
    useGetLoansQuery(query);
  const { data: leadsData, isFetching: isFetchingLeads } =
    useGetLeadsQuery(query);

  useEffect(() => {
    if (createError) {
      if (createHasError && createError) {
        //@ts-ignore
        if (createError?.data && createError.data['hydra:description']) {
          //@ts-ignore
          setError(createError?.data['hydra:description']);
        } else {
          setError('Something went wrong, please try again.');
        }
        setFormSubmitted(false);
      }
    }
  }, [createError]);

  useEffect(() => {
    if (createSuccess) {
      setTimeout(() => {
        if (followUp) {
          const formattedDueDate = moment(state.dueDate).format('YYYY-DD-MM');

          const payload: any = {
            type: 'Follow Up',
            subject: 'Follow Up',
            dueDate: moment(
              formattedDueDate +
                ' ' +
                moment(state.dueDateTime).format('h:mm A'),
              'YYYY-DD-MM h:mm A'
            ).toISOString(),
            dueDateTime: moment(
              formattedDueDate +
                ' ' +
                moment(state.dueDateTime).format('h:mm A'),
              'YYYY-DD-MM h:mm A'
            ).toISOString(),
          };

          if (!defaultData) {
            payload[selectedAssociate.type.toLowerCase()] =
              selectedAssociate['@id'];
          } else {
            payload[defaultData['@type'].toLowerCase()] = defaultData['@id'];
          }
          showModal({ formType: 'task', data: payload });
        } else {
          cancelAction();
        }
      }, 2000);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (!isFetching) {
      if (page === 1) {
        setAutoCompleteData([
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      } else {
        setAutoCompleteData([
          ...autoCompleteData,
          ...dataTransformer(contactsData),
          ...dataTransformer(loansData),
          ...dataTransformer(leadsData),
        ]);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingLeads && !isFetchingContacts && !isFetchingLoans) {
      setIsFetching(false);
    } else {
      setIsFetching(true);
    }
  }, [isFetchingContacts, isFetchingLoans, isFetchingLeads]);

  async function handleSubmit() {
    try {
      setFormSubmitted(true);
      setError(null);
      await checkForRequiredFields();
      const formattedDueDate = moment(state.dueDate).format('YYYY-DD-MM');
      const localDueDate = moment(
        formattedDueDate + ' ' + moment(state.dueDateTime).format('h:mm A'),
        'YYYY-DD-MM h:mm A'
      ).toISOString();

      const payload: any = {
        type: displayValueTask,
        subject: displayValueTask,
        dueDate: localDueDate,
        dueDateTime: localDueDate,
        isCompleted: true,
        outcome: displayOutcomeValue,
        resolution: state.description,
        referralCount: parseInt(state.numOfReferrals) || 0,
        advanced: state.advanced,
      };

      if (!defaultData) {
        payload[selectedAssociate.type.toLowerCase()] =
          selectedAssociate['@id'];
      } else {
        payload[defaultData['@type'].toLowerCase()] = defaultData['@id'];
      }

      createTask(payload);
    } catch (error) {
      setError(error.message);
      setFormSubmitted(false);
    }
  }

  async function checkForRequiredFields() {
    if (!state.type || state.type.row === 0)
      throw new Error(
        'Missing task type, please make sure all required fields are filled'
      );

    if (state.priority === null)
      throw new Error(
        'Missing priority, please make sure all required fields are filled'
      );
    if (!state.dueDate)
      throw new Error(
        'Missing due date, please make sure all required fields are filled'
      );
    if (!state.dueDateTime)
      throw new Error(
        'Missing due date time, please make sure all required fields are filled'
      );
    if (!defaultData && !selectedAssociate?.id)
      throw new Error('Please select someone to associate this task with');
  }

  const associateType =
    defaultData?.['@type'] || selectedAssociate?.type || null;
  const associateUri =
    defaultData?.['@id'] || selectedAssociate?.['@id'] || null;

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Log a Call'} />
      <FormGroup label="Select Task Type">
        <Select
          placeholder="Select Task Type"
          selectedIndex={state.type}
          onSelect={(index: any) =>
            setState((prevState) => ({ ...prevState, type: index }))
          }
          value={displayValueTask}
        >
          {TYPES.map(renderOption)}
        </Select>
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Outcome">
            <Select
              placeholder="Outcome"
              selectedIndex={state.outcome}
              onSelect={(index: any) =>
                setState((prevState) => ({ ...prevState, outcome: index }))
              }
              value={displayOutcomeValue}
            >
              {outcomes.map(renderOption)}
            </Select>
          </FormGroup>
        </View>
        {displayOutcomeValue.includes('Referrals') && (
          <View style={{ flex: 1 }}>
            <FormGroup label="Number of referrals">
              <Input
                keyboardType="numeric"
                onBlur={() => Keyboard.dismiss()}
                placeholder=""
                value={state.numOfReferrals.toString() || ''}
                onChangeText={(e) =>
                  setState((prevState) => ({ ...prevState, numOfReferrals: e }))
                }
              />
            </FormGroup>
          </View>
        )}
      </View>
      <FormGroup label="Notes" required={false}>
        <Input
          onBlur={() => Keyboard.dismiss()}
          multiline={true}
          textStyle={{ minHeight: 100 }}
          placeholder=""
          value={state.description || ''}
          onChangeText={(e) =>
            setState((prevState) => ({ ...prevState, description: e }))
          }
        />
      </FormGroup>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <FormGroup label="Due Date">
            <Datepicker
              dateService={
                new NativeDateService('en', {
                  format: 'MM/DD/YYYY',
                })
              }
              status="basic"
              style={{ flex: 1 }}
              min={new Date(new Date().setMonth(new Date().getMonth() - 1))}
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              ref={componentRef}
              date={state.dueDate}
              initialVisibleDate={new Date()}
              onSelect={(e) =>
                setState((prevState) => ({ ...prevState, dueDate: e }))
              }
            />
          </FormGroup>
        </View>
        <View style={{ flex: 1 }}>
          <FormGroup label="Select Time">
            <View style={{ flexDirection: 'row' }}>
              <RNTimePicker
                placeholder={'Select a time'}
                value={state.dueDateTime}
                onSelect={(dateTime) => {
                  setState((prevState) => ({
                    ...prevState,
                    dueDateTime: dateTime,
                  }));
                }}
              />
            </View>
          </FormGroup>
        </View>
      </View>
      {!defaultData ? (
        <FormGroup required label="Associate with">
          {autoCompleteData && (
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setAutoCompleteData([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetching}
              setQuery={setQuery}
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={undefined}
              page={page}
              setPage={setPage}
              selectedAssociate={selectedAssociate}
            />
          )}
        </FormGroup>
      ) : null}
      <FormGroup label="" required={false}>
        <CheckBox
          checked={followUp}
          onChange={(nextChecked) => setFollowUp(nextChecked)}
        >
          Create Follow Up Task
        </CheckBox>
      </FormGroup>
      <View
        style={{
          justifyContent: 'flex-start',
          marginTop: 24,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {state.loanData?.advances?.length ? (
          <FormGroup label="Advanced Option" required={false}>
            <View style={{ flex: 1, marginLeft: 8 }}>
              <CheckBox checked={state.loanData?.advances?.length > 0}>
                {state.loanData?.advances?.length > 0
                  ? state.loanData.advances[0].advanceType
                  : ''}
              </CheckBox>
            </View>
          </FormGroup>
        ) : null}
        <Button
          onPress={() => setModalVisible(true)}
          style={{}}
          disabled={
            !defaultData?.['@id'] && !selectedAssociate?.['@id']
              ? true
              : associateType === 'Loan'
              ? true
              : false
          }
          size="small"
          appearance="outline"
          status={state.scenarioCreated ? 'primary' : 'danger'}
        >
          {state.scenarioCreated === true
            ? 'Edit Scenario'
            : 'Create a Scenario'}
        </Button>
      </View>

      {!!error && (
        <View>
          <Text
            style={{
              color: 'red',
              fontFamily: 'VerbBold',
              textAlign: 'center',
              fontSize: 12,
            }}
          >
            {error}
          </Text>
        </View>
      )}
      {/* @ts-ignore */}
      <Modal
        animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
        style={{ zIndex: 999 }}
        visible={modalVisible}
        backdropStyle={{
          backgroundColor: Platform.OS === 'web' ? 'rgba(0,0,0,.5)' : '#fff',
        }}
      >
        <ModalWrapper>
          {defaultData?.['@id'] || selectedAssociate?.['@id'] ? (
            <CreateScenarioForm
              cancelAction={() => setModalVisible(false)}
              defaultData={{
                ...defaultData,
                ...selectedAssociate,
                uri: associateUri,
                type: associateType,
                loanData: state.loanData || null,
                callback: function (loanData) {
                  setState((prevState) => ({
                    ...prevState,
                    scenarioCreated: true,
                    loanData: loanData,
                    advanced: loanData?.advances?.length ? true : false,
                  }));
                },
              }}
            />
          ) : null}
        </ModalWrapper>
      </Modal>
      {!!createSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'Log Call'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
});

export default LogCallForm;
