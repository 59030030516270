import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Layout, Text } from '@ui-kitten/components';
import Footer from '../Footer';
import Header from '../Header';

import Colors from '../../../helpers/Colors';
import FormGroup from '../FormGroup';
import { FormSuccess } from '../../../components-ui';
//@ts-ignore
import GlobalSearchAutoComplete, {
  dataTransformer, //@ts-ignore
} from '../../../components-ui/GlobalSearchAutoComplete';
import {
  useGetContactsQuery,
  usePatchContactMutation,
} from '../../../services/ContactApiSlice';

function AddReferral({ cancelAction, defaultData }: any) {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [patchContact, { isSuccess }] = usePatchContactMutation();
  const [error, setError] = useState<string>('');
  const [selectedLoan, setSelectedAssociate] = useState<any>({});
  const [query, setQuery] = useState('');
  const [autoCompleteData, setAutoCompleteData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const { data: contactsData, isFetching: isFetchingContacts } =
    useGetContactsQuery('referredByContactId=false' + query);

  useEffect(() => {
    if (!isFetchingContacts) {
      setAutoCompleteData([...dataTransformer(contactsData)]);
    }
  }, [isFetchingContacts]);

  useEffect(() => {
    if (isSuccess) {
      handleSuccess();
    }
  }, [isSuccess]);

  async function handleSuccess() {
    if (defaultData?.callback) {
      await defaultData.callback();
    }
    setTimeout(() => {
      cancelAction();
    }, 2000);
  }

  async function handleSubmit(): Promise<void> {
    try {
      if (!selectedLoan?.id) {
        setError('Please select a loan');
        return;
      }
      if (selectedLoan?.id) {
        setError('');
        patchContact({
          id: selectedLoan.id.split('/')[2],
          referredBy: defaultData['@id'],
          referralType: defaultData.type,
        });
      }
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <Layout style={{ flex: 1, position: 'relative' }}>
      <Header heading={'Add Referral'} />
      <View style={{ flexDirection: 'column', flex: 1, minHeight: 350 }}>
        <View>
          <FormGroup label="Select a contact" required={true}>
            <GlobalSearchAutoComplete
              clearAutoCompleteData={() => setSelectedAssociate([])}
              autoCompleteData={autoCompleteData}
              isFetching={isFetchingContacts}
              setQuery={setQuery}
              listDirection="down"
              handleOnChange={(e) => setSelectedAssociate(e)}
              defaultValue={null}
              page={page}
              setPage={setPage}
            />
          </FormGroup>
        </View>
      </View>
      {error ? (
        <Text category="label" style={{ textAlign: 'center', color: 'red' }}>
          {error}
        </Text>
      ) : null}
      {!!isSuccess && <FormSuccess />}
      <Footer
        cancelAction={cancelAction}
        submitAction={handleSubmit}
        disabled={formSubmitted}
        submitText={'SAVE'}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flex: 1,
    width: 768,
  },
  card: {
    flex: 1,
    margin: 0,
    borderColor: Colors.darkBlue,
    position: 'relative',
  },
  radio: {
    margin: 2,
  },
});

export default AddReferral;
