import { Button, Divider, Layout, Popover, Text } from '@ui-kitten/components';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  View,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { skipToken } from '@reduxjs/toolkit/query';
// @ts-ignore
import { formatPhoneNumber } from '../../helpers/dataTransformerHelper';
import { useGetContactQuery } from '../../services/ContactApiSlice';
//@ts-ignore
import { useGetAllBorrowersQuery } from '../../services/BorrowerApiSlice';
import { useGetAllBorrowerPairsQuery } from '../../services/BorrowerPairsApiSlice';
import { useGetCreditRequestsQuery } from '../../services/CreditRequestApiSlice';
import { useGetAllUserNamesQuery } from '../../services/UserSlice';
import { format, parse, parseISO } from 'date-fns';
import MetadataCard from './MetadataCard';
import { useDispatch } from 'react-redux';
import { showModal as realShow } from '../../services/FormSlice';

function LoanDetailContactColumn({ loanData, contactData, refetch }) {
  return (
    <Layout
      level="2"
      style={{
        flex: 3,
        minWidth: Platform.OS === 'web' ? 465 : Dimensions.get('screen').width,
        borderRightWidth: 1,
        borderRightColor: '#aaa',
        paddingBottom: Platform.OS === 'web' ? 0 : 64,
      }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingHorizontal: 24, paddingTop: 24 }}
      >
        <View style={{ flex: 1 }}>
          <BorrowerCard loanData={loanData} />
          <AdditionalInfoCard
            contactData={contactData}
            refetch={refetch}
            loanData={loanData}
          />
          <MetadataCard loanData={loanData} />
        </View>
      </ScrollView>
    </Layout>
  );
}

function BorrowerCard({ loanData }) {
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const [isStillFetching, setIsStillFetching] = useState<boolean>(false);
  const [visible, setVisible] = useState({});
  const { data: borrowerPairs, isFetching: fetchingPairs } =
    useGetAllBorrowerPairsQuery(loanData ? { loanId: loanData.id } : skipToken);

  const { data: borrowerData, isFetching: fetchingBorrowerData } =
    useGetAllBorrowersQuery(loanData ? { loanId: loanData.id } : skipToken);

  const { data: creditRequests, isFetching: fetchingCreditRequests } =
    useGetCreditRequestsQuery(loanData ? { loanId: loanData.id } : skipToken);

  let timeout;
  useEffect(() => {
    clearTimeout(timeout);
    if (fetchingPairs || fetchingBorrowerData) {
      setIsStillFetching(true);
    }
    if (!fetchingPairs && !fetchingBorrowerData) {
      timeout = setTimeout(() => {
        setIsStillFetching(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [fetchingPairs, fetchingBorrowerData]);

  return (
    <Layout style={styles.cardWrapper}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 0,
        }}
      >
        <Text style={styles.cardHeader} category="h6">
          Borrowers
        </Text>
        <Pressable
          disabled={!loanData || fetchingBorrowerData}
          onPress={() => {
            showModal({
              formType: 'borrowerPair',
              data: { loanData },
            });
          }}
          style={{ position: 'absolute', right: 16 }}
        >
          {(loanData.contact || loanData.lead) && !fetchingBorrowerData ? (
            <Text category="label" style={{ color: 'white' }}>
              Manage
            </Text>
          ) : null}
        </Pressable>
      </View>
      <ScrollView style={{ flex: 1, minHeight: 150, maxHeight: 250 }}>
        {borrowerData &&
        !isStillFetching &&
        !fetchingCreditRequests &&
        borrowerPairs?.length ? (
          borrowerPairs.map((pair, index) => {
            const pairs = [];
            if (pair.borrower) pairs.push(pair.borrower);
            if (pair.coBorrower) pairs.push(pair.coBorrower);

            return (
              <View key={`pair-${index}`} style={{ position: 'relative' }}>
                <Layout
                  level="4"
                  style={{
                    paddingVertical: 4,
                    paddingHorizontal: 8,
                  }}
                >
                  <Text
                    category="label"
                    style={{ fontSize: 14, textAlign: 'center' }}
                  >
                    Pair {pair.pairNumber}
                  </Text>
                </Layout>
                <View style={{ flexDirection: 'row' }}>
                  {pairs.map((borrower, pairIndex) => {
                    if (!borrower) return null;
                    const currentBorrower = borrowerData.find(
                      (item) => item['@id'] === borrower
                    );
                    const creditDocument = creditRequests?.find(
                      (item) =>
                        item.borrower1 === currentBorrower['@id'] ||
                        item.borrower2 === currentBorrower['@id']
                    );
                    const creditDocumentId = creditDocument?.['@id'];

                    return (
                      <View
                        key={currentBorrower.firstName + pairIndex}
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          paddingVertical: 2,
                          marginBottom: 2,
                          borderRightWidth:
                            pairs.length - 1 === pairIndex ? 0 : 1,
                          borderRightColor: '#eee',
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            paddingVertical: 12,
                            paddingLeft: 24,
                          }}
                        >
                          <Popover
                            visible={visible[`${index}-${pairIndex}`]}
                            anchor={() => (
                              <Pressable
                                onPress={() =>
                                  setVisible({
                                    [`${index}-${pairIndex}`]: true,
                                  })
                                }
                              >
                                <Text style={{ fontFamily: 'VerbBold' }}>
                                  {currentBorrower.firstName || ''}{' '}
                                  {currentBorrower.middleName || ''}{' '}
                                  {currentBorrower.lastName || ''}
                                </Text>
                                {currentBorrower?.creditScore ? (
                                  <Text category="label">
                                    FICO: {currentBorrower.creditScore}
                                  </Text>
                                ) : null}
                              </Pressable>
                            )}
                            onBackdropPress={() =>
                              setVisible({
                                [`${index}-${pairIndex}`]: false,
                              })
                            }
                          >
                            <Layout
                              style={{
                                position: 'relative',
                                paddingHorizontal: 32,
                                paddingTop: 42,
                                paddingBottom: 32,
                                borderWidth: 2,
                                borderColor: '#eee',
                                minWidth: 300,
                                overflow: 'hidden',
                              }}
                            >
                              <Button
                                onPress={() => {
                                  setVisible({
                                    [`${index}-${pairIndex}`]: false,
                                  });
                                  showModal({
                                    formType: 'createBorrower',
                                    data: {
                                      loanIRI: loanData['@id'],
                                      loanId: loanData.id,
                                      borrowerData: currentBorrower,
                                    },
                                  });
                                }}
                                size="small"
                                appearance="ghost"
                                style={{
                                  position: 'absolute',
                                  top: 6,
                                  right: 6,
                                }}
                              >
                                <Text status="danger" category="label">
                                  Edit
                                </Text>
                              </Button>
                              <Text
                                style={{
                                  fontFamily: 'VerbBold',
                                  textAlign: 'center',
                                  fontSize: 17,
                                }}
                              >
                                {currentBorrower.firstName || ''}{' '}
                                {currentBorrower.middleName || ''}{' '}
                                {currentBorrower.lastName || ''}
                              </Text>
                              <Text
                                status="info"
                                category="label"
                                style={{ marginTop: 16 }}
                              >
                                Contact Info
                              </Text>
                              <View
                                style={{
                                  marginBottom: 8,
                                  borderBottomWidth: 1,
                                  borderBottomColor: '#eee',
                                }}
                              />
                              {currentBorrower.email ? (
                                <Text>{currentBorrower.email}</Text>
                              ) : null}
                              {currentBorrower.homePhone ? (
                                <Text>
                                  {formatPhoneNumber(currentBorrower.homePhone)}
                                </Text>
                              ) : null}

                              {currentBorrower.borrowerAddresses?.[0] ? (
                                <>
                                  <Text
                                    status="info"
                                    category="label"
                                    style={{ marginTop: 16 }}
                                  >
                                    Address
                                  </Text>
                                  <View
                                    style={{
                                      marginBottom: 4,
                                      paddingBottom: 4,
                                      borderBottomWidth: 1,
                                      borderBottomColor: '#eee',
                                    }}
                                  />
                                  <Text>
                                    {currentBorrower.borrowerAddresses[0]
                                      .houseNumber || ''}{' '}
                                    {currentBorrower.borrowerAddresses[0]
                                      .street || ''}{' '}
                                    {currentBorrower.borrowerAddresses[0]
                                      .apartment
                                      ? `apt ${currentBorrower.borrowerAddresses[0].apartment}`
                                      : ''}
                                  </Text>
                                  <Text>
                                    {currentBorrower.borrowerAddresses[0]
                                      .city || ''}{' '}
                                    {currentBorrower.borrowerAddresses[0]
                                      .state || ''}{' '}
                                    {currentBorrower.borrowerAddresses[0]
                                      .zipCode || ''}
                                  </Text>
                                </>
                              ) : null}
                              <Text
                                status="info"
                                category="label"
                                style={{ marginTop: 16 }}
                              >
                                Credit Info
                              </Text>
                              <View
                                style={{
                                  marginBottom: 4,
                                  paddingBottom: 4,
                                  borderBottomWidth: 1,
                                  borderBottomColor: '#eee',
                                }}
                              />
                              <Text style={{ fontFamily: 'VerbBold' }}>
                                Experian: {currentBorrower.experianScore || ''}
                              </Text>
                              <Text style={{ fontFamily: 'VerbBold' }}>
                                Transunion:{' '}
                                {currentBorrower.transUnionScore || ''}
                              </Text>
                              <Text style={{ fontFamily: 'VerbBold' }}>
                                Equifax: {currentBorrower.equifaxScore || ''}
                              </Text>
                              {creditDocumentId ? (
                                <View style={{ marginTop: 16 }}>
                                  <Text
                                    style={{ textAlign: 'center' }}
                                    appearance="hint"
                                    category="label"
                                  >
                                    Credit Reference:{' '}
                                    {creditDocument.creditReportIdentifier}
                                  </Text>
                                  <Button
                                    onPress={() =>
                                      showModal({
                                        formType: 'PreviewPdf',
                                        data: {
                                          creditDocumentId: creditDocument.id,
                                          loanId: loanData.id,
                                        },
                                      })
                                    }
                                    size="tiny"
                                    status="info"
                                  >
                                    View Credit Report
                                  </Button>
                                </View>
                              ) : null}
                            </Layout>
                          </Popover>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })
        ) : (
          <ActivityIndicator style={{ marginTop: 16 }} size="large" />
        )}
      </ScrollView>
    </Layout>
  );
}

function AdditionalInfoCard({ contactData, refetch, loanData }) {
  const dispatch = useDispatch();
  const showModal = (payload) => dispatch(realShow(payload));
  const { data: referredByData } = useGetContactQuery(
    contactData?.referredByContactId || skipToken
  );
  return (
    <Layout
      style={[
        styles.shadow,
        {
          marginBottom: 24,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          overflow: 'hidden',
          flex: 1,
        },
      ]}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <Text style={styles.cardHeader} category="h6">
          Additional Info
        </Text>
        <Pressable
          onPress={() => {
            showModal({
              formType: 'editLead',
              data: {
                fields: ['additionalInfo'],
                data: { ...contactData, referred: { ...referredByData } },
                leadId: contactData.id,
                callback: refetch,
              },
            });
          }}
          style={{ position: 'absolute', right: 16 }}
        >
          {loanData.contact ? (
            <Text category="label" style={{ color: 'white' }}>
              Edit
            </Text>
          ) : null}
        </Pressable>
      </View>
      <ScrollView
        style={{ flex: 1, minHeight: 220 }}
        contentContainerStyle={{ paddingHorizontal: 16 }}
      >
        <Text category="label" style={{ fontSize: 15 }}>
          {contactData?.additionalInfo || ''}
        </Text>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  icon: {
    height: 14,
    width: 14,
    marginHorizontal: 4,
    borderRadius: 32,
    padding: 4,
  },
  divider: {
    height: 2,
    backgroundColor: 'white',
    width: '100%',
    marginVertical: 32,
  },
  label: {
    fontSize: 15,
    marginRight: 8,
  },
  value: {
    fontSize: 15,
    flexShrink: 1,
    fontFamily: 'VerbBold',
  },
  row: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 16,
    paddingHorizontal: 16,
    flex: 1,
  },
  cardWrapper: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginBottom: 24,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    overflow: 'hidden',
  },
  cardHeader: {
    paddingVertical: 6,
    backgroundColor: '#007aff',
    color: 'white',
    width: '100%',
    flex: 1,
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
});

export default LoanDetailContactColumn;
