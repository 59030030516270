import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
} from 'victory-native';
import { Platform, View } from 'react-native';
import Colors from '../../../src/helpers/Colors';
import { useTheme, Text } from '@ui-kitten/components'

function AppsAndLoansChart({
  prevAppTrend,
  currAppTrend,
  prevLoanTrend,
  currLoanTrend,
}) {
  const theme = useTheme()
  return (
    <>
      <VictoryChart
        horizontal={Platform.OS !== 'web'}
        domainPadding={{ x: 50 }}
        width={Platform.OS === 'web' ? 1920 : 400}
        height={Platform.OS === 'web' ? 500 : 450}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={[
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]}
          style={{ tickLabels: { fontFamily: 'VerbBold' } }}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={(x) => {
            if (String(x).length >= 6) {
              return `$${x / 1000000}M`;
            }
            return `$${x / 1000}K`;
          }}
          style={{ tickLabels: { fontFamily: 'VerbBold' } }}
        />
        <VictoryGroup
          offset={Platform.OS === 'web' ? 15 : 10}
          style={{ data: { width: 15 } }}
          colorScale={[theme['color-info-500'], theme['color-warning-500']]}
        >
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            barWidth={Platform.OS === 'web' ? 48 : 14}
            data={[
              { x: 'Jan', y: prevLoanTrend.Jan.loanAmount },
              { x: 'Feb', y: prevLoanTrend.Feb.loanAmount },
              { x: 'Mar', y: prevLoanTrend.Mar.loanAmount },
              { x: 'Apr', y: prevLoanTrend.Apr.loanAmount },
              { x: 'May', y: prevLoanTrend.May.loanAmount },
              { x: 'Jun', y: prevLoanTrend.Jun.loanAmount },
              { x: 'Jul', y: prevLoanTrend.Jul.loanAmount },
              { x: 'Aug', y: prevLoanTrend.Aug.loanAmount },
              { x: 'Sep', y: prevLoanTrend.Sep.loanAmount },
              { x: 'Oct', y: prevLoanTrend.Oct.loanAmount },
              { x: 'Nov', y: prevLoanTrend.Nov.loanAmount },
              { x: 'Dec', y: prevLoanTrend.Dec.loanAmount },
            ]}
          />
          <VictoryBar
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            barWidth={Platform.OS === 'web' ? 48 : 14}
            data={[
              { x: 'Jan', y: currLoanTrend.Jan.loanAmount },
              { x: 'Feb', y: currLoanTrend.Feb.loanAmount },
              { x: 'Mar', y: currLoanTrend.Mar.loanAmount },
              { x: 'Apr', y: currLoanTrend.Apr.loanAmount },
              { x: 'May', y: currLoanTrend.May.loanAmount },
              { x: 'Jun', y: currLoanTrend.Jun.loanAmount },
              { x: 'Jul', y: currLoanTrend.Jul.loanAmount },
              { x: 'Aug', y: currLoanTrend.Aug.loanAmount },
              { x: 'Sep', y: currLoanTrend.Sep.loanAmount },
              { x: 'Oct', y: currLoanTrend.Oct.loanAmount },
              { x: 'Nov', y: currLoanTrend.Nov.loanAmount },
              { x: 'Dec', y: currLoanTrend.Dec.loanAmount },
            ]}
          />
        </VictoryGroup>
        <VictoryGroup offset={0} style={{ data: { width: 15 } }}>
          <VictoryLine
            color="#9BABB7"
            animate={{
              duration: 2000,
              onLoad: { duration: 1500 },
            }}
            data={[
              { x: 'Jan', y: prevAppTrend.Jan.loanAmount },
              { x: 'Feb', y: prevAppTrend.Feb.loanAmount },
              { x: 'Mar', y: prevAppTrend.Mar.loanAmount },
              { x: 'Apr', y: prevAppTrend.Apr.loanAmount },
              { x: 'May', y: prevAppTrend.May.loanAmount },
              { x: 'Jun', y: prevAppTrend.Jun.loanAmount },
              { x: 'Jul', y: prevAppTrend.Jul.loanAmount },
              { x: 'Aug', y: prevAppTrend.Aug.loanAmount },
              { x: 'Sep', y: prevAppTrend.Sep.loanAmount },
              { x: 'Oct', y: prevAppTrend.Oct.loanAmount },
              { x: 'Nov', y: prevAppTrend.Nov.loanAmount },
              { x: 'Dec', y: prevAppTrend.Dec.loanAmount },
            ]}
          />
          <VictoryScatter
            color="#9BABB7"
            data={[
              { x: 'Jan', y: prevAppTrend.Jan.loanAmount },
              { x: 'Feb', y: prevAppTrend.Feb.loanAmount },
              { x: 'Mar', y: prevAppTrend.Mar.loanAmount },
              { x: 'Apr', y: prevAppTrend.Apr.loanAmount },
              { x: 'May', y: prevAppTrend.May.loanAmount },
              { x: 'Jun', y: prevAppTrend.Jun.loanAmount },
              { x: 'Jul', y: prevAppTrend.Jul.loanAmount },
              { x: 'Aug', y: prevAppTrend.Aug.loanAmount },
              { x: 'Sep', y: prevAppTrend.Sep.loanAmount },
              { x: 'Oct', y: prevAppTrend.Oct.loanAmount },
              { x: 'Nov', y: prevAppTrend.Nov.loanAmount },
              { x: 'Dec', y: prevAppTrend.Dec.loanAmount },
            ]}
          />
        </VictoryGroup>
        <VictoryGroup offset={0} style={{ data: { width: 15 } }}>
          <VictoryLine
            color="#224973"
            animate={{
              duration: 2000,
              onLoad: { duration: 1500 },
            }}
            data={[
              { x: 'Jan', y: currAppTrend.Jan.loanAmount },
              { x: 'Feb', y: currAppTrend.Feb.loanAmount },
              { x: 'Mar', y: currAppTrend.Mar.loanAmount },
              { x: 'Apr', y: currAppTrend.Apr.loanAmount },
              { x: 'May', y: currAppTrend.May.loanAmount },
              { x: 'Jun', y: currAppTrend.Jun.loanAmount },
              { x: 'Jul', y: currAppTrend.Jul.loanAmount },
              { x: 'Aug', y: currAppTrend.Aug.loanAmount },
              { x: 'Sep', y: currAppTrend.Sep.loanAmount },
              { x: 'Oct', y: currAppTrend.Oct.loanAmount },
              { x: 'Nov', y: currAppTrend.Nov.loanAmount },
              { x: 'Dec', y: currAppTrend.Dec.loanAmount },
            ]}
          />
          <VictoryScatter
            color="#224973"
            data={[
              { x: 'Jan', y: currAppTrend.Jan.loanAmount },
              { x: 'Feb', y: currAppTrend.Feb.loanAmount },
              { x: 'Mar', y: currAppTrend.Mar.loanAmount },
              { x: 'Apr', y: currAppTrend.Apr.loanAmount },
              { x: 'May', y: currAppTrend.May.loanAmount },
              { x: 'Jun', y: currAppTrend.Jun.loanAmount },
              { x: 'Jul', y: currAppTrend.Jul.loanAmount },
              { x: 'Aug', y: currAppTrend.Aug.loanAmount },
              { x: 'Sep', y: currAppTrend.Sep.loanAmount },
              { x: 'Oct', y: currAppTrend.Oct.loanAmount },
              { x: 'Nov', y: currAppTrend.Nov.loanAmount },
              { x: 'Dec', y: currAppTrend.Dec.loanAmount },
            ]}
          />
        </VictoryGroup>
      </VictoryChart>
      <View
        style={{
          flexDirection: 'column',
          alignSelf: 'center',
          borderWidth: 1,
          borderColor: Colors.custGray,
          borderRadius: 8,
          padding: 10,
          marginRight: 10,
          marginLeft: -25,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
          }}
        >
           <Text style={{fontFamily: 'VerbBold', fontSize: 18}} category='label' status='info'>{prevLoanTrend.Jan.year}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
          }}
        >
           <Text style={{fontFamily: 'VerbBold', fontSize: 18}} category='label' status='warning'>{currLoanTrend.Jan.year}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 2,
          }}
        >
          <Text  style={{fontFamily: 'VerbBold', fontSize: 18}}  category='danger'>Apps</Text>
        </View>
      </View>
    </>
  );
}

export default AppsAndLoansChart;
